import styles from '../ChoosingDates/ChoosingDates.styles';
import { Box, Typography } from '@mui/material';
import deskReservationStyles from 'pages/DeskReservation/DeskReservation.styles';
import { FC, useContext } from 'react';
import { CallToActionProps } from './CallToAction.types';
import DisplayWrapper from 'components/DisplayWrapper';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useBreakPoints from 'hooks/useBreakpoints';

const CallToActionComponent: FC<CallToActionProps> = ({
  image,
  alt,
  labelText,
  subText,
}) => {
  const { activeDesk } = useContext(DeskContext);
  const { isMobile } = useBreakPoints();

  if (activeDesk !== null || isMobile) return <></>;

  return (
    <DisplayWrapper>
      <Box sx={styles.content}>
        <Box sx={styles.buttonContainer}>
          <Box>
            <img src={image} style={deskReservationStyles.image} alt={alt} />
          </Box>
        </Box>
        <Typography variant={'h1'} align="center">
          {labelText}
        </Typography>
        {subText ? (
          <Typography variant="body1" sx={styles.text}>
            {subText}
          </Typography>
        ) : null}
      </Box>
    </DisplayWrapper>
  );
};

export default CallToActionComponent;
