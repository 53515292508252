import { FC, useContext, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import plLocale from '@fullcalendar/core/locales/pl';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.scss';
import { getTitle, getWeekDay } from './Calendar.helpers';
import DisplayWrapper from 'components/DisplayWrapper';
import { CalendarProps } from './Calendar.types';
import RoomReservationContext from 'contexts/RoomReservation/RoomReservationContext';
import useRoomCalendarEvents from 'hooks/useRoomCalendarEvents/useRoomCalendarEvents';
import { getFutureDatesInMonthsRange } from 'utils/date';
import { CALENDAR_SELECTABLE_MONTHS } from './const';

export const Calendar: FC<CalendarProps> = ({ onSelect, reservedRooms }) => {
  const { setMonth } = useContext(RoomReservationContext);
  const { events, calendarRef, onNext, onPrev } = useRoomCalendarEvents({
    reservedRooms,
  });
  const dates = useMemo(
    () => getFutureDatesInMonthsRange(CALENDAR_SELECTABLE_MONTHS),
    []
  );

  return (
    <DisplayWrapper>
      <FullCalendar
        ref={calendarRef}
        longPressDelay={0}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          center: 'title',
          left: 'prev',
          right: 'next',
        }}
        selectable
        selectMirror
        moreLinkText=""
        firstDay={1}
        titleFormat={(data) => getTitle(data)}
        locales={[plLocale]}
        select={(value) => onSelect(value.start)}
        locale="pl"
        hiddenDays={[0, 6]}
        events={events}
        selectAllow={(e) =>
          !!(e.startStr >= dates[0] && e.startStr <= dates[dates.length - 1])
        }
        customButtons={{
          prev: {
            click: () => onPrev(setMonth),
          },
          next: {
            click: () => onNext(setMonth),
          },
        }}
        dayHeaderFormat={(data) => getWeekDay(data)}
        aspectRatio={1.2}
      />
    </DisplayWrapper>
  );
};

export default Calendar;
