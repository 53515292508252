import { KudosType } from 'api/types/kudos';
import { COLORS } from 'theme/index';

const { silver, opaqueWhite, black, blue, error } = COLORS;

const styles = {
  root: (changeOnHover: boolean) => ({
    'flex': 1,
    '&:hover': changeOnHover && {
      boxShadow: `0 0 0 0.25rem ${blue[300]}`,
      cursor: 'pointer',
    },
  }),
  wrapper: {
    position: 'relative',
  },
  input: {
    fontFamily: 'inherit',
    height: '100%',
  },
  htmlInput: (lineSize: number, isError?: boolean, isDisabled?: boolean) => ({
    'height': '100%',
    'fontFamilly': 'inherit',
    'color': black,
    'padding': '0 1rem',
    'lineHeight': `${lineSize}px`,

    // Styles for imitating note as background of input
    'backgroundAttachment': 'local',
    'backgroundImage': `repeating-linear-gradient(to bottom, ${opaqueWhite}, ${opaqueWhite} ${
      lineSize - 1
    }px, ${isError ? error : silver}, ${
      isError ? error : silver
    } ${lineSize}px)`,
    '&:disabled': {
      color: black,
      WebkitTextFillColor: black,
      opacity: 1,
    },
    '&:focus': isDisabled && {
      caretColor: 'transparent',
      cursor: 'pointer',
    },
    '&:hover': isDisabled && {
      caretColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  imageWrapper: {
    position: 'relative',
  },
  image: (type: KudosType) => ({
    position: 'absolute',
    top: 10,
    right: 15,
    zIndex: 100,
    width: '4rem',
    height: type === KudosType.congratulations ? '6rem' : '4rem',
  }),
  cardContent: (height?: string) => ({
    height: height ?? '12rem',
    backgroundColor: opaqueWhite,
    paddingX: '2rem',
  }),
};

export default styles;
