import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import { UserDesksReservations } from 'api/types/deskReservation';
import { RoomReservation } from 'api/types/roomReservation';
import { COLORS } from 'theme/Theme.const';

export const getWeekDay = (data: VerboseFormattingArg) => {
  const day = data.date.marker.toLocaleString('default', {
    weekday: 'short',
  });

  return day.charAt(0).toUpperCase() + day.slice(1).replace('.', '');
};

export const getTitle = (data: VerboseFormattingArg) => {
  const title = data.date.marker.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return title.charAt(0).toUpperCase() + title.slice(1).replace('.', '');
};

export const generateEvent = (
  date: string,
  currentUser: number,
  reservation?: RoomReservation
) => {
  const isCurrentUserReservation = reservation?.booker === currentUser;
  const reservationColor = isCurrentUserReservation
    ? COLORS.blue[300]
    : COLORS.roomReservationsLegendColors.darkOrange;

  return {
    start: date,
    end: date,
    display: 'background',
    backgroundColor: isCurrentUserReservation
      ? reservationColor
      : COLORS.roomReservationsLegendColors.green,
  };
};

export const getRoomReservationBackgroundEvent = (
  eventDate: string,
  currentUserId?: number,
  roomReservation?: RoomReservation,
  deskReservation?: UserDesksReservations
) => {
  const { date, booker } = { ...roomReservation };
  const { date: deskReservationDate } = { ...deskReservation };
  const isCurrentUser = booker === currentUserId;
  const isDateInReservations = date === eventDate;
  const isCurrentUserReservation = isDateInReservations && isCurrentUser;
  const isOtherUserReservation = isDateInReservations && !isCurrentUser;
  const isDeskReservation = deskReservationDate === eventDate;

  let dateCellColor;
  switch (true) {
    case isCurrentUserReservation:
      dateCellColor = COLORS.blue[300];
      break;
    case isOtherUserReservation:
      dateCellColor = COLORS.roomReservationsLegendColors.darkOrange;
      break;
    case isDeskReservation:
      dateCellColor = COLORS.roomReservationsLegendColors.lightOrange;
      break;
    default:
      dateCellColor = COLORS.roomReservationsLegendColors.green;
  }

  return {
    start: eventDate,
    end: eventDate,
    display: 'background',
    backgroundColor: dateCellColor,
  };
};

export const getDeskTitle = (
  numberOfReservedDesks: number,
  isMobile: boolean
) => {
  if (isMobile) {
    return numberOfReservedDesks;
  }

  return numberOfReservedDesks === 1
    ? `${numberOfReservedDesks} biurko`
    : numberOfReservedDesks > 4
      ? `${numberOfReservedDesks} biurek`
      : `${numberOfReservedDesks} biurka`;
};

export const getDeskReservationEvent = (
  eventDate: string,
  isMobile: boolean,
  numberOfReservedDesks?: number
) => {
  if (numberOfReservedDesks) {
    const text = getDeskTitle(numberOfReservedDesks, isMobile);

    return {
      title: `${text}`,
      start: eventDate,
      end: eventDate,
      backgroundColor: COLORS.black,
    };
  }

  return undefined;
};
