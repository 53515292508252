import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    minHeight: '6.875rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.appBar,
  },
};

export default styles;
