import { FC } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import enLocale from 'date-fns/locale/en-GB';
import InputWrapper from '../InputWrapper';
import { DatePickerInputProps } from './DatePickerInput.types';

const DatePickerInput: FC<DatePickerInputProps> = ({
  name,
  fullWidth = false,
  wrapperSx = {},
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <LocalizationProvider adapterLocale={enLocale} dateAdapter={AdapterDateFns}>
      <InputWrapper sx={wrapperSx} fullWidth={fullWidth} name={name}>
        <Controller
          control={control}
          name={name}
          render={({
            formState: { errors },
            field: { ref, onChange, value, onBlur },
          }) => (
            <DatePicker
              slotProps={{
                textField: {
                  error: !!get(errors, name),
                },
              }}
              format="dd-MM-yyyy"
              inputRef={ref}
              onChange={onChange}
              value={value || null}
              onClose={onBlur}
              {...rest}
            />
          )}
        />
      </InputWrapper>
    </LocalizationProvider>
  );
};

export default DatePickerInput;
