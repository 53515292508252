import { z } from 'zod';
import { errorMessages, MIN_DATE } from 'common/consts/validation';
import { isBefore } from 'date-fns';
import { SocialSecurityBenefitType } from 'api/types/socialSecurityBenefits';

const socialSecurityBenefitFormValidator = z
  .object({
    dateFrom: z
      .date({ invalid_type_error: errorMessages.required })
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
    dateTo: z
      .date()
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
    type: z.nativeEnum(SocialSecurityBenefitType),
    addInfo: z.string().optional(),
  })
  .refine(({ dateFrom, dateTo }) => !isBefore(dateTo, dateFrom), {
    message: errorMessages.invalidRange,
    path: ['dateTo'],
  });

export default socialSecurityBenefitFormValidator;
