import { useMutation } from '@tanstack/react-query';
import { peopleApiDelete, peopleApiPatch, peopleApiPost } from 'api/axios';

import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import queryClient from 'api/queryClient';
import {
  MutateUserHolidayPoolDeleteProps,
  MutateUserHolidayPoolPatchProps,
  MutateUserHolidayPoolPostProps,
} from './useHolidayPools.types';

export const useMutateUserHolidayPool = () => {
  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ holidayPool }: MutateUserHolidayPoolPatchProps) =>
      peopleApiPatch(
        `${PEOPLE_API_URLS.holidayPool}${holidayPool.id}/`,
        holidayPool
      ),
    onSuccess: () => {
      enqueueSnackbar('Holiday pool successfully changed', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holidayPool],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot edit holiday pool', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ holidayPoolId }: MutateUserHolidayPoolDeleteProps) =>
      peopleApiDelete(`${PEOPLE_API_URLS.holidayPool}${holidayPoolId}/`),
    onSuccess: () => {
      enqueueSnackbar('Holiday pool successfully deleted', {
        type: SnackbarType.info,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holidayPool],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete holiday pool', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ holidayPool }: MutateUserHolidayPoolPostProps) =>
      peopleApiPost(PEOPLE_API_URLS.holidayPool, holidayPool),
    onSuccess: () => {
      enqueueSnackbar('Holiday pool successfully added', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holidayPool],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add new holiday pool', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    holidayPoolPatchMutate: patchMutate,
    holidayPoolDeleteMutate: deleteMutate,
    holidayPoolPostMutate: postMutate,
  };
};

export default useMutateUserHolidayPool;
