import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';

import PageContent from 'components/PageContent/PageContent';
import DisplayWrapper from 'components/DisplayWrapper/DisplayWrapper';
import styles from './MyKudos.styles';
import useKudos from 'hooks/useKudos/useKudos';
import Carousel from 'react-material-ui-carousel';
import { mapKudosToPages } from './MyKudos.helpers';
import {
  CAROUSEL_DESKTOP_HEIGHT,
  CAROUSEL_MOBILE_HEIGHT,
  CAROUSEL_TABLET_HEIGHT,
  KUDOS_PER_PAGE,
} from './MyKudos.const';
import KudosCard from 'pages/Kudos/components/KudosCard';
import { Kudos } from 'api/types/kudos';
import ModalContext, { KudosModalType } from 'contexts/Modal/ModalContext';
import useBreakPoints from 'hooks/useBreakpoints';
import { RouterPaths } from 'src/router/types';
import { useLocation } from 'react-router-dom';
import SentKudosContext from 'contexts/SentKudos/SentKudosContext';
import EditKudosModal from 'pages/MyKudos/EditKudosModal/EditKudosModal';
import PreviewKudosModal from 'pages/MyKudos/PreviewKudosModal';

const MyKudos = memo(() => {
  const { isMobile, isTablet } = useBreakPoints();
  const { openModal } = useContext(ModalContext);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();

  const { isSentKudosView, setIsSentKudosView } = useContext(SentKudosContext);

  useEffect(() => {
    location.pathname === RouterPaths.sentKudos
      ? setIsSentKudosView(true)
      : setIsSentKudosView(false);
  }, [setIsSentKudosView, location.pathname]);

  const { data, isLoading } = useKudos({ pageNumber, isSentKudosView });

  const title = isSentKudosView
    ? 'Wysłane kudosy oczekujące na opublikowanie'
    : 'Otrzymane kudosy';

  const [selected, setSelected] = useState<Kudos>();

  const pages = useMemo(
    () => mapKudosToPages(data?.result ?? [], KUDOS_PER_PAGE),
    [data]
  );

  const pageCount = useMemo(() => data?.pageCount, [data]);

  const carouselHeight = useMemo(
    () =>
      isMobile
        ? CAROUSEL_MOBILE_HEIGHT
        : isTablet
          ? CAROUSEL_TABLET_HEIGHT
          : CAROUSEL_DESKTOP_HEIGHT,
    [isMobile, isTablet]
  );

  return (
    <PageContent isLoading={isLoading}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="extra">{title}</Typography>
          <Carousel
            sx={styles.carousel}
            indicators={false}
            animation="slide"
            duration={700}
            autoPlay={false}
            height={carouselHeight}
            fullHeightHover
            navButtonsAlwaysInvisible
          >
            {pages.map((page, i) => (
              <Box key={`page-${i}`} sx={styles.page}>
                {page.map((kudos) => (
                  <KudosCard
                    key={`kudos-${kudos.id}`}
                    type={kudos.kudosType}
                    value={kudos.description}
                    user={kudos.user}
                    onClickHandler={() => {
                      setSelected(kudos);
                      isSentKudosView
                        ? openModal(KudosModalType.EditKudos)
                        : openModal(KudosModalType.PreviewKudos);
                    }}
                    disabledInput
                  />
                ))}
              </Box>
            ))}
          </Carousel>
          <Pagination
            count={pageCount}
            page={pageNumber}
            onChange={(_, page) => {
              setPageNumber(page);
            }}
            size="large"
          />
        </Box>
        {isSentKudosView ? (
          <EditKudosModal kudos={selected as Kudos} />
        ) : (
          <PreviewKudosModal kudos={selected} setKudos={setSelected} />
        )}
      </DisplayWrapper>
    </PageContent>
  );
});

MyKudos.displayName = 'MyKudos';

export default MyKudos;
