import {
  Certificates,
  Educations,
  ProgrammingLanguages,
  Projects,
  Skills,
  WorkerSummary,
} from 'pages/Profile/components';
import Courses from 'pages/Profile/components/Courses/Courses';
import { SkillsProps } from 'pages/Profile/components/Skills/Skills.types';
import { FC } from 'react';

import { OfferBodyProps } from './OfferBody.types';

const OfferBody: FC<OfferBodyProps> = ({ worker, isLoading }) => {
  const { skills, projectSet, educationSet, certificateSet, courseSet } =
    worker?.details || {};

  const commonProps = {
    hideEmpty: true,
    isReadonly: true,
  };

  const skillsProps: SkillsProps = {
    ...commonProps,
    isLoading,
    skills,
  };

  return (
    <>
      <WorkerSummary
        title={worker?.suggestedPosition.name}
        workerDetails={worker?.details}
        hideEmpty
      />
      <ProgrammingLanguages {...skillsProps} />
      <Skills {...skillsProps} />
      <Projects projects={projectSet} {...commonProps} />
      <Educations educations={educationSet} {...commonProps} />
      <Certificates certificates={certificateSet} isOffer {...commonProps} />
      <Courses courses={courseSet} {...commonProps} />
    </>
  );
};

export default OfferBody;
