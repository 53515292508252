import { createContext, Dispatch, SetStateAction } from 'react';
import { Nullable } from 'common/types';

interface IDeskContext {
  activeDesk: Nullable<number>;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setActiveDesk: Dispatch<SetStateAction<Nullable<number>>>;
  date: Nullable<Date>;
  setDate: Dispatch<SetStateAction<Nullable<Date>>>;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
}

const DeskContext = createContext<IDeskContext>({
  setActiveDesk: () => void null,
  setEditMode: () => void null,
  activeDesk: null,
  setDate: () => void null,
  date: null,
  setSelectedDate: () => void Date,
  selectedDate: new Date(),
  editMode: false,
});

export default DeskContext;
