import { DrawerOption } from './AppBar.types';
import {
  AdminPanelSettingsOutlined,
  Desk,
  GroupsOutlined,
  InsertEmoticon,
  LocalSeeOutlined,
  MenuBook,
  Person,
  Inventory,
  Today,
} from '@mui/icons-material';
import { ExternalPaths, RouterPaths } from 'src/router/types';

const { REACT_APP_HOLIDAY_CALENDAR_URL, REACT_APP_ADMIN_PANEL_URL } =
  process.env;

export const DRAWER_OPTIONS_UNDER_DEVELOPMENT: Array<DrawerOption> =
  process.env.NODE_ENV === 'production'
    ? []
    : [
        {
          label: 'Kalendarz urlopów ERP',
          navigateTo: RouterPaths.holidayCalendar,
          Icon: Today,
        },
      ];

export const DRAWER_OPTIONS: Array<DrawerOption> = [
  {
    label: 'Uzupełnij profil',
    navigateTo: RouterPaths.profile,
    Icon: Person,
  },
  {
    label: 'Kalendarz urlopów',
    navigateTo: REACT_APP_HOLIDAY_CALENDAR_URL ?? '',
    Icon: Today,
  },
  {
    label: 'Panel administratora',
    navigateTo: REACT_APP_ADMIN_PANEL_URL ?? '',
    Icon: AdminPanelSettingsOutlined,
    adminOnly: true,
  },
  {
    label: 'Kudos',
    navigateTo: RouterPaths.kudos,
    Icon: InsertEmoticon,
  },
  {
    label: 'Wyślij zdjęcie',
    navigateTo: RouterPaths.sendPhoto,
    Icon: LocalSeeOutlined,
  },
  {
    label: 'Projekty i działy',
    navigateTo: RouterPaths.projectsDepartments,
    Icon: GroupsOutlined,
  },
  {
    label: 'Culture Book',
    navigateTo: ExternalPaths.cultureBook,
    Icon: MenuBook,
    hideOnMainMenu: true,
  },
  {
    label: 'Dokumenty',
    navigateTo: RouterPaths.documents,
    Icon: Inventory,
    hideOnMainMenu: true,
  },
  {
    label: 'Rezerwacja biurka',
    navigateTo: RouterPaths.deskReservation,
    Icon: Desk,
  },
  {
    label: 'Rezerwacje pokoju',
    navigateTo: RouterPaths.roomReservation,
    Icon: MenuBook,
    adminOnly: true,
  },
  ...DRAWER_OPTIONS_UNDER_DEVELOPMENT,
];
