const styles = {
  box: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  innerBox: {
    display: 'flex',
    gap: '0.5rem',
  },
};

export default styles;
