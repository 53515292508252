import { COLORS } from 'theme/index';

const styles = {
  root: (isError: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    borderColor: isError ? COLORS.error : COLORS.blue[200],
    borderWidth: 0,
    borderBottomWidth: '0.063rem',
    borderStyle: 'solid',
  }),
  buttonGroup: {
    margin: '0.5rem',
  },
  divider: {
    my: '0.5rem',
  },
};

export default styles;
