import { z } from 'zod';
import { option } from './types';

const editDeskValidator = z.object({
  name: z.string().optional().nullable(),
  owner: z.object(option).optional().nullable(),
  features: z.array(z.number()).optional(),
});

export default editDeskValidator;
