import { KeyValueBase } from './common';

export enum SkillCategories {
  'Programming Languages' = 1,
  'Technical' = 2,
  'Soft Skills' = 3,
  'Management' = 4,
}

export type SkillCategoriesKeys = keyof typeof SkillCategories;

export interface SkillNames extends KeyValueBase {
  category: number;
}

export type SkillNamesGetResponse = Array<SkillNames>;
