import { useMediaQuery } from '@mui/material';
import { BreakPoints } from 'theme/Theme.types';

const useBreakPoints = () => {
  const { Tablet, Desktop } = BreakPoints;

  const isMobile = useMediaQuery(`(max-width: ${Tablet - 1}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${Tablet}px) and (max-width: ${Desktop - 1}px)`
  );
  const isDesktop = useMediaQuery(`(min-width: ${Desktop}px)`);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useBreakPoints;
