import { RoomReservation } from 'api/types/roomReservation';
import { createContext, Dispatch, SetStateAction } from 'react';

export enum ModalType {
  NoReservations,
  CreateReservation,
  CancelReservation,
  MyReservations,
  OtherReservation,
  DeskReservations,
}

interface IRoomReservationContext {
  month: number;
  setMonth: Dispatch<SetStateAction<number>>;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  modal: ModalType;
  setModal: Dispatch<SetStateAction<ModalType>>;
  roomReservationToCancel?: RoomReservation;
  setRoomReservationToCancel: Dispatch<
    SetStateAction<RoomReservation | undefined>
  >;
  dateToFetch?: Date;
  setDateToFetch: Dispatch<SetStateAction<Date | undefined>>;
}

const RoomReservationContext = createContext<IRoomReservationContext>({
  selectedDate: new Date(),
  month: new Date().getMonth() + 1,
  setMonth: () => void 0,
  setSelectedDate: () => void 0,
  setRoomReservationToCancel: () => void undefined,
  modal: ModalType.NoReservations,
  setModal: () => void ModalType.MyReservations,
  setDateToFetch: () => void undefined,
});

export default RoomReservationContext;
