import { COLORS } from 'src/theme';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  icon: {
    cursor: 'pointer',
    color: COLORS.black,
  },
  todayButton: {
    'color': COLORS.black,
    'borderColor': COLORS.black,
    '&:hover': {
      borderColor: COLORS.black,
    },
    'width': 130,
  },
};

export default styles;
