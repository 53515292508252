import { FC, PropsWithChildren, useState } from 'react';
import RoomReservationContext, { ModalType } from './RoomReservationContext';
import { RoomReservation } from 'api/types/roomReservation';

const RoomReservationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [roomReservationToCancel, setRoomReservationToCancel] = useState<
    RoomReservation | undefined
  >();
  const [modal, setModal] = useState<ModalType>(ModalType.MyReservations);
  const [dateToFetch, setDateToFetch] = useState<Date>();

  const value = {
    month,
    setMonth,
    selectedDate,
    setSelectedDate,
    modal,
    setModal,
    roomReservationToCancel,
    setRoomReservationToCancel,
    dateToFetch,
    setDateToFetch,
  };

  return (
    <RoomReservationContext.Provider value={value}>
      {children}
    </RoomReservationContext.Provider>
  );
};

export default RoomReservationProvider;
