import { Kudos } from 'api/types/kudos';

export const mapKudosToPages = (kudos: Array<Kudos>, pageSize: number) => {
  return kudos.reduce(
    (prvs, curr) => {
      const latestArr = prvs[prvs.length - 1];

      // Init new array when there is none or its having kudos page limit
      if (!latestArr || latestArr?.length === pageSize) {
        return [...prvs, [curr]];
      }

      // Concat to latest array
      return [...prvs.slice(0, prvs.length - 1), [...latestArr, curr]];
    },
    [] as Array<Array<Kudos>>
  );
};
