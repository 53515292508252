import { MenuItem, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './AppBar.styles';
import { DrawerItemProps } from './AppBar.types';

const DrawerItem: FC<DrawerItemProps> = ({
  selected = false,
  Icon,
  label,
  onClick,
  disabled,
}) => (
  <MenuItem
    disableGutters
    dense
    sx={styles.drawerRow}
    selected={selected}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon sx={styles.drawerIcon} />
    <Typography noWrap variant="body1" component="span" color="inherit">
      {label.toUpperCase()}
    </Typography>
  </MenuItem>
);

export default DrawerItem;
