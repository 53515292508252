export enum PublicHolidayType {
  Company = 'company',
  NationalHoliday = 'national_holiday',
  HolidayComp = 'holiday_comp',
}

export type PublicHolidaysApi = {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: PublicHolidayType;
  additionalInfo: string;
};

export type PublicHolidays = {
  id: number;
  dateFrom: Date;
  dateTo: Date;
  type: PublicHolidayType;
  additionalInfo: string;
};

export type GetPublicHolidaysResponse = {
  results: PublicHolidaysApi[];
};
