import { memo, useMemo } from 'react';
import {
  AccessTimeOutlined,
  CalendarToday,
  Group,
  Label,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { isEmpty } from 'pages/Profile/Profile.helpers';
import IconLabel from '../../../../../components/IconLabel/IconLabel';
import styles from './InfoSection.styles';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';
import { parseAndFormatDate } from 'utils/date';
import { getDurationFromDates } from '../Projects.helpers';
import { ProjectSectionProps } from '../Projects.types';
import { TeamSize } from 'api/types/projects';

const InfoSection = memo((props: ProjectSectionProps) => {
  const { project, hideEmpty } = props;
  const { projectDetails, dateFrom, dateTo } = project;

  const rangeString = useMemo(
    () =>
      dateFrom
        ? `${parseAndFormatDate(dateFrom)} - ${
            dateTo ? parseAndFormatDate(dateTo) : 'present'
          }`
        : DEFAULT_EMPTY_STRING,
    [dateFrom, dateTo]
  );

  const durationString = useMemo(
    () =>
      dateFrom ? getDurationFromDates(dateFrom, dateTo) : DEFAULT_EMPTY_STRING,
    [dateFrom, dateTo]
  );

  const teamSizeString = useMemo(
    () =>
      projectDetails?.teamSize
        ? TeamSize[projectDetails?.teamSize]
        : DEFAULT_EMPTY_STRING,
    [projectDetails?.teamSize]
  );

  const nameString = useMemo(
    () => projectDetails?.name || DEFAULT_EMPTY_STRING,
    [projectDetails?.name]
  );

  const shouldHideComponent = useMemo(
    () =>
      [rangeString, durationString, teamSizeString, nameString].every(isEmpty),
    [durationString, nameString, rangeString, teamSizeString]
  );

  if (shouldHideComponent) return <></>;

  return (
    <Box sx={styles.infoGrid}>
      {!(isEmpty(rangeString) && hideEmpty) && (
        <IconLabel Icon={CalendarToday} label={rangeString} />
      )}
      {!(isEmpty(durationString) && hideEmpty) && (
        <IconLabel
          Icon={AccessTimeOutlined}
          label={`duration: ${durationString}`}
        />
      )}
      {!(isEmpty(teamSizeString) && hideEmpty) && (
        <IconLabel Icon={Group} label={`team size: ${teamSizeString}`} />
      )}
      {!(isEmpty(nameString) && hideEmpty) && (
        <IconLabel Icon={Label} label={`project: ${nameString}`} />
      )}
    </Box>
  );
});

InfoSection.displayName = 'InfoSection';

export default InfoSection;
