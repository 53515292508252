import { Box, CircularProgress, Typography } from '@mui/material';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent';
import { memo, useEffect } from 'react';
import { RouterPaths } from 'src/router/types';
import useCustomNavigation from 'src/router/useNavigation';
import EmaiIcon from '../EmaiIcon';
import styles from 'src/pages/Photos/Photos.styles';

const PhotosAddedModal = memo(() => {
  const navigation = useCustomNavigation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigation.navigate(RouterPaths.holidayImages);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigation]);

  return (
    <PageContent>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <EmaiIcon />
          <Typography variant="extra">ZDJĘCIA WYSŁANE!</Typography>
          <Typography variant="h1">
            ŚLEDŹ NASZE PROFILE NA FACEBOOKU I INSTAGRAMIE
            <br /> I WYPATRUJ SWOJEGO ZDJĘCIA 😊
          </Typography>
          <CircularProgress color="primary" size={20} />
          <Typography variant="caption">
            ZARAZ ZOSTANIESZ PRZEKIEROWANY DO GALERII ZDJĘĆ
          </Typography>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

PhotosAddedModal.displayName = 'PhotosAddedModal';

export default PhotosAddedModal;
