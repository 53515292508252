import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { DesksReservationsGetResponse } from 'api/types/deskReservation';
import { ROOM_ID } from './useDesksReservations.const';
import { getDateRangeFromToday } from 'utils/date';
import { useMemo } from 'react';

export const fetchDesksReservations = (dateFrom: string, dateTo: string) =>
  apiGet<DesksReservationsGetResponse>(
    `${API_URLS.deskReservations}?date_from=${dateFrom}&date_to=${dateTo}&room=${ROOM_ID}`
  );

const useDesksReservations = () => {
  const { start, end } = useMemo(() => getDateRangeFromToday(3), []);

  const {
    data,
    refetch: refetchDesksReservations,
    isLoading,
  } = useQuery({
    queryKey: [API_URLS.deskReservations, start, end],
    queryFn: () => fetchDesksReservations(start, end),
  });

  return {
    desksReservations: data?.data,
    refetchDesksReservations,
    isLoading,
  };
};

export default useDesksReservations;
