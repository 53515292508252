import { Nullable } from 'common/types';
import { KeyValueBase } from 'api/types/common';

interface Technology extends KeyValueBase {
  category: KeyValueBase;
}

export enum TeamSize {
  '1 - 5' = 1,
  '6 - 10' = 2,
  '11 - 20' = 3,
}

export interface ProjectDetail {
  id: number;
  name: string;
  technologies?: Array<Technology>;
  description?: Nullable<string>;
  teamSize: Nullable<number>;
  isExternal?: boolean;
}

export type ProjectDetailsGetResponse = Array<ProjectDetail>;

export type ProjectDetailsPostResponse = Omit<ProjectDetail, 'technologies'>;
