import { Box, Button, Typography } from '@mui/material';
import styles from './ChoosingDates.styles';
import { useContext, useMemo } from 'react';
import FormWrapper from 'components/FormWrapper';
import useDeskReservationForm from 'hooks/useDeskReservationForm/useDeskReservationForm';
import deskFormValidator from 'validation/deskFormValidator';
import MultiCheckboxInput from 'components/MultiCheckboxInput';
import { formatDateForReservation, formatToBackendDate } from 'utils/date';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import useChoosingDates from 'pages/DeskReservation/components/ChoosingDates/hooks/useChoosingDates';
import useReservationsForDesk from 'hooks/useReservationsForDesk';
import useDesk from 'hooks/useDesk';
import useDeskReservation from 'hooks/useDeskReservation';
import useReservation from 'hooks/useRoomReservations/useReservation';
import DeskContext from 'contexts/DeskReservation/DeskContext';

const ChoosingDates = () => {
  const { date } = useContext(DeskContext);
  const {
    reservedRooms: roomReservations,
    isFetched: areRoomReservationsFetched,
  } = useReservation();
  const { desk } = useDesk();
  const { myReservations } = useDeskReservation();
  const { reservationsForDesk, isFetched: areDeskReservationsFetched } =
    useReservationsForDesk(desk?.id);
  const { methods, onSubmitHandler } = useDeskReservationForm();
  const { choosingDatesContent } = useChoosingDates({
    areDeskReservationsFetched,
    areRoomReservationsFetched,
    desk,
    myReservations,
    reservationsForDesk,
    roomReservations,
  });

  const formattedDate = useMemo(
    () => formatToBackendDate(date as Date),
    [date]
  );

  const reservedRoom = useMemo(
    () =>
      roomReservations.find(
        (reservation) => reservation.date === formattedDate
      ),
    [formattedDate, roomReservations]
  );

  const header =
    reservedRoom && desk?.room
      ? 'Zarezerwowany pokój cichej pracy'
      : 'Zarezerwuj biurko';

  return (
    <Box>
      <Typography variant="h1" sx={styles.headerText}>
        {header}
      </Typography>
      <Typography sx={styles.headerText}>
        {`Wybrano biurko ${choosingDatesContent.deskTitle ?? ''}`}
      </Typography>
      <Box sx={styles.featuresContainer}>
        {desk?.features
          ? desk.features.map((feature) => (
              <Typography sx={styles.featureText} key={feature.id}>
                {feature?.name}
              </Typography>
            ))
          : null}
      </Box>
      <FormWrapper
        methods={methods}
        id="desk-form"
        onSubmit={onSubmitHandler}
        validator={deskFormValidator}
        sx={styles.inputHolder}
      >
        <MultiCheckboxInput
          name="dates"
          options={choosingDatesContent.options}
        />
      </FormWrapper>
      {desk?.room === reservedRoom?.room &&
        areDeskReservationsFetched &&
        areRoomReservationsFetched && (
          <ErrorMessage
            header="Uwaga!"
            subHeader={`W dniu ${formatDateForReservation(
              date as Date
            )} pokój cichej pracy jest zarezerwowany.`}
          />
        )}
      <Box sx={styles.buttonContainer}>
        <Button sx={styles.saveButton} form={'desk-form'} type={'submit'}>
          Zarezerwuj
        </Button>
      </Box>
    </Box>
  );
};

export default ChoosingDates;
