export const { REACT_APP_BACKEND_PEOPLE_URL } = process.env;
export const PEOPLE_API_URLS = {
  logout: 'accounts/logout/',

  // additional-holiday
  additionalHoliday: 'people/additional-holiday/',
  additionalHolidayPool: 'people/additional-holiday-pool/',
  calculateAdditionaldaysForUser: 'people/calculate-additional-days-for-user/',

  // holiday
  holidayPool: 'people/holiday-pool/',
  holidayUser: 'people/holiday-user/',
  holidaySchedule: 'people/holiday/create-employee-holiday/',
  holidayCreate: 'people/holiday/create-employee-holiday/',
  holidayCreateAdmin: 'people/holiday/create-admin-holiday/',
  holidayCancel: 'user/delete/holidays-employee',
  holidayDelete: 'user/delete/holidays-employee',
  holidayReport: 'people/holiday/generate-holiday-report/',
  holiday: 'people/holiday/',
  holidayCancelAdmin: (holidayId: string | number) =>
    `people/holiday/${holidayId}/cancel-admin-holiday/`,
  holidayAcceptEmploy: 'people/accept-holiday-employee/',
  holidayAcceptAdmin: 'people/accept-holiday-admin/',
  holidayRejectAdmin: 'people/cancel-holiday-admin/',

  // public-holidays
  publicHolidays: 'people/public-holidays/',

  // additional holiday
  socialSecurityBenefits: 'people/social-security-benefits/',
  socialSecurityBenefitsSummary:
    'people/social-security-benefits/get-days-sum/',

  // Summary and Chart
  statsForTable: 'people/get-stats-for-table/',
  daysUsedstatsForChart: 'people/get-used-days-statistics-for-chart/',
  holidayTypeStatsForChart: 'get-holiday-type-statistics-for-chart',

  // users
  user: 'users/user/',
  users: 'users/users/',
};
