import { Contract, ContractType } from 'api/types/contract';

export const ContractTypeDict: Record<ContractType, string> = {
  [ContractType.B2B]: 'B2B',
  [ContractType.UoD]: 'umowa o dzieło',
  [ContractType.UoP]: 'umowa o pracę',
  [ContractType.UoZ]: 'umowa zlecenie',
};

export interface ContractsProps {
  contracts?: Array<Contract>;
}

export interface ContractProps {
  contract: Contract;
  handleEditContract: () => void;
}
