const styles = {
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: {
      Desktop: `repeat(4, 1fr)`,
      Tablet: `repeat(2, 1fr)`,
      Mobile: '1fr',
    },
    gap: `1rem 4rem`,
  },
};

export default styles;
