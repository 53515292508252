import {
  EditDeskForm,
  editFormSubmitData,
} from 'pages/DeskReservation/DeskReservation.types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import editDeskValidator from 'validation/editDeskValidator';
import useMutateDesk from './useMutateDesk';
import useDesk from './useDesk';
import { EditHotDeskRequest, UseEditDeskFormProps } from './useDeskForm.types';
import { useContext } from 'react';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useMutateDeskFeatures from 'hooks/useDeskFeatures/useMutateDeskFeatures';
import { Feature } from 'api/types/deskFeatures';

const useDeskForm = ({ features, switchState }: UseEditDeskFormProps) => {
  const { deskPatchMutate } = useMutateDesk();
  const { desk } = useDesk();
  const { setActiveDesk } = useContext(DeskContext);
  const { deskFeaturePostMutate } = useMutateDeskFeatures();

  const methods = useForm<EditDeskForm>({
    resolver: zodResolver(editDeskValidator),
  });

  const saveDesk = async (
    data: editFormSubmitData,
    featuresToAdd?: Feature[]
  ) => {
    if (desk) {
      let dataToSend;
      if (!switchState) {
        dataToSend = {
          owner: Number(data.owner?.value),
          deskId: desk.id,
        };
      } else {
        dataToSend = {
          name: data.name !== null && data.name !== '' ? data.name : desk.name,
          owner: null,
          features: featuresToAdd?.map(({ id }) => id),
          deskId: desk.id,
        };
      }
      await deskPatchMutate(dataToSend as EditHotDeskRequest);
    }
  };

  const onSubmitHandler = async (data: editFormSubmitData) => {
    const featuresToAdd = features
      ?.filter((feature) => feature?.isNew)
      .map((feature) => feature.name);
    if (featuresToAdd?.length) {
      const res = await deskFeaturePostMutate(featuresToAdd);

      const newFeatures = res.data.map(({ id, name }) => ({ id, name }));

      const existingFeatures =
        features?.filter((feature) => !feature.isNew) || [];

      return await saveDesk(data, [...newFeatures, ...existingFeatures]);
    } else {
      return await saveDesk(data, features);
    }
  };

  const onCancelHandler = () => setActiveDesk(null);

  return {
    methods,
    onSubmitHandler,
    onCancelHandler,
  };
};

export default useDeskForm;
