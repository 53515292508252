import { z } from 'zod';
import { formatToBackendDate, formatToNullableBackendDate } from 'utils/date';
import certificatesValidator from 'validation/certificatesFormValidator';

export const formatToBackendFormData = ({
  issueDate,
  expirationDate,
  name,
  url,
}: z.infer<typeof certificatesValidator>) => ({
  issueDate: formatToBackendDate(issueDate),
  expirationDate: formatToNullableBackendDate(expirationDate),
  name: name.label,
  url,
});
