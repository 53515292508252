import { FC, PropsWithChildren, useState } from 'react';

import ModalContext, { ModalTypes } from './ModalContext';

export const ModalProviderContext: {
  getCurrentModalType?: () => ModalTypes | undefined;
  openModal?: (type: ModalTypes) => void;
  closeModal?: () => void;
} = {};

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<ModalTypes>();

  const openModal = (type: ModalTypes) => setCurrentModal(type);
  const closeModal = () => setCurrentModal(undefined);

  ModalProviderContext.getCurrentModalType = () => currentModal;
  ModalProviderContext.closeModal = closeModal;
  ModalProviderContext.openModal = openModal;

  return (
    <ModalContext.Provider
      value={{
        currentModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
