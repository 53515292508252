import AuthContext from 'contexts/Auth/AuthContext';
import { FC, PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { RouterPaths } from './types';

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isAuth } = useContext(AuthContext);

  if (!isAuth) {
    return <Navigate to={RouterPaths.login} />;
  }

  return children;
};

export default PrivateRoute;
