import { Nullable, Option } from 'common/types';
import { RadioLabels } from 'pages/ProjectsDepartments/components/Controls/Controls.types';
import { createContext, Dispatch, SetStateAction } from 'react';

export type RadioValue = keyof typeof RadioLabels;

interface IProjectsDepartmentsContext {
  radioValue: RadioValue;
  setRadioValue: Dispatch<SetStateAction<RadioValue>>;
  optionProject?: Nullable<Option<string, string>>;
  optionDepartment?: Nullable<Option<string, string>>;
  setOptionProject: Dispatch<SetStateAction<Nullable<Option<string, string>>>>;
  setOptionDepartment: Dispatch<
    SetStateAction<Nullable<Option<string, string>>>
  >;
}

const ProjectsDepartmentsContext = createContext<IProjectsDepartmentsContext>({
  radioValue: 'projects',
  setRadioValue: () => void 0,
  setOptionProject: () => void 0,
  setOptionDepartment: () => void 0,
});

export default ProjectsDepartmentsContext;
