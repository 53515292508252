export enum HolidayType {
  Leisure = 'leisure',
  OnDemand = 'on_demand',
  Company = 'company',
  Unpaid = 'unpaid',
  Compassionate = 'compassionate',
  Care = 'care',
  HolidayComp = 'holiday_comp',
  DelegationComp = 'delegation_comp',
  NationalHoliday = 'national_holiday',
}

export interface Holiday {
  id: number;
  addInfo: string;
  type: string;
  user: number;
  dateFrom: string;
  dateTo: string;
  isHalfDay: boolean;
}

export type UserHoliday = {
  addInfo: string;
  dateFrom: Date;
  dateTo: Date;
  firstName: string;
  id: number;
  isAcceptedByAdmin: boolean;
  isAcceptedByEmployee: boolean;
  isCancelled: boolean;
  isHalfDay: boolean;
  lastName: string;
  type: string;
  /** User id */
  user: number;
};

export type UserHolidays = {
  firstName: string;
  holidaySet: Array<UserHoliday>;
  id: number;
  lastName: string;
};

export interface UserHolidayApi extends Holiday {
  firstName: string;
  isAcceptedByAdmin: boolean;
  isAcceptedByEmployee: boolean;
  isCancelled: boolean;
  lastName: string;
}

export type UserHolidaysApi = {
  firstName: string;
  holidaySet: Array<UserHolidayApi>;
  id: number;
  lastName: string;
};
