import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';

import certificatesFormValidator from 'validation/certificatesFormValidator';

import {
  CertificateForm,
  CertificateFormDialogProps,
  formSubmitData,
} from './CertificateFormDialog.types';
import { parseNullishBackendDate } from 'utils/date';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import useCertificates from 'hooks/useCertificates';
import useMutateUserCertificates from 'hooks/useCertificates/useMutateUserCertificates';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { formatToBackendFormData } from './CertificateFormDialog.helpers';

const useCertificateFormDialog = ({
  certificate,
  onClose,
}: CertificateFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === ProfileModalType.AddCertificate ||
    currentModal === ProfileModalType.EditCertificate;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();
  const { data, isLoading } = useCertificates(isOpen);

  const { postMutate, patchMutate } = useMutateUserCertificates();

  const methods = useForm<CertificateForm>({
    resolver: zodResolver(certificatesFormValidator),
  });

  const title = useMemo(
    () =>
      `${
        currentModal === ProfileModalType.AddCertificate ? 'Add' : 'Edit'
      } certificate`,
    [currentModal]
  );

  const [issueDateWatcher, expirationDateWatcher, isPersistentWatcher] =
    methods.watch(['issueDate', 'expirationDate', 'isPersistent']);

  const { issueDate, expirationDate, name, url } = certificate ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        issueDate: parseNullishBackendDate(issueDate),
        expirationDate: parseNullishBackendDate(expirationDate),
        name: name ? { value: name, label: name } : undefined,
        isPersistent: !expirationDate,
        url: url ?? undefined,
      });
    }
  }, [expirationDate, isOpen, issueDate, methods, name, url]);

  useEffect(() => {
    if (isPersistentWatcher)
      methods.setValue('expirationDate', null, { shouldValidate: true });
  }, [isPersistentWatcher, methods]);

  useEffect(() => {
    if (issueDateWatcher && methods.formState.isSubmitted)
      methods.trigger('expirationDate');
  }, [methods, issueDateWatcher]);

  const onSubmitHandler = async (data: formSubmitData) => {
    if (userId) {
      setIsSubmitting(true);
      const payload = {
        userId,
        certificate: formatToBackendFormData(data),
      };
      try {
        if (certificate) {
          await patchMutate({
            ...payload,
            certificate: { id: certificate.id, ...payload.certificate },
          });
        } else {
          await postMutate(payload);
        }
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    isPersistentWatcher,
    isOpen,
    issueDateWatcher,
    expirationDateWatcher,
    methods,
    title,
    onSubmitHandler,
    certificates: data,
    isLoading: isLoading || isSubmitting,
  };
};

export default useCertificateFormDialog;
