import { COLORS } from 'theme/Theme.const';

const styles = {
  container: {
    height: 'auto',
    padding: {
      Desktop: '2.5rem',
      Tablet: '2rem',
      Mobile: '2rem',
    },
  },
  content: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
  },
  cancelButton: {
    border: `1px solid ${COLORS.lightBlue}`,
  },
  saveButton: {
    backgroundColor: COLORS.blue[600],
    color: COLORS.white,
  },
  reservationsContainer: {
    'display': 'inline-grid',
    'gridTemplateColumns': '0.1fr 2.5fr 0.1fr',
    'padding': '1rem',
    'gap': '2rem',
    'borderBottom': `1px solid ${COLORS.gray[300]}`,
    'alignItems': 'center',
    '&:hover': {
      bgcolor: `${COLORS.gray[50]}`,
      cursor: 'pointer',
    },
  },
  iconColor: {
    color: COLORS.blue[600],
    width: 22,
    height: 22,
  },
  rightIcon: {
    color: COLORS.blue[600],
  },
  boxStyle: {
    display: 'flex',
    gap: '0.5rem',
  },
};

export default styles;
