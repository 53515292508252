import { Box, Button } from '@mui/material';
import { useMemo } from 'react';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import DatePickerInput from 'components/DatePickerInput';
import formStyles from 'common/styles/forms';
import TextInput from 'components/TextInput';
import { ContractFormDialogProps } from './ContractFormDialog.types';
import useContractFormDialog from './useContractFormDialog';
import contractFormValidator from 'validation/contractFormValidator';
import { ContractTypeDict } from '../Contract.types';
import SelectInput from 'components/SelectInput';

const ContractFormDialog = ({ contract, onClose }: ContractFormDialogProps) => {
  const CONTRACT_OPTIONS = useMemo(
    () =>
      Object.entries(ContractTypeDict).map(([value, label]) => ({
        value,
        label,
      })),
    []
  );

  const {
    isOpen,
    methods,
    title,
    isLoading,
    onSubmitHandler,
    dateFromWatcher,
  } = useContractFormDialog({
    contract,
    onClose,
  });

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="contract-form" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="contract-form"
        onSubmit={onSubmitHandler}
        validator={contractFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <SelectInput
              options={CONTRACT_OPTIONS}
              label="Contract type"
              name="type"
            />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <DatePickerInput name="dateFrom" label="Date from" />
            <DatePickerInput
              name="dateTo"
              label="Date to"
              minDate={dateFromWatcher ?? undefined}
            />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <TextInput type="number" name="holidayPool" label="Holiday pool" />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default ContractFormDialog;
