import { toast } from 'react-hot-toast';
import { SnackbarConfig, SnackbarType } from './Snackbars.types';
import { SNACKBAR_DEFAULT_DURATION } from './Snackbars.const';
import {
  ErrorSnackbar,
  InfoSnackbar,
  SuccessSnackbar,
} from 'components/Snackbars';

export const enqueueSnackbar = (
  message: string,
  config?: Partial<SnackbarConfig>
) => {
  const { id, type, duration, position } = config ?? {};

  const snackbarConfig = {
    ...config,
    duration: duration ?? SNACKBAR_DEFAULT_DURATION,
    position,
  };

  // This needs to stay otherwise it will change type of toast internally
  const nativeConfig = {
    ...snackbarConfig,
    type: 'custom',
  };

  toast.custom(({ id: nativeId }) => {
    const sharedConfig = {
      ...snackbarConfig,
      id: id ?? nativeId,
    };

    switch (type) {
      case SnackbarType.error:
        return <ErrorSnackbar message={message} config={sharedConfig} />;
      case SnackbarType.success:
        return <SuccessSnackbar message={message} config={sharedConfig} />;
      case SnackbarType.info:
      default:
        return <InfoSnackbar message={message} config={sharedConfig} />;
    }
  }, nativeConfig);
};
