import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  formatToBackendDate,
  formatToNullableBackendDate,
  parseNullishBackendDate,
} from 'utils/date';
import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import contractFormValidator from 'validation/contractFormValidator';
import {
  ContractForm,
  ContractFormDialogProps,
  contractFormSubmitData,
} from './ContractFormDialog.types';
import useMutateUserContract from 'hooks/useContracts/useMutateUserContract';

const useContractFormDialog = ({
  contract,
  onClose,
}: ContractFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === AdminProfileModalType.AddContract ||
    currentModal === AdminProfileModalType.EditContract;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();

  const methods = useForm<ContractForm>({
    resolver: zodResolver(contractFormValidator),
  });

  const { control } = methods;
  const { contractPatchMutate, contractPostMutate } = useMutateUserContract();

  const [dateFromWatcher, dateToWatcher] = useWatch({
    name: ['dateFrom', 'dateTo'],
    control,
  });

  const title = useMemo(
    () =>
      `${
        currentModal === AdminProfileModalType.AddContract ? 'Add' : 'Edit'
      } contract`,
    [currentModal]
  );

  const { dateTo, dateFrom, type, holidayPool } = contract ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        type: type,
        holidayPool: holidayPool ? parseFloat(holidayPool) : undefined,
      });
    }
  }, [dateFrom, dateTo, holidayPool, isOpen, methods, type]);

  useEffect(() => {
    if (dateFromWatcher && !dateToWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
    if (!dateFromWatcher && dateToWatcher) {
      methods.setValue('dateFrom', dateToWatcher);
    }
    if (dateToWatcher && dateFromWatcher && dateToWatcher < dateFromWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
  }, [dateFromWatcher, dateToWatcher, methods]);

  const onSubmitHandler = async (data: contractFormSubmitData) => {
    if (userId) {
      const payload = {
        dateFrom: formatToBackendDate(data.dateFrom),
        dateTo: formatToNullableBackendDate(data.dateTo),
        type: data.type.toString(),
        holidayPool: data.holidayPool.toString(),
        user: userId,
      };
      setIsSubmitting(true);
      try {
        if (contract) {
          await contractPatchMutate({
            contract: { id: contract.id, ...payload },
          });
        } else {
          await contractPostMutate({ contract: payload });
        }
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    dateFromWatcher,
    isOpen,
    methods,
    title,
    onSubmitHandler,
    isLoading: isSubmitting,
  };
};

export default useContractFormDialog;
