import { EventInput } from '@fullcalendar/core';
import { UserHoliday, UserHolidays } from 'api/types/holiday';
import { SocialSecurityBenefits } from 'api/types/socialSecurityBenefits';
import { LegendContent } from 'components/Legend/Legend.types';
import { addHours, endOfDay, startOfDay } from 'date-fns';
import { PublicHolidayType, PublicHolidays } from 'hooks/usePublicHolidays';
import { COLORS } from 'theme/index';

export const LEGEND_CONTENT: Array<LegendContent> = [
  {
    color: COLORS.holiday.accepted,
    text: 'Urlop Zatwierdzony - mój',
  },
  {
    color: COLORS.holiday.unaccepted,
    text: 'Urlop Niezatwierdzony - mój',
  },
  {
    color: COLORS.holiday.acceptedOthers,
    text: 'Urlop Zatwierdzony - innych',
  },
  {
    color: COLORS.holiday.unacceptedOthers,
    text: 'Urlop Niezatwierdzony - innych',
  },
  {
    color: COLORS.holiday.company,
    text: 'Urlop firmowy',
  },
  {
    color: COLORS.holiday.national,
    text: 'Dzień ustawowo wolny',
  },
  {
    color: COLORS.holiday.sickLeave,
    text: 'L4 i inne ',
  },
];

const calculateHolidaysColor = (holiday: UserHoliday, user?: number) => {
  if (holiday.user === user) {
    return holiday.isAcceptedByAdmin && holiday.isAcceptedByEmployee
      ? COLORS.holiday.accepted
      : COLORS.holiday.unaccepted;
  } else {
    return holiday.isAcceptedByAdmin && holiday.isAcceptedByEmployee
      ? COLORS.holiday.acceptedOthers
      : COLORS.holiday.unacceptedOthers;
  }
};

export const convertUserHolidaysToCalendarEvent: (
  userHolidays: UserHolidays,
  user?: number
) => EventInput[] = (userHolidays: UserHolidays, user?: number) => {
  return userHolidays.holidaySet.map<EventInput>((holiday) => {
    return {
      title: `${userHolidays?.firstName} ${userHolidays?.lastName[0]}.`,
      extendedProps: {
        additionalInfo: `${userHolidays?.firstName} ${userHolidays?.lastName}: ${holiday.addInfo}`,
        ...holiday,
      },
      start: startOfDay(holiday.dateFrom),
      end: addHours(endOfDay(holiday.dateTo), 24),
      allDay: true,
      display: 'auto',
      backgroundColor: calculateHolidaysColor(holiday, user),
      className: [
        holiday.isAcceptedByAdmin && holiday.isAcceptedByEmployee
          ? 'holiday-accepted'
          : 'holiday-not-accepted',
        holiday.isHalfDay ? 'half-day' : '',
        'user-holiday',
        holiday.type || '',
      ],
    };
  });
};

export const convertSocialSecurityBenefitsToCalendarEvent: (
  socialSecurityBenefits: SocialSecurityBenefits
) => EventInput = (socialSecurityBenefits) => {
  return {
    title: `${socialSecurityBenefits?.firstName} ${socialSecurityBenefits?.lastName[0]}.`,
    extendedProps: {
      additionalInfo: `${socialSecurityBenefits?.firstName} ${socialSecurityBenefits?.lastName}: ${socialSecurityBenefits.addInfo}`,
    },
    start: startOfDay(Date.parse(socialSecurityBenefits.dateFrom)),
    end: addHours(startOfDay(Date.parse(socialSecurityBenefits.dateTo)), 24),
    allDay: true,
    backgroundColor: COLORS.holiday.sickLeave,
    className: [socialSecurityBenefits.type ?? '', `social-security-benefits`],
  };
};

export const convertPublicHolidaysToCalendarEvent: (
  publicHolidays: PublicHolidays
) => EventInput = (publicHolidays) => {
  return {
    title: publicHolidays.additionalInfo,
    extendedProps: {
      type: publicHolidays.type,
    },
    start: startOfDay(publicHolidays.dateFrom),
    end: addHours(startOfDay(publicHolidays.dateTo), 24),
    allDay: true,
    display: 'background',
    backgroundColor:
      publicHolidays.type === PublicHolidayType.NationalHoliday
        ? COLORS.holiday.national
        : COLORS.holiday.company,
    className: [publicHolidays.type ?? '', `national_holiday`],
  };
};
