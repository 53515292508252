import { useCallback, useContext } from 'react';
import useMutateRoomReservation from './useMutateRoomReservations';
import { UseCancelReservationProps } from './useRoomReservations.types';
import RoomReservationContext, {
  ModalType,
} from 'contexts/RoomReservation/RoomReservationContext';

const useCancelRoomReservation = ({
  roomReservationToCancel,
  setRoomReservationToCancel,
}: UseCancelReservationProps) => {
  const { deleteMutate } = useMutateRoomReservation();
  const { setModal } = useContext(RoomReservationContext);

  const onCloseClick = useCallback(() => {
    setRoomReservationToCancel(undefined);
    setModal(ModalType.MyReservations);
  }, [setModal, setRoomReservationToCancel]);

  const onCancelReservation = useCallback(async () => {
    await deleteMutate({ roomId: roomReservationToCancel.id });
    setModal(ModalType.MyReservations);
    setRoomReservationToCancel(undefined);
  }, [
    deleteMutate,
    roomReservationToCancel.id,
    setModal,
    setRoomReservationToCancel,
  ]);

  return { onCloseClick, onCancelReservation };
};
export default useCancelRoomReservation;
