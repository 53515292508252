import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  printWrapper: {
    display: 'none',
    displayPrint: 'block',
  },
  pageBreak: {
    pageBreakAfter: 'always',
  },
  frontPage: {
    /* For A4 paper size 297mm - (2 * 10mm) margins. I made it a little smaller
       because on Firefox it was going out-of-bounds */
    maxHeight: '270mm',
    padding: '30mm 10mm 0 10mm',
  },
  content: {
    'display': 'flex',
    'gap': '10mm',
    'flexDirection': 'column',
    'alignItems': 'center',

    'padding': '10mm 5mm',
    'border': `1.5px solid ${COLORS.shadow}`,
    'boxShadow': '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
    '-moz-box-shadow': '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
    '-webkit-box-shadow': '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
  },
  logo: {
    width: '5cm',
    height: '5cm',
    borderRadius: '100%',
  },
  descriptionWrapper: {
    textAlign: 'left',
    width: '100%',
  },
  flat: {
    boxShadow: 'unset',
    border: 'none',
  },
};

export const printMediaQueries = `
  @media print {
    html, body {
      font-size: 12px;
      
    }

    @page {
      /* For A4 paper size (210mm x 297mm) */
      size: A4;
      margin: 1cm 5mm;
    }
  }
`;

export default styles;
