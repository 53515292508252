const styles = {
  box: {
    height: 'auto',
    padding: {
      Desktop: '2.5rem',
      Tablet: '2rem',
      Mobile: '2rem',
    },
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '30px 1fr',
    gridTemplateRows: {
      Desktop: `5px 1fr`,
      Tablet: `15px 1fr`,
      Mobile: '1fr',
    },
    gap: '0.2rem 1.5rem',
  },
};

export default styles;
