import {
  SocialSecurityBenefitType,
  SocialSecurityBenefitsWithUserModel,
} from 'api/types/socialSecurityBenefits';

export const SocialSecurityBenefitsTypeDict: Record<
  SocialSecurityBenefitType,
  string
> = {
  [SocialSecurityBenefitType.L4]: 'L4',
  [SocialSecurityBenefitType.Care]: 'opieka nad dzieckiem',
  [SocialSecurityBenefitType.Maternal]: 'macierzyński',
  [SocialSecurityBenefitType.Paternal]: 'ojcowski',
  [SocialSecurityBenefitType.Parental]: 'rodzicielski',
  [SocialSecurityBenefitType.ChildCare]: 'wychowawczy',
  [SocialSecurityBenefitType.Rehabilitaion]: 'zasiłek rehabilitacyjny',
  [SocialSecurityBenefitType.AdditionalHoliday]: 'inne',
};

export interface SocialSecurityBenefitsProps {
  socialSecurityBenefits: Array<SocialSecurityBenefitsWithUserModel>;
}

export interface SocialSecurityBenefitProps {
  socialSecurityBenefit: SocialSecurityBenefitsWithUserModel;
  handleEditSocialSecurityBenefit: () => void;
}
