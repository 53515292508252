import { FC, PropsWithChildren, useState } from 'react';
import ProfileContext from './ProfileContext';

const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeUser, setActiveUser] = useState<number>();

  return (
    <ProfileContext.Provider
      value={{
        activeUser,
        setActiveUser,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
