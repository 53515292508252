import { Box, Button } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import AutoCompleteInput from 'components/AutoCompleteInput';
import useCourseFormDialog from './useCourseFormDialog';
import DatePickerInput from 'components/DatePickerInput';
import { mapKeyValueToOption } from 'utils/objects';
import { CourseFormDialogProps } from './CourseFormDialog.types';
import formStyles from 'common/styles/forms';
import coursesFormValidator from 'validation/coursesFormValidator';

const CourseFormDialog = ({ course, onClose }: CourseFormDialogProps) => {
  const {
    isOpen,
    methods,
    title,
    dateFromWatcher,
    dateToWatcher,
    isLoading,
    onSubmitHandler,
    courses,
  } = useCourseFormDialog({
    course,
    onClose,
  });

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="course-form" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="course-form"
        onSubmit={onSubmitHandler}
        validator={coursesFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={courses.map(mapKeyValueToOption)}
              label="Name"
              name="name"
              freeSolo
            />
          </Box>
          <Box sx={formStyles.tripleInputs}>
            <DatePickerInput
              disableFuture
              name="dateFrom"
              label="Start date"
              maxDate={dateToWatcher ?? undefined}
            />
            <DatePickerInput
              name="dateTo"
              label="End date"
              minDate={dateFromWatcher ?? undefined}
            />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default CourseFormDialog;
