import { z } from 'zod';
import {
  errorMessages,
  MIN_DATE,
  MIN_HOLIDAY_POOL,
} from 'common/consts/validation';
import { isBefore } from 'date-fns';
import { ContractType } from 'api/types/contract';

const contractFormValidator = z
  .object({
    type: z.nativeEnum(ContractType),
    dateFrom: z
      .date({ invalid_type_error: errorMessages.required })
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
      .max(new Date(), { message: errorMessages.maxDate(new Date()) }),
    dateTo: z
      .date()
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
      .nullable(),
    holidayPool: z.coerce
      .number({
        invalid_type_error: errorMessages.required,
      })
      .min(MIN_HOLIDAY_POOL, { message: errorMessages.nonNegativeValue }),
  })
  .refine(
    ({ dateFrom, dateTo }) => !isBefore(dateTo ? dateTo : new Date(), dateFrom),
    {
      message: errorMessages.invalidRange,
      path: ['dateTo'],
    }
  );

export default contractFormValidator;
