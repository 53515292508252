import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { DesksGetResponse } from 'api/types/deskReservation';

const fetchDesks = () => apiGet<DesksGetResponse>(API_URLS.desks);

export const useDesks = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.desks],
    queryFn: fetchDesks,
  });

  return {
    allDesks: data?.data,
    hotDesks: data?.data.filter((desk) => desk.isHotdesk),
    refetch,
    isLoading,
  };
};

export default useDesks;
