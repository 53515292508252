import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  paper: {
    borderRadius: '0.75rem',
    display: 'flex',
    justifyContent: 'center',
    padding: {
      Desktop: '2rem',
      Tablet: '2rem',
      Mobile: '1rem',
    },
    border: `1px solid ${COLORS.shadow}`,
    background: COLORS.white,
    boxShadow: '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
  },
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '3rem',
  },
  actionButton: {
    borderRadius: 1.5,
    color: 'primary.light',
  },
};

export default styles;
