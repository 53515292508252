import { useForm } from 'react-hook-form';
import { useContext, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';

import educationFormValidator from 'validation/educationFormValidator';

import { parseNullishBackendDate } from 'utils/date';
import {
  EducationForm,
  EducationFormDialogProps,
  formSubmitData,
} from './EducationFormDialog.types';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import { mapKeyValueToOption } from 'utils/objects';
import useEducation from 'hooks/useEducation/useEducation';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import useMutateUserEducation from 'hooks/useEducation/useMutateUserEducation';
import { formatToBackendFormData } from './EducationFormDialog.helpers';

const useEducationFormDialog = ({
  education,
  onClose,
}: EducationFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === ProfileModalType.AddEducation ||
    currentModal === ProfileModalType.EditEducation;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();
  const { data, isLoading } = useEducation(isOpen);
  const { schools, fieldsOfStudy } = data;

  const { patchMutate, postMutate } = useMutateUserEducation();

  const methods = useForm<EducationForm>({
    resolver: zodResolver(educationFormValidator),
  });

  const title = useMemo(
    () =>
      `${
        currentModal === ProfileModalType.AddProject ? 'Add' : 'Edit'
      } education`,
    [currentModal]
  );

  const [startDateWatcher, endDateWatcher, atPresentWatcher] = methods.watch([
    'dateFrom',
    'dateTo',
    'atPresent',
  ]);

  const { dateFrom, dateTo, fieldOfStudy, school, department, city, degree } =
    education ?? {};

  useEffect(() => {
    if (isOpen)
      methods.reset({
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        fieldOfStudy: fieldOfStudy
          ? mapKeyValueToOption(fieldOfStudy)
          : undefined,
        school: school ? mapKeyValueToOption(school) : undefined,
        atPresent: !dateTo?.length,
        department,
        city,
        degree,
      });
  }, [
    city,
    dateFrom,
    dateTo,
    degree,
    department,
    fieldOfStudy,
    isOpen,
    methods,
    school,
  ]);

  useEffect(() => {
    if (atPresentWatcher)
      methods.setValue('dateTo', null, { shouldValidate: true });
  }, [atPresentWatcher, methods]);

  useEffect(() => {
    if (startDateWatcher && methods.formState.isSubmitted)
      methods.trigger('dateTo');
  }, [startDateWatcher, methods]);

  const onSubmitHandler = async (data: formSubmitData) => {
    if (userId) {
      setIsSubmitting(true);
      const payload = {
        userId,
        education: formatToBackendFormData(data),
      };

      try {
        if (education) {
          await patchMutate({
            ...payload,
            education: { id: education.id, ...payload.education },
          });
        } else {
          await postMutate(payload);
        }
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    isOpen,
    isLoading: isLoading || isSubmitting,
    title,
    startDateWatcher,
    endDateWatcher,
    atPresentWatcher,
    methods,
    onSubmitHandler,
    schools,
    fieldsOfStudy,
  };
};

export default useEducationFormDialog;
