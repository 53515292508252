import { zodResolver } from '@hookform/resolvers/zod';
import ModalContext, { KudosModalType } from 'contexts/Modal/ModalContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import kudosFormValidator from 'validation/kudosFormValidator';
import {
  formSubmitData,
  KudosForm,
  SendKudosModalProps,
} from './SendKudosModal.types';
import {
  formatToBackendFormData,
  mapProfileListToOption,
} from './SendKudosModal.helpers';
import useMutateKudos from 'hooks/useKudos/useMutateKudos';
import useProfileList from 'hooks/useProfileList';

const useSendKudos = (props: SendKudosModalProps) => {
  const [isSending, setIsSending] = useState(false);
  const { closeModal, currentModal } = useContext(ModalContext);
  const isOpen =
    currentModal === KudosModalType.SendKudos ||
    currentModal === KudosModalType.EditKudos;
  const { kudosType, kudos } = props;

  const { data } = useProfileList(true);

  const [kudosUser] = useMemo(
    () =>
      data
        ?.filter(({ id }) => id === kudos?.user)
        ?.map(mapProfileListToOption) ?? [],
    [data, kudos?.user]
  );

  const { postMutate, patchMutate } = useMutateKudos();

  const methods = useForm<KudosForm>({
    resolver: zodResolver(kudosFormValidator),
    defaultValues: {
      message: '',
      user: undefined,
    },
  });

  const messageController = useController({
    name: 'message',
    control: methods.control,
  });

  useEffect(() => {
    methods.setValue('user', kudosUser);
    methods.setValue('message', kudos?.description);
  }, [kudos?.id, kudos?.description, kudosUser, methods]);

  useEffect(() => {
    if (isOpen) {
      if (kudos) {
        methods.reset({ message: kudos?.description, user: kudosUser });
      } else {
        methods.reset({ message: undefined, user: undefined });
      }
    }
  }, [isOpen, methods, kudos, kudosUser]);

  const onCloseHandler = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onSubmitHandler = useCallback(
    async (data: formSubmitData) => {
      if (kudos) {
        setIsSending(true);
        const payload = {
          ...formatToBackendFormData(data),
          kudosType: kudos?.kudosType,
        };
        await patchMutate({ kudosId: kudos.id, kudos: payload })
          .finally(() => {
            onCloseHandler();
            setIsSending(false);
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (kudosType && !kudos) {
        setIsSending(true);
        const payload = {
          ...formatToBackendFormData(data),
          kudosType,
        };
        await postMutate(payload).finally(() => {
          onCloseHandler();
          setIsSending(false);
        });
      }
    },
    [kudosType, onCloseHandler, postMutate, patchMutate, kudos]
  );

  return {
    methods,
    messageController,
    isOpen,
    onSubmitHandler,
    onCloseHandler,
    isSending,
  };
};

export default useSendKudos;
