import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    gap: '2rem',
    flexDirection: {
      Tablet: 'row',
      Mobile: 'column',
    },
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: '31.25rem',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '20rem',
    gap: '1rem',
  },
  info: {
    display: 'flex',
    flex: 1,
    gap: '0.5rem',
    flexDirection: 'column',
  },
};

export default styles;
