import { useMutation } from '@tanstack/react-query';
import { apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';

import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import {
  MutateProjectPatchProps,
  MutateProjectPostProps,
} from './useProjects.types';
import useProjects from './useProjects';
import { ProjectDetailsPostResponse } from 'api/types/projects';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

export const useMutateProject = () => {
  const { userId } = useActiveWorker();
  const { refetch: refetchProjects } = useProjects(true, userId);

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: (props: MutateProjectPostProps) =>
      apiPost<ProjectDetailsPostResponse>(
        `${API_URLS.projectDetails}?external-owner=${userId}`,
        props
      ),
    onSuccess: (_, { refetch = true, name }) => {
      if (refetch) {
        refetchProjects();
      }
      enqueueSnackbar(`${name} project successfully created`, {
        type: SnackbarType.success,
      });
    },
    onError: () => {
      enqueueSnackbar('There was an issue while creating a new project', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ projectId, teamSize }: MutateProjectPatchProps) =>
      apiPatch<ProjectDetailsPostResponse>(
        `${API_URLS.projectDetails}${projectId}/?external-owner=${userId}`,
        { teamSize }
      ),
    onSuccess: (_, { refetch = true }) => {
      if (refetch) {
        refetchProjects();
      }
      enqueueSnackbar(`External project successfully changed`, {
        type: SnackbarType.success,
      });
    },
    onError: () => {
      enqueueSnackbar(`There was an issue while updating external project`, {
        type: SnackbarType.error,
      });
    },
  });

  return {
    postMutate,
    patchMutate,
  };
};

export default useMutateProject;
