import { FC, useContext } from 'react';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import EditDesk from '../Admin/EditDesk';
import MyDeskReservations from '../MyDeskReservations/MyDeskReservations';
import useDeskReservation from 'hooks/useDeskReservation';

const CurrentReservationContent: FC = () => {
  const { myReservations } = useDeskReservation();
  const { editMode, activeDesk } = useContext(DeskContext);

  if (!editMode && myReservations) {
    return <MyDeskReservations />;
  } else if (editMode && activeDesk) {
    <EditDesk />;
  }
};

export default CurrentReservationContent;
