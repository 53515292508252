import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from '@mui/material';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { KudosCardProps } from './KudosCard.types';
import styles from './KudosCard.styles';
import { KudosCardProperties } from './KudosCard.const';
import { get } from 'react-hook-form';
import useProfileList from 'hooks/useProfileList';
import SentKudosContext from 'contexts/SentKudos/SentKudosContext';
import { KUDOS_ROWS } from 'pages/Kudos/Kudos.const';

const KudosCard: FC<KudosCardProps> = ({
  type,
  onClickHandler,
  disabledInput = false,
  value,
  height,
  controller,
  user,
}) => {
  const { header, icon, color } = KudosCardProperties[type];

  const { field, formState } = controller ?? {};

  const { data } = useProfileList(true);
  const kudosReceiver = useMemo(() => {
    return data?.find(({ id }) => id === user)?.title;
  }, [data, user]);
  const { isSentKudosView } = useContext(SentKudosContext);

  const {
    onChange,
    onBlur,
    ref,
    value: formValue,
    name,
    ...fieldProps
  } = field ?? {};

  const defaultValue = disabledInput && value ? value : '';
  const valueFromForm = disabledInput && formValue ? '' : formValue;

  return (
    <Card
      sx={[styles.root(!!onClickHandler), styles.wrapper]}
      variant="outlined"
      onClick={onClickHandler}
    >
      <Box sx={styles.imageWrapper}>
        <img
          src={icon}
          alt={`Kudo card image`}
          style={styles.image(type) as CSSProperties}
        />
      </Box>
      <CardHeader title={header} sx={{ backgroundColor: color }} />
      <CardContent sx={styles.cardContent(height)}>
        {isSentKudosView ? <Typography>{kudosReceiver}</Typography> : null}
        <TextField
          {...fieldProps}
          inputRef={ref}
          onBlur={onBlur}
          onChange={(event) => {
            onChange?.(event.target.value);
          }}
          placeholder="Tu wpisz coś miłego :)"
          value={valueFromForm ?? defaultValue}
          rows={KUDOS_ROWS}
          fullWidth
          multiline
          variant="standard"
          sx={styles.input}
          inputProps={{
            sx: styles.htmlInput(
              32,
              !!get(formState?.errors, name),
              disabledInput
            ),
            style: styles.input, // Only this is passing height: 100% to textarea component
          }}
          InputProps={{
            disableUnderline: true,
            sx: styles.input,
            multiline: true,
            rows: KUDOS_ROWS,
            inputComponent: 'textarea',
            value: valueFromForm ?? defaultValue,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default KudosCard;
