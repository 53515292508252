import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorMessageProps } from './ErrorMessage.types';
import styles from './ErrorMessage.styles';

const ErrorMessage: FC<ErrorMessageProps> = ({ header, subHeader }) => (
  <Box sx={styles.boxStyle}>
    <Typography variant="sub2" sx={styles.header}>
      {header}
    </Typography>
    <Typography variant="sub2" sx={styles.subheader}>
      {subHeader}
    </Typography>
  </Box>
);

export default ErrorMessage;
