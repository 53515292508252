import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import DisplayWrapper from 'components/DisplayWrapper';
import useOtherUsersDeskReservations from 'hooks/useRoomReservations/useOtherUsersDeskReservations';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { MdPersonOutline } from 'react-icons/md';
import { COLORS } from 'theme/Theme.const';
import styles from './ReservedDesksModal.styles';

const ReservedDesksModal: FC = () => {
  const { bookersData, formattedDate } = useOtherUsersDeskReservations();

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.box}>
        <Typography variant="h1" sx={styles.title}>
          Zarezerwuj pokój
        </Typography>
        <Typography variant="sub1">{formattedDate}</Typography>
        <ErrorMessage
          header="Uwaga!"
          subHeader="Nie możesz zarezerwować pokoju, jeśli wcześniej zarezerwowano w nim biurko. "
        />
        <Typography variant="sub1">Biurka są zarezerwowane przez</Typography>
        {bookersData?.map((bookerData, index) => (
          <Box key={index} sx={styles.innerBox}>
            <MdPersonOutline color={COLORS.blue[600]} size={22} />
            <Typography variant="sub2">{bookerData.title}</Typography>
          </Box>
        ))}
      </Box>
    </DisplayWrapper>
  );
};

export default ReservedDesksModal;
