import { memo, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import formStyles from 'common/styles/forms';
import CheckboxInput from 'components/CheckboxInput';
import DatePickerInput from 'components/DatePickerInput';
import { useFormContext } from 'react-hook-form';
import { ProfileForm } from '../EditProfileDialog.types';

const ExperienceForm = memo(() => {
  const { setValue, watch } = useFormContext<ProfileForm>();

  const calculateFromProjectsWatcher = watch('calculateFromProjects');

  useEffect(() => {
    if (calculateFromProjectsWatcher)
      setValue('experienceSince', null, { shouldValidate: true });
  }, [calculateFromProjectsWatcher, setValue]);

  return (
    <Box sx={formStyles.section}>
      <Typography sx={{ mb: 3 }} variant="sub1">
        Experience
      </Typography>
      <CheckboxInput
        wrapperSx={{ mb: 2 }}
        name="calculateFromProjects"
        label="Experience calculated from projects"
      />
      <DatePickerInput
        disabled={calculateFromProjectsWatcher}
        disableFuture
        label="Experience since"
        name="experienceSince"
      />
      <DatePickerInput
        disableFuture
        label="In Revolve since"
        name="inRevolve"
      />
    </Box>
  );
});
ExperienceForm.displayName = 'ExperienceForm';

export default ExperienceForm;
