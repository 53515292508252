import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';

const useMenuBar = () => {
  const { editor } = useCurrentEditor();
  const focus = useCallback(() => editor?.chain().focus(), [editor]);

  const toggleBold = useCallback(() => focus()?.toggleBold().run(), [focus]);
  const isBold = editor?.isActive('bold');

  const toggleItalic = useCallback(
    () => focus()?.toggleItalic().run(),
    [focus]
  );
  const isItalic = editor?.isActive('italic');

  const toggleUnderline = useCallback(
    () => focus()?.toggleUnderline().run(),
    [focus]
  );
  const isUnderline = editor?.isActive('underline');

  const toggleStrike = useCallback(
    () => focus()?.toggleStrike().run(),
    [focus]
  );
  const isStrike = editor?.isActive('strike');

  const toggleBulletList = useCallback(
    () => focus()?.toggleBulletList().run(),
    [focus]
  );
  const isBulletList = editor?.isActive('bulletList');

  const toggleOrderedList = useCallback(
    () => focus()?.toggleOrderedList().run(),
    [focus]
  );
  const isOrderedList = editor?.isActive('orderedList');

  const clearFormatting = useCallback(
    () => focus()?.unsetAllMarks().run(),
    [focus]
  );

  return {
    toggleBold,
    isBold,
    toggleItalic,
    isItalic,
    toggleUnderline,
    isUnderline,
    toggleStrike,
    isStrike,
    toggleBulletList,
    isBulletList,
    toggleOrderedList,
    isOrderedList,
    clearFormatting,
  };
};

export default useMenuBar;
