import { Button } from '@mui/material';

import formStyles from 'common/styles/forms';
import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import useProjectFormDialog from './useProjectFormDialog';
import projectValidator from 'validation/projectValidator';
import DescriptionForm from './components/DescriptionForm';
import GeneralInformation from './components/GeneralInformation';
import { ProjectFormDialogProps } from './ProjectFormDialog.types';
import KeyTechnologies from './components/KeyTechnologies';

const ProjectFormDialog = ({ project, onClose }: ProjectFormDialogProps) => {
  const { isOpen, methods, title, isLoading, onSubmitHandler } =
    useProjectFormDialog({
      project,
      onClose,
    });

  return (
    <AppDialog
      title={title}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            form="project-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </>
      }
      open={isOpen}
      onClose={onClose}
    >
      <FormWrapper
        id="project-form"
        sx={formStyles.main('1fr 1fr')}
        methods={methods}
        isLoading={isLoading}
        validator={projectValidator}
        onSubmit={onSubmitHandler}
      >
        <GeneralInformation />
        <KeyTechnologies />
        <DescriptionForm />
      </FormWrapper>
    </AppDialog>
  );
};

export default ProjectFormDialog;
