import { memo } from 'react';
import { Box, Typography } from '@mui/material';

import formStyles from 'common/styles/forms';
import TextInput from 'components/TextInput';
import DatePickerInput from 'components/DatePickerInput';
import ProfilePictureForm from './ProfilePictureForm';
import styles from './PersonalInformationForm.styles';
import { PersonalInformationFormProps } from './PersonalInformationForm.types';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';

const PersonalInformationForm = memo(
  ({ name = DEFAULT_EMPTY_STRING }: PersonalInformationFormProps) => (
    <Box sx={formStyles.section}>
      <Box sx={styles.firstRow}>
        <Box sx={styles.personalInformation}>
          <Typography variant="sub1">Personal information</Typography>
          <Typography variant="sub2">{name}</Typography>
          <DatePickerInput
            disableFuture
            label="Date of Birth"
            name="dateOfBirth"
          />
        </Box>
        <ProfilePictureForm />
      </Box>
      <TextInput name="description" multiline label="Description" fullWidth />
    </Box>
  )
);

PersonalInformationForm.displayName = 'PersonalInformationForm';

export default PersonalInformationForm;
