import { ExtendedHolidayPool, HolidayPool } from 'api/types/holidayPool';
import { getYear } from 'date-fns';

export const getHolidayPoolYearOptions = (
  holidayPools: Array<ExtendedHolidayPool>,
  holidayPool?: HolidayPool
): {
  value: number;
  label: string;
}[] => {
  let years;
  if (holidayPool?.year) {
    years = [holidayPool?.year - 1, holidayPool?.year, holidayPool?.year + 1];
  } else {
    const currentYear = getYear(new Date());
    years = [currentYear - 1, currentYear, currentYear + 1];
  }

  return years
    .map((year) => ({ value: year, label: year.toString() }))
    .filter(
      ({ value }) =>
        !holidayPools.some(
          ({ id, year }) => year === value && id !== holidayPool?.id
        )
    );
};
