import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { CoursesGetResponse } from 'api/types/courses';

export const fetchCourses = () => apiGet<CoursesGetResponse>(API_URLS.courses);

const useCourses = (enabled?: boolean) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.courses],
    queryFn: fetchCourses,
    enabled,
  });

  return {
    data: data?.data ?? [],
    refetch,
    isLoading,
  };
};

export default useCourses;
