export const COLORS = {
  appBar: '#020815',
  gray2: '#676767',
  silver: '#CCCCCC',
  shadow: '#E6E6E6',
  borderColor: '#3788d8',
  white: '#FFFFFF',
  opaqueWhite: 'rgba(255, 255, 255, 0.75)',
  black: '#151515',
  quiteWorkRoomReserved: '#FFEFEB',
  action: '#1C1B1F',
  disabledBackground: '#5D5D5D',
  transparent: 'transparent',
  info: '#7DA4D4',
  error: '#d32f2f',
  blue: {
    900: '#04153A',
    800: '#062460',
    700: '#083286',
    600: '#0A399A',
    500: '#0C47C0',
    400: '#195FF1',
    300: '#5287F4',
    200: '#A9C3FA',
    100: '#E2EBFD',
    50: '#F3F7FE',
  },
  gray: {
    400: '#6E6E6E',
    300: '#A8A8A8',
    200: '#CFCFCF',
    100: '#ECECEC',
    50: '#FAFAFA',
  },
  koral: {
    200: '#CC5235',
    100: '#F58B72',
    40: '#FBD1C7',
  },
  roomReservationsLegendColors: {
    green: '#E1F1D7',
    darkOrange: '#F58B72',
    lightOrange: '#FBD1C7',
  },
  red: {
    100: '#F58B72',
  },
  green: {
    200: '#366418',
    100: '#79B84F',
  },
  purple: {
    100: '#8751DA',
  },
  lightBlue: '#3788D8',
  holiday: {
    unaccepted: '#D9DEFE',
    unacceptedOthers: '#CDF1FC',
    national: '#B2F4D5',
    accepted: '#6F81FF',
    acceptedOthers: '#5CC9FA',
    company: '#FEC8C8',
    sickLeave: '#B8B8BD',
    nationalIconColor: '#16A360',
    companyIconColor: '#ED5565',
  },
} as const;
