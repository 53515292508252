import { Autocomplete, TextField } from '@mui/material';
import { FC, useContext } from 'react';
import { getOptionLabel } from 'components/AutoCompleteInput';
import { mapKeyValueToOption } from 'utils/objects';
import { Option } from 'common/types';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { NEW_VALUE_PREFIX } from 'components/AutoCompleteInput/AutoCompleteInput.const';
import useDesks from 'hooks/useDesks';

const ChoosingDesk: FC = () => {
  const { hotDesks } = useDesks();
  const { activeDesk, setActiveDesk } = useContext(DeskContext);

  return (
    <Autocomplete<
      Option<number | null | string, string> & { inputValue?: string }
    >
      fullWidth
      options={hotDesks?.map(mapKeyValueToOption) ?? []}
      onChange={(event, newValue) => {
        event.preventDefault();
        if (newValue?.value) setActiveDesk(+newValue.value);
      }}
      isOptionEqualToValue={(option, selectedOption) => {
        // Just to mute material warning for default empty value
        if (`${selectedOption.value}`.includes(NEW_VALUE_PREFIX)) return true;

        return option.label === selectedOption.label;
      }}
      getOptionLabel={getOptionLabel}
      value={
        hotDesks
          ?.map(mapKeyValueToOption)
          .find((hd) => hd.value === `${activeDesk}`) ?? null
      }
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField label={'Wybierz biurko'} {...params} />
      )}
      // type any need to be here because of @mui/material has problem with this prop.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      disableClearable={true as any}
    />
  );
};

export default ChoosingDesk;
