import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { RoomReservationGetResponse } from 'api/types/roomReservation';
import { ROOM_RESERVATION_URL } from './useRoomReservations.consts';
import { getDateRangeFromToday } from 'utils/date';
import { API_URLS } from 'api/axios/axios.consts';
import { useMemo } from 'react';

const fetchRoomReservation = (dateFrom: string, dateTo: string) =>
  apiGet<RoomReservationGetResponse>(
    `${API_URLS.roomReservations}?date_from=${dateFrom}&date_to=${dateTo}`
  );

const useReservation = () => {
  const { start, end } = useMemo(() => getDateRangeFromToday(3), []);

  const { data, refetch, isFetched } = useQuery({
    queryKey: [ROOM_RESERVATION_URL, start, end],
    queryFn: () => fetchRoomReservation(start, end),
  });

  return {
    reservedRooms: data?.data ?? [],
    refetch,
    isFetched,
  };
};

export default useReservation;
