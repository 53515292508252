import { memo, useContext, useState } from 'react';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import OfferSettings from 'pages/Offer/components/OfferSettings';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import styles from './AppBar.styles';
import Drawer from './Drawer';
import { getUserGreeting } from './AppBar.helpers';
import { RouterPaths } from 'src/router/types';
import { AppBarContent, AppBarProps } from './AppBar.types';
import AuthContext from 'contexts/Auth/AuthContext';
import OfferPrint from 'pages/Offer/components/OfferPrint/OfferPrint';
import OfferContext from 'contexts/Offer/OfferContext';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useBreakPoints from 'hooks/useBreakpoints';

const AppBar = memo(({ content = AppBarContent.drawer }: AppBarProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isStaff } = useContext(AuthContext);
  const { isLogged } = useContext(OfferContext);
  const { editMode, setEditMode, setActiveDesk } = useContext(DeskContext);
  const { isMobile } = useBreakPoints();

  const handleOnClickForDeskAdmin = () => {
    setActiveDesk(null);
    setEditMode(!editMode);
  };

  return (
    <MuiAppBar position="static" sx={styles.root}>
      <IconButton component="a" disableRipple href={RouterPaths.home}>
        <Logo />
      </IconButton>
      <Box sx={styles.spacer} />
      {content === AppBarContent.drawer ? (
        <>
          <Typography variant="h1" sx={styles.greetingText}>
            {getUserGreeting()}
          </Typography>
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <Menu fontSize="large" sx={{ color: 'primary.light' }} />
          </IconButton>
          <Drawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
        </>
      ) : content === AppBarContent.offer ? (
        isLogged && (
          <Box sx={styles.printButtonContainer}>
            {isStaff ? <OfferSettings /> : null}
            <OfferPrint />
          </Box>
        )
      ) : content === AppBarContent.deskReservation ? (
        <>
          {isStaff && !isMobile && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={editMode}
                    onChange={handleOnClickForDeskAdmin}
                    color="primary"
                    sx={styles.switchStyles}
                  />
                }
                label="Desk Admin"
              />
            </FormGroup>
          )}
          <Typography variant="h1" sx={styles.greetingText}>
            {getUserGreeting()}
          </Typography>
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <Menu fontSize="large" sx={{ color: 'primary.light' }} />
          </IconButton>
          <Drawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
        </>
      ) : null}
    </MuiAppBar>
  );
});

AppBar.displayName = 'AppBar';

export default AppBar;
