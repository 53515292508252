import axios from 'axios';
import {
  API_URL,
  CONTENT_TYPE,
  XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME,
} from './axios.consts';
import { REACT_APP_BACKEND_PEOPLE_URL } from './axiosPeople.consts';
import { GlobalModalType } from 'contexts/Modal/ModalContext';
import { ModalProviderContext } from 'contexts/Modal/ModalProvider';
import { HttpStatusCode } from 'axios';

export const instancePeople = axios.create({
  baseURL: `${REACT_APP_BACKEND_PEOPLE_URL}${API_URL}`,
  xsrfHeaderName: XSRF_HEADER_NAME,
  xsrfCookieName: XSRF_COOKIE_NAME,
  withCredentials: true,
  headers: {
    accept: CONTENT_TYPE,
    //paste your csrf people token from cookies header in get response
    [XSRF_HEADER_NAME]: process.env.REACT_APP_PEOPLE_CSRF_TOKEN,
  },
});

instancePeople.interceptors.response.use(
  (request) => request,
  async (error) => {
    if (error.response?.status === HttpStatusCode.Forbidden) {
      console.debug('Not login to people endpoint');
      ModalProviderContext.openModal
        ? ModalProviderContext.openModal(GlobalModalType.LoginToPeople)
        : null;
    }

    return Promise.reject(error);
  }
);

export const {
  get: peopleApiGet,
  post: peopleApiPost,
  patch: peopleApiPatch,
  put: peopleApiPut,
  delete: peopleApiDelete,
} = instancePeople;
