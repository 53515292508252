import { createContext } from 'react';

export interface AuthUserData {
  isStaff: boolean;
  isAuth: boolean;
  id?: number;
}

interface IAuthContext {
  id?: number;
  name: string;
  isAuth: boolean;
  isStaff: boolean;
  logout: () => void;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContext>({
  name: '',
  isAuth: false,
  isStaff: false,
  logout: () => null,
  isLoading: false,
});

export default AuthContext;
