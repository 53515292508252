import { memo, useCallback } from 'react';
import { Rating } from 'components/Rating/Rating.types';
import { SkillsRatingsProps } from './SkillsRatings.types';
import { useMutateUserSkills } from 'hooks/useSkills';
import { Box, Typography } from '@mui/material';
import RatingRow from './RatingRow/RatingRow';
import styles from './SkillsRatings.styles';
import commonStyles from 'pages/Profile/Profile.styles';
import useBreakPoints from 'hooks/useBreakpoints';
import RatingsHeaders from './RatingsHeaders';
import EmptySection from '../../EmptySection';
import { MutateSkillPatchProps } from 'hooks/useSkills/useSkills.types';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const SkillsRatings = memo(
  ({ type, isReadonly, showLegend, isLoading, skills }: SkillsRatingsProps) => {
    const { deleteMutate, patchMutate } = useMutateUserSkills();

    const { isMobile } = useBreakPoints();
    const { userId } = useActiveWorker();

    const saveSkill = useCallback(
      (isLevel: boolean, skillId: number) => async (rating: Rating) => {
        if (userId !== undefined && rating) {
          const payload: MutateSkillPatchProps = { skillId, userId };

          if (isLevel) payload.value = rating;
          else payload.attitude = rating;

          patchMutate(payload);
        }
      },
      [patchMutate, userId]
    );

    const removeSkill = useCallback(
      (skillId: number) => async () => {
        if (userId !== undefined) {
          deleteMutate({ skillsIds: [skillId], userId });
          enqueueSnackbar('Skill successfully deleted', {
            type: SnackbarType.info,
          });
        }
      },
      [userId, deleteMutate]
    );

    const isTwoColGrid = !showLegend && !isMobile;
    const numberOfSkills = skills?.[type]?.length;

    return (
      <Box sx={commonStyles.skillColumn(showLegend)}>
        {type !== 'Programming Languages' && (
          <Typography variant="sub1">
            {type === 'Technical' ? 'Technologies' : type}
          </Typography>
        )}
        {numberOfSkills ? (
          <Box sx={styles.grid(isReadonly, isTwoColGrid ? 2 : 1)}>
            <RatingsHeaders
              isReadonly={isReadonly}
              isTwoColumn={isTwoColGrid && numberOfSkills >= 2}
            />
            {skills?.[type].map(({ id, name, value, attitude }, i) => (
              <RatingRow
                disabled={isLoading || isReadonly}
                key={`languageRow-${i}`}
                label={name}
                level={value}
                attitude={attitude}
                setLevel={isReadonly ? undefined : saveSkill(true, id)}
                setAttitude={isReadonly ? undefined : saveSkill(false, id)}
                onDeleteHandler={isReadonly ? undefined : removeSkill(id)}
              />
            ))}
          </Box>
        ) : (
          <EmptySection />
        )}
      </Box>
    );
  }
);
SkillsRatings.displayName = 'SkillsRatings';

export default SkillsRatings;
