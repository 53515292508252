import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import PageContent from 'components/PageContent/PageContent';
import DisplayWrapper from 'components/DisplayWrapper/DisplayWrapper';
import KudosCard from './components/KudosCard';
import styles from './Kudos.styles';
import KudosHeader from './components/KudosHeader';
import { KudosType } from 'api/types/kudos';
import SendKudosModal from './components/SendKudosModal/SendKudosModal';
import ModalContext, { KudosModalType } from 'contexts/Modal/ModalContext';

const Kudos = memo(() => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [type, setType] = useState<KudosType>();

  const onClickHandler = useCallback(
    (type: KudosType) => {
      setType(type);
      openModal(KudosModalType.SendKudos);
    },
    [openModal]
  );

  useEffect(() => {
    return () => closeModal();
    //Ensure that the modal is closed when leaving the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <KudosHeader />
          <Box sx={styles.kudosWrapper}>
            <KudosCard
              type={KudosType.thankYou}
              onClickHandler={() => onClickHandler(KudosType.thankYou)}
              disabledInput
            />
            <KudosCard
              type={KudosType.congratulations}
              onClickHandler={() => onClickHandler(KudosType.congratulations)}
              disabledInput
            />
            <KudosCard
              type={KudosType.goodJob}
              onClickHandler={() => onClickHandler(KudosType.goodJob)}
              disabledInput
            />
          </Box>
        </Box>
        <SendKudosModal kudosType={type} />
      </DisplayWrapper>
    </PageContent>
  );
});

Kudos.displayName = 'Kudos';

export default Kudos;
