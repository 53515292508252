import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '2rem',
  },
  carousel: {
    width: '100%',
    overflowY: 'auto',
  },
  page: {
    display: 'grid',
    gridTemplateColumns: {
      Desktop: 'repeat(3, 1fr)',
      Tablet: 'repeat(2, 1fr)',
      Mobile: 'repeat(1, 1fr)',
    },
    gap: '1rem',
    padding: '0.5rem 2.5rem',
  },
  navButtonWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
