import {
  DeskReservationForm,
  formSubmitData,
} from 'pages/DeskReservation/DeskReservation.types';
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Option } from 'common/types';
import { zodResolver } from '@hookform/resolvers/zod';
import deskFormValidator from 'validation/deskFormValidator';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useMutateDeskReservation from 'hooks/useMutateDeskReservation';

const useDeskReservationForm = () => {
  const [isSending] = useState(false);
  const { activeDesk } = useContext(DeskContext);

  const methods = useForm<DeskReservationForm>({
    defaultValues: {
      dates: [] as Array<Option<string, string>>,
    },
    resolver: zodResolver(deskFormValidator),
  });

  const { postMutate } = useMutateDeskReservation();

  const onSubmitHandler = useCallback(
    async (data: formSubmitData) => {
      await postMutate({
        dates: data.dates,
        desk: activeDesk,
      });
      methods.setValue('dates', []);
    },
    [postMutate, methods, activeDesk]
  );

  return {
    methods,
    onSubmitHandler,
    isSending,
  };
};

export default useDeskReservationForm;
