import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { FieldsOfStudyGetResponse } from 'api/types/fieldsOfStudy';

export const fetchFieldsOfStudy = () =>
  apiGet<FieldsOfStudyGetResponse>(API_URLS.fieldsOfStudy);

const useFieldsOfStudy = (enabled?: boolean) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.fieldsOfStudy],
    queryFn: fetchFieldsOfStudy,
    enabled,
  });

  return {
    data: data?.data ?? [],
    refetch,
    isLoading,
  };
};

export default useFieldsOfStudy;
