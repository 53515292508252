import { useQuery } from '@tanstack/react-query';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { peopleApiGet } from 'api/axios';
import { AxiosError } from 'axios';
import {
  GetPublicHolidaysResponse,
  PublicHolidays,
} from './usePublicHolidays.types';
import { HttpStatusCode } from 'axios';

export const fetchPublicHolidays = async () => {
  try {
    const response = await peopleApiGet<GetPublicHolidaysResponse>(
      PEOPLE_API_URLS.publicHolidays
    );

    return response.data.results;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (
        [HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(
          error.response?.status || 0
        )
      ) {
        return [];
      }
    }
    console.error(error);
    throw error;
  }
};

export const usePublicHolidays = () => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [PEOPLE_API_URLS.publicHolidays],
    queryFn: fetchPublicHolidays,
  });
  const newData = data?.map<PublicHolidays>(({ dateFrom, dateTo, ...rest }) => {
    return {
      ...rest,
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    };
  });

  return {
    data: newData || [],
    refetch,
    isLoading,
    isFetching,
  };
};
