import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    textAlign: 'center',
  },
  link: {
    color: COLORS.blue[500],
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  kudosWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '2rem',
    flexDirection: {
      Desktop: 'row',
      Mobile: 'column',
    },
    paddingX: {
      Desktop: 0,
      Tablet: '6rem',
    },
  },
};

export default styles;
