const styles = {
  dialog: {
    overflow: 'hidden',
  },
  dialogPaper: {
    width: '60rem',
    height: '40rem',
  },
  dialogTitle: {
    padding: { Mobile: '0rem', Desktop: '0rem' },
  },
  dialogContent: {
    padding: 0,
    marginLeft: '-0.125rem',
    marginTop: '-0.125rem',
    marginRight: '-0.125rem',
    overflow: 'hidden',
    width: '60.125rem',
  },
  closeButton: { padding: 0, height: 'fit-content' },
};

export default styles;
