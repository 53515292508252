import { Box } from '@mui/material';
import { UserDesksReservations } from 'api/types/deskReservation';
import { useState } from 'react';
import MyReservedDesks from '../MyReservedDesks';
import CancelReservation from '../CancelReservation';
import useDeskReservation from 'hooks/useDeskReservation';

const MyDeskReservations = () => {
  const { myReservations } = useDeskReservation();
  const [deskReservationToCancel, setDeskReservationToCancel] = useState<
    UserDesksReservations | undefined
  >();

  return (
    <Box>
      {deskReservationToCancel ? (
        <CancelReservation
          deskReservationToCancel={deskReservationToCancel}
          setDeskReservationToCancel={setDeskReservationToCancel}
        />
      ) : (
        <MyReservedDesks
          setDeskReservationToCancel={setDeskReservationToCancel}
          myReservations={myReservations}
        />
      )}
    </Box>
  );
};

export default MyDeskReservations;
