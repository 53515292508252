import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { useContext } from 'react';
import { WorkerSummary } from 'api/types/worker';
import AuthContext from 'contexts/Auth/AuthContext';
import ProfileContext from 'contexts/Profile/ProfileContext';

const useProfileList = (isEnabled?: boolean) => {
  const { isStaff, id } = useContext(AuthContext);
  const { setActiveUser } = useContext(ProfileContext);

  const query = useQuery({
    queryKey: [API_URLS.profile],
    queryFn: () =>
      apiGet<Array<WorkerSummary>>(API_URLS.profile).then((response) => {
        id === undefined && setActiveUser(id);

        return response;
      }),
    enabled: isEnabled || isStaff,
  });

  return {
    ...query,
    data: query.data?.data ?? [],
    refetchProfileList: query.refetch,
  };
};

export default useProfileList;
