import { useMemo } from 'react';
import { mapKeyValueToOption } from 'utils/objects';
import useCompanyInfo from 'hooks/useCompanyInfo';

const useOptions = () => {
  const { projectsDepartmentsData, departmentsData } = useCompanyInfo();

  const projectsDepartmentsOptions = useMemo(
    () => projectsDepartmentsData?.map(mapKeyValueToOption) ?? [],
    [projectsDepartmentsData]
  );

  const departmentsOptions = useMemo(
    () => departmentsData?.map(mapKeyValueToOption) ?? [],
    [departmentsData]
  );

  return {
    projectsDepartmentsOptions,
    departmentsOptions,
  };
};

export default useOptions;
