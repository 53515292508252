import { FC } from 'react';
import { UserHolidaysPoolSummary } from 'components/UserHolidaysPoolSummary/UserHolidaysPoolSummary';
import { UserHolidaysPoolSummaryStyle } from 'components/UserHolidaysPoolSummary/UserHolidaysPoolSummary.types';
import { UserHolidayPoolSummary } from 'hooks/useUserHolidayPool';
import { useUser } from 'hooks/useUsers/useUser';

interface Props {
  holidayPools: UserHolidayPoolSummary[];
}

export const DetailedHolidayPoolSummary: FC<Props> = ({ holidayPools }) => {
  const user = useUser();

  return holidayPools.map((holidaysPool) => {
    return (
      <UserHolidaysPoolSummary
        key={`${holidaysPool.id}-holidaypool-detailed`}
        holidaysPool={holidaysPool}
        type={
          user.data?.id === holidaysPool.user || user.data?.isCompanyAdmin
            ? UserHolidaysPoolSummaryStyle.Full
            : UserHolidaysPoolSummaryStyle.Short
        }
      />
    );
  });
};
