import { Box, Typography } from '@mui/material';
import { Fragment, memo, useContext, useMemo, useState } from 'react';

import Education from 'pages/Profile/components/Educations/Education';
import { EducationsProps } from './Educations.types';
import styles from './Educations.styles';
import EmptySection from '../EmptySection';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import EducationFormDialog from './EducationFormDialog/EducationFormDialog';

const Educations = memo((props: EducationsProps) => {
  const { currentModal, openModal, closeModal } = useContext(ModalContext);
  const { isReadonly, educations, hideEmpty } = props;

  const isEducationEmpty = useMemo(
    () => !educations?.length,
    [educations?.length]
  );

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  if (hideEmpty && isEducationEmpty) return <></>;

  return (
    <Box sx={styles.root}>
      <Typography component="h1" variant="h1">
        Education
      </Typography>
      {!isEducationEmpty ? (
        educations?.map((education, index) => (
          <Fragment key={`education-${index}`}>
            <Education
              education={education}
              handleEdit={() => {
                setEditId(education.id);
                openModal(ProfileModalType.EditEducation);
              }}
              isReadonly={isReadonly}
              hideEmpty={hideEmpty}
            />
            {!isReadonly &&
              currentModal === ProfileModalType.EditEducation &&
              editId === education.id && (
                <EducationFormDialog
                  education={education}
                  onClose={onCloseModal}
                />
              )}
          </Fragment>
        ))
      ) : (
        <EmptySection />
      )}
      {!isReadonly && currentModal === ProfileModalType.AddEducation && (
        <EducationFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Educations.displayName = 'Educations';

export default Educations;
