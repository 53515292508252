import { memo } from 'react';
import PageContent from 'components/PageContent/PageContent';
import DisplayWrapper from 'components/DisplayWrapper';
import { Box, Button, Typography } from '@mui/material';
import { getUserGreeting } from 'components/AppBar/AppBar.helpers';
import { DRAWER_OPTIONS } from 'components/AppBar/AppBar.consts';
import useCustomNavigation from 'src/router/useNavigation/useNavigation';
import styles from './Home.styles';

const Home = memo(() => {
  const navigation = useCustomNavigation();

  return (
    <PageContent>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Box sx={styles.headers}>
            <Typography variant="extra">{getUserGreeting()}</Typography>
            <Typography variant="h1">Co chcesz zrobić?</Typography>
          </Box>
          <Box sx={styles.buttonsContainer}>
            {DRAWER_OPTIONS.filter(
              ({ adminOnly, hideOnMainMenu }) => !adminOnly && !hideOnMainMenu
            ).map(({ label, navigateTo }, i) => (
              <Button
                key={`home-button-${i}`}
                variant="contained"
                onClick={() => navigation.navigate(navigateTo)}
                sx={styles.button}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

Home.displayName = 'Home';

export default Home;
