import { HoverTooltipProps } from './HoverTooltip.types';
import { Tooltip, Zoom, Typography } from '@mui/material';

const HoverTooltip = ({ Icon, text }: HoverTooltipProps) => {
  return (
    <Tooltip
      title={
        <Typography variant="body2" sx={{ display: 'inline-block' }}>
          {text}
        </Typography>
      }
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 100 }}
      placement="top"
    >
      <Icon />
    </Tooltip>
  );
};

export default HoverTooltip;
