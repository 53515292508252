import { WorkerLanguage, WorkerProject } from 'api/types/worker';
import { differenceInCalendarMonths } from 'date-fns';
import {
  getDurationText,
  mergeOverlappingIntervals,
  parseBackendDate,
} from 'utils/date';

// This function merge projects into intervals and calculate monthly exp e.g
// Someone have 2 projects 1st from January to March and 2nd from February to April
// for this case it will return 4 since those projects are overlapping each other.
export const getMonthsOfExp = (projects: Array<WorkerProject>) => {
  const mergedIntervals = mergeOverlappingIntervals(
    projects?.map(({ dateFrom, dateTo }) => ({
      start: parseBackendDate(dateFrom),
      end: parseBackendDate(dateTo),
    })) ?? []
  );

  const monthsOfProjects =
    mergedIntervals.length >= 1
      ? mergedIntervals.map(
          ({ start, end }) => differenceInCalendarMonths(end, start) + 1
        )
      : [1]; // If projects exp is empty we always start from 1 month as default

  return monthsOfProjects.reduce((a, b) => a + b, 0);
};

export const getExpString = (months: number) => {
  const year = Math.floor(months / 12);
  const month = months % 12;

  return getDurationText(year, month);
};

export const getLanguageString = (languages: Array<WorkerLanguage>) =>
  languages?.reduce(
    (rest, { name, level }, i) =>
      `${rest}` + `${name} ${level}${i === languages.length - 1 ? '' : ', '}`,
    ''
  );
