import { memo } from 'react';

import PageContent from 'components/PageContent/PageContent';
import DisplayWrapper from 'components/DisplayWrapper';
import { Box, Link, Typography } from '@mui/material';
import useCustomNavigation from 'src/router/useNavigation/useNavigation';
import styles from './Documents.styles';
import { DOCUMENTS } from './Documents.const';

const Documents = memo(() => {
  const navigation = useCustomNavigation();

  return (
    <PageContent>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="h1">Dokumenty</Typography>
          <ul style={{ paddingLeft: '2rem' }}>
            {DOCUMENTS.map(({ label, navigateTo }, i) => (
              <li key={`document-${i}`} style={{ marginBottom: '1rem' }}>
                <Link
                  sx={styles.link}
                  onClick={() => navigation.navigate(navigateTo)}
                  target={'_blank'}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

Documents.displayName = 'Documents';

export default Documents;
