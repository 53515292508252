import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '2.5rem', // Just so content is not "jumping" on hover
    gap: '0.25rem',
  },
  ratingWrapper: { lineHeight: 0 },
  icon: {
    width: 16,
    height: 16,
  },
};

export default styles;
