import { DeskColor, DeskStatus } from 'hooks/useDeskStatus/useDeskStatus.types';
import { COLORS } from 'theme/index';

export const mapColors: Record<DeskStatus, DeskColor> = {
  [DeskStatus.NotAvailableToReserve]: {
    mainColor: COLORS.gray[200],
    borderColor: COLORS.gray[400],
  },
  [DeskStatus.QuiteWorkRoomReserved]: {
    mainColor: COLORS.koral[100],
    borderColor: COLORS.koral[200],
  },
  [DeskStatus.Reserved]: {
    mainColor: COLORS.koral[100],
    borderColor: COLORS.koral[200],
  },
  [DeskStatus.AvailableToReserve]: {
    mainColor: COLORS.green[100],
    borderColor: COLORS.green[200],
  },
  [DeskStatus.ReservedByYou]: {
    mainColor: COLORS.blue[300],
    borderColor: COLORS.blue[600],
  },
};
