import { FC } from 'react';
import { EditorProvider } from '@tiptap/react';
import MenuBar from './components/MenuBar';
import InputContent from './components/InputContent';
import { Box } from '@mui/material';
import styles from './RichTextInput.styles';
import { RichTextInputProps } from './RichTextInput.types';
import extensions from './extensions';
import InputWrapper from 'components/InputWrapper';
import { Controller, useFormContext } from 'react-hook-form';

const RichTextInput: FC<RichTextInputProps> = ({
  name,
  isError = false,
  onFormChangeHandler,
}) => {
  const { control } = useFormContext();

  return (
    <InputWrapper sx={{}} name={name}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <Box sx={styles.root(isError)}>
            <EditorProvider
              extensions={extensions}
              content={value}
              slotBefore={<MenuBar isError={isError} />}
              // TODO: Add debounce later
              onUpdate={({ editor }) => onChange(editor.getHTML())}
              onBlur={onBlur}
            >
              <InputContent onFormChangeHandler={onFormChangeHandler} />
            </EditorProvider>
          </Box>
        )}
      />
    </InputWrapper>
  );
};

export default RichTextInput;
