import { useContext, useMemo } from 'react';
import useProfileList from 'hooks/useProfileList';
import RoomReservationContext from 'contexts/RoomReservation/RoomReservationContext';
import {
  formatDateForReservation,
  formatIsoDateForReservation,
} from 'utils/date';
import useDesksReservations from 'hooks/useDesksReservations';

const useOtherUsersDeskReservations = () => {
  const { desksReservations } = useDesksReservations();
  const { selectedDate } = useContext(RoomReservationContext);

  const { data: workersData } = useProfileList();

  const formattedDate = useMemo(
    () => formatDateForReservation(selectedDate),
    [selectedDate]
  );
  const dateToCompare = useMemo(
    () => formatIsoDateForReservation(selectedDate),
    [selectedDate]
  );

  const desksReservationsBookerIds = useMemo(
    () =>
      desksReservations
        ?.filter((reservation) => reservation.date === dateToCompare)
        .map((reservation) => reservation.booker),
    [dateToCompare, desksReservations]
  );

  const bookersData = workersData.filter((worker) =>
    desksReservationsBookerIds?.some((bookerId) => bookerId === worker.id)
  );

  return {
    bookersData,
    formattedDate,
  };
};

export default useOtherUsersDeskReservations;
