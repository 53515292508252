import { memo, useContext } from 'react';

import AuthContext from 'contexts/Auth/AuthContext';
import useProfile from 'hooks/useProfile/useProfile';
import useProfileList from 'hooks/useProfileList/useProfileList';
import PageContent from 'components/PageContent/PageContent';
import UserExperienceInformation from './components/UserExperienceInformation/UserExperienceInformation';
import DisplayWrapper from 'components/DisplayWrapper';
import { WorkerSummary } from './components';
import { ProfileModalType } from 'contexts/Modal/ModalContext';
import AdminProfile from './components/AdminProfile';

const Profile = memo(() => {
  const { isStaff } = useContext(AuthContext);
  const { data: workerData } = useProfile();
  const { data: workersData } = useProfileList();

  return (
    <PageContent isLoading={!workerData || (isStaff && !workersData.length)}>
      {isStaff ? (
        <AdminProfile />
      ) : (
        <>
          <DisplayWrapper modalToOpen={ProfileModalType.EditProfile}>
            <WorkerSummary workerDetails={workerData} />
          </DisplayWrapper>
          <UserExperienceInformation />
        </>
      )}
    </PageContent>
  );
});

Profile.displayName = 'Profile';

export default Profile;
