import DOMPurify from 'dompurify';

export const compareStrings = (
  string: string,
  stringToCompare: string,
  ignoreCase?: boolean
) =>
  !string.localeCompare(stringToCompare, undefined, {
    sensitivity: ignoreCase ? 'accent' : undefined,
  });

export const capitalizeString = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

export const sanitizeAndDisplayHTML = (html: string) => {
  const sanitizedHTML = DOMPurify.sanitize(html);

  return { __html: sanitizedHTML };
};

export const parseHtmlToText = (html: string) =>
  new DOMParser().parseFromString(html, 'text/html').body.textContent ?? '';
