import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { DeskFeaturesGetResponse } from 'api/types/deskFeatures';

const useDeskFeatures = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.deskFeatures],
    queryFn: () =>
      apiGet<Array<DeskFeaturesGetResponse>>(API_URLS.deskFeatures),
  });

  return {
    features: data?.data ?? [],
    refetch,
    isLoading,
  };
};

export default useDeskFeatures;
