import { typography } from 'theme/index';

const styles = {
  input: {
    ...typography.body1,
    padding: '0.625rem 0.875rem',
    outline: 'none',
    height: '8rem',
    overflowY: 'auto',
  },
};

export default styles;
