import { Box, Link, Typography } from '@mui/material';
import {
  CalendarToday,
  VerifiedUser,
  Link as LinkIcon,
} from '@mui/icons-material';

import styles from './Certificates.styles';
import ActionButtons from '../ActionButtons';
import { parseAndFormatDate } from 'utils/date';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import { CertificateProps } from './Certificates.types';
import useMutateUserCertificates from 'hooks/useCertificates/useMutateUserCertificates';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import useCustomNavigation from 'src/router/useNavigation';

const Certificate = (props: CertificateProps) => {
  const { certificate, handleEdit, isReadonly, isLoading, isOffer } = props;
  const { id, name, issueDate, expirationDate, url } = certificate;
  const navigation = useCustomNavigation();

  const { userId } = useActiveWorker();
  const { deleteMutate } = useMutateUserCertificates();

  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () => userId && deleteMutate({ userId, certificateId: id }),
    title: `Are you sure you want to delete certificate${
      name ? `: ${name}` : ''
    }?`,
  });

  return (
    <Box sx={{ display: 'flex' }}>
      {isReadonly ? null : (
        <ActionButtons
          disabled={isLoading}
          isStart
          onDeleteHandler={openConfirmationModal}
          onEditHandler={() => handleEdit(certificate)}
        />
      )}
      <ConfirmationDialog />
      <Box sx={styles.certificateData}>
        <Box sx={styles.iconWrapper}>
          <CalendarToday sx={styles.icon} />
          <Typography variant="body1">
            {parseAndFormatDate(issueDate)}
            {expirationDate && ` - ${parseAndFormatDate(expirationDate)}`}
          </Typography>
        </Box>
        <Box sx={styles.iconWrapper}>
          <VerifiedUser sx={styles.icon} />
          <Typography variant="body1">{name}</Typography>
        </Box>
        {!isOffer && url && (
          <Link
            sx={styles.iconWrapper}
            underline="none"
            onClick={() => navigation.navigate(url)}
            target={'_blank'}
          >
            <LinkIcon sx={styles.icon} />
            <Typography>{url}</Typography>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default Certificate;
