import { Box } from '@mui/material';
import { FC, memo } from 'react';

import Level from './Level';
import Attitude from './Attitude';
import styles from './Legend.styles';

const Legend: FC = memo(() => (
  <Box sx={styles.root}>
    <Level />
    <Attitude />
  </Box>
));

Legend.displayName = 'Legend';

export default Legend;
