import { useQuery } from '@tanstack/react-query';
import { Offer } from 'api/types/offer';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import useCustomNavigation from 'src/router/useNavigation';
import { useContext } from 'react';
import OfferContext from 'contexts/Offer/OfferContext';
import { HttpStatusCode } from 'axios';

const useOffer = () => {
  const { isLogged, offerLogout } = useContext(OfferContext);
  const { params } = useCustomNavigation();
  const { offerName } = params as { offerName: string };
  const { activeUser, setActiveUser } = useContext(OfferContext);

  const query = useQuery({
    queryKey: [API_URLS.offer, offerName],
    queryFn: () =>
      apiGet<Offer>(`${API_URLS.offer}${offerName}/`).then((response) => {
        if (response?.data.workers[0] && activeUser === undefined) {
          setActiveUser(response?.data.workers[0].id);
        } else if (
          response?.status === HttpStatusCode.Forbidden ||
          response?.status === HttpStatusCode.NotFound
        ) {
          offerLogout();
        }

        return response;
      }),
    enabled: isLogged,
  });

  return {
    offerName,
    ...query,
    data: query?.data?.data,
    refetchOffer: query.refetch,
  };
};

export default useOffer;
