import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  emailIcon: {
    borderRadius: 45,
    width: '4rem',
    height: '4rem',
    fontSize: '3rem',
    background: COLORS.blue[500],
    paddingTop: '0.5rem',
  },
};

export default styles;
