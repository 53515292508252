import { Box, CircularProgress, ScopedCssBaseline } from '@mui/material';
import AppBar from 'components/AppBar';
import { AppBarContent } from 'components/AppBar/AppBar.types';
import Footer from 'components/Footer';
import { FC, PropsWithChildren, useMemo } from 'react';
import { PageContentProps } from './PageContent.types';
import { default as MakeStyles } from './PageContent.styles';
import LoginToPeopleModal from 'components/LoginToPeopleModal';

const PageContent: FC<PropsWithChildren<PageContentProps>> = ({
  header,
  appBarContent = AppBarContent.drawer,
  isLoading,
  children,
  fullWidth,
}) => {
  const styles = useMemo(() => MakeStyles(!!fullWidth), [fullWidth]);

  return (
    <ScopedCssBaseline sx={styles.baseLine}>
      <AppBar content={appBarContent} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {header}
          <Box sx={styles.content}>{children}</Box>
        </>
      )}
      <Footer />
      <LoginToPeopleModal />
    </ScopedCssBaseline>
  );
};

export default PageContent;
