import { z } from 'zod';
import {
  errorMessages,
  MAX_CERTIFICATE_LENGTH,
  MIN_DATE,
} from 'common/consts/validation';
import { isBefore } from 'date-fns';
import { option } from './types';

const coursesFormValidator = z
  .object({
    name: z
      .object(option, {
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      })
      .refine(({ label }) => label.length <= MAX_CERTIFICATE_LENGTH, {
        message: errorMessages.maxCharacters(MAX_CERTIFICATE_LENGTH),
      }),
    dateFrom: z
      .date({ invalid_type_error: errorMessages.required })
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
      .max(new Date(), { message: errorMessages.maxDate(new Date()) }),
    dateTo: z
      .date({
        invalid_type_error: errorMessages.required,
        required_error: errorMessages.required,
      })
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
  })
  .refine(({ dateFrom, dateTo }) => !isBefore(dateTo, dateFrom), {
    message: errorMessages.invalidRange,
    path: ['dateTo'],
  });

export default coursesFormValidator;
