import { Box, Button, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { InfoMessageProps } from './InfoMessageDeskReservation.types';
import infoMessageDeskReservationStyles from './InfoMessageDeskReservation.styles';
import { formatDateForReservation } from 'utils/date';
import useMutateDeskReservation from 'hooks/useMutateDeskReservation';

const InfoMessageDeskReservation: FC<InfoMessageProps> = ({
  date,
  reservationForChosenDate,
  formattedDate,
}) => {
  const { deleteMutate } = useMutateDeskReservation();

  const onDeleteReservation = useCallback(async () => {
    if (reservationForChosenDate) {
      await deleteMutate({
        desk: reservationForChosenDate.desk,
        dates: [formattedDate],
      });
    }
  }, [deleteMutate, reservationForChosenDate, formattedDate]);

  return (
    <Box sx={infoMessageDeskReservationStyles.boxStyle}>
      <Typography variant="sub2" sx={infoMessageDeskReservationStyles.header}>
        INFO
      </Typography>
      <Typography
        variant="sub2"
        sx={infoMessageDeskReservationStyles.subheader}
      >
        W dniu {formatDateForReservation(date as Date)} masz już rezerwację
        biurka
      </Typography>
      <Box sx={infoMessageDeskReservationStyles.buttonContainer}>
        <Button
          sx={infoMessageDeskReservationStyles.cancelButton}
          onClick={onDeleteReservation}
        >
          Anuluj rezerwacje
        </Button>
      </Box>
    </Box>
  );
};

export default InfoMessageDeskReservation;
