import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';

import coursesFormValidator from 'validation/coursesFormValidator';

import {
  CourseForm,
  CourseFormDialogProps,
  formSubmitData,
} from './CourseFormDialog.types';
import { parseNullishBackendDate } from 'utils/date';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import useCourses from 'hooks/useCourses';
import useMutateUserCourses from 'hooks/useCourses/useMutateUserCourses';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { formatToBackendFormData } from './CourseFormDialog.helpers';

const useCourseFormDialog = ({ course, onClose }: CourseFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === ProfileModalType.AddCourse ||
    currentModal === ProfileModalType.EditCourse;

  const { data, isLoading } = useCourses(isOpen);
  const { userId } = useActiveWorker();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { postMutate, patchMutate } = useMutateUserCourses();

  const methods = useForm<CourseForm>({
    resolver: zodResolver(coursesFormValidator),
  });

  const title = useMemo(
    () =>
      `${currentModal === ProfileModalType.AddCourse ? 'Add' : 'Edit'} course`,
    [currentModal]
  );

  const [dateFromWatcher, dateToWatcher] = methods.watch([
    'dateFrom',
    'dateTo',
  ]);

  const { dateFrom, dateTo, name } = course ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        name: name ? { value: name, label: name } : undefined,
      });
    }
  }, [dateFrom, dateTo, isOpen, methods, name]);

  useEffect(() => {
    if (dateFrom && methods.formState.isSubmitted) methods.trigger('dateTo');
  }, [methods, dateFrom]);

  const onSubmitHandler = async (data: formSubmitData) => {
    if (userId) {
      setIsSubmitting(true);
      const payload = {
        userId,
        course: formatToBackendFormData(data),
      };
      try {
        if (course) {
          await patchMutate({
            ...payload,
            course: { id: course.id, ...payload.course },
          });
        } else {
          await postMutate(payload);
        }
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    isOpen,
    methods,
    title,
    dateFromWatcher,
    dateToWatcher,
    onSubmitHandler,
    courses: data,
    isLoading: isLoading || isSubmitting,
  };
};

export default useCourseFormDialog;
