import useProfile from 'hooks/useProfile/useProfile';
import { ProfileModalType } from 'contexts/Modal/ModalContext';

import DisplayWrapper from 'components/DisplayWrapper';
import Projects from '../Projects';
import { ProgrammingLanguages, Skills } from '../Skills';
import Educations from '../Educations';
import Certificates from '../Certificates';
import Courses from '../Courses';
import { SkillsProps } from '../Skills/Skills.types';

const UserExperienceInformation = () => {
  const { data: workerData, isFetching } = useProfile();

  const { skills, projectSet, educationSet, certificateSet, courseSet } =
    workerData || {};

  const skillsProps: SkillsProps = {
    isLoading: isFetching,
    showLegend: true,
    skills,
  };

  return (
    <>
      <DisplayWrapper modalToOpen={ProfileModalType.AddLanguage}>
        <ProgrammingLanguages {...skillsProps} />
      </DisplayWrapper>
      <DisplayWrapper modalToOpen={ProfileModalType.AddSkill}>
        <Skills {...skillsProps} />
      </DisplayWrapper>
      <DisplayWrapper modalToOpen={ProfileModalType.AddProject}>
        <Projects projects={projectSet} />
      </DisplayWrapper>
      <DisplayWrapper modalToOpen={ProfileModalType.AddEducation}>
        <Educations educations={educationSet} />
      </DisplayWrapper>
      <DisplayWrapper modalToOpen={ProfileModalType.AddCertificate}>
        <Certificates certificates={certificateSet} />
      </DisplayWrapper>
      <DisplayWrapper modalToOpen={ProfileModalType.AddCourse}>
        <Courses courses={courseSet} />
      </DisplayWrapper>
    </>
  );
};

export default UserExperienceInformation;
