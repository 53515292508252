import { Box, Typography } from '@mui/material';
import DisplayWrapper from 'components/DisplayWrapper';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { useContext, useMemo } from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import styles from './ReservedDesk.styles';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { formatDateForReservation } from 'utils/date';
import useDesk from 'hooks/useDesk';
import useDeskReservationsForDay from 'hooks/useDeskReservationsForDay';
import useProfileList from 'hooks/useProfileList';

const ReservedDesk = () => {
  const { date, activeDesk } = useContext(DeskContext);
  const { desk } = useDesk();
  const { deskReservations } = useDeskReservationsForDay();
  const { data } = useProfileList(true);

  const activeReservedDesk = useMemo(
    () =>
      deskReservations?.find(
        (deskReservation) => deskReservation.desk === activeDesk
      )?.booker,
    [activeDesk, deskReservations]
  );
  const deskBooker = useMemo(
    () => data?.find((booker) => booker.id === activeReservedDesk)?.title,
    [activeReservedDesk, data]
  );

  if (activeDesk === null) return <></>;

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant="h1">Zarezerwowane biurko {desk?.name}</Typography>
        <Box sx={styles.featuresContainer}>
          {desk?.features
            ? desk.features.map((feature) => (
                <Typography sx={styles.featureText} key={feature.id}>
                  {feature?.name}
                </Typography>
              ))
            : null}
        </Box>
        <Box sx={styles.textContainer}>
          <PersonOutlineOutlinedIcon />
          <Typography>{deskBooker}</Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <CalendarTodayOutlinedIcon />
          <Typography>{formatDateForReservation(date as Date)}</Typography>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default ReservedDesk;
