import { COLORS } from 'theme/Theme.const';
import { LegendContent } from './Legend.types';

export const LEGEND_CONTENT: Array<LegendContent> = [
  {
    color: COLORS.blue[300],
    text: 'Zarezerwowane przez Ciebie',
  },
  {
    color: COLORS.roomReservationsLegendColors.green,
    text: 'Dostępne do rezerwacji',
  },
  {
    color: COLORS.roomReservationsLegendColors.darkOrange,
    text: 'Pokój jest zarezerwowany',
  },
  {
    color: COLORS.roomReservationsLegendColors.lightOrange,
    text: 'Biurka w pokoju są zarezerwowane',
  },
];

export const DESK_CONTENT: Array<LegendContent> = [
  {
    color: COLORS.blue[300],
    text: 'Zarezerwowane przez Ciebie',
  },
  {
    color: COLORS.green[100],
    text: 'Dostępne do rezerwacji',
  },
  {
    color: COLORS.roomReservationsLegendColors.darkOrange,
    text: 'Zarezerwowane',
  },
  {
    color: COLORS.roomReservationsLegendColors.lightOrange,
    text: 'Zarezerwowany pokój cichej pracy',
  },
  {
    color: COLORS.gray[200],
    text: 'Niedostępne do rezerewacji',
  },
];

export const DESK_ADMIN_CONTENT: Array<LegendContent> = [
  {
    color: COLORS.green[100],
    text: 'Hot Deski',
  },
  {
    color: COLORS.gray[200],
    text: 'Zwykłe biurko',
  },
];
