import { Box } from '@mui/material';
import useOffer from 'hooks/useOffer';
import useOfferWorkers from 'hooks/useOfferWorkers';
import { Fragment, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import OfferBody from '../OfferBody';
import DrawerItem from 'components/AppBar/DrawerItem';
import { Print } from '@mui/icons-material';
import styles, { printMediaQueries } from './OfferPrint.styles';
import FrontPage from './FrontPage';
import DisplayWrapper from 'components/DisplayWrapper';
import { ThemeProvider } from '@mui/material/styles';
import { printTheme } from 'theme/Theme';

const OfferPrint = () => {
  const { data } = useOffer();
  const { workers, isLoading } = useOfferWorkers();

  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `Offer for ${data?.company}`,
  });

  return (
    <>
      {/* We use DrawerItem here just so we have similar styling to items in header */}
      <DrawerItem
        Icon={Print}
        disabled={isLoading}
        onClick={handlePrint}
        label={'Print'}
      />
      <ThemeProvider theme={printTheme}>
        <Box ref={ref} sx={styles.printWrapper}>
          <FrontPage />
          {!isLoading &&
            workers?.map((worker, i) => (
              <Fragment key={`worker-${i}`}>
                <DisplayWrapper sx={styles.flat}>
                  <OfferBody worker={worker?.data} />
                </DisplayWrapper>
                <Box sx={styles.pageBreak} />
              </Fragment>
            ))}
          <style>{printMediaQueries}</style>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default OfferPrint;
