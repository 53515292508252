import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { KudosGetResponse } from 'api/types/kudos';

interface KudosOptions {
  pageNumber: number;
  isSentKudosView?: boolean;
}

const useKudos = ({ pageNumber, isSentKudosView }: KudosOptions) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [pageNumber, isSentKudosView],
    queryFn: () =>
      apiGet<KudosGetResponse>(
        `${isSentKudosView ? API_URLS.sentKudos : API_URLS.kudos}?page=${pageNumber}`
      ),
  });

  return {
    data: data?.data,
    isLoading,
    refetchKudos: refetch,
  };
};

export default useKudos;
