import { FC, memo, useContext } from 'react';
import useOptions from './useOptions';
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { RadioLabels, RadioValue } from './Controls.types';
import ProjectsDepartmentsContext from 'contexts/ProjectsDepartments/ProjectsDepartmentsContext';

const Controls: FC = memo(() => {
  const { departmentsOptions, projectsDepartmentsOptions } = useOptions();
  const {
    radioValue,
    setRadioValue,
    optionProject,
    optionDepartment,
    setOptionProject,
    setOptionDepartment,
  } = useContext(ProjectsDepartmentsContext);

  return (
    <>
      <Typography variant="h1">Projekty i działy</Typography>
      <RadioGroup
        value={radioValue}
        onChange={(event) => {
          const value = event.target.value as RadioValue;
          setRadioValue(value);
        }}
        sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
      >
        <FormControlLabel
          control={<Radio />}
          value="projects"
          label={RadioLabels.projects}
        />
        <Autocomplete
          disabled={radioValue !== 'projects'}
          noOptionsText={'Brak projektu'}
          options={projectsDepartmentsOptions}
          renderInput={(params) => <TextField {...params} label={'Projekty'} />}
          onChange={(event, newValue) => {
            event.preventDefault();
            setOptionProject(newValue);
          }}
          value={optionProject}
          isOptionEqualToValue={(option, selectedOption) =>
            option.value === selectedOption.value && option !== null
          }
        />
        <FormControlLabel
          control={<Radio />}
          value="departments"
          label={RadioLabels.departments}
        />
        <Autocomplete
          disabled={radioValue !== 'departments'}
          noOptionsText={'Brak działu'}
          options={departmentsOptions}
          renderInput={(params) => <TextField {...params} label={'Działy'} />}
          onChange={(event, newValue) => {
            event.preventDefault();
            setOptionDepartment(newValue);
          }}
          value={optionDepartment}
          isOptionEqualToValue={(option, selectedOption) =>
            option.value === selectedOption.value && option !== null
          }
        />
      </RadioGroup>
    </>
  );
});

Controls.displayName = 'Controls';

export default Controls;
