import { UserHolidayPoolSummary } from 'hooks/useUserHolidayPool/useUserHolidayPool.types';

export enum UserHolidaysPoolSummaryStyle {
  Full = 'full',
  Short = 'short',
  ShortWithName = 'short_with_name',
}

export interface UserHolidaysPoolSummaryProps {
  holidaysPool: UserHolidayPoolSummary;
  type?: UserHolidaysPoolSummaryStyle;
}
