const styles = {
  root: (isStart?: boolean) => ({
    display: 'flex',
    gap: '0.5rem',
    alignSelf: 'center',
    ...(isStart ? { mr: 2, gap: 0 } : {}),
  }),
};

export default styles;
