import { Box, Typography } from '@mui/material';
import { Fragment, memo, useContext, useMemo, useState } from 'react';

import Course from './Course';
import CourseFormDialog from './CourseFormDialog';

import { CoursesProps } from './Courses.types';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import EmptySection from '../EmptySection';
import styles from './Courses.styles';

const Courses = memo((props: CoursesProps) => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);
  const { isReadonly, courses, hideEmpty } = props;

  const areCoursesEmpty = useMemo(() => !courses?.length, [courses?.length]);

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  if (hideEmpty && areCoursesEmpty) return <></>;

  return (
    <Box sx={styles.root}>
      <Typography component="h1" variant="h1">
        Courses
      </Typography>
      {!areCoursesEmpty ? (
        courses?.map((course, index) => (
          <Fragment key={`course-${index}`}>
            <Course
              key={course.id}
              course={course}
              handleEdit={() => {
                setEditId(course.id);
                openModal(ProfileModalType.EditCourse);
              }}
              isReadonly={isReadonly}
              hideEmpty={hideEmpty}
            />
            {!isReadonly &&
              currentModal === ProfileModalType.EditCourse &&
              editId === course.id && (
                <CourseFormDialog course={course} onClose={onCloseModal} />
              )}
          </Fragment>
        ))
      ) : (
        <EmptySection />
      )}
      {!isReadonly && currentModal === ProfileModalType.AddCourse && (
        <CourseFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Courses.displayName = 'Courses';

export default Courses;
