import { memo, useContext } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import ModalContext, { GlobalModalType } from 'contexts/Modal/ModalContext';
import styles from './LoginToPeopleModal.styles';
import { REACT_APP_BACKEND_PEOPLE_URL } from 'api/axios/axiosPeople.consts';
import { fetchUser } from 'hooks/useUsers/useUser';

const LoginToPeopleModal = memo(() => {
  const { closeModal, currentModal } = useContext(ModalContext);

  const onLoadIframe = () => {
    void checkLoginToPeople();
  };

  const checkLoginToPeople = async () => {
    const user = await fetchUser();
    if (user && 'id' in user) {
      closeModal();
    }
  };

  return (
    <Dialog
      sx={styles.dialog}
      PaperProps={{ sx: styles.dialogPaper }}
      open={currentModal === GlobalModalType.LoginToPeople}
    >
      <DialogContent sx={styles.dialogContent}>
        <iframe
          width="960"
          height="640"
          src={REACT_APP_BACKEND_PEOPLE_URL}
          onLoad={onLoadIframe}
        />
      </DialogContent>
    </Dialog>
  );
});

LoginToPeopleModal.displayName = 'LoginToPeopleModal';

export default LoginToPeopleModal;
