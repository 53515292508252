import { FC, PropsWithChildren, useContext } from 'react';
import { Box, IconButton, Paper } from '@mui/material';

import { Add, Edit } from '@mui/icons-material';
import { DisplayWrapperProps } from './DisplayWrapper.types';
import styles from './DisplayWrapper.styles';
import ModalContext from 'contexts/Modal/ModalContext';

const DisplayWrapper: FC<PropsWithChildren<DisplayWrapperProps>> = ({
  children,
  modalToOpen,
  sx = {},
}) => {
  const { openModal } = useContext(ModalContext);

  return (
    <Paper sx={[styles.paper, sx]}>
      <Box sx={styles.childrenWrapper}>{children}</Box>
      {modalToOpen && (
        <Box>
          <IconButton
            onClick={() => openModal(modalToOpen)}
            sx={styles.actionButton}
            color="primary"
          >
            {modalToOpen?.includes('Add') ? (
              <Add color="inherit" />
            ) : (
              <Edit color="inherit" />
            )}
          </IconButton>
        </Box>
      )}
    </Paper>
  );
};

export default DisplayWrapper;
