import {
  filterOptions,
  NEW_VALUE_PREFIX,
} from 'components/AutoCompleteInput/AutoCompleteInput.const';
import { AutoCompleteOption } from 'components/AutoCompleteInput/AutoCompleteInput.types';
import { FilterOptionsState } from '@mui/material';
import { Nullable, Option } from 'common/types';
import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import { compareStrings } from 'utils/string';

export const getFilterOptions = (
  options: Array<AutoCompleteOption>,
  params: FilterOptionsState<Option<Nullable<number | string>, string>> & {
    inputValue?: string;
  }
) => {
  const filtered = filterOptions(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some((option) =>
    compareStrings(inputValue, option.label, true)
  );

  if (inputValue !== '' && !isExisting)
    filtered.unshift({
      inputValue,
      label: `Add "${inputValue}"`,
      value: `${NEW_VALUE_PREFIX}${inputValue}`,
    });

  return filtered;
};

export const getOptionLabel = (option: string | AutoCompleteOption) => {
  if (typeof option === 'string') return option;

  return option.inputValue ? option.inputValue : option.label ?? '';
};

export const transformEventValue = (
  newValue: AutocompleteValue<
    string | AutoCompleteOption,
    false,
    boolean,
    boolean
  >
) =>
  typeof newValue === 'string'
    ? {
        label: newValue,
        value: `${NEW_VALUE_PREFIX}${newValue}`,
      }
    : newValue && newValue.inputValue
      ? {
          label: newValue.inputValue,
          value: `${NEW_VALUE_PREFIX}${newValue.inputValue}`,
        }
      : newValue;
