import { Box, Button, Typography } from '@mui/material';
import DisplayWrapper from 'components/DisplayWrapper';
import styles from './NotFound.styles';
import PageContent from 'components/PageContent';
import { CSSProperties, memo } from 'react';
import useCustomNavigation from 'src/router/useNavigation';
import NotFoundSvg from 'assets/images/404.svg';
import { RouterPaths } from 'src/router/types';

const NotFound = memo(() => {
  const navigation = useCustomNavigation();

  return (
    <PageContent>
      <DisplayWrapper sx={styles.displayWrapper}>
        <Box sx={styles.root}>
          <Typography variant="extra">O kurka!</Typography>
          <Typography variant="h1">
            Wygląda na to, że strona, której szukasz nie istnieje!
          </Typography>
          <img
            src={NotFoundSvg}
            alt={'Not found'}
            style={styles.img as CSSProperties}
          />
          <Button
            variant="contained"
            onClick={() => navigation.navigate(RouterPaths.home)}
          >
            Wróć na start
          </Button>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

NotFound.displayName = 'NotFound';

export default NotFound;
