import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Settings } from '@mui/icons-material';
import DrawerItem from 'components/AppBar/DrawerItem';
import { OfferSettingsI } from 'api/types/offer';
import OfferContext from 'contexts/Offer/OfferContext';
import useOffer from 'hooks/useOffer';
import useOfferSettings, {
  useMutateOfferSettings,
} from 'hooks/useOfferSettings';

const OfferSettings = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { offerSettings, setOfferSettings } = useContext(OfferContext);
  const { offerName } = useOffer();

  const mutate = useMutateOfferSettings();
  const settings = useOfferSettings(setOfferSettings, offerName);
  const updateSettings = async (settingKey: keyof OfferSettingsI) => {
    if (!offerSettings) return;

    const newSettings = { ...offerSettings };
    newSettings[settingKey] = !newSettings[settingKey];
    mutate(newSettings);
  };

  return (
    <Box onMouseLeave={handleClose}>
      <DrawerItem
        Icon={Settings}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={'Settings'}
        disabled={settings.isLoading}
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ pointerEvents: 'none' }}
        MenuListProps={{
          sx: {
            pointerEvents: 'all',
          },
        }}
        onMouseLeave={handleClose}
      >
        <FormGroup>
          {Object.entries(offerSettings ?? {}).map(
            ([settingKey, settingValue]) => (
              <MenuItem key={settingKey}>
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={() =>
                    updateSettings(settingKey as keyof OfferSettingsI)
                  }
                  label={settingKey}
                  checked={settingValue}
                />
              </MenuItem>
            )
          )}
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default OfferSettings;
