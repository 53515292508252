import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    textAlign: 'center',
  },
  link: {
    color: COLORS.blue[200],
    textDecoration: 'none',
  },
  button: {
    width: '16rem',
  },
  imageContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    maxWidth: '31.25rem',
  },
  image: { height: '6.25rem', width: '6.25rem' },
  removeImageButton: {
    left: '8.5rem',
    bottom: '6rem',
    size: '16px',
    height: '1rem',
    width: '1rem',
    background: COLORS.white,
    borderRadius: '50%',
    minWidth: 'auto',
  },
};

export default styles;
