import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { KeyValueBase } from 'api/types/common';

export const fetchDepartments = () =>
  apiGet<Array<KeyValueBase>>(API_URLS.departments);

const useDepartments = () => {
  const query = useQuery({
    queryKey: [API_URLS.departments],
    queryFn: fetchDepartments,
  });

  return {
    ...query,
    data: query.data?.data,
    refetchDepartments: query.refetch,
  };
};

export default useDepartments;
