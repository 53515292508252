import { memo, useContext, Fragment, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DelegationsProps } from './Delegation.types';
import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import Delegation from './Delegation';
import EmptySection from '../../EmptySection';
import DelegationFormDialog from './DelegationFormDialog/DelegationFormDialog';
import styles from '../AdminProfile.styles';

const Delegations = memo(({ delegations }: DelegationsProps) => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  return (
    <Box>
      <Typography component="h1" variant="h1">
        Delegations
      </Typography>
      <Box sx={styles.contentWrapper}>
        {delegations && delegations.length > 0 ? (
          delegations.map((delegation) => (
            <Fragment key={`${delegation.id}-delegation`}>
              <Delegation
                delegation={delegation}
                handleEditDelegation={() => {
                  setEditId(delegation.id);
                  openModal(AdminProfileModalType.EditDelegation);
                }}
              />
              {currentModal === AdminProfileModalType.EditDelegation &&
                editId === delegation.id && (
                  <DelegationFormDialog
                    delegation={delegation}
                    onClose={onCloseModal}
                  />
                )}
            </Fragment>
          ))
        ) : (
          <EmptySection />
        )}
      </Box>
      {currentModal === AdminProfileModalType.AddDelegation && (
        <DelegationFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Delegations.displayName = 'Delegations';

export default Delegations;
