import { memo, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { isEmpty } from 'pages/Profile/Profile.helpers';
import styles from './DescriptionSection.styles';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';
import { ProjectSectionProps } from '../Projects.types';
import { sanitizeAndDisplayHTML } from 'utils/string';

const DescriptionSection = memo((props: ProjectSectionProps) => {
  const { project, hideEmpty } = props;
  const { description, projectTechnologies } = project;

  const descriptionString = useMemo(
    () => description || DEFAULT_EMPTY_STRING,
    [description]
  );

  const shouldHideComponent = useMemo(
    () =>
      hideEmpty && isEmpty(descriptionString) && !projectTechnologies.length,
    [descriptionString, hideEmpty, projectTechnologies.length]
  );

  if (shouldHideComponent) return <></>;

  return (
    <Box sx={styles.descriptionContainer}>
      {!(isEmpty(descriptionString) && hideEmpty) && (
        <Box sx={styles.descriptionColumn}>
          <Typography variant="sub2">About Project</Typography>
          <Typography
            variant="body2"
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={sanitizeAndDisplayHTML(descriptionString)}
          />
        </Box>
      )}
      {!(!projectTechnologies.length && hideEmpty) && (
        <Box sx={styles.descriptionColumn}>
          <Typography variant="sub2">Key technologies</Typography>
          {projectTechnologies.length ? (
            <Box sx={styles.technologiesContainer}>
              {projectTechnologies.map((item, technologyIndex) => (
                <Box
                  key={`technology-${technologyIndex}`}
                  sx={styles.technologyChip}
                >
                  <Typography variant="body2" sx={{ color: 'primary.light' }}>
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">{DEFAULT_EMPTY_STRING}</Typography>
          )}
        </Box>
      )}
    </Box>
  );
});

DescriptionSection.displayName = 'DescriptionSection';

export default DescriptionSection;
