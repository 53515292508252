import { useForm } from 'react-hook-form';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  formSubmitData,
  ProjectForm,
  ProjectFormDialogProps,
} from './ProjectFormDialog.types';
import { parseNullishBackendDate } from 'utils/date';
import { zodResolver } from '@hookform/resolvers/zod';
import projectValidator from 'validation/projectValidator';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import { mapKeyValueToOption } from 'utils/objects';
import usePositions from 'hooks/usePositions';
import useProfile from 'hooks/useProfile';
import useMutateUserProject from 'hooks/useProjects/useMutateUserProject';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { formatToBackendFormData } from './ProjectFormDialog.helpers';
import { NEW_VALUE_PREFIX } from 'components/AutoCompleteInput/AutoCompleteInput.const';
import useMutateProject from 'hooks/useProjects/useMutateProject';
import useProjects from 'hooks/useProjects';

const useProjectFormDialog = ({ project, onClose }: ProjectFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching: isProfileLoading } = useProfile();
  const { data, isFetching: arePositionsLoading } = usePositions();
  const { userId } = useActiveWorker();

  const {
    postMutate: postMutateUserProject,
    patchMutate: patchMutateUserProject,
  } = useMutateUserProject();
  const { postMutate, patchMutate } = useMutateProject();

  const { data: projectList, refetch: refetchProjects } = useProjects(
    true,
    userId
  );

  const {
    dateFrom,
    dateTo,
    projectDetails,
    position,
    description,
    projectTechnologies,
  } = project ?? {};

  const { teamSize } = projectDetails ?? {};

  const isOpen = useMemo(
    () =>
      currentModal === ProfileModalType.AddProject ||
      currentModal === ProfileModalType.EditProject,
    [currentModal]
  );

  const methods = useForm<ProjectForm>({
    resolver: zodResolver(projectValidator),
    defaultValues: {
      atPresent: false,
    },
  });

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        name: projectDetails ? mapKeyValueToOption(projectDetails) : undefined,
        position: position ? mapKeyValueToOption(position) : undefined,
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        description,
        technologies: projectTechnologies?.map(mapKeyValueToOption) ?? [],
        teamSize: teamSize ? `${teamSize}` : undefined,
        atPresent: !dateTo?.length,
      });
    }
  }, [
    data,
    dateFrom,
    dateTo,
    description,
    isOpen,
    methods,
    position,
    projectDetails,
    projectTechnologies,
    teamSize,
  ]);

  const title = useMemo(
    () =>
      `${
        currentModal === ProfileModalType.AddProject ? 'Add' : 'Edit'
      } project`,
    [currentModal]
  );

  const onSubmitHandler = async (data: formSubmitData) => {
    const { name, description, teamSize } = data ?? {};
    const newTeamSize = Number(teamSize);
    const { value: projectId, label: projectName } = name;

    const isNewProject = projectId.includes(NEW_VALUE_PREFIX);

    let newProjectId = undefined;

    if (isNewProject) {
      try {
        const { data: newProjectData } = await postMutate({
          name: projectName,
          description,
          teamSize: newTeamSize,
          refetch: false,
        });

        newProjectId = newProjectData.id;
      } catch {
        return;
      }
    }

    if (userId) {
      setIsSubmitting(true);
      const projectPayload = formatToBackendFormData(data);
      const wasNewProjectCreated = isNewProject && newProjectId;

      if (newProjectId) {
        projectPayload.projectDetails = `${newProjectId}`;
      }

      const payload = {
        userId,
        project: projectPayload,
      };

      try {
        if (projectList?.find(({ id }) => `${id}` === projectId)?.isExternal) {
          // Patching external project itself and not just relation to user
          await patchMutate({
            projectId: newProjectId ?? Number(projectId),
            teamSize: newTeamSize,
          });
        }

        if (project) {
          await patchMutateUserProject({
            ...payload,
            project: { id: project.id, ...payload.project },
          });
        } else {
          await postMutateUserProject(payload);
        }
      } finally {
        if (wasNewProjectCreated) {
          refetchProjects();
        }

        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    isOpen,
    methods,
    title,
    isLoading: isSubmitting || arePositionsLoading || isProfileLoading,
    onSubmitHandler,
  };
};

export default useProjectFormDialog;
