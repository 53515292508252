const styles = {
  hide: {
    'display': 'none',

    '@media print': {
      display: 'block',
    },
  },
};

export default styles;
