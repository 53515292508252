import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import {
  MutateUserProjectDeleteProps,
  MutateUserProjectPatchProps,
  MutateUserProjectPostProps,
} from './useProjects.types';
import useProfile from 'hooks/useProfile/useProfile';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const getProjectUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.projects}`;

export const useMutateUserProject = () => {
  const { refetchProfile } = useProfile();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, project }: MutateUserProjectPatchProps) =>
      apiPatch(`${getProjectUrl(userId)}${project.id}/`, project),
    onSuccess: () => {
      enqueueSnackbar('Project successfully changed', {
        type: SnackbarType.success,
      });
      refetchProfile();
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ userId, projectId }: MutateUserProjectDeleteProps) =>
      apiDelete(`${getProjectUrl(userId)}${projectId}/`),
    onSuccess: () => {
      enqueueSnackbar('Project successfully deleted', {
        type: SnackbarType.info,
      });
      refetchProfile();
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, project }: MutateUserProjectPostProps) =>
      apiPost(getProjectUrl(userId), project),
    onSuccess: () => {
      enqueueSnackbar('Project successfully added', {
        type: SnackbarType.success,
      });
      refetchProfile();
    },
  });

  return {
    patchMutate,
    deleteMutate,
    postMutate,
  };
};

export default useMutateUserProject;
