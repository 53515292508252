import { createContext, Dispatch, SetStateAction } from 'react';
import { OfferSettingsI } from 'api/types/offer';

interface IOfferContext {
  isLogged?: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>;
  offerLogout: () => void;
  activeUser?: number;
  setActiveUser: Dispatch<SetStateAction<number | undefined>>;
  offerSettings?: OfferSettingsI;
  setOfferSettings: Dispatch<SetStateAction<OfferSettingsI | undefined>>;
}

const OfferContext = createContext<IOfferContext>({
  offerLogout: () => void 0,
  setIsLogged: () => void 0,
  setActiveUser: () => void 0,
  setOfferSettings: () => void 0,
});

export default OfferContext;
