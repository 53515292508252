const formStyles = {
  main: (desktopColumns: string) => ({
    display: 'grid',
    gap: { Mobile: '1rem 0', Tablet: '2rem 1.5rem' },
    gridTemplateColumns: { Mobile: '1fr', Desktop: desktopColumns },
  }),
  secondary: {
    flexDirection: 'column',
    display: 'flex',
    width: { Mobile: '100%', Desktop: '75%' },
    mt: 1,
  },
  sectionsWrapper: {
    display: 'flex',
    flexDirection: { Mobile: 'column', Desktop: 'row' },
    gap: { Tablet: '0', Desktop: '9rem' },
  },
  section: { display: 'flex', flexDirection: 'column', gap: 0.5 },
  addForm: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    mb: 2.5,
  },
  chipClose: {
    button: {
      ml: 0.5,
      padding: 0,
    },
    icon: { width: 12, height: 12 },
  },
  addInputs: {
    display: 'grid',
    gridTemplateColumns: { Mobile: '1fr', Tablet: '3fr 1fr 1fr' },
    gap: { Mobile: 0, Tablet: '1.5rem' },
  },
  doubleInputs: {
    display: 'grid',
    gap: '0.5rem 1.5rem',
    gridTemplateColumns: { Mobile: '1fr', Desktop: '1fr 1fr' },
    mb: { Mobile: 0, Desktop: 1 },
  },
  tripleInputs: {
    display: 'grid',
    gap: '0 1.5rem',
    gridTemplateColumns: { Mobile: '1fr', Desktop: '1fr 1fr 1fr' },
  },
  checkboxInput: {
    gridColumn: { Mobile: 1, Desktop: 2 },
    label: { marginLeft: 0 },
    marginTop: '-0.8rem',
  },
  formChip: {
    span: {
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  addButton: {
    height: '3.5rem',
    marginTop: { Mobile: 2.5, Tablet: 0 },
  },
};

export default formStyles;
