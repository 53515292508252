const styles = {
  iconLabel: {
    display: 'flex',
    alignItems: 'start',
    gap: '0.5rem',
  },
  icon: {
    marginTop: 0.25,
    width: '1rem',
    height: '1rem',
  },
};

export default styles;
