import { HolidayType, UserHolidayApi } from 'api/types/holiday';
import { MuiIcon, SxStyle } from 'theme/Theme.types';

export const HolidaysTypeDict: Partial<Record<HolidayType, string>> = {
  [HolidayType.Leisure]: 'wypoczynkowy',
  [HolidayType.OnDemand]: 'na żądanie',
  [HolidayType.Company]: 'firmowy',
  [HolidayType.Unpaid]: 'bezpłatny',
  [HolidayType.Care]: 'opieka nad dzieckiem',
  [HolidayType.Compassionate]: 'okolicznościowy',
  [HolidayType.DelegationComp]: 'za delegację',
  [HolidayType.NationalHoliday]: 'za święto',
};

export interface HolidaysProps {
  holidays: Array<UserHolidayApi>;
}

export interface HolidayProps {
  holiday: UserHolidayApi;
  handleEditHoliday: () => void;
}

export interface HolidayRowView {
  onDeleteHandler?: () => void;
  onEditHandler?: () => void;
  onAcceptHandler?: () => void;
  style?: SxStyle;
  icon: MuiIcon;
}
