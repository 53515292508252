import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { CertificatesGetResponse } from 'api/types/certificate';

export const fetchCertificates = () =>
  apiGet<CertificatesGetResponse>(API_URLS.certificates);

const useCertificates = (enabled?: boolean) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.certificates],
    queryFn: fetchCertificates,
    enabled,
  });

  return {
    data: data?.data ?? [],
    refetch,
    isLoading,
  };
};

export default useCertificates;
