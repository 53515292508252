import { useQuery } from '@tanstack/react-query';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { peopleApiGet } from 'api/axios';
import { AxiosError } from 'axios';

import { formatToNullableBackendDate } from 'utils/date';
import { HttpStatusCode } from 'axios';
import {
  UserHolidayGetResponse,
  UserHolidaysParameters,
} from './useUsersCalendarHolidays.types';
import { UserHoliday, UserHolidays } from 'api/types/holiday';

export const fetchUsersCalendarHolidays = async (
  parameters?: UserHolidaysParameters
) => {
  try {
    const response = await peopleApiGet<UserHolidayGetResponse>(
      PEOPLE_API_URLS.holidayUser,
      {
        params: {
          from: formatToNullableBackendDate(parameters?.from || null),
          to: formatToNullableBackendDate(parameters?.to || null),
          user: parameters?.userId || null,
        },
      }
    );

    return response.data.results;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (
        [HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(
          error.response?.status || 0
        )
      ) {
        return [];
      }
    }
    console.error(error);
    throw error;
  }
};

const useUsersCalendarHolidays = (parameters?: UserHolidaysParameters) => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [
      PEOPLE_API_URLS.user,
      parameters?.from,
      parameters?.to,
      parameters?.userId,
    ],
    queryFn: () => fetchUsersCalendarHolidays(parameters),
  });
  const newData = data?.map<UserHolidays>((userHolidays) => {
    return {
      firstName: userHolidays.firstName,
      id: userHolidays.id,
      lastName: userHolidays.lastName,
      holidaySet: userHolidays.holidaySet.map<UserHoliday>(
        ({ dateFrom, dateTo, ...userHoliday }) => {
          return {
            ...userHoliday,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
          };
        }
      ),
    };
  });

  return {
    data: newData || [],
    refetch,
    isLoading,
    isFetching,
  };
};

export default useUsersCalendarHolidays;
