import { Box, Button, Typography } from '@mui/material';
import {
  CSRF_HIDDEN_FORM_FIELD_NAME,
  CSRF_TOKEN_COOKIE_NAME,
} from 'api/axios/axios.consts';
import { AppBarContent } from 'components/AppBar/AppBar.types';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent';
import AuthContext from 'contexts/Auth/AuthContext';
import Cookies from 'js-cookie';
import { FC, useContext } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { Navigate } from 'react-router-dom';
import { RouterPaths } from 'src/router/types';
import styles from './Login.styles';

const Login: FC = () => {
  const { isAuth, isLoading } = useContext(AuthContext);

  if (isAuth) {
    return <Navigate to={RouterPaths.home} />;
  }

  return (
    <PageContent isLoading={isLoading} appBarContent={AppBarContent.blank}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="extra">Zaloguj się do intranetu</Typography>
          <form
            method="POST"
            action={`${process.env.REACT_APP_BACKEND_URL}/accounts/google/login/`}
          >
            <input
              type="hidden"
              name={CSRF_HIDDEN_FORM_FIELD_NAME}
              value={Cookies.get(CSRF_TOKEN_COOKIE_NAME)}
            />
            <Button type="submit" sx={styles.button}>
              <FcGoogle size={28} />
              Zaloguj przez google
            </Button>
          </form>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
};

export default Login;
