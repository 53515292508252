import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1rem',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    gap: '1rem',
  },
  subHeader: {
    minHeight: '2rem',
  },
  iconWrapper: {
    width: 60,
    height: 60,
  },
};

export default styles;
