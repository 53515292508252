import { AutoCompleteOption } from 'components/AutoCompleteInput';
import { useEffect, useMemo, useState } from 'react';
import { holidayCalendarForm, userOrAllOption } from '../HolidayCalendar.types';
import { Nullable } from 'common/types';
import { useUser } from 'hooks/useUsers/useUser';
import { useForm } from 'react-hook-form';
import { SelectUserProps } from './HolidayApplicationForm.types';
import useUsersCalendarHolidays from 'hooks/useCalendarHoliday';
import { UserHolidays } from 'api/types/holiday';

export const useSelectUser = ({ setSelectUser }: SelectUserProps) => {
  const user = useUser();
  const methods = useForm<holidayCalendarForm>();

  // To Do: use diffrent endpoint to feach list of users
  const { data: usersHolidays } = useUsersCalendarHolidays();

  const [selectUserInitialized, setSelectUserInitialized] =
    useState<boolean>(false);
  const [selectUserOption, setSelectUserOption] =
    useState<Nullable<userOrAllOption>>(null);

  const usersToSelect: Array<userOrAllOption> = useMemo(
    () =>
      (usersHolidays || []).reduce<userOrAllOption[]>(
        (seed: Array<userOrAllOption>, userHolidays: UserHolidays) => {
          seed.push({
            value: userHolidays.id,
            label: `${userHolidays.firstName} ${userHolidays.lastName}`,
          });

          return seed;
        },
        [{ value: 'all', label: 'Wszyscy' }]
      ),
    [usersHolidays]
  );

  const handleUserChange = (
    event: React.SyntheticEvent,
    value: AutoCompleteOption | string | null
  ) => {
    setSelectUserOption(value as userOrAllOption);
    const user = usersHolidays.find(
      ({ id }) => (value as AutoCompleteOption)?.value === id
    );
    if (user) {
      setSelectUser({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
      });
    }
  };

  useEffect(() => {
    if (
      selectUserInitialized === false &&
      user.isLoading === false &&
      usersToSelect.length > 1
    ) {
      const optionToSelect = usersToSelect.find(
        (userOption) => userOption.value === user.data?.id
      );
      if (
        optionToSelect &&
        selectUserOption?.value != optionToSelect.value &&
        user.data
      ) {
        setSelectUserOption(optionToSelect);
        setSelectUserInitialized(true);
        setSelectUser({
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          id: user.data.id,
        });
      }
    }
  }, [
    selectUserInitialized,
    selectUserOption?.value,
    setSelectUser,
    user.data,
    user.isLoading,
    usersToSelect,
  ]);

  return {
    methods,
    usersToSelect,
    selectUserOption,
    handleUserChange,
  };
};
