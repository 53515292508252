import { differenceInCalendarMonths } from 'date-fns';
import { Nullable } from 'common/types';
import { getDurationText, parseBackendDate } from 'utils/date';

export const getDurationFromDates = (
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>
) => {
  const from = parseBackendDate(dateFrom);
  const to = parseBackendDate(dateTo);

  const experienceInMonths = differenceInCalendarMonths(to, from) + 1;

  const years = Math.floor(experienceInMonths / 12);
  const months = experienceInMonths % 12;

  return getDurationText(years, months);
};
