import { FC } from 'react';
import { startOfYear, subYears } from 'date-fns';
import { Box, Button } from '@mui/material';
import FormWrapper from 'components/FormWrapper';
import CheckboxInput from 'components/CheckboxInput';
import SelectInput from 'components/SelectInput';
import TextInput from 'components/TextInput';
import formStyles from 'common/styles/forms';
import { HolidayType } from 'api/types/holiday';
import {
  HolidayApplicationFormProps,
  holidayOptions,
  subTypeOptions,
} from './HolidayApplicationForm.types';
import { useHolidayApplicationForm } from './useHolidayApplicationForm';
import AppDialog from 'components/AppDialog';
import { formatLongDate } from 'utils/date';
import { useUser } from 'hooks/useUsers/useUser';
import holidayFormValidator from 'validation/holidayFormValidator';
import DatePickerInput from 'components/DatePickerInput';

export const HolidayApplicationForm: FC<HolidayApplicationFormProps> = (
  props
) => {
  const { onClose } = props;
  const user = useUser();

  const {
    isOpen,
    onSubmitHandler,
    methods,
    dateFromWatcher,
    dateToWatcher,
    isCheckedWatcher,
    typeWatcher,
  } = useHolidayApplicationForm(props);

  return (
    <AppDialog
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>Cofnij</Button>
          <Button
            form="holiday-application-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </>
      }
      title={`WNIOSEK URLOPOWY - ${user.data?.firstName} ${user.data?.lastName}- ${formatLongDate(new Date())}`}
    >
      <FormWrapper
        onSubmit={onSubmitHandler}
        methods={methods}
        validator={holidayFormValidator}
        id="holiday-application-form"
      >
        <Box sx={formStyles.doubleInputs} pt={1}>
          <SelectInput
            name="type"
            label="rodzaj urlopu"
            options={holidayOptions}
            defaultValue={HolidayType.Leisure}
          />
          {typeWatcher === HolidayType.Compassionate && (
            <SelectInput
              name="compassionateSubType"
              label="powód"
              options={subTypeOptions}
            />
          )}
        </Box>
        <Box sx={formStyles.doubleInputs}>
          <DatePickerInput
            label="od"
            name="dateFrom"
            minDate={startOfYear(subYears(new Date(), 1))}
            value={dateFromWatcher}
          />
          <DatePickerInput
            label="do"
            name="dateTo"
            minDate={dateFromWatcher ?? undefined}
            disabled={isCheckedWatcher}
            value={dateToWatcher}
          />
        </Box>
        <Box sx={formStyles.doubleInputs}>
          <CheckboxInput
            name="isHalfDay"
            label="0,5 dnia"
            wrapperSx={formStyles.checkboxInput}
          />
        </Box>
        <TextInput
          name="addInfo"
          label="uwagi"
          fullWidth
          multiline
          minRows={2}
        />
      </FormWrapper>
    </AppDialog>
  );
};
