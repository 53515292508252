import { COLORS } from 'theme/index';

const styles = {
  root: (isError: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    borderColor: isError ? COLORS.error : COLORS.blue[200],
    borderRadius: '0.625rem',
    borderWidth: '0.063rem',
    borderStyle: 'solid',
  }),
};

export default styles;
