import { Box, Button } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import formStyles from 'common/styles/forms';
import SelectInput from 'components/SelectInput';
import { HolidayPoolFormDialogProps } from './HolidayPoolFormDialog.types';
import useHolidayPoolFormDialog from './useHolidayPoolFormDialog';
import holidayPoolFormValidator from 'validation/holidayPoolFormValidator';
import TextInput from 'components/TextInput';
import { getHolidayPoolYearOptions } from '../HolidayPool.helpers';

const HolidayPoolFormDialog = ({
  holidayPool,
  holidayPools,
  onClose,
}: HolidayPoolFormDialogProps) => {
  const { isOpen, methods, title, isLoading, onSubmitHandler } =
    useHolidayPoolFormDialog({
      holidayPool,
      onClose,
    });

  const options = getHolidayPoolYearOptions(holidayPools, holidayPool);

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="holiday-pool-form" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="holiday-pool-form"
        onSubmit={onSubmitHandler}
        validator={holidayPoolFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <SelectInput options={options} label="Year" name="year" />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <TextInput type="number" name="days" label="Days" />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <TextInput
              type="number"
              name="daysFromPreviousYear"
              label="Days from previous year"
            />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default HolidayPoolFormDialog;
