import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    textAlign: 'center',
  },
  photosContainer: {
    gap: '1rem 0',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    padding: '1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '8px 3px 56px -39px rgba(168, 168, 169, 1);',
  },
  image: {
    width: '250px',
    maxHeight: '160px',
    objectFit: 'cover',
  },
  singleImageMobile: {
    width: '400px',
  },
  svgWrapper: {
    width: '2rem',
    height: '2rem',
    left: '14.5rem',
    display: 'flex',
    borderRadius: '50%',
    padding: '0.5rem',
    background: COLORS.green[100],
    position: 'absolute',
  },
  checkIcon: {
    height: '1.75rem',
    width: '1.75rem',
    color: COLORS.white,
  },
  modalImageContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: '2rem',
    paddingTop: '2rem',
  },
};

export default styles;
