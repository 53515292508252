import { Box, Button } from '@mui/material';

import formStyles from 'common/styles/forms';
import AppDialog from 'components/AppDialog';
import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';
import FormWrapper from 'components/FormWrapper';
import CheckboxInput from 'components/CheckboxInput';
import AutoCompleteInput from 'components/AutoCompleteInput';
import useEducationFormDialog from './useEducationFormDialog';
import DatePickerInput from 'components/DatePickerInput';
import educationFormValidator from 'validation/educationFormValidator';
import { DEGREES_OPTIONS } from './EducationFormDialog.consts';
import { EducationFormDialogProps } from './EducationFormDialog.types';
import { mapKeyValueToOption } from 'utils/objects';

const EducationFormDialog = ({
  education,
  onClose,
}: EducationFormDialogProps) => {
  const {
    startDateWatcher,
    isOpen,
    atPresentWatcher,
    endDateWatcher,
    methods,
    title,
    isLoading,
    onSubmitHandler,
    schools,
    fieldsOfStudy,
  } = useEducationFormDialog({
    education,
    onClose,
  });

  // TODO - should ID be renamed from 'certificate-form' to 'education-form'?

  return (
    <AppDialog
      open={isOpen}
      onClose={onClose}
      title={title}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            form="certificate-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        methods={methods}
        id="certificate-form"
        onSubmit={onSubmitHandler}
        validator={educationFormValidator}
        isLoading={isLoading}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <SelectInput
              options={DEGREES_OPTIONS}
              name="degree"
              label="Degree"
            />
            <Box />
            <AutoCompleteInput
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={schools.map(mapKeyValueToOption)}
              label="University"
              name="school"
            />
            <TextInput name="city" label="City" />
            <AutoCompleteInput
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={fieldsOfStudy.map(mapKeyValueToOption)}
              label="Field of study"
              name="fieldOfStudy"
            />
            <TextInput name="department" label="Department" />
          </Box>
          <Box sx={formStyles.tripleInputs}>
            <DatePickerInput
              maxDate={endDateWatcher ?? undefined}
              disableFuture
              label="Start date"
              name="dateFrom"
            />
            <DatePickerInput
              minDate={startDateWatcher ?? undefined}
              disabled={atPresentWatcher}
              label="End date"
              name="dateTo"
            />
          </Box>
          <Box sx={formStyles.tripleInputs}>
            <CheckboxInput
              isSmall
              wrapperSx={formStyles.checkboxInput}
              name="atPresent"
              label="At present"
            />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default EducationFormDialog;
