import { HolidayType, UserHolidayApi } from 'api/types/holiday';
import { UserData } from 'api/types/holidayPool';
import { Nullable, Option } from 'common/types';
import holidayFormValidator from 'validation/holidayFormValidator';
import { z } from 'zod';

export const holidayOptions: Array<Option> = [
  { label: 'Wypoczynkowy', value: HolidayType.Leisure },
  { label: 'Na żądanie', value: HolidayType.OnDemand },
  { label: 'Firmowy', value: HolidayType.Company },
  { label: 'Bezpłatny', value: HolidayType.Unpaid },
  { label: 'Okolicznościowy', value: HolidayType.Compassionate },
  { label: 'Opieka nad dzieckiem', value: HolidayType.Care },
  { label: 'Za święto', value: HolidayType.HolidayComp },
  { label: 'Za delegację', value: HolidayType.DelegationComp },
];

export const subTypeOptions: Array<Option<string | number>> = [
  { label: 'Ślub', value: 'Ślub' },
  { label: 'Narodziny dziecka', value: 'Narodziny dziecka' },
  { label: 'Pogrzeb', value: 'Pogrzeb' },
];

export interface HolidayAddModel {
  dateTo: Nullable<Date>;
  dateFrom: Nullable<Date>;
  type: string;
  addInfo?: string;
  user: number;
  isAcceptedByEmployee: boolean;
  isHalfDay: boolean;
}

export interface HolidayAddModelForm extends HolidayAddModel {
  compassionateSubType?: string;
}

export interface HolidayApplicationFormProps {
  dateFrom?: Nullable<Date>;
  dateTo?: Nullable<Date>;
  userHoliday?: UserHolidayApi;
  onClose: () => void;
}

export interface SelectUserProps {
  setSelectUser: (user: Nullable<UserData>) => void;
}

export type holidayFormSubmitData = z.infer<typeof holidayFormValidator>;
