import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExternalPaths } from 'src/router/types';
import { EXTERNAL_SITE_PREFIX } from 'src/router/useNavigation/useNavigation.consts';

const useCustomNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const navigation = useCallback(
    (destination: string) => {
      // External links (keep in mind that they are opened in new browser tab)
      if (Object.values(ExternalPaths).includes(destination as ExternalPaths)) {
        window.open(destination);

        return;
      }
      // Other erp services (calendar/admin-panel)
      if (EXTERNAL_SITE_PREFIX.some((prefix) => destination.includes(prefix))) {
        window.location.href = destination;

        return;
      }
      navigate(destination);
    },
    [navigate]
  );

  return {
    params,
    location,
    navigate: navigation,
    goBack,
  };
};

export default useCustomNavigation;
