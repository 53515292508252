import { apiGet } from 'api/axios';
import { UserDesksReservations } from 'api/types/deskReservation';
import { API_URLS } from 'api/axios/axios.consts';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { formatToBackendDate } from 'utils/date';

const fetchMyReservations = (date: string) =>
  apiGet<Array<UserDesksReservations>>(
    `${API_URLS.deskReservations}?date=${date}`
  );

const useDeskReservationsForDay = () => {
  const { date } = useContext(DeskContext);

  const { data: deskReservations, refetch: refetchReservations } = useQuery({
    queryKey: [API_URLS.desks, date],
    queryFn: () => fetchMyReservations(formatToBackendDate(date as Date)),
  });

  return {
    deskReservations: deskReservations && deskReservations?.data,
    refetchReservations,
  };
};

export default useDeskReservationsForDay;
