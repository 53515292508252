import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import HoverTooltip from 'components/HoverTooltip';
import useBreakPoints from 'hooks/useBreakpoints';
import styles from './InfoIcon.styles';
import { InfoIconProps } from './InfoIcon.types';
import { useMemo } from 'react';

const InfoIcon = ({ addInfo }: InfoIconProps) => {
  const { isMobile } = useBreakPoints();
  const infoIcon = useMemo(() => {
    if (addInfo) {
      return (
        <Box sx={styles.addInfo}>
          <Typography variant="body2">
            {`dodatkowe informacje: ${isMobile ? addInfo : ''}`}
          </Typography>
          {!isMobile && <HoverTooltip Icon={InfoOutlined} text={addInfo} />}
        </Box>
      );
    } else if (!isMobile) {
      //return the box not to omit a grid column in tablet and desktop view
      return <Box />;
    }

    return null;
  }, [addInfo, isMobile]);

  return infoIcon;
};

export default InfoIcon;
