import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { HolidayPhotoDataGetResponse } from 'api/types/photo';

const useHolidayPhoto = (id?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`${API_URLS.holidayPhoto}${id}`],
    queryFn: () =>
      apiGet<HolidayPhotoDataGetResponse>(`${API_URLS.holidayPhoto}${id}/`),
    enabled: !!id,
  });

  return {
    holidayPhotoDataResponse: data?.data,
    isLoadingHolidayPhoto: isLoading,
    refetchHolidayPhoto: refetch,
  };
};
export default useHolidayPhoto;
