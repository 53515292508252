import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { apiPost } from 'api/axios';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import offerFormValidator from 'validation/offerFormValidator';
import { LoginForm as LoginFormType } from './LoginForm.types';
import OfferContext from 'contexts/Offer/OfferContext';
import { API_URLS } from 'api/axios/axios.consts';

const useLoginForm = () => {
  const { isLogged, setIsLogged } = useContext(OfferContext);
  const { pathname } = useLocation();

  const methods = useForm<LoginFormType>({
    resolver: zodResolver(offerFormValidator),
    defaultValues: {
      password: '',
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (password: string) =>
      await apiPost(
        `${pathname}${pathname.endsWith('/') ? '' : '/'}${API_URLS.login}`, // TODO: Consider using url-join or something similar
        { password }
      ),
    onSuccess: (res) => {
      if (res.status === 200 || res.status === 201)
        setIsLogged(
          !!document.cookie
            .split(';')
            .filter((line) => line.includes('rev_auth'))[0]
        );
    },
    onError: () => {
      const message =
        'Offer not found or something is wrong with your password';

      methods.setError('password', { message });
    },
  });

  return { isLogged, methods, loginMutation };
};
export default useLoginForm;
