import { Box, Button, IconButton, Typography } from '@mui/material';
import { useContext } from 'react';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { formatDateForReservation, getBusinessDays } from 'utils/date';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { addDays, subDays, isToday, isBefore, isWeekend } from 'date-fns';
import { DESK_RESERVATIONS_NUM_DAYS } from 'pages/DeskReservation/DeskReservation.consts';
import styles from 'pages/DeskReservation/components/DateSwitcher/DateSwitcher.styles';

const DateSwitcher = () => {
  const { selectedDate, setSelectedDate, setDate, setActiveDesk } =
    useContext(DeskContext);
  const dates = getBusinessDays(DESK_RESERVATIONS_NUM_DAYS);
  const today = new Date();

  const onClickNextDay = () => {
    let nextDate = addDays(selectedDate, 1);
    while (isWeekend(nextDate)) {
      nextDate = addDays(nextDate, 1);
    }
    setDate(nextDate);
    setSelectedDate(nextDate);
    setActiveDesk(null);
  };

  const onClickPreviousDay = () => {
    let prevDate = subDays(selectedDate, 1);
    while (isWeekend(prevDate)) {
      prevDate = subDays(prevDate, 1);
    }
    setDate(prevDate);
    setSelectedDate(prevDate);
    setActiveDesk(null);
  };

  const onClickToday = () => {
    setDate(today);
    setSelectedDate(today);
  };

  const isLeftIconDisabled = isToday(selectedDate as Date);
  const isRightIconDisabled = !isBefore(
    addDays(selectedDate, 1),
    dates[dates.length - 1]
  );

  return (
    <Box sx={styles.container}>
      <Button
        size={'small'}
        variant={'outlined'}
        onClick={onClickToday}
        sx={styles.todayButton}
      >
        Dzisiaj
      </Button>
      <IconButton disabled={isLeftIconDisabled} onClick={onClickPreviousDay}>
        <ChevronLeft
          sx={styles.icon}
          opacity={isLeftIconDisabled ? '0.2' : '1'}
        />
      </IconButton>
      <IconButton onClick={onClickNextDay} disabled={isRightIconDisabled}>
        <ChevronRight
          sx={styles.icon}
          opacity={isRightIconDisabled ? '0.2' : '1'}
        />
      </IconButton>
      <Typography variant="h1">
        {formatDateForReservation(selectedDate)}
      </Typography>
    </Box>
  );
};

export default DateSwitcher;
