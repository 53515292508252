import { memo, useContext, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import Legend from '../Legend';
import styles from '../../Profile.styles';
import SkillsRatings from './SkillsRatings';
import { SkillsProps } from './Skills.types';
import SkillsDialog from './SkillsRatings/SkillsDialog';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';

const Skills = memo((props: SkillsProps) => {
  const { currentModal } = useContext(ModalContext);
  const { isReadonly, showLegend, skills, hideEmpty } = props;

  const isTechnicalEmpty = useMemo(
    () => !skills?.['Technical']?.length && hideEmpty,
    [hideEmpty, skills]
  );

  const isManagementEmpty = useMemo(
    () => !skills?.['Management']?.length && hideEmpty,
    [hideEmpty, skills]
  );

  const areSoftSkillsEmpty = useMemo(
    () => !skills?.['Soft Skills']?.length && hideEmpty,
    [hideEmpty, skills]
  );

  const shouldHideComponent = useMemo(
    () =>
      isTechnicalEmpty && isManagementEmpty && areSoftSkillsEmpty && hideEmpty,
    [areSoftSkillsEmpty, hideEmpty, isManagementEmpty, isTechnicalEmpty]
  );

  if (shouldHideComponent) return <></>;

  return (
    <Box sx={styles.skillContainer}>
      <Box sx={styles.skillColumn(showLegend)}>
        <Typography variant="h1">Skills</Typography>
        {!isTechnicalEmpty && <SkillsRatings type="Technical" {...props} />}
        {!isManagementEmpty && <SkillsRatings type="Management" {...props} />}
        {!areSoftSkillsEmpty && <SkillsRatings type="Soft Skills" {...props} />}
      </Box>
      {showLegend && <Legend />}
      {!isReadonly && currentModal === ProfileModalType.AddSkill && (
        <SkillsDialog />
      )}
    </Box>
  );
});

Skills.displayName = 'Skills';

export default Skills;
