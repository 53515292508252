import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { PositionsGetResponse } from 'api/types/positions';

export const fetchPositions = () =>
  apiGet<PositionsGetResponse>(API_URLS.positions);

const usePositions = (enabled?: boolean) => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [API_URLS.positions],
    queryFn: fetchPositions,
    enabled,
  });

  return {
    data: data?.data,
    refetch,
    isLoading,
    isFetching,
  };
};

export default usePositions;
