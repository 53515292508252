import { FC, PropsWithChildren, useState } from 'react';
import OfferContext from 'contexts/Offer/OfferContext';
import { OfferSettingsI } from 'api/types/offer';

const OfferProvider: FC<PropsWithChildren> = ({ children }) => {
  const isToken = !!document.cookie
    .split(';')
    .filter((line) => line.includes('rev_auth'))[0];

  const [isLogged, setIsLogged] = useState<boolean | undefined>(isToken);
  const [activeUser, setActiveUser] = useState<number>();
  const [offerSettings, setOfferSettings] = useState<OfferSettingsI>();

  const offerLogout = () => {
    setIsLogged(false);

    // Logout
    const newCookie = document.cookie
      .split(';')
      .filter((str) => !str.includes('rev_auth='))
      .join(';');

    document.cookie = newCookie;
  };

  return (
    <OfferContext.Provider
      value={{
        isLogged,
        setIsLogged,
        offerLogout,
        activeUser,
        setActiveUser,
        offerSettings,
        setOfferSettings,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;
