import { Box, Button, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { MdOutlineCalendarToday } from 'react-icons/md';
import styles from './CancelRoomReservationModal.styles';
import { CancelRoomReservationModalProps } from './CancelRoomReservationModal.types';
import { formatDate } from 'pages/RoomReservation/RoomReservation.helpers';
import { COLORS } from 'theme/Theme.const';
import DisplayWrapper from 'components/DisplayWrapper';
import useCancelRoomReservation from 'hooks/useRoomReservations/useCancelRoomReservation';

const CancelRoomReservationModal: FC<CancelRoomReservationModalProps> = ({
  roomReservationToCancel,
  setRoomReservationToCancel,
}) => {
  const formattedDate = useMemo(
    () => formatDate(roomReservationToCancel.date),
    [roomReservationToCancel]
  );
  const { onCancelReservation, onCloseClick } = useCancelRoomReservation({
    roomReservationToCancel,
    setRoomReservationToCancel,
  });

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant={'h1'}>Zarezerwowany pokój</Typography>
        <Box sx={styles.mainTextContainer}>
          <MdOutlineCalendarToday color={COLORS.blue[600]} />
          <Typography>{formattedDate}</Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.cancelButton} onClick={onCloseClick}>
            Zamknij
          </Button>
          <Button sx={styles.saveButton} onClick={onCancelReservation}>
            Anuluj Rezerwacje
          </Button>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default CancelRoomReservationModal;
