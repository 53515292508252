import { useForm } from 'react-hook-form';
import { useCallback, useContext, useEffect, useState } from 'react';

import { HolidayFilterForm } from './HolidayFiltersDialog.types';
import { AutoCompleteOption } from 'components/AutoCompleteInput';
import useUserHolidays from 'hooks/useUserHoliday/useUserHoliday';
import { UserHolidayApi } from 'api/types/holiday';
import ModalContext from 'contexts/Modal/ModalContext';
import { holidayOptions } from 'pages/HolidayCalendar/components/HolidayApplicationForm.types';

const useHolidayFiltersDialog = () => {
  const { data: holidaysData, isLoading } = useUserHolidays();
  const { closeModal } = useContext(ModalContext);

  const [filteredHolidays, setFilteredHolidays] =
    useState<Array<UserHolidayApi>>();
  const [holidayTypesOptions, setHolidayTypesOptions] = useState<
    Array<AutoCompleteOption>
  >(holidayOptions as AutoCompleteOption[]);
  const [selectedTypes, setSelectedTypes] = useState<Array<AutoCompleteOption>>(
    []
  );

  useEffect(() => {
    if (!isLoading) {
      setFilteredHolidays(holidaysData);
    }
  }, [holidaysData, isLoading]);

  const methods = useForm<HolidayFilterForm>();

  const handleDeleteType = useCallback(
    (option: AutoCompleteOption) => {
      setSelectedTypes(
        selectedTypes.filter((type) => type.value !== option.value)
      );
      setHolidayTypesOptions((prev) => [option, ...prev]);
    },
    [selectedTypes]
  );

  const onOptionChange = useCallback(
    (option: AutoCompleteOption) => {
      setHolidayTypesOptions(
        holidayTypesOptions.filter((type) => type.value !== option.value)
      );
      setSelectedTypes((prev) => [option, ...prev]);

      methods.setValue('type', undefined);
    },
    [holidayTypesOptions, methods]
  );

  const resetFilters = useCallback(() => {
    setFilteredHolidays(holidaysData);
    methods.reset();
    setSelectedTypes([]);
  }, [holidaysData, methods]);

  const onSubmitHandler = useCallback(
    (data: HolidayFilterForm) => {
      const {
        isAcceptedByAdmin,
        isAcceptedByEmployee,
        endHolidayFrom,
        endHolidayTo,
        startHolidayFrom,
        startHolidayTo,
      } = data;

      const filtered = holidaysData.filter((holiday) => {
        const dateFromMonth = parseInt(holiday.dateFrom.split('-')[1]);
        const dateToMonth = parseInt(holiday.dateTo.split('-')[1]);
        const isFilterMismatch =
          (selectedTypes.length > 0 &&
            !selectedTypes.some(({ value }) => value === holiday.type)) ||
          (isAcceptedByAdmin &&
            isAcceptedByAdmin !== holiday.isAcceptedByAdmin) ||
          (isAcceptedByEmployee &&
            isAcceptedByEmployee !== holiday.isAcceptedByEmployee) ||
          (startHolidayFrom && !(dateFromMonth >= startHolidayFrom.value)) ||
          (startHolidayTo && !(dateFromMonth <= startHolidayTo.value)) ||
          (endHolidayFrom && !(dateToMonth >= endHolidayFrom.value)) ||
          (endHolidayTo && !(dateToMonth <= endHolidayTo.value));

        if (isFilterMismatch) {
          return false;
        }

        return true;
      });
      setFilteredHolidays(filtered);
      closeModal();
    },
    [closeModal, holidaysData, selectedTypes]
  );

  return {
    onSubmitHandler,
    filteredHolidays,
    handleDeleteType,
    onOptionChange,
    holidayTypesOptions,
    selectedTypes,
    methods,
    resetFilters,
  };
};

export default useHolidayFiltersDialog;
