import { KeyValueBase } from 'api/types/common';

export type SchoolsGetResponse = Array<KeyValueBase>;

export enum SchoolDegree {
  Engineer = 'Engineer',
  Bachelor = 'Bachelor',
  Master = 'Master',
  PhD = 'PhD',
  Other = 'Other',
}
