export enum DeskCardContent {
  NoDeskReservations,
  OthersDeskReservation,
  NoHotDesk,
  ReserveDesk,
  EditDesk,
}

export interface DeskCardContentProps {
  deskCardContent: DeskCardContent;
}
