import formStyles from 'common/styles/forms';
import { COLORS } from 'theme/index';

const styles = {
  firstRow: {
    ...formStyles.sectionsWrapper,
    mb: '1rem',
  },
  personalInformation: {
    ...formStyles.section,
    gap: '1.5rem',
  },
};

export const pictureStyles = {
  form: {
    mb: {
      Mobile: '1rem',
      Tablet: 0,
    },
  },
  avatar: {
    background: 'none',
    border: `1px solid ${COLORS.gray2}`,
  },
  img: {
    width: '7rem',
    height: '7rem',
    borderRadius: '100%',
  },
  icon: { width: '1.5rem', height: '1.5rem' },
  picture: {
    display: 'flex',
    flexDirection: { Mobile: 'column', Tablet: 'row' },
    gap: { Mobile: 3, Tablet: 5 },
    alignItems: { Mobile: 'center', Tablet: 'unset' },
  },
  button: {
    width: { Mobile: '100%', Tablet: 'fit-content' },
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
  },
};

export default styles;
