import { Box } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import ActionButtons from 'pages/Profile/components/ActionButtons';
import IconLabel from 'components/IconLabel/IconLabel';
import { DelegationProps } from './Delegation.types';
import useMutateUserDelegation from 'hooks/useDelegations/useMutateUserDelegation';
import styles from './Delegation.styles';
import InfoIcon from '../InfoIcon';

const Delegation = ({ delegation, handleEditDelegation }: DelegationProps) => {
  const { delegationDeleteMutate } = useMutateUserDelegation();
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      delegation.id && delegationDeleteMutate({ delegationId: delegation.id }),
    title: `Are you sure you want to delete this delegation?`,
  });
  const { dateFrom, dateTo, addInfo } = delegation;

  return (
    <Box sx={styles.infoGrid}>
      <IconLabel Icon={CalendarToday} label={`${dateFrom} - ${dateTo}`} />
      <InfoIcon addInfo={addInfo} />
      <ActionButtons
        isStart
        onDeleteHandler={openConfirmationModal}
        onEditHandler={handleEditDelegation}
      />
      <ConfirmationDialog />
    </Box>
  );
};

export default Delegation;
