import { ChangeEvent, FC } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import InputWrapper from '../InputWrapper';
import { MultiCheckboxInputProps } from './MultiCheckboxInput.types';

const MultiCheckboxInput: FC<MultiCheckboxInputProps> = ({
  options,
  name,
  wrapperSx = {},
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <InputWrapper sx={wrapperSx} name={name}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...field } }) => (
          <FormGroup {...field} {...rest}>
            {options.map(({ value: optionValue, label, disabled }) => (
              <FormControlLabel
                disabled={disabled}
                key={optionValue}
                label={label}
                control={
                  <Checkbox
                    disabled={disabled}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const newValues = value as Array<string>;
                      const isChecked = event.target.checked;
                      const checkboxName = event.target.name;

                      isChecked
                        ? newValues.push(checkboxName)
                        : newValues.splice(newValues.indexOf(checkboxName), 1);

                      onChange(newValues);
                    }}
                    name={optionValue}
                    checked={(value as Array<string> | undefined)?.some(
                      (value) => value === optionValue
                    )}
                    sx={{ transform: 'scale(0.8)' }}
                  />
                }
              />
            ))}
          </FormGroup>
        )}
      />
    </InputWrapper>
  );
};

export default MultiCheckboxInput;
