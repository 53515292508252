import { memo } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { SnackbarProps } from '../Snackbars.types';
import SnackbarAnimation from './SnackbarAnimation';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';

const SuccessSnackbar = memo((props: SnackbarProps) => {
  const { message, config } = props;

  return (
    <SnackbarAnimation duration={config?.duration}>
      <Alert
        severity="success"
        icon={<CheckCircleOutlineOutlined color="inherit" />}
      >
        <AlertTitle>{config?.title ?? 'Success'}</AlertTitle>
        {message}
      </Alert>
    </SnackbarAnimation>
  );
});

SuccessSnackbar.displayName = 'SuccessSnackbar';

export default SuccessSnackbar;
