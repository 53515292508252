import { z } from 'zod';
import { formatToBackendDate, formatToNullableBackendDate } from 'utils/date';
import coursesValidator from 'validation/coursesFormValidator';

export const formatToBackendFormData = ({
  dateFrom,
  dateTo,
  name,
}: z.infer<typeof coursesValidator>) => ({
  dateFrom: formatToBackendDate(dateFrom),
  dateTo: formatToNullableBackendDate(dateTo),
  name: name.label,
});
