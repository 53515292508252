import { useCallback, useContext } from 'react';
import { Desk } from 'api/types/deskReservation';
import AuthContext from 'contexts/Auth/AuthContext';
import { DeskStatus } from './useDeskStatus.types';
import useReservation from 'hooks/useRoomReservations/useReservation';
import { mapColors } from './useDeskStatus.const';
import useDeskReservation from 'hooks/useDeskReservation';
import useDeskReservationsForDay from 'hooks/useDeskReservationsForDay';
import MapBackground from 'assets/images/map_background.svg';
import ReservedRoomMapBackground from 'assets/images/room_reserved.svg';
import DeskContext from 'contexts/DeskReservation/DeskContext';

const useDeskStatus = () => {
  const { deskReservations } = useDeskReservationsForDay();
  const { activeDesk, editMode } = useContext(DeskContext);
  const { reservedRooms } = useReservation();
  const { id } = useContext(AuthContext);
  const { myReservations } = useDeskReservation();

  const getDeskStatus = useCallback(
    (date: string, desk: Desk) => {
      if (editMode) {
        return desk.isHotdesk
          ? mapColors[DeskStatus.AvailableToReserve]
          : mapColors[DeskStatus.NotAvailableToReserve];
      }
      const deskReservation = deskReservations?.find(
        (reservation) =>
          reservation.date === date && reservation.desk === desk.id
      );

      const myDesksReservations = Array.isArray(myReservations)
        ? myReservations?.find(
            (reservation) =>
              reservation.date === date && reservation.desk === desk.id
          )
        : undefined;

      if (!desk.isHotdesk) {
        return mapColors[DeskStatus.NotAvailableToReserve];
      }
      if (
        reservedRooms.some(
          (roomReservation) =>
            roomReservation.date === date && desk.room === roomReservation.room
        )
      ) {
        return mapColors[DeskStatus.QuiteWorkRoomReserved];
      }

      if (deskReservation && deskReservation.booker !== id) {
        return mapColors[DeskStatus.Reserved];
      }
      if (myDesksReservations) {
        return mapColors[DeskStatus.ReservedByYou];
      }

      return mapColors[DeskStatus.AvailableToReserve];
    },
    [deskReservations, id, myReservations, reservedRooms, editMode]
  );

  const getIconStyle = useCallback(
    (date: string, desk: Desk): React.CSSProperties => {
      return {
        fill: getDeskStatus(date, desk).mainColor,
        color: getDeskStatus(date, desk).mainColor,
        stroke:
          desk.id === activeDesk
            ? getDeskStatus(date, desk).borderColor
            : '#FAFAFA',
      };
    },
    [activeDesk, getDeskStatus]
  );
  const getDeskPosition = useCallback((desk: Desk): React.CSSProperties => {
    return {
      position: 'absolute',
      top: `${desk.y - 3.5}%`,
      left: `${desk.x - 4.5}%`,
      width: '7%',
      height: '4%',
      rotate: `${desk.rotation}deg`,
      padding: 0,
    };
  }, []);

  const getBackgroundImage = useCallback(
    (date: string) => {
      if (editMode) {
        return MapBackground;
      }
      const roomReservation = reservedRooms.find(
        (roomReservation) => roomReservation.date === date
      );

      return roomReservation ? ReservedRoomMapBackground : MapBackground;
    },
    [reservedRooms, editMode]
  );

  return { getDeskPosition, getIconStyle, getBackgroundImage };
};

export default useDeskStatus;
