import { EditDeskMutate } from 'pages/DeskReservation/DeskReservation.types';
import useDesk from 'hooks/useDesk';
import { useMutation } from '@tanstack/react-query';
import { apiPatch } from 'api/axios';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import { API_URLS } from 'api/axios/axios.consts';
import useDesks from 'hooks/useDesks';
import { AxiosError } from 'axios';

const getDesksUrl = (deskId: number) => `${API_URLS.desks}${deskId}/`;

const useMutateDesk = () => {
  const { refetchDesk } = useDesk();
  const { refetch } = useDesks();

  const { mutate: patchMutate } = useMutation({
    mutationFn: ({ owner, name, features, deskId }: EditDeskMutate) => {
      return apiPatch(getDesksUrl(deskId), { features, name, owner });
    },
    onSuccess: () => {
      enqueueSnackbar('Desk successfully edited', {
        type: SnackbarType.success,
      });
      refetchDesk();
      refetch();
    },
    onError: (error: AxiosError) => {
      const errorResponse = error.response?.data as { name: Array<string> };
      enqueueSnackbar(`Desk edit failed, ${errorResponse.name}`, {
        type: SnackbarType.error,
      });
    },
  });

  return {
    deskPatchMutate: patchMutate,
  };
};

export default useMutateDesk;
