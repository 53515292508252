import { RoomReservation } from 'api/types/roomReservation';
import RoomReservationContext, {
  ModalType,
} from 'contexts/RoomReservation/RoomReservationContext';
import { useCallback, useContext } from 'react';

const useMyRoomReservations = () => {
  const { setModal, setRoomReservationToCancel } = useContext(
    RoomReservationContext
  );

  const onReservationClick = useCallback(
    (room: RoomReservation) => {
      setRoomReservationToCancel(room);
      setModal(ModalType.CancelReservation);
    },
    [setModal, setRoomReservationToCancel]
  );

  return {
    onReservationClick,
  };
};
export default useMyRoomReservations;
