export const imgStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
};

export const container = {
  position: 'relative',
};

export const crownStyle: React.CSSProperties = {
  position: 'absolute',
  width: '50%',
  height: '30%',
  top: 2,
};
