import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import {
  MutateUserDelegationDeleteProps,
  MutateUserDelegationPatchProps,
  MutateUserDelegationPostProps,
} from './useDelegations.types';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import queryClient from 'api/queryClient';
import { API_URLS } from 'api/axios/axios.consts';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

export const useMutateUserDelegation = () => {
  const { userId } = useActiveWorker();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ delegation }: MutateUserDelegationPatchProps) =>
      apiPatch(`${API_URLS.delegations(userId)}${delegation.id}/`, delegation),
    onSuccess: () => {
      enqueueSnackbar('Delegation successfully changed', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.delegations(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot edit delegation', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ delegationId }: MutateUserDelegationDeleteProps) =>
      apiDelete(`${API_URLS.delegations(userId)}${delegationId}/`),
    onSuccess: () => {
      enqueueSnackbar('Delegation successfully deleted', {
        type: SnackbarType.info,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.delegations(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete delegation', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ delegation }: MutateUserDelegationPostProps) =>
      apiPost(API_URLS.delegations(userId), delegation),
    onSuccess: () => {
      enqueueSnackbar('Delegation successfully added', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.delegations(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add new delegation', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    delegationPatchMutate: patchMutate,
    delegationDeleteMutate: deleteMutate,
    delegationPostMutate: postMutate,
  };
};

export default useMutateUserDelegation;
