import { z } from 'zod';
import {
  errorMessages,
  MAX_CERTIFICATE_LENGTH,
  MIN_DATE,
} from 'common/consts/validation';
import { isBefore } from 'date-fns';
import { option } from './types';

const certificatesFormValidator = z.intersection(
  z
    .discriminatedUnion('isPersistent', [
      z.object({
        issueDate: z
          .date({ invalid_type_error: errorMessages.required })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
          .max(new Date(), { message: errorMessages.maxDate(new Date()) }),
        isPersistent: z.literal(true),
        expirationDate: z.null(),
        url: z.string().optional(),
      }),
      z.object({
        issueDate: z
          .date({ invalid_type_error: errorMessages.required })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
          .max(new Date(), { message: errorMessages.maxDate(new Date()) }),
        isPersistent: z.literal(false),
        expirationDate: z
          .date({
            invalid_type_error: errorMessages.required,
            required_error: errorMessages.required,
          })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
        url: z.string().optional(),
      }),
    ])
    .refine(
      ({ isPersistent, issueDate, expirationDate }) =>
        isPersistent || !isBefore(expirationDate, issueDate),
      { message: errorMessages.invalidRange, path: ['expirationDate'] }
    ),
  z.object({
    name: z
      .object(option, {
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      })
      .refine(({ label }) => label.length <= MAX_CERTIFICATE_LENGTH, {
        message: errorMessages.maxCharacters(MAX_CERTIFICATE_LENGTH),
      }),
  })
);

export default certificatesFormValidator;
