import { Box, IconButton, Typography } from '@mui/material';
import { memo, useContext, useState, Fragment } from 'react';

import ModalContext, {
  AdminProfileModalType,
  GlobalModalType,
} from 'contexts/Modal/ModalContext';
import EmptySection from '../../EmptySection';
import Holiday from './Holiday';
import styles from '../AdminProfile.styles';
import holidayStyles from './Holiday.styles';
import { HolidayApplicationForm } from 'pages/HolidayCalendar/components/HolidayApplicationForm';
import HolidayFiltersDialog from './HolidayFiltersDialog/HolidayFiltersDialog';
import useHolidayFiltersDialog from './HolidayFiltersDialog/useHolidayFiltersDialog';
import { FilterList, FilterListOff } from '@mui/icons-material';

const Holidays = memo(() => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);

  const {
    onSubmitHandler,
    selectedTypes,
    methods,
    holidayTypesOptions,
    onOptionChange,
    handleDeleteType,
    filteredHolidays,
    resetFilters,
  } = useHolidayFiltersDialog();

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  return (
    <Box>
      <Box sx={holidayStyles.container}>
        <Typography component="h1" variant="h1">
          Holidays
        </Typography>
        <Box sx={holidayStyles.filterButtons}>
          <IconButton
            onClick={() => openModal(AdminProfileModalType.HolidayFilter)}
            color="primary"
            sx={holidayStyles.actionButton}
          >
            <FilterList color="inherit" />
          </IconButton>
          <IconButton onClick={resetFilters} sx={holidayStyles.actionButton}>
            <FilterListOff />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styles.contentWrapper}>
        {filteredHolidays && filteredHolidays?.length > 0 ? (
          filteredHolidays?.map((holiday) => (
            <Fragment key={`${holiday.id}-holiday`}>
              <Holiday
                key={holiday.id}
                holiday={holiday}
                handleEditHoliday={() => {
                  setEditId(holiday.id);
                  openModal(GlobalModalType.EditHoliday);
                }}
              />
              {currentModal === GlobalModalType.EditHoliday &&
                editId === holiday.id && (
                  <HolidayApplicationForm
                    userHoliday={holiday}
                    onClose={onCloseModal}
                  />
                )}
            </Fragment>
          ))
        ) : (
          <EmptySection />
        )}
      </Box>
      {currentModal === GlobalModalType.AddHoliday && (
        <HolidayApplicationForm onClose={onCloseModal} />
      )}
      {currentModal === AdminProfileModalType.HolidayFilter && (
        <HolidayFiltersDialog
          onClose={onCloseModal}
          onSubmitHandler={onSubmitHandler}
          onOptionChange={onOptionChange}
          holidayTypesOptions={holidayTypesOptions}
          handleDeleteType={handleDeleteType}
          methods={methods}
          selectedTypes={selectedTypes}
        />
      )}
    </Box>
  );
});

Holidays.displayName = 'Holidays';

export default Holidays;
