import { FC, useState } from 'react';
import { Circle, CircleOutlined } from '@mui/icons-material';
import { Box, Rating as MuiRating, Typography } from '@mui/material';

import { RatingProps } from './Rating.types';
import styles from './Rating.styles';
import useBreakPoints from 'hooks/useBreakpoints';

const Rating: FC<RatingProps> = ({
  rating,
  setRating,
  hoverLabels,
  disabled,
  sx,
}) => {
  const { isDesktop } = useBreakPoints();
  const [hoverText, setHoverText] = useState<string | null>('');

  return (
    <Box sx={styles.root}>
      {/* This onMouseLeave is added because onChangeActive is not working properly whenever disabled is passed (this
          is added so user won't spam calls to api) */}
      <Box
        sx={styles.ratingWrapper}
        onMouseLeave={() => disabled && setHoverText(null)}
      >
        <MuiRating
          disabled={disabled}
          value={rating ?? 1} // Setup as 1 so <Rating /> is always in controlled state
          onChange={(_, value) => setRating(value)}
          onChangeActive={(_, value) =>
            hoverLabels && setHoverText(hoverLabels[value - 1])
          }
          icon={<Circle sx={styles.icon} />}
          emptyIcon={<CircleOutlined sx={styles.icon} />}
          sx={sx}
        />
      </Box>
      <Typography variant={'caption'}>
        {/* TODO: This should be determined by something that is checking if its mobile device not breakpoint */}
        {isDesktop || disabled ? hoverText : hoverLabels?.[(rating ?? 1) - 1]}
      </Typography>
    </Box>
  );
};

export default Rating;
