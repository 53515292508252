export enum DeskStatus {
  NotAvailableToReserve = 'NotAvailableToReserve',
  QuiteWorkRoomReserved = 'QuiteWorkRoomReserved',
  Reserved = 'Reserved',
  AvailableToReserve = 'AvailableToReserve',
  ReservedByYou = 'ReservedByYou',
}

export interface DeskColor {
  mainColor: string;
  borderColor: string;
}
