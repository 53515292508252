import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { KeyValueBase } from 'api/types/common';

export const fetchProjectsDepartments = () =>
  apiGet<Array<KeyValueBase>>(
    `${API_URLS.projectsDepartments}project-options/`
  );

const useProjectsDepartments = () => {
  const query = useQuery({
    queryKey: [API_URLS.projectsDepartments],
    queryFn: fetchProjectsDepartments,
  });

  return {
    ...query,
    data: query.data?.data,
    refetchProjectsDepartments: query.refetch,
  };
};

export default useProjectsDepartments;
