const styles = {
  text: {
    marginBottom: '2rem',
  },
  wrapper: { marginX: { Desktop: '4%', Tablet: '10%' } },
  content: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: {
      Desktop: '2.5fr 1fr',
      Tablet: '1fr',
      Mobile: '1fr',
    },
    gap: '2rem',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
    minWidth: {
      Desktop: '420px',
    },
  },
};

export default styles;
