import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  link: {
    fontSize: 20,
    color: COLORS.black,
    cursor: 'pointer',
  },
};

export default styles;
