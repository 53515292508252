import { EditorContent, useCurrentEditor } from '@tiptap/react';
import { FC, useEffect } from 'react';
import styles from './InputContent.styles';
import { useFormContext } from 'react-hook-form';
import { InputContentProps } from './InputContent.types';

const InputContent: FC<InputContentProps> = ({ onFormChangeHandler }) => {
  const { watch } = useFormContext();
  const { editor } = useCurrentEditor();

  useEffect(() => {
    // Apply custom styles to the editor's content
    const editorContent = document.querySelector(
      '.ProseMirror'
    ) as HTMLElement | null;

    if (editorContent) {
      Object.assign(editorContent?.style, styles.input);
    }
  }, []);

  // This useEffect was introduced, since we cannot attach ref to input because tiptap library
  // is rendering all content as pure html and not input so we cannot do setValue for example.
  // Basically react-hook-form is reacting on changes from tiptap, but tiptap is not reacting
  // on react-hook-form changes.
  useEffect(() => {
    watch(); // Just to force re-render
    if (editor && onFormChangeHandler) {
      onFormChangeHandler(editor);
    }
  }, [editor, onFormChangeHandler, watch]);

  if (!editor) return <></>;

  return <EditorContent editor={editor} />;
};

export default InputContent;
