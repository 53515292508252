import { FC, PropsWithChildren, useState } from 'react';
import SentKudosContext from './SentKudosContext';

export const SentKudosProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSentKudosView, setIsSentKudosView] = useState(false);

  return (
    <SentKudosContext.Provider value={{ isSentKudosView, setIsSentKudosView }}>
      {children}
    </SentKudosContext.Provider>
  );
};
