import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './Footer.styles';
import useCustomNavigation from 'src/router/useNavigation';
import { ExternalPaths } from 'src/router/types';

const Footer: FC = () => {
  const { navigate } = useCustomNavigation();

  return (
    <Box sx={styles.root}>
      <Link
        onClick={() => navigate(ExternalPaths.companyPage)}
        underline="none"
      >
        <Typography variant="body2" color={'inherit'}>
          {new Date().getFullYear()} Revolve - www.revolve.pro. All rights
          reserved.
        </Typography>
      </Link>
    </Box>
  );
};

export default Footer;
