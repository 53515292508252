import { useMutation } from '@tanstack/react-query';
import { apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { MutateSkillPatchProps, MutateSkillsProps } from './useSkills.types';
import useProfile from 'hooks/useProfile/useProfile';

const getSkillsUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.skills}`;

export const useMutateUserSkills = () => {
  const { refetchProfile } = useProfile();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, skillId, value, attitude }: MutateSkillPatchProps) =>
      apiPatch(`${getSkillsUrl(userId)}${skillId}/`, {
        value,
        attitude,
      }),
    onSuccess: (_, { refetch = true }) => refetch && refetchProfile(),
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ userId, skillsIds }: MutateSkillsProps) =>
      apiPost(
        `${getSkillsUrl(userId)}bulk-delete/`,
        skillsIds.map((id) => ({ id: Number(id) }))
      ),
    onSuccess: (_, { refetch = true }) => refetch && refetchProfile(),
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, skillsIds }: MutateSkillsProps) =>
      apiPost(
        getSkillsUrl(userId),
        skillsIds.map((id) => ({ id: Number(id) }))
      ),
    onSuccess: (_, { refetch = true }) => refetch && refetchProfile(),
  });

  return {
    postMutate,
    patchMutate,
    deleteMutate,
  };
};

export default useMutateUserSkills;
