import { memo, useContext, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import Legend from '../Legend';
import styles from '../../Profile.styles';
import SkillsRatings from './SkillsRatings';
import SkillsDialog from './SkillsRatings/SkillsDialog';
import { SkillsProps } from './Skills.types';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';

const ProgrammingLanguages = memo((props: SkillsProps) => {
  const { currentModal } = useContext(ModalContext);
  const { isReadonly, showLegend, skills, hideEmpty } = props;

  const areLanguagesEmpty = useMemo(
    () => !skills?.['Programming Languages']?.length,
    [skills]
  );

  if (hideEmpty && areLanguagesEmpty) return <></>;

  return (
    <>
      <Box sx={styles.skillContainer}>
        <Box sx={styles.skillColumn(showLegend)}>
          <Typography variant="h1">Programming languages</Typography>
          <SkillsRatings type="Programming Languages" {...props} />
        </Box>
        {showLegend && <Legend />}
      </Box>
      {!isReadonly && currentModal === ProfileModalType.AddLanguage && (
        <SkillsDialog />
      )}
    </>
  );
});
ProgrammingLanguages.displayName = 'ProgrammingLanguages';

export default ProgrammingLanguages;
