import { Box, Typography } from '@mui/material';
import { memo, useContext, useState, Fragment } from 'react';

import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import EmptySection from '../../EmptySection';

import { HolidayPoolsProps } from './HolidayPool.types';
import HolidayPool from './HolidayPool';
import HolidayPoolFormDialog from './HolidayPoolFormDialog/HolidayPoolFormDialog';
import styles from '../AdminProfile.styles';

const HolidayPools = memo(({ holidayPools }: HolidayPoolsProps) => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  return (
    <Box>
      <Typography component="h1" variant="h1">
        Holiday pools
      </Typography>
      <Box sx={styles.contentWrapper}>
        {holidayPools && holidayPools?.length > 0 ? (
          holidayPools?.map((holidayPool) => (
            <Fragment key={`${holidayPool.id}-holidayPool`}>
              <HolidayPool
                key={holidayPool.id}
                holidayPool={holidayPool}
                handleEditHolidayPool={() => {
                  setEditId(holidayPool.id);
                  openModal(AdminProfileModalType.EditHolidayPool);
                }}
              />
              {currentModal === AdminProfileModalType.EditHolidayPool &&
                editId === holidayPool.id && (
                  <HolidayPoolFormDialog
                    holidayPools={holidayPools}
                    holidayPool={holidayPool}
                    onClose={onCloseModal}
                  />
                )}
            </Fragment>
          ))
        ) : (
          <EmptySection />
        )}
      </Box>
      {currentModal === AdminProfileModalType.AddHolidayPool && (
        <HolidayPoolFormDialog
          holidayPools={holidayPools}
          onClose={onCloseModal}
        />
      )}
    </Box>
  );
});

HolidayPools.displayName = 'HolidayPools';

export default HolidayPools;
