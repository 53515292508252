import { COLORS } from 'src/theme';
const styles = {
  chip: {
    border: `1px solid ${COLORS.blue[600]}`,
    borderRadius: '6px',
    padding: '.5rem',
    background: 'none',
    color: COLORS.blue[600],
  },
  deleteFeature: {
    color: COLORS.blue[600],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1.5rem',
    flexShtink: '0',
  },
  chipBox: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    marginBottom: '1rem',
  },
};

export default styles;
