import { Option } from 'common/types';
import { AutoCompleteOption } from 'components/AutoCompleteInput';
import { UseFormReturn } from 'react-hook-form';

export interface HolidayFiltersDialogProps {
  onClose: () => void;
  methods: UseFormReturn<HolidayFilterForm, unknown>;
  onSubmitHandler: (data: HolidayFilterForm) => void;
  holidayTypesOptions: AutoCompleteOption[];
  onOptionChange: (option: AutoCompleteOption) => void;
  selectedTypes: AutoCompleteOption[];
  handleDeleteType: (option: AutoCompleteOption) => void;
}

export const MONTH_OPTIONS: Array<AutoCompleteOption> = [
  { label: 'Styczeń', value: 1 },
  { label: 'Luty', value: 2 },
  { label: 'Marzec', value: 3 },
  { label: 'Kwiecień', value: 4 },
  { label: 'Maj', value: 5 },
  { label: 'Czerwiec', value: 6 },
  { label: 'Lipiec', value: 7 },
  { label: 'Sierpien', value: 8 },
  { label: 'Wrzesień', value: 9 },
  { label: 'Październik', value: 10 },
  { label: 'Listopad', value: 11 },
  { label: 'Grudzień', value: 12 },
];

export interface HolidayFilterForm {
  type?: Option<string>;
  isAcceptedByAdmin?: boolean;
  isAcceptedByEmployee?: boolean;
  startHolidayFrom?: Option<number>;
  startHolidayTo?: Option<number>;
  endHolidayFrom?: Option<number>;
  endHolidayTo?: Option<number>;
}
