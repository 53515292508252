import { memo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import formStyles from 'common/styles/forms';
import RichTextInput from 'components/RichTextInput/RichTextInput';
import { useFormContext } from 'react-hook-form';
import { ProjectForm } from '../ProjectFormDialog.types';
import { Editor } from '@tiptap/react';
import useProjects from 'hooks/useProjects';
import useProfile from 'hooks/useProfile/useProfile';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

const DescriptionForm = memo(() => {
  const { userId } = useActiveWorker();
  const { data } = useProjects(true, userId);
  const { data: profileData } = useProfile();
  const { getValues, getFieldState } = useFormContext<ProjectForm>();

  const isError = !!getFieldState('description').error;

  const [lastSelectedId, setLastSelectedId] = useState('');

  const onFormChangeHandler = (editor: Editor) => {
    const projectId = getValues('name.value');

    // Just to make sure this logic is running exactly one time after
    // synchronizing form
    if (lastSelectedId !== projectId) {
      setLastSelectedId(projectId);

      const projectDescription = data?.find(
        ({ id }) => `${id}` === projectId
      )?.description;

      const userProjectDescription = profileData?.projectSet.find(
        ({ projectDetails }) => `${projectDetails?.id}` === projectId
      )?.description;

      const description = userProjectDescription ?? projectDescription;

      if (description) {
        editor?.commands.setContent(description, true);
      }
    }
  };

  return (
    <Box sx={formStyles.section}>
      <Typography sx={{ mb: 3 }} variant="sub1">
        Description
      </Typography>
      <RichTextInput
        name="description"
        isError={isError}
        onFormChangeHandler={onFormChangeHandler}
      />
    </Box>
  );
});

DescriptionForm.displayName = 'DescriptionForm';

export default DescriptionForm;
