import { useCallback, useContext, useMemo } from 'react';
import { getFormattedDate } from 'pages/RoomReservation/RoomReservation.helpers';
import RoomReservationContext, {
  ModalType,
} from 'contexts/RoomReservation/RoomReservationContext';
import AuthContext from 'contexts/Auth/AuthContext';
import useReservation from './useReservation';
import useDesksReservations from 'hooks/useDesksReservations';

export const useRoomReservations = () => {
  const { id } = useContext(AuthContext);
  const { setSelectedDate, setRoomReservationToCancel, setModal } = useContext(
    RoomReservationContext
  );
  const { reservedRooms } = useReservation();
  const { desksReservations } = useDesksReservations();

  const currentUserReservations = useMemo(
    () => reservedRooms.filter((reservedRoom) => reservedRoom.booker === id),
    [id, reservedRooms]
  );

  const onSelect = useCallback(
    (date: Date) => {
      const formattedDate = getFormattedDate(date);
      const { formattedDay, formattedMonth, year } = formattedDate || {};
      const formattedDateStr = formattedDay
        ? `${year}-${formattedMonth}-${formattedDay}`
        : null;

      const roomReservation = reservedRooms.find(
        (reservation) => reservation.date === formattedDateStr
      );
      const desksReservation = desksReservations?.some(
        (reservation) => reservation.date === formattedDateStr
      );

      setSelectedDate(date);

      if (roomReservation) {
        if (roomReservation.booker !== id) {
          setModal(ModalType.OtherReservation);
        } else {
          setRoomReservationToCancel(roomReservation);
          setModal(ModalType.CancelReservation);
        }
      } else if (desksReservation) {
        setModal(ModalType.DeskReservations);
      } else {
        setModal(ModalType.CreateReservation);
      }
    },
    [
      desksReservations,
      id,
      reservedRooms,
      setSelectedDate,
      setModal,
      setRoomReservationToCancel,
    ]
  );

  return {
    currentUserReservations,
    onSelect,
    reservedRooms,
  };
};

export default useRoomReservations;
