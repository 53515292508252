import { z } from 'zod';
import {
  errorMessages,
  MAX_CITY_LENGTH,
  MAX_EDUCATION_LENGTH,
  MIN_DATE,
} from 'common/consts/validation';
import { SchoolDegree } from 'api/types/schools';
import { isBefore } from 'date-fns';
import { option } from './types';

const educationFormValidator = z.intersection(
  z
    .discriminatedUnion('atPresent', [
      z.object({
        atPresent: z.literal(true),
        dateFrom: z
          .date({
            invalid_type_error: errorMessages.required,
            required_error: errorMessages.required,
          })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
        dateTo: z.null(),
      }),
      z.object({
        atPresent: z.literal(false),
        dateFrom: z
          .date({
            invalid_type_error: errorMessages.required,
            required_error: errorMessages.required,
          })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
        dateTo: z
          .date({
            invalid_type_error: errorMessages.required,
            required_error: errorMessages.required,
          })
          .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) }),
      }),
    ])
    .refine(
      ({ atPresent, dateFrom, dateTo }) =>
        atPresent || !isBefore(dateTo, dateFrom),
      { message: errorMessages.invalidRange, path: ['dateTo'] }
    ),
  z.object({
    city: z
      .string()
      .max(MAX_CITY_LENGTH, errorMessages.maxCharacters(MAX_CITY_LENGTH))
      .nullish(),
    department: z
      .string()
      .max(
        MAX_EDUCATION_LENGTH,
        errorMessages.maxCharacters(MAX_EDUCATION_LENGTH)
      )
      .nullish(),
    degree: z.nativeEnum(SchoolDegree).optional(),
    school: z
      .object(option, {
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      })
      .refine(({ label }) => label.length <= MAX_EDUCATION_LENGTH, {
        message: errorMessages.maxCharacters(MAX_EDUCATION_LENGTH),
      }),
    fieldOfStudy: z
      .object(option, {
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      })
      .refine(({ label }) => label.length <= MAX_EDUCATION_LENGTH, {
        message: errorMessages.maxCharacters(MAX_EDUCATION_LENGTH),
      }),
  })
);

export default educationFormValidator;
