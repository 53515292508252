import { memo } from 'react';

import PageContent from 'components/PageContent';
import DisplayWrapper from 'components/DisplayWrapper';
import { Box, Divider } from '@mui/material';
import styles from './ProjectsDepartments.styles';

import useCompanyInfo from 'hooks/useCompanyInfo';
import Controls from './components/Controls';
import Content from './components/Content';
import useBreakPoints from 'hooks/useBreakpoints';

const ProjectsDepartments = memo(() => {
  const { isLoading } = useCompanyInfo();
  const { isMobile } = useBreakPoints();

  return (
    <PageContent isLoading={isLoading}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Box sx={styles.controls}>
            <Controls />
          </Box>
          <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
          <Box sx={styles.info}>
            <Content />
          </Box>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

ProjectsDepartments.displayName = 'ProjectsDepartments';

export default ProjectsDepartments;
