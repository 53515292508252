import { Box, Typography } from '@mui/material';
import { memo } from 'react';

import styles from './Legend.styles';

const Level = memo(() => (
  <Box sx={styles.section}>
    <Typography variant="sub2">Level</Typography>
    <Box sx={styles.content}>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Beginner
        </Typography>
        <Typography variant="body2">
          Czytałem o tym, rozumiem ogólną koncepcję i wiem do czego to stosować.
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Basic
        </Typography>
        <Typography variant="body2">
          Zrobiłem tutorial, jakieś przykłady i próbowałem tego użyć w praktyce.
          Komercyjnie używałem niewiele albo wcale.
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Intermediate
        </Typography>
        <Typography variant="body2">
          Już jakiś czas używam w praktyce komercyjnej, często korzystam z
          pomocy innych lub internetu, ale radzę sobie.
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Advanced
        </Typography>
        <Typography variant="body2">
          Pracuję z tym sprawnie, ale jeszcze czasem coś mnie zaskakuje. Pracuję
          z tym przynajmniej pół roku.
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Fluent
        </Typography>
        <Typography variant="body2">
          Pracuję z tym od przynajmniej roku. Czuję, że poruszam się płynnie w
          tej technologii.
        </Typography>
      </Box>
    </Box>
  </Box>
));
Level.displayName = 'Level';

export default Level;
