import { memo } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { SnackbarProps } from '../Snackbars.types';
import SnackbarAnimation from './SnackbarAnimation';
import { InfoOutlined } from '@mui/icons-material';

const InfoSnackbar = memo((props: SnackbarProps) => {
  const { message, config } = props;

  return (
    <SnackbarAnimation duration={config?.duration}>
      <Alert severity="info" icon={<InfoOutlined color="inherit" />}>
        <AlertTitle>{config?.title ?? 'Info'}</AlertTitle>
        {message}
      </Alert>
    </SnackbarAnimation>
  );
});

InfoSnackbar.displayName = 'InfoSnackbar';

export default InfoSnackbar;
