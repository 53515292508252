import { Box, Button } from '@mui/material';
import TextInput from 'components/TextInput';
import { FC, KeyboardEvent } from 'react';
import { ButtonRowProps } from './ButtonRow.types';
import styles from './ButtonRow.styles';
import { AddNewSkillFieldName, SkillForm } from '../SkillsDialog.types';
import { useFormContext } from 'react-hook-form';
import searchStyles from 'components/SearchField/SearchField.styles';
import useRemoveButtonRowValidation from '../useRemoveButtonValidation';

const ButtonRow: FC<ButtonRowProps> = ({ skillCategory, addNewSkill }) => {
  const newSkillName = `new ${skillCategory}` as AddNewSkillFieldName;
  const { watch, setFocus, clearErrors } = useFormContext<SkillForm>();

  const valueLength = watch(newSkillName)?.length;
  const isDisabled = !valueLength;

  const addSkillAndFocus = () => {
    addNewSkill(skillCategory);
    setFocus(newSkillName);
  };

  const onEnterHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !isDisabled) {
      addSkillAndFocus();
    }
  };

  useRemoveButtonRowValidation(valueLength, clearErrors, newSkillName);

  return (
    <Box sx={styles.buttonRow}>
      <TextInput
        label="Add new"
        name={newSkillName}
        onKeyDown={onEnterHandler}
        sx={searchStyles.search}
      />
      <Button
        variant="contained"
        sx={{ height: '3.5rem' }}
        onClick={addSkillAndFocus}
        disabled={isDisabled}
      >
        Add
      </Button>
    </Box>
  );
};

export default ButtonRow;
