import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { peopleApiGet } from 'api/axios';
import { UserHolidayGetResponse } from './useUserHoliday.types';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { useUser } from 'hooks/useUsers/useUser';

export const fetchUserHolidays = (
  context: QueryFunctionContext<[string, number]>
) => {
  const [, id = 0] = context.queryKey;

  return peopleApiGet<UserHolidayGetResponse>(
    `${PEOPLE_API_URLS.holiday}?user=${id}`
  );
};

const useUserHolidays = (enabled?: boolean) => {
  const { data: user } = useUser();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [PEOPLE_API_URLS.holiday, user?.id ?? 0],
    queryFn: fetchUserHolidays,
    enabled,
  });

  return {
    data: data?.data.results ?? [],
    refetch,
    isLoading,
  };
};

export default useUserHolidays;
