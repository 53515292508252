import {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { Slide } from '@mui/material';
import { SnackbarAnimationProps } from '../Snackbars.types';

const SnackbarAnimation: FC<PropsWithChildren<SnackbarAnimationProps>> = ({
  children,
  duration,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <Slide direction="up" in={isVisible}>
      {children as ReactElement}
    </Slide>
  );
};

export default SnackbarAnimation;
