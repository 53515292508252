import { FC } from 'react';
import AutoCompleteInput from 'components/AutoCompleteInput';
import { Option } from 'common/types';
import FormWrapper from 'components/FormWrapper';
import { SelectUserProps } from './HolidayApplicationForm.types';
import { useSelectUser } from './useSelectUser';

export type userOrAllOption = Option<number | 'all', string>;
export interface holidayCalendarForm {
  employee?: userOrAllOption;
}

export const SelectUser: FC<SelectUserProps> = (props) => {
  const { methods, usersToSelect, selectUserOption, handleUserChange } =
    useSelectUser(props);

  return (
    <FormWrapper id="select-user-form" methods={methods} onSubmit={() => {}}>
      <AutoCompleteInput
        fullWidth
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        options={usersToSelect}
        name="employee"
        label="Pracownik"
        value={selectUserOption}
        onChange={handleUserChange}
      />
    </FormWrapper>
  );
};
