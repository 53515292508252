import { /* COLORS,*/ SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: {
      Mobile: 'column',
      Tablet: 'column',
      Desktop: 'row',
    },
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '2rem',
  },
  leftPanel: {
    maxWidth: '20rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    width: {
      Mobile: '100%',
      Tablet: '15rem',
      Desktop: '20rem',
    },
  },
  mainPanel: {
    width: '100%',
    justifyContent: 'center',
  },
  button: {
    width: '16rem',
    margin: '1rem 0',
  },
};

export default styles;
