import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from 'pages/RoomReservation/components/ReserveRoomModal/ReserverRoomModal.styles';
import { MdArrowForwardIos, MdOutlineCalendarToday } from 'react-icons/md';
import { MyReservedRoomsModalProps } from './MyReserverdRoomsModal.types';
import { formatDate } from 'pages/RoomReservation/RoomReservation.helpers';
import DisplayWrapper from 'components/DisplayWrapper';
import useMyRoomReservations from 'hooks/useRoomReservations/useMyRoomReservations';

const MyReservedRoomsModal: FC<MyReservedRoomsModalProps> = ({
  myReservations,
}) => {
  const { onReservationClick } = useMyRoomReservations();

  return (
    <>
      <DisplayWrapper sx={styles.container}>
        <Typography variant={'h1'}>Moje Rezerwacje</Typography>
        {myReservations.map((myReservation) => (
          <Box
            onClick={() => onReservationClick(myReservation)}
            key={myReservation.id}
            sx={styles.reservationsContainer}
          >
            <MdOutlineCalendarToday style={styles.iconColor} />
            <Typography variant={'body1'}>
              {formatDate(myReservation.date)}
            </Typography>
            <MdArrowForwardIos style={styles.rightIcon} />
          </Box>
        ))}
      </DisplayWrapper>
    </>
  );
};

export default MyReservedRoomsModal;
