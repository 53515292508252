import Calendar from 'pages/RoomReservation/components/Calendar';
import { Box, Typography } from '@mui/material';
import CardContent from './components/CardContent/CardContent';
import styles from './RoomReservation.styles';
import PageContent from 'components/PageContent';
import useRoomReservations from 'hooks/useRoomReservations';
import { LEGEND_CONTENT } from 'components/Legend/Legend.consts';
import Legend from '../../components/Legend';
import { useMemo } from 'react';
import MyReservedRoomsModal from 'pages/RoomReservation/components/MyReservedRoomsModal';

const RoomReservation = () => {
  const { currentUserReservations, onSelect, reservedRooms } =
    useRoomReservations();

  const isMyReservedRoomsModalOpen = useMemo(() => {
    return currentUserReservations.length > 0;
  }, [currentUserReservations.length]);

  return (
    <PageContent fullWidth>
      <Box sx={styles.wrapper}>
        <Typography variant="h1" sx={styles.text}>
          Rezerwacja pokoju cichej pracy
        </Typography>
        <Box sx={styles.content}>
          <Calendar onSelect={onSelect} reservedRooms={reservedRooms} />
          <Box sx={styles.rightColumn}>
            <CardContent />
            {isMyReservedRoomsModalOpen && (
              <MyReservedRoomsModal myReservations={currentUserReservations} />
            )}
            <Legend dotsContentScheme={LEGEND_CONTENT} />
          </Box>
        </Box>
      </Box>
    </PageContent>
  );
};

export default RoomReservation;
