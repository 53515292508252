import { z, ZodType } from 'zod';
import { BaseSyntheticEvent, KeyboardEvent, useCallback } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { FieldValues, FormProvider } from 'react-hook-form';

import styles from './FormWrapper.styles';
import { FormWrapperProps } from './types';

const checkKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  // Allow text area to work the same
  if (event.key === 'Enter' && !['TEXTAREA'].includes(target.tagName))
    event.preventDefault();
};

const FormWrapper = <T extends FieldValues, K extends ZodType | undefined>({
  methods,
  children,
  onSubmit,
  validator,
  id,
  isLoading = false,
  sx = {},
}: FormWrapperProps<T, K>) => {
  const submitHandler = useCallback(
    async (data: T, event?: BaseSyntheticEvent) => {
      const returnedData = (
        validator ? validator.parse(data) : data
      ) as K extends ZodType ? z.infer<K> : T;

      event?.preventDefault();

      return onSubmit(returnedData, event);
    },
    [validator, onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id={id}
        noValidate
        sx={[styles.form, sx]}
        data-testid="form"
        onKeyDown={(e) => checkKeyDown(e)}
        onSubmit={(e) => void methods.handleSubmit(submitHandler)(e)}
      >
        {isLoading && (
          <Box sx={styles.loader}>
            <CircularProgress />
          </Box>
        )}
        {children}
      </Box>
    </FormProvider>
  );
};

export default FormWrapper;
