import { COLORS, SxStylesheet } from 'theme/index';

export const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    borderLeft: '1px solid',
    borderColor: COLORS.blue[300],
    paddingLeft: '1rem',
    textTransform: 'uppercase',
  },
  summaryEntry: {
    fontSize: 14,
    width: '100%',
  },
};
