import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import AppDialog from 'components/AppDialog';
/* eslint react/prop-types: 0 */
// Hooks with tsx break prop-types apparently, TODO: Remove prop-types

const useConfirmationModal = ({
  acceptHandler,
  title = 'Are you sure you want to continue?',
  acceptText = 'Confirm',
  cancelText = 'Cancel',
}: {
  acceptHandler: () => void;
  title?: string;
  acceptText?: string;
  cancelText?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openConfirmationModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onAccept = useCallback(() => {
    acceptHandler();
    close();
  }, [acceptHandler, close]);

  const ConfirmationDialog = useCallback(
    () => (
      <AppDialog
        onClose={close}
        open={isOpen}
        PaperProps={{
          sx: { 'maxWidth': '32rem', '.MuiDialogActions-root': { pt: 0 } },
        }}
        actions={
          <>
            <Button onClick={close}>{cancelText}</Button>
            <Button variant="contained" onClick={onAccept}>
              {acceptText}
            </Button>
          </>
        }
        title={title}
      />
    ),
    [isOpen, close, onAccept, title, acceptText, cancelText]
  );

  return useMemo(
    () => ({
      isOpen,
      ConfirmationDialog,
      openConfirmationModal,
      close,
    }),
    [isOpen, ConfirmationDialog, openConfirmationModal, close]
  );
};

export default useConfirmationModal;
