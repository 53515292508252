import { FC, useMemo, useRef } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';
import {
  Box,
  ButtonBaseActions,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

import styles from './CheckboxInput.styles';
import { CheckboxInputProps } from './CheckboxInput.types';

const CheckboxInput: FC<CheckboxInputProps> = ({
  label,
  name,
  wrapperSx = {},
  handleChange,
  isSmall,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const actionRef = useRef<ButtonBaseActions | null>(null);

  const formErrors = useMemo(() => get(errors, name), [errors, name]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange, ref, ...rest } }) => (
        <>
          <Box sx={[styles.checkboxWrapper, wrapperSx]}>
            <FormControlLabel
              label={label}
              {...props}
              control={
                <Checkbox
                  size={isSmall ? 'small' : undefined}
                  action={(ref) => {
                    actionRef.current = ref;
                  }}
                  onFocus={() => actionRef?.current?.focusVisible()}
                  inputRef={ref}
                  checked={!!value}
                  onChange={(event) => {
                    handleChange?.(!value);
                    onChange(event);
                    onBlur();
                  }}
                  {...rest}
                />
              }
            />
          </Box>
          {formErrors && (
            <FormHelperText error>
              {(formErrors as FieldError)?.message ?? ''}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default CheckboxInput;
