import { FC, useState } from 'react';
import { TextField } from '@mui/material';
import { Controller, get, useFormContext } from 'react-hook-form';
import InputWrapper from '../InputWrapper';
import { TextInputProps } from './types';

const TextInput: FC<TextInputProps> = ({
  name,
  label,
  fullWidth = false,
  wrapperSx = {},
  normalizer,
  onBlur: handleBlur,
  onKeyDown,
  ...rest
}) => {
  const { control } = useFormContext();

  const [focused, setFocused] = useState(false);

  return (
    <InputWrapper sx={wrapperSx} fullWidth={fullWidth} name={name}>
      <Controller
        control={control}
        name={name}
        render={({
          formState: { errors },
          field: { onChange, onBlur, ref, value, ...fieldProps },
        }) => (
          <TextField
            label={label}
            {...fieldProps}
            {...rest}
            value={value ?? ''}
            error={!!get(errors, name)}
            onWheel={
              rest?.type === 'number'
                ? (e) => (e.target as HTMLElement)?.blur() //prevent wheel scroll from changing # value
                : undefined
            }
            inputRef={ref}
            InputLabelProps={{
              ...(rest.InputProps?.startAdornment
                ? {
                    // We need to set the shrink property,
                    // because when startAdornment is added
                    // MUI by default sets it to 'true'
                    shrink: !!value || focused,
                    sx: {
                      ml: value || focused ? 0 : 4,
                    },
                  }
                : {}),
              ...(rest.InputLabelProps ?? {}),
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={(event) => {
              onBlur();
              setFocused(false);
              handleBlur?.(event);
            }}
            onChange={(event) => {
              if (normalizer) {
                const { value } = event.target;
                event.target.value = normalizer(value);
              }
              onChange(event.target.value);
            }}
            onKeyDown={onKeyDown}
          />
        )}
      />
    </InputWrapper>
  );
};

export default TextInput;
