import { FC } from 'react';
import { UserHolidaysPoolSummary } from 'components/UserHolidaysPoolSummary/UserHolidaysPoolSummary';
import { UserHolidaysPoolSummaryStyle } from 'components/UserHolidaysPoolSummary/UserHolidaysPoolSummary.types';
import { UserHolidayPoolSummary } from 'hooks/useUserHolidayPool';

interface Props {
  holidayPools: UserHolidayPoolSummary[];
}

export const ShortHolidayPoolSummary: FC<Props> = ({ holidayPools }) =>
  holidayPools.map((holidaysPool) => {
    return (
      <UserHolidaysPoolSummary
        key={`${holidaysPool.id}-holidaypool-short`}
        holidaysPool={holidaysPool}
        type={UserHolidaysPoolSummaryStyle.ShortWithName}
      />
    );
  });
