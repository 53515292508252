import { Box, Button, Chip } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import formStyles from 'common/styles/forms';
import AutoCompleteInput, {
  AutoCompleteOption,
} from 'components/AutoCompleteInput';
import styles from './HolidayFiltersDialog.styles';
import { Clear } from '@mui/icons-material';
import CheckboxInput from 'components/CheckboxInput';
import {
  HolidayFiltersDialogProps,
  MONTH_OPTIONS,
} from './HolidayFiltersDialog.types';

const HolidayFiltersDialog = ({
  onClose,
  methods,
  onSubmitHandler,
  holidayTypesOptions,
  onOptionChange,
  selectedTypes,
  handleDeleteType,
}: HolidayFiltersDialogProps) => {
  return (
    <AppDialog
      title={'Holiday list filters'}
      open
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="holiday-filters" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        methods={methods}
        id="holiday-filters"
        onSubmit={onSubmitHandler}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={holidayTypesOptions}
              label="typ"
              name="type"
              onChange={(_, value) => {
                const val = value as AutoCompleteOption;
                onOptionChange(val);
              }}
            />
          </Box>
          <Box sx={styles.chipBox}>
            {selectedTypes?.map((type, id) => (
              <Chip
                label={type.label}
                key={`${type}-${id}`}
                deleteIcon={<Clear style={styles.deleteFeature} />}
                onDelete={() => handleDeleteType(type)}
                style={styles.chip}
              />
            ))}
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <CheckboxInput
              label="zaapkceptowany przez admina"
              name="isAcceptedByAdmin"
            />
            <CheckboxInput
              label="zaapkceptowany przez pracownika"
              name="isAcceptedByEmployee"
            />
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              clearOnEscape
              selectOnFocus
              handleHomeEndKeys
              options={MONTH_OPTIONS}
              label="rozpoczęcie urlopu od"
              name="startHolidayFrom"
            />
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              clearOnEscape
              selectOnFocus
              handleHomeEndKeys
              options={MONTH_OPTIONS}
              label="rozpoczęcie urlopu do"
              name="startHolidayTo"
            />
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              clearOnEscape
              selectOnFocus
              handleHomeEndKeys
              options={MONTH_OPTIONS}
              label="zakończenie urlopu od"
              name="endHolidayFrom"
            />
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              clearOnEscape
              selectOnFocus
              handleHomeEndKeys
              options={MONTH_OPTIONS}
              label="zakończenie urlopu do"
              name="endHolidayTo"
            />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default HolidayFiltersDialog;
