import { Box } from '@mui/material';
import { CalendarToday, BeachAccess } from '@mui/icons-material';

import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import ActionButtons from 'pages/Profile/components/ActionButtons';
import { ContractProps, ContractTypeDict } from './Contract.types';
import IconLabel from 'components/IconLabel/IconLabel';
import { Label } from '@mui/icons-material';
import styles from './Contract.styles';
import { ContractType } from 'api/types/contract';
import useMutateUserContract from 'hooks/useContracts/useMutateUserContract';
import { formatHolidayPool } from './Contract.helpers';

const Contract = ({ contract, handleEditContract }: ContractProps) => {
  const { contractDeleteMutate } = useMutateUserContract();
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      contract.id && contractDeleteMutate({ contractId: contract.id }),
    title: `Are you sure you want to delete this contract?`,
  });
  const { dateFrom, dateTo, type, holidayPool } = contract;

  return (
    <Box sx={styles.infoGrid}>
      <IconLabel
        Icon={CalendarToday}
        label={`${dateFrom} - ${dateTo !== null ? dateTo : 'indefinite'}`}
      />
      <IconLabel
        Icon={Label}
        label={`typ: ${ContractTypeDict[type as ContractType]}`}
      />
      <IconLabel
        Icon={BeachAccess}
        label={`pula urlopowa: ${formatHolidayPool(holidayPool)}`}
      />
      <ActionButtons
        isStart
        onDeleteHandler={openConfirmationModal}
        onEditHandler={handleEditContract}
      />
      <ConfirmationDialog />
    </Box>
  );
};

export default Contract;
