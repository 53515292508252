import { ExternalPaths } from 'src/router/types';

export const DOCUMENTS = [
  {
    label: 'Polityka jakości',
    navigateTo: ExternalPaths.qualityPolicy,
  },
  {
    label: 'Polityka bezpieczeństwa',
    navigateTo: ExternalPaths.securityPolicy,
  },
];
