import { Box, Button, Typography } from '@mui/material';
import { Desk } from 'api/types/deskReservation';
import styles from './CancelReservation.styles';
import deskReservationStyles from '../../DeskReservation.styles';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DisplayWrapper from 'components/DisplayWrapper';
import { formatDateForReservation } from 'utils/date';
import useMutateDeskReservation from 'hooks/useMutateDeskReservation';
import useDesks from 'hooks/useDesks';
import useDesk from 'hooks/useDesk';
import { CancelReservationProps } from './CancelReservation.types';

const CancelReservation = ({
  setDeskReservationToCancel,
  deskReservationToCancel,
}: CancelReservationProps) => {
  const { deleteMutate } = useMutateDeskReservation();
  const { hotDesks } = useDesks();
  const { desk } = useDesk();

  if (!deskReservationToCancel) return <></>;

  const selectedDesk = hotDesks?.find(
    (desk: Desk) => desk?.id === deskReservationToCancel.desk
  );
  if (!selectedDesk) return <></>;

  const onDeleteReservation = async () => {
    if (deskReservationToCancel) {
      await deleteMutate({
        desk: deskReservationToCancel.desk,
        dates: [deskReservationToCancel.date],
      });
      setDeskReservationToCancel(undefined);
    }
  };

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant="h1" sx={styles.headerText}>
          Moje rezerwacje
        </Typography>
        <Box>
          <Box sx={styles.dateContainer}>
            <CalendarTodayOutlinedIcon />
            <Typography>
              {formatDateForReservation(new Date(deskReservationToCancel.date))}
            </Typography>
          </Box>
          <Typography sx={styles.bottomText}>
            Biurko {selectedDesk.name}
          </Typography>
          <Box sx={styles.featuresContainer}>
            {desk?.features
              ? desk.features.map((feature) => (
                  <Typography sx={styles.featureText} key={feature.id}>
                    {feature?.name}
                  </Typography>
                ))
              : null}
          </Box>
          <Box sx={styles.buttonContainer}>
            <Button
              sx={styles.cancelButton}
              onClick={() => setDeskReservationToCancel(undefined)}
            >
              Zamknij
            </Button>
            <Button
              sx={deskReservationStyles.saveButton}
              onClick={onDeleteReservation}
            >
              Anuluj rezerwacje
            </Button>
          </Box>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default CancelReservation;
