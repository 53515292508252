import { memo, useContext, useMemo } from 'react';
import AppDialog from 'components/AppDialog';
import ModalContext, { KudosModalType } from 'contexts/Modal/ModalContext';
import { Button } from '@mui/material';
import { EditKudosModalProps } from 'pages/MyKudos/EditKudosModal/EditKudosModal.types';
import useMutateKudos from 'hooks/useKudos/useMutateKudos';
import AutoCompleteInput from 'components/AutoCompleteInput';
import { mapProfileListToOption } from 'pages/Kudos/components/SendKudosModal/SendKudosModal.helpers';
import useProfileList from 'hooks/useProfileList';
import AuthContext from 'contexts/Auth/AuthContext';
import kudosFormValidator from 'validation/kudosFormValidator';
import useSendKudos from 'pages/Kudos/components/SendKudosModal/useSendKudos';
import FormWrapper from 'components/FormWrapper';
import KudosCard from 'pages/Kudos/components/KudosCard';
import InputWrapper from 'components/InputWrapper';

const EditKudosModal = memo(({ kudos }: EditKudosModalProps) => {
  const { closeModal, currentModal } = useContext(ModalContext);
  const { deleteMutate } = useMutateKudos();

  const { data } = useProfileList(true);
  const { id: userId } = useContext(AuthContext);

  const {
    methods,
    onSubmitHandler,
    messageController,
    isSending,
    onCloseHandler,
  } = useSendKudos({
    kudos,
  });

  const deleteKudos = async (kudosId: number) => {
    await deleteMutate({ kudosId }).catch((error) => {
      console.error(error);
    });
    closeModal();
  };

  const selectOptions = useMemo(
    () =>
      data?.filter(({ id }) => id !== userId).map(mapProfileListToOption) ?? [],
    [data, userId]
  );

  return (
    <AppDialog
      title={'Edytuj kudosa'}
      actions={
        <>
          <Button variant="outlined" onClick={() => deleteKudos(kudos.id)}>
            Usuń
          </Button>
          <Button
            variant="contained"
            form="kudos-form"
            type="submit"
            disabled={isSending}
          >
            Edytuj
          </Button>
        </>
      }
      open={currentModal === KudosModalType.EditKudos}
      onClose={onCloseHandler}
    >
      <FormWrapper
        id="kudos-form"
        methods={methods}
        validator={kudosFormValidator}
        onSubmit={onSubmitHandler}
      >
        <AutoCompleteInput
          fullWidth
          clearOnBlur
          options={selectOptions}
          label="Wybierz komu chcesz wysłać kudosa"
          noOptionsText="Brak użytkownika"
          name="user"
          sx={{ pt: '0.3rem' }}
        />
        <InputWrapper sx={{ gap: '0.5rem' }} name="message" fullWidth>
          <KudosCard
            type={kudos?.kudosType}
            height="14rem"
            controller={messageController}
          />
        </InputWrapper>
      </FormWrapper>
    </AppDialog>
  );
});

EditKudosModal.displayName = 'EditKudosModal';

export default EditKudosModal;
