import { getImageSize } from 'utils/styles';

const styles = {
  root: {
    display: 'flex',
    gap: {
      Desktop: '4.813rem',
      Tablet: '4.813rem',
      Mobile: '1.25rem',
    },
    flexDirection: {
      Desktop: 'row',
      Tablet: 'row',
      Mobile: 'column',
    },
  },
  image: {
    background: 'none',
    ...getImageSize('10rem'),
    borderRadius: '5rem',
  },
  userInfo: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '0 2.201rem',
    marginY: '1.375rem',
  },
} as const;

export default styles;
