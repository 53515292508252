import { FC } from 'react';
import { DeskCardContent, DeskCardContentProps } from './DeskCardContent.types';
import ReserveDesk from '../ReserveDesk';
import NotHotDesk from '../NotHotDesk/NotHotDesk';
import EditDesk from '../Admin/EditDesk';
import ReservedDesk from '../ReservedDesk';

const DeskCard: FC<DeskCardContentProps> = ({ deskCardContent }) => {
  switch (deskCardContent) {
    case DeskCardContent.ReserveDesk:
      return <ReserveDesk />;
    case DeskCardContent.OthersDeskReservation:
      return <ReservedDesk />;
    case DeskCardContent.NoHotDesk:
      return <NotHotDesk />;
    case DeskCardContent.EditDesk:
      return <EditDesk />;
    default:
      return <></>;
  }
};

export default DeskCard;
