import { FC, memo } from 'react';
import { Card as MuiCard, CardContent, CardHeader } from '@mui/material';
import styles from './Card.styles';
import { CardProps } from './Card.types';

const Card: FC<CardProps> = memo(({ title, content }: CardProps) => {
  return (
    <MuiCard sx={styles.root}>
      <CardHeader subheader={title} sx={styles.header} />
      <CardContent sx={styles.content}>{content}</CardContent>
    </MuiCard>
  );
});

Card.displayName = 'Card';

export default Card;
