import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { ProjectDetailsGetResponse } from 'api/types/projects';

export const fetchProfile = (
  context: QueryFunctionContext<[string, number | undefined]>
) => {
  const [, externalOwner] = context.queryKey;

  return apiGet<ProjectDetailsGetResponse>(
    `${API_URLS.projectDetails}${
      externalOwner ? `?external-owner=${externalOwner}` : ''
    }`
  );
};

// External project is used to fetch projects that user created from frontend and are assigned directly
// to him. Normal company projects can be created only via backend. Whenever you are calling this hook
// with passed external-owner parameter you are getting both yours and company project.
const useProjects = (enabled?: boolean, externalOwner?: number) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.projectDetails, externalOwner],
    queryFn: fetchProfile,
    enabled,
  });

  return {
    data: data?.data,
    refetch,
    isLoading,
  };
};

export default useProjects;
