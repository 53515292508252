import { apiGet } from 'api/axios';
import { DeskReservation } from 'api/types/deskReservation';
import { API_URLS } from 'api/axios/axios.consts';
import { useQuery } from '@tanstack/react-query';

const fetchReservationsForDesk = (deskId?: number) =>
  apiGet<Array<DeskReservation>>(`${API_URLS.deskReservations}?desk=${deskId}`);

export const useReservationsForDesk = (deskId?: number) => {
  const {
    data: reservationsForDesk,
    refetch: refetchReservationsForDesk,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: [API_URLS.deskReservations, deskId],
    queryFn: () => fetchReservationsForDesk(deskId),
    enabled: !!deskId,
  });

  return {
    reservationsForDesk: reservationsForDesk && reservationsForDesk?.data,
    refetchReservationsForDesk,
    isLoading,
    isFetched,
  };
};

export default useReservationsForDesk;
