import { memo } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styles } from './Accordion.styles';
import { AccordionProps } from '.';

const Accordion = memo(({ summary, details }: AccordionProps) => {
  return (
    <MuiAccordion>
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
        <Typography sx={styles.h1} variant="h1">
          {summary}
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>{details}</MuiAccordionDetails>
    </MuiAccordion>
  );
});

Accordion.displayName = 'Accordion';

export default Accordion;
