import { Box, Typography } from '@mui/material';
import { Desk } from 'api/types/deskReservation';
import { useContext, useMemo } from 'react';
import DisplayWrapper from 'components/DisplayWrapper';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useDesks from 'hooks/useDesks';
import { PersonOutline } from '@mui/icons-material';
import styles from './NotHotDesk.styles';
import useProfileList from 'hooks/useProfileList';

const NotHotDesk = () => {
  const { allDesks } = useDesks();
  const { activeDesk } = useContext(DeskContext);
  const { data } = useProfileList(true);

  const selectedDesk = useMemo(
    () => allDesks?.find((desk: Desk) => desk?.id === activeDesk),
    [activeDesk, allDesks]
  );
  const deskBooker = useMemo(
    () => data?.find((booker) => booker.id === selectedDesk?.owner)?.title,
    [data, selectedDesk]
  );

  if (activeDesk === null) return <></>;

  return (
    <DisplayWrapper>
      <Box>
        <Typography variant="h1">Biurko</Typography>
        <Box>
          <Box sx={styles.textContainer}>
            <PersonOutline />
            <Typography>{deskBooker}</Typography>
          </Box>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default NotHotDesk;
