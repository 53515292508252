import { Box, Typography } from '@mui/material';
import { Desk } from 'api/types/deskReservation';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import styles from 'pages/DeskReservation/components/MyReservedDesks/MyReservedDesks.styles';
import { MyReservedDesksProps } from 'pages/DeskReservation/components/MyReservedDesks/MyReservedDesks.types';
import DisplayWrapper from 'components/DisplayWrapper';
import { formatDateForReservation } from 'utils/date';
import { useContext } from 'react';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import useDesks from 'hooks/useDesks';

const MyReservedDesks = ({
  myReservations,
  setDeskReservationToCancel,
}: MyReservedDesksProps) => {
  const { editMode } = useContext(DeskContext);
  const { hotDesks } = useDesks();

  if (editMode || !myReservations?.length) return <></>;

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant="h1" sx={styles.headerText}>
          Moje rezerwacje
        </Typography>
        {myReservations?.map((reservation) => (
          <Box
            onClick={() => {
              setDeskReservationToCancel(reservation);
            }}
            key={reservation.id}
            sx={styles.reservationTextContainer}
          >
            <Box sx={styles.reservationContainer}>
              <CalendarTodayOutlinedIcon />
              <Typography>
                {formatDateForReservation(new Date(reservation.date))}
              </Typography>
              <ArrowForwardIosOutlinedIcon sx={styles.rightIcon} />
            </Box>
            <Typography sx={styles.bottomText}>
              Biurko{' '}
              {hotDesks?.find((desk: Desk) => desk?.id === reservation?.desk)
                ?.name || ''}
            </Typography>
          </Box>
        ))}
      </Box>
    </DisplayWrapper>
  );
};

export default MyReservedDesks;
