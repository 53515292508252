import { useUser } from 'hooks/useUsers/useUser';
import { useState, useContext } from 'react';
import { DateRange } from './HolidayCalendar.types';
import { UserData } from 'api/types/holidayPool';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import {
  convertPublicHolidaysToCalendarEvent,
  convertSocialSecurityBenefitsToCalendarEvent,
  convertUserHolidaysToCalendarEvent,
} from './HolidayCalendar.helpers';
import { usePublicHolidays } from 'hooks/usePublicHolidays';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import { subDays } from 'date-fns';
import { Nullable } from 'common/types';
import useSocialSecurityBenefits from 'hooks/useSocialSecurityBenefits';
import ModalContext, { GlobalModalType } from 'contexts/Modal/ModalContext';
import { UserHoliday } from 'api/types/holiday';
import useUsersCalendarHolidays from 'hooks/useCalendarHoliday';

export const useHolidayCalendar = () => {
  const user = useUser();
  const [selectUser, setSelectUser] = useState<Nullable<UserData>>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({});
  const [holidayToConfirm, setHolidayToConfirm] =
    useState<Nullable<UserHoliday>>(null);
  const { openModal } = useContext(ModalContext);

  const usersHolidays = useUsersCalendarHolidays({
    userId: selectUser === null ? undefined : selectUser.id,
  });

  const selectUserHolidays = usersHolidays?.data?.find(
    (usersHoliday) => usersHoliday.id === selectUser?.id
  );
  const selectUserHolidaysEvent: EventInput[] = selectUserHolidays
    ? convertUserHolidaysToCalendarEvent(selectUserHolidays, user.data?.id)
    : [];
  const allUserHolidaysEvent: EventInput[] =
    usersHolidays.data?.reduce<EventInput[]>((allEvents, userHolidays) => {
      return [
        ...allEvents,
        ...convertUserHolidaysToCalendarEvent(userHolidays, user.data?.id),
      ];
    }, []) || [];

  const socialSecurityBenefits = useSocialSecurityBenefits(
    selectUser === null ? undefined : selectUser.id
  );
  const socialSecurityBenefitsEvents =
    socialSecurityBenefits.data?.map<EventInput>(
      convertSocialSecurityBenefitsToCalendarEvent
    );
  const publicHolidays = usePublicHolidays();
  const publicHolidaysEvents = publicHolidays.data?.map<EventInput>(
    convertPublicHolidaysToCalendarEvent
  );

  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () => {
      console.debug('To do: Accept holiday on back end ;-)', holidayToConfirm);
      setHolidayToConfirm(null);
    },
    title: `Czy chcesz zaakceptować urlop ?`,
    acceptText: 'Zaakceptuj',
    cancelText: 'Zamknij',
  });

  const [expandCalendarRows, setExpandCalendarRows] = useState(false);
  const [hoveredEventId, setHoveredEventId] = useState<string | undefined>();

  const currentEvents: EventInput[] = [
    ...(socialSecurityBenefitsEvents ?? []),
    ...(publicHolidaysEvents ?? []),
    ...(selectUser === null ? allUserHolidaysEvent : selectUserHolidaysEvent),
  ];

  const selectDateRange = (calendarEveent: DateSelectArg) => {
    console.debug('hoveredEventId:', hoveredEventId);
    setSelectedDateRange({
      dateFrom: calendarEveent.start,
      dateTo: subDays(calendarEveent.end, 1),
    });
    openModal(GlobalModalType.AddHoliday);
    if (expandCalendarRows) {
      setExpandCalendarRows(false);
    }
  };

  const calendarEveentClick = (eventArgs: EventClickArg) => {
    // Click on item in the calendar.
    // To do: Open dialog to make any changes with holidays, L4, ...
    const userHoliday: UserHoliday = eventArgs.event._def
      .extendedProps as UserHoliday;
    if (
      (user.data?.id === userHoliday.user &&
        userHoliday.isAcceptedByEmployee === false) ||
      (userHoliday.isAcceptedByAdmin === false &&
        user.data?.isCompanyAdmin === true)
    ) {
      setHolidayToConfirm(userHoliday);
      openConfirmationModal();
    }
  };

  return {
    calendarEveentClick,
    selectDateRange,
    currentEvents,
    setHoveredEventId,
    ConfirmationDialog,
    openConfirmationModal,
    selectedDateRange,
    selectUser,
    setSelectUser,
    setSelectedDateRange,
    expandCalendarRows,
    setExpandCalendarRows,
    isLoading: user.isLoading,
    user: user.data,
  };
};
