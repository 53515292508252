import DeskContext from './DeskContext';
import { FC, PropsWithChildren, useState } from 'react';
import { Nullable } from 'common/types';

const DeskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeDesk, setActiveDesk] = useState<Nullable<number>>(null);
  const [date, setDate] = useState<Nullable<Date>>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <DeskContext.Provider
      value={{
        activeDesk,
        setActiveDesk,
        date,
        setDate,
        selectedDate,
        setSelectedDate,
        editMode,
        setEditMode,
      }}
    >
      {children}
    </DeskContext.Provider>
  );
};

export default DeskProvider;
