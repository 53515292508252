import { Nullable } from 'common/types';

export enum ContractType {
  UoZ = 'UoZ',
  UoD = 'UoD',
  UoP = 'UoP',
  B2B = 'B2B',
}

export interface Contract {
  id: number;
  dateFrom: string;
  dateTo: Nullable<string>;
  type: string;
  holidayPool: string;
}
