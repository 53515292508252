import { COLORS } from 'theme/Theme.const';

const styles = {
  contentWrapper: {
    marginTop: '2rem',
    maxHeight: '22rem',
    overflowY: 'auto',
  },
  cancelledIcon: {
    width: '1.2rem',
    height: '1.2rem',
    color: COLORS.error,
  },
  acceptedIcon: {
    width: '1.2rem',
    height: '1.2rem',
    color: COLORS.green[100],
  },
};

export default styles;
