import { Box } from '@mui/material';
import { memo } from 'react';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import styles from './EmailIcon.styles';
import { COLORS } from 'src/theme';

const EmailIcon = memo(() => {
  return (
    <Box sx={styles.emailIcon}>
      <MdOutlineMarkEmailRead color={COLORS.white} />
    </Box>
  );
});

EmailIcon.displayName = 'EmailIcon';

export default EmailIcon;
