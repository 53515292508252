const styles = {
  projectDetailsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0.5rem',
  },
  dateGrid: {
    display: 'grid',
    gridTemplateColumns: { Mobile: '1fr', Tablet: '1fr 1fr' },
    gap: '0 1.5rem',
  },
};

export default styles;
