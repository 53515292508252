import { Box, Button, Link, Typography } from '@mui/material';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent';
import {
  ChangeEventHandler,
  CSSProperties,
  memo,
  useCallback,
  useState,
} from 'react';
import styles from './Photos.styles';
import { RouterPaths } from 'src/router/types';
import useCustomNavigation from 'src/router/useNavigation';
import { Nullable } from 'common/types';
import PhotosAddedModal from './components/PhotosAddedModal';
import useMutateHolidayPhoto from 'hooks/useHolidayPhoto/useMutateHolidayPhoto';

const Photos = memo(() => {
  const navigation = useCustomNavigation();
  const [images, setImages] = useState<Array<Nullable<File | undefined>>>([]);
  const [wasPhotoSend, setWasPhotoSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { postMutate } = useMutateHolidayPhoto();

  const putImageToArray: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const eventFileList = event.target.files;
      const isImagedAlreadyUsed = images.find(
        (image) => image?.name === eventFileList?.[0]?.name
      );

      if (!isImagedAlreadyUsed && eventFileList) {
        setImages([...images, ...eventFileList]);
      }
    },
    [images]
  );

  const uploadPhotos = async () => {
    setIsLoading(true);
    try {
      await Promise.all(images.map((image) => postMutate({ image })));
      setWasPhotoSend(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (wasPhotoSend) {
    return <PhotosAddedModal />;
  }

  const filterImageArray = (name: string) => {
    setImages(images.filter((image) => image?.name !== name));
  };

  return (
    <PageContent isLoading={isLoading}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="h1">
            CHCESZ ŻEBY TWOJE ZDJĘCIE Z WAKACJI ZOSTAŁO OPUBLIKOWANE
            <br />W NASZYCH MEDIACH SPOŁECZNOŚCIOWYCH?
          </Typography>

          <Button variant="contained" component="label" sx={styles.button}>
            Dodaj zdjęcie
            <input
              type="file"
              hidden
              accept="image/png, image/jpeg"
              onChange={putImageToArray}
            />
          </Button>
          <Box sx={styles.imageContainer}>
            {images.map((image) =>
              image ? (
                <Box key={image.name}>
                  <Button
                    sx={styles.removeImageButton}
                    onClick={() => filterImageArray(image.name)}
                  >
                    x
                  </Button>
                  <img
                    key={image?.name}
                    src={URL.createObjectURL(image)}
                    alt={`Zdjęcie ${image?.name}`}
                    style={styles.image as CSSProperties}
                  />
                </Box>
              ) : (
                []
              )
            )}
          </Box>
          <Typography variant="sub2">
            *ZADBAJ O TO, ŻEBY ZDJĘCIE BYŁO DOBREJ JAKOŚCI, A WIZYTÓWKA BYŁA
            WYRAŹNA
            <br />W INNYM PRZYPADKU ZDJĘCIE MOŻE NIE ZOSTAĆ OPUBLIKOWANE&nbsp;
          </Typography>
          <Button
            disabled={!images.length}
            variant="contained"
            sx={styles.button}
            onClick={uploadPhotos}
          >
            Wyślij
          </Button>
          <Typography variant="sub1">
            …ALBO KLIKNIJ&nbsp;
            <Link
              onClick={() => navigation.navigate(RouterPaths.holidayImages)}
              sx={styles.link}
            >
              TUTAJ
            </Link>
            &nbsp;ŻEBY ZOBACZYĆ GALERIĘ WYSŁANYCH ZDJĘĆ.
          </Typography>
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

Photos.displayName = 'Photos';

export default Photos;
