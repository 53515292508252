import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { ExternalPaths, RouterPaths } from 'src/router/types';
import { MuiIcon } from 'theme//Theme.types';

export enum AppBarContent {
  drawer,
  offer,
  blank,
  deskReservation,
}

export interface AppBarProps {
  content?: AppBarContent;
  isStaff?: boolean;
}

export interface DrawerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface DrawerOption {
  navigateTo: RouterPaths | ExternalPaths | string;
  Icon: MuiIcon;
  label: string;
  adminOnly?: boolean;
  hideOnMainMenu?: boolean;
}

export interface DrawerItemProps {
  selected?: boolean;
  disabled?: boolean;
  Icon: MuiIcon;
  label: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
}
