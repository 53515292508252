import { z } from 'zod';
import { formatToBackendDate, formatToNullableBackendDate } from 'utils/date';
import educationValidator from 'validation/educationFormValidator';
import { SchoolDegree } from 'api/types/schools';

export const formatToBackendFormData = ({
  dateFrom,
  dateTo,
  city,
  degree,
  department,
  fieldOfStudy,
  school,
}: z.infer<typeof educationValidator>) => ({
  dateFrom: formatToBackendDate(dateFrom),
  dateTo: formatToNullableBackendDate(dateTo),
  city,
  degree: degree?.toString() ?? SchoolDegree.Other,
  department,
  fieldOfStudy: fieldOfStudy.label,
  school: school.label,
});
