import { useForm } from 'react-hook-form';
import { useContext, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';

import editProfileValidator from 'validation/editProfileValidator';
import { parseNullishBackendDate } from 'utils/date';
import { EditProfileDialogProps, ProfileForm } from './EditProfileDialog.types';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';

const useEditProfileDialog = ({ worker }: EditProfileDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen = useMemo(
    () => currentModal === ProfileModalType.EditProfile,
    [currentModal]
  );

  const methods = useForm<ProfileForm>({
    resolver: zodResolver(editProfileValidator),
    defaultValues: {
      languages: [],
    },
  });

  const { dateOfBirth, inRevolve, description, experienceSince, languageSet } =
    worker ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        languages:
          languageSet?.map(({ code, name, level }) => ({
            id: code,
            name,
            level,
          })) ?? [],
        description: description ?? '',
        calculateFromProjects: !experienceSince,
        experienceSince: parseNullishBackendDate(experienceSince),
        inRevolve: parseNullishBackendDate(inRevolve),
        dateOfBirth: parseNullishBackendDate(dateOfBirth),
      });
    }
  }, [
    dateOfBirth,
    description,
    experienceSince,
    inRevolve,
    isOpen,
    languageSet,
    methods,
  ]);

  return { isOpen, methods };
};

export default useEditProfileDialog;
