import { useMutation } from '@tanstack/react-query';
import { apiPost } from 'api/axios';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import { API_URLS } from 'api/axios/axios.consts';
import useDeskFeatures from './useDeskFeatures';
import { Feature } from 'api/types/deskFeatures';

const useMutateDeskFeatures = () => {
  const { refetch } = useDeskFeatures();

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: (feature: string[]) =>
      apiPost<Array<Feature>>(
        API_URLS.deskFeatures,
        feature.map((featureName) => {
          return { name: featureName };
        })
      ),
    onSuccess: () => {
      enqueueSnackbar('Desk successfully edited', {
        type: SnackbarType.success,
      });
      refetch();
    },
    onError: () => {
      enqueueSnackbar('Desk edit failed', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    deskFeaturePostMutate: postMutate,
  };
};

export default useMutateDeskFeatures;
