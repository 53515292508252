import { COLORS } from 'theme/index';
import { BreakPoints } from 'theme/Theme.types';
import { getImageSize } from 'utils/styles';

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: COLORS.blue[200],
    padding: {
      Desktop: '5rem',
      Tablet: '2rem',
      Mobile: '1rem',
    },
  },
  companyInfoContainer: {
    maxWidth: BreakPoints.Tablet,
    minWidth: {
      Desktop: BreakPoints.Tablet,
      Tablet: BreakPoints.Tablet * 0.75,
      Mobile: BreakPoints.Tablet * 0.5,
    },
  },
  image: {
    ...getImageSize('4rem'),
    borderRadius: '2rem',
    marginBottom: '0.5rem',
  },
};

export default styles;
