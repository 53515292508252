import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddNewSkillFieldName, SkillForm } from '../SkillsDialog.types';
import skillsForm from 'validation/skillsForm';
import {
  SkillsToSendDict,
  TmpSkillStorage,
  useSkillsDialogProps,
} from './useSkillsDialog.types';
import {
  DEFAULT_ADD_SKILLS_DICT,
  DEFAULT_OPTIONS_DICT,
  DEFAULT_SKILLS_DICT,
  TMP_PREFIX,
} from './useSkillsDialog.const';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import { SkillCategories, SkillCategoriesKeys } from 'api/types/skills';
import { mapSkillNamesToOption, mapSkillToId } from './useSkillsDialog.helpers';
import useProfile from 'hooks/useProfile';
import { compareStrings } from 'utils/string';
import { errorMessages, MAX_TECHNOLOGY_LENGTH } from 'common/consts/validation';
import {
  useMutateSkills,
  useMutateUserSkills,
  useSkills,
} from 'hooks/useSkills';
import { AxiosResponse } from 'axios';
import { MutateSkillsBaseProps } from 'hooks/useSkills/useSkills.types';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const useSkillsDialog = ({ onClose }: useSkillsDialogProps) => {
  const { userId } = useActiveWorker();

  const { currentModal, closeModal: handleClose } = useContext(ModalContext);

  const {
    data: workerData,
    isFetching: isProfileLoading,
    refetchProfile,
  } = useProfile();
  const {
    data: skillNames,
    isFetching: areSkillsLoading,
    refetch: refetchSkillNames,
  } = useSkills();
  const { deleteMutate, postMutate } = useMutateUserSkills();
  const { postMutate: postMutateSkills } = useMutateSkills();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // For storing values in tmp without need of sending them to api whenever somebody press add button, since we
  // only want to do this on modal save
  const [tmpSkillStorage, setTmpSkillStorage] =
    useState<TmpSkillStorage>(DEFAULT_OPTIONS_DICT);

  const closeModal = useCallback(() => {
    setTmpSkillStorage(DEFAULT_OPTIONS_DICT);
    onClose();
    handleClose();
  }, [handleClose, onClose]);

  const isOpen =
    currentModal === ProfileModalType.AddLanguage ||
    currentModal === ProfileModalType.AddSkill;

  // Those fields are in this format so we don't have to map them every time we want to do something on them.
  // For the list of skills they are taking the backend name as the key and for add inputs, they are just
  // adding a new keyword to that key.
  const methods = useForm<SkillForm>({
    resolver: zodResolver(skillsForm),
    defaultValues: {
      ...DEFAULT_SKILLS_DICT,
      ...DEFAULT_ADD_SKILLS_DICT,
    },
  });

  useEffect(() => {
    const {
      'Programming Languages': ProgrammingLanguages,
      Technical,
      Management,
      'Soft Skills': SoftSkills,
    } = workerData?.skills ?? {};

    methods.reset({
      ['Programming Languages']: ProgrammingLanguages?.map(mapSkillToId) ?? [],
      ['Technical']: Technical?.map(mapSkillToId) ?? [],
      ['Management']: Management?.map(mapSkillToId) ?? [],
      ['Soft Skills']: SoftSkills?.map(mapSkillToId) ?? [],
      ...DEFAULT_ADD_SKILLS_DICT,
    });
  }, [isOpen, methods, workerData?.skills]);

  // All options from backend + those stored in tmp (so we don't send data to backend with every new added skill)
  const options = useMemo(() => {
    const dict = { ...DEFAULT_OPTIONS_DICT };

    skillNames?.forEach((skillName) => {
      const key = SkillCategories[skillName.category] as SkillCategoriesKeys;

      dict[key] = dict[key].concat(mapSkillNamesToOption(skillName));
    });

    (Object.keys(tmpSkillStorage) as Array<SkillCategoriesKeys>).forEach(
      (tmp) => {
        dict[tmp] = dict[tmp].concat(tmpSkillStorage[tmp]);
      }
    );

    return dict;
  }, [skillNames, tmpSkillStorage]);

  // For checked/unchecked returns ids, for tmp just names since we don't have ids yet
  const getSkillsToSend = useCallback(
    (data: SkillForm) => {
      const dict: Record<SkillCategoriesKeys, SkillsToSendDict> = {
        'Programming Languages': {},
        'Technical': {},
        'Soft Skills': {},
        'Management': {},
      };

      (Object.keys(dict) as Array<SkillCategoriesKeys>).forEach((key) => {
        const initial = (methods.formState.defaultValues?.[key]?.filter(
          (val) => val !== undefined
        ) ?? []) as Array<string>;

        const userData = data?.[key];
        const tmpData = tmpSkillStorage[key];
        const tmpIds = tmpData.map(({ value }) => value);

        dict[key] = {
          initial,
          checked: userData?.filter(
            (skill) => !initial?.includes(skill) && !tmpIds.includes(skill)
          ),
          unchecked: initial?.filter(
            (skill) => !userData?.includes(skill) && !tmpIds.includes(skill)
          ),
          tmpChecked: tmpData.filter(({ value }) => userData?.includes(value)),
          tmpUnchecked: tmpData.filter(
            ({ value }) => !userData?.includes(value)
          ),
        };
      });

      return dict;
    },
    [methods.formState.defaultValues, tmpSkillStorage]
  );

  const onSubmitHandler = useCallback(
    async (data: SkillForm) => {
      setIsSubmitting(true);
      const skillsToSubmit = getSkillsToSend(data);
      const mutations: Array<Promise<AxiosResponse>> = [];

      (Object.keys(skillsToSubmit) as Array<SkillCategoriesKeys>).forEach(
        (key) => {
          const { checked, unchecked, tmpChecked, tmpUnchecked } =
            skillsToSubmit[key];

          if (userId !== undefined) {
            const baseProps: MutateSkillsBaseProps = {
              refetch: false,
              userId,
            };

            if (checked?.length)
              mutations.push(
                postMutate({
                  skillsIds: checked,
                  ...baseProps,
                })
              );

            if (unchecked?.length)
              mutations.push(
                deleteMutate({
                  skillsIds: unchecked,
                  ...baseProps,
                })
              );

            if (tmpChecked?.length)
              mutations.push(
                postMutateSkills({
                  skills: tmpChecked.map(({ label }) => ({
                    category: SkillCategories[key],
                    name: label,
                  })),
                  ...baseProps,
                })
              );
          }

          if (tmpUnchecked?.length)
            mutations.push(
              postMutateSkills({
                skills: tmpUnchecked.map(({ label }) => ({
                  category: SkillCategories[key],
                  name: label,
                })),
                refetch: false,
              })
            );
        }
      );

      try {
        await Promise.all(mutations).finally(async () => {
          await Promise.all([refetchProfile(), refetchSkillNames()]);
        });
        enqueueSnackbar('Skills successfully changed', {
          type: SnackbarType.success,
        });
      } catch (error) {
        enqueueSnackbar('An error occurred while changing skills', {
          type: SnackbarType.error,
        });
      } finally {
        setIsSubmitting(false);
        closeModal();
      }
    },
    [
      closeModal,
      deleteMutate,
      getSkillsToSend,
      postMutate,
      postMutateSkills,
      refetchProfile,
      refetchSkillNames,
      userId,
    ]
  );

  const addNewSkill = async (category: SkillCategoriesKeys) => {
    const fieldName = `new ${category}` as AddNewSkillFieldName;
    const newSkill = methods.watch(fieldName) ?? '';
    const duplicatedInTmp = tmpSkillStorage?.[category].some(({ label }) =>
      compareStrings(label, newSkill, true)
    );
    const duplicatedInForm = skillNames?.some(({ name }) =>
      compareStrings(name, newSkill, true)
    );
    const isDuplicated = duplicatedInForm || duplicatedInTmp;
    const isTooLong = newSkill.length > MAX_TECHNOLOGY_LENGTH;

    if (isDuplicated)
      methods.setError(fieldName, {
        message: errorMessages.existing,
      });

    if (isTooLong)
      methods.setError(fieldName, {
        message: errorMessages.maxCharacters(MAX_TECHNOLOGY_LENGTH),
      });

    if (newSkill && !isDuplicated && !isTooLong) {
      const newTmp = { ...tmpSkillStorage };
      newTmp[category] = tmpSkillStorage?.[category].concat({
        value: `${TMP_PREFIX}${newSkill}`,
        label: newSkill,
      });
      setTmpSkillStorage(newTmp);
      // Clear add input and check newly added skill by default
      methods.setValue(`new ${category}`, null);
      methods.setValue(
        category,
        methods.getValues(category)?.concat(`${TMP_PREFIX}${newSkill}`)
      );
    }

    return Promise.resolve();
  };

  return {
    type: currentModal,
    isOpen,
    isLoading: isProfileLoading || areSkillsLoading || isSubmitting,
    closeModal,
    methods,
    onSubmitHandler,
    addNewSkill,
    options,
  };
};

export default useSkillsDialog;
