import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AuthContext, { AuthUserData } from './AuthContext';
import axios, { apiGet } from 'api/axios';
import Cookies from 'js-cookie';
import { CSRF_TOKEN_COOKIE_NAME } from 'api/axios/axios.consts';
import { AuthUserGetResponse } from 'api/types/auth';
import { LocalStorageKeys } from 'common/consts/storage';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import { AxiosError } from 'axios';

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  // TODO: Those 2 useStates are redundant, replace me with useQuery later (it's used in many places, so it would take another task)
  // TODO: isStaff is returning 401/403 on offer fix later
  const [{ isAuth, isStaff, id }, setUserData] = useState<AuthUserData>({
    isAuth: localStorage.getItem(LocalStorageKeys.IsAuth) === 'true',
    isStaff: false,
  });

  // TODO: Replace with react-query
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Rewrite me to useQuery and use specialized hook that returns the data and sets everything in localStorage
        setIsLoading(true);
        const { data } = await apiGet<AuthUserGetResponse>('auth/user/');
        const { id, firstName, isStaff } = data;

        localStorage.setItem(LocalStorageKeys.Id, `${id}`);
        localStorage.setItem(LocalStorageKeys.IsAuth, 'true');
        localStorage.setItem(LocalStorageKeys.Name, firstName);
        setUserData({ isAuth: true, isStaff, id });
      } catch (e) {
        localStorage.removeItem(LocalStorageKeys.Id);
        localStorage.removeItem(LocalStorageKeys.IsAuth);
        localStorage.removeItem(LocalStorageKeys.Name);
        setUserData({ isAuth: false, isStaff: false });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // TODO: useMutation
  const logout = useCallback(async () => {
    setIsLoading(true);
    // this url is different to keep it consistent with intranet-people IdP urls urls pattern
    axios.defaults.baseURL = '';

    try {
      const { status } = await axios.post('/accounts/logout/', {
        config: {
          headers: {
            CSRF_TOKEN_HEADER_NAME: Cookies.get(CSRF_TOKEN_COOKIE_NAME),
          },
        },
      });

      if (status === 200) {
        localStorage.clear();
        setUserData({ isAuth: false, isStaff: false });
      }

      enqueueSnackbar('Do zobaczenia', {
        type: SnackbarType.success,
        title: 'Udane wylogowanie',
      });
    } catch (e) {
      enqueueSnackbar((e as AxiosError).message, { type: SnackbarType.error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const name = useMemo(
    () =>
      isAuth
        ? localStorage.getItem(LocalStorageKeys.Name) ?? 'Anonymous'
        : 'Anonymous',
    [isAuth]
  );

  return (
    <AuthContext.Provider
      value={{
        id,
        name,
        isStaff,
        isAuth,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
