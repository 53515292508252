const styles = {
  container: {
    height: 'auto',
    padding: {
      Desktop: '2.5rem',
      Tablet: '2rem',
      Mobile: '2rem',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1.25rem',
  },
  title: {
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
  },
  innerBox: {
    display: 'flex',
    gap: '0.5rem',
  },
};

export default styles;
