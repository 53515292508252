import { z } from 'zod';

import {
  errorMessages,
  MAX_DESCRIPTION_LENGTH,
  MAX_PROJECT_LENGTH,
  MIN_DATE,
} from 'common/consts/validation';
import { isBefore } from 'date-fns';
import { option } from './types';
import { parseHtmlToText } from 'utils/string';

const { maxCharacters, minDate, maxDate, required, invalidRange } =
  errorMessages;

const projectValidator = z.intersection(
  z
    .discriminatedUnion('atPresent', [
      z.object({
        atPresent: z.literal(true),
        dateTo: z.null(),
        dateFrom: z
          .date({
            invalid_type_error: required,
            required_error: required,
          })
          .min(MIN_DATE, { message: minDate(MIN_DATE) })
          .max(new Date(), { message: maxDate(new Date()) }),
      }),
      z.object({
        atPresent: z.literal(false),
        dateTo: z
          .date({
            invalid_type_error: required,
            required_error: required,
          })
          .min(MIN_DATE, { message: minDate(MIN_DATE) }),
        dateFrom: z
          .date({
            invalid_type_error: required,
            required_error: required,
          })
          .min(MIN_DATE, { message: minDate(MIN_DATE) })
          .max(new Date(), { message: maxDate(new Date()) }),
      }),
    ])
    .refine(
      ({ atPresent, dateFrom, dateTo }) =>
        atPresent || !isBefore(dateTo, dateFrom),
      { message: invalidRange, path: ['dateTo'] }
    ),
  z.object({
    name: z
      .object(option, { invalid_type_error: required })
      .refine(({ label }) => label.length <= MAX_PROJECT_LENGTH, {
        message: maxCharacters(MAX_PROJECT_LENGTH),
      }),
    position: z.object(option, { invalid_type_error: required }),
    technologies: z.array(z.object(option)),
    description: z
      .string()
      .refine((html) => parseHtmlToText(html).length >= 1, {
        message: required,
      })
      .refine(
        (html) => parseHtmlToText(html).length <= MAX_DESCRIPTION_LENGTH,
        { message: maxCharacters(MAX_DESCRIPTION_LENGTH) }
      ),
    teamSize: z.string({ invalid_type_error: required }),
  })
);

export default projectValidator;
