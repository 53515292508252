const styles = {
  icon: {
    width: 16,
    height: 16,
  },
  disabled: {
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
};

export default styles;
