import kudosFormValidator from 'validation/kudosFormValidator';
import { z } from 'zod';

export const mapProfileListToOption = ({
  id,
  title,
}: {
  id: number;
  title: string;
}) => ({
  value: `${id}`,
  label: `${title}`,
});

export const formatToBackendFormData = ({
  message,
  user,
}: z.infer<typeof kudosFormValidator>) => ({
  description: message,
  user: Number(user.value),
});
