import { z } from 'zod';
import { formatToBackendDate, formatToNullableBackendDate } from 'utils/date';
import projectValidator from 'validation/projectValidator';

export const formatToBackendFormData = ({
  dateFrom,
  dateTo,
  description,
  position,
  name,
  technologies,
}: z.infer<typeof projectValidator>) => ({
  projectDetails: name.value,
  dateFrom: formatToBackendDate(dateFrom),
  dateTo: formatToNullableBackendDate(dateTo),
  description,
  position: position.value,
  projectTechnologies: technologies.map((technology) => ({
    id: technology.value,
  })),
});
