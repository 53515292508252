import { createContext } from 'react';

export enum ProfileModalType {
  EditProfile = 'EditProfile',
  AddLanguage = 'AddLanguage',
  AddSkill = 'AddSkill',
  AddProject = 'AddProject',
  EditProject = 'EditProject',
  AddEducation = 'AddEducation',
  EditEducation = 'EditEducation',
  AddCertificate = 'AddCertificate',
  EditCertificate = 'EditCertificate',
  AddCourse = 'AddCourse',
  EditCourse = 'EditCourse',
  Skill = 'Skill',
}

export enum AdminProfileModalType {
  AddContract = 'AddContract',
  EditContract = 'EditContract',
  AddHolidayPool = 'AddHolidayPool',
  EditHolidayPool = 'EditHolidayPool',
  AddDelegation = 'AddDelegation',
  EditDelegation = 'EditDelegation',
  AddSocialSecurityBenefit = 'AddSocialSecurityBenefit',
  EditSocialSecurityBenefit = 'EditSocialSecurityBenefit',
  HolidayFilter = 'HolidayFilter',
}

export enum KudosModalType {
  PreviewKudos = 'PreviewKudos',
  SendKudos = 'SendKudos',
  EditKudos = 'EditKudos',
}

export enum GlobalModalType {
  LoginToPeople = 'LoginToPeople',
  AddHoliday = 'AddHoliday',
  EditHoliday = 'EditHoliday',
}

// Add modals from other sub pages here
export type ModalTypes =
  | ProfileModalType
  | KudosModalType
  | GlobalModalType
  | AdminProfileModalType;

interface ModalContext {
  currentModal?: ModalTypes;
  openModal: (type: ModalTypes) => void;
  closeModal: () => void;
}

const ModalContextInstance = createContext<ModalContext>({
  currentModal: undefined,
  openModal: () => null,
  closeModal: () => null,
});

export default ModalContextInstance;
