import { memo } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { SnackbarProps } from '../Snackbars.types';
import SnackbarAnimation from './SnackbarAnimation';
import { ErrorOutline } from '@mui/icons-material';

const ErrorSnackbar = memo((props: SnackbarProps) => {
  const { message, config } = props;

  return (
    <SnackbarAnimation duration={config?.duration}>
      <Alert severity="error" icon={<ErrorOutline color="inherit" />}>
        <AlertTitle>{config?.title ?? 'Error'}</AlertTitle>
        {message}
      </Alert>
    </SnackbarAnimation>
  );
});

ErrorSnackbar.displayName = 'ErrorSnackbar';

export default ErrorSnackbar;
