import { FC, KeyboardEvent, useCallback } from 'react';
import { Button, Typography } from '@mui/material';

import DisplayWrapper from 'components/DisplayWrapper';
import offerFormValidator from 'validation/offerFormValidator';
import FormWrapper from 'components/FormWrapper';
import TextInput from 'components/TextInput';
import { LoginFormProps } from './LoginForm.types';
import styles from './LoginForm.styles';

const LoginForm: FC<LoginFormProps> = ({
  methods,
  onSubmitHandler,
  isLoading,
}) => {
  const isLoginDisabled = !methods.watch('password').length || isLoading;

  const onKeyDownHandler = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !isLoginDisabled) {
        onSubmitHandler();
      }
    },
    [isLoginDisabled, onSubmitHandler]
  );

  return (
    <DisplayWrapper sx={styles.root}>
      <FormWrapper
        id="offer-login-form"
        methods={methods}
        validator={offerFormValidator}
        onSubmit={onSubmitHandler}
        sx={styles.form}
      >
        <Typography variant="h1">ENTER YOUR PASSWORD</Typography>
        <TextInput
          name="password"
          label="Password"
          type="password"
          onKeyDown={onKeyDownHandler}
        />
        <Button
          form="offer-login-form"
          type="submit"
          variant="contained"
          disabled={isLoginDisabled}
        >
          Login
        </Button>
      </FormWrapper>
    </DisplayWrapper>
  );
};

export default LoginForm;
