import { API_URLS } from 'api/axios/axios.consts';
import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPost } from 'api/axios';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import { MutateRoomReservationProps } from './useRoomReservations.types';
import useReservation from './useReservation';

const useMutateRoomReservation = () => {
  const { refetch } = useReservation();

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ date, room, booker }: MutateRoomReservationProps) =>
      apiPost(`${API_URLS.roomReservations}`, { date, room, booker }),
    onSuccess: () => {
      enqueueSnackbar('Room reservation successfully added', {
        type: SnackbarType.success,
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add reservation', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ roomId }: { roomId: number }) =>
      apiDelete(`${API_URLS.roomReservations}${roomId}/`),
    onSuccess: () => {
      enqueueSnackbar('Room reservation successfully deleted', {
        type: SnackbarType.info,
      });
      refetch();
    },
    onError: () => {
      enqueueSnackbar('Cannot delete reservation', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    postMutate,
    deleteMutate,
  };
};

export default useMutateRoomReservation;
