import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';

import useProfile from 'hooks/useProfile/useProfile';
import {
  MutateEducationDeleteProps,
  MutateEducationPatchProps,
  MutateEducationPostProps,
} from './useEducation.types';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const getEducationUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.education}`;

export const useMutateUserEducation = () => {
  const { refetchProfile } = useProfile();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, education }: MutateEducationPatchProps) =>
      apiPatch(`${getEducationUrl(userId)}${education.id}/`, education),
    onSuccess: () => {
      enqueueSnackbar('Education successfully changed', {
        type: SnackbarType.success,
      });
      refetchProfile();
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ userId, educationId }: MutateEducationDeleteProps) =>
      apiDelete(`${getEducationUrl(userId)}${educationId}/`),
    onSuccess: () => {
      enqueueSnackbar('Education successfully deleted', {
        type: SnackbarType.info,
      });
      refetchProfile();
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, education }: MutateEducationPostProps) =>
      apiPost(getEducationUrl(userId), education),
    onSuccess: () => {
      enqueueSnackbar('Education successfully added', {
        type: SnackbarType.success,
      });
      refetchProfile();
    },
  });

  return {
    patchMutate,
    deleteMutate,
    postMutate,
  };
};

export default useMutateUserEducation;
