import { useMemo } from 'react';
import { Box } from '@mui/material';
import {
  CalendarToday,
  EventBusy,
  Label,
  EventAvailable,
  HourglassBottom,
} from '@mui/icons-material';

import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import ActionButtons from '../../ActionButtons';
import {
  HolidayProps,
  HolidayRowView,
  HolidaysTypeDict,
} from './Holiday.types';
import styles from './Holiday.styles';
import adminProfileStyles from '../AdminProfile.styles';
import { HolidayType } from 'api/types/holiday';
import InfoIcon from '../InfoIcon';
import IconLabel from 'components/IconLabel/IconLabel';
import { useMutateHolidayApplication } from 'hooks/useUserHoliday/useMutateUserHoliday';

const Holiday = ({ holiday, handleEditHoliday }: HolidayProps) => {
  const { cancelAdminHolidayApplication, acceptAdminHolidayApplication } =
    useMutateHolidayApplication();
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      holiday.id &&
      cancelAdminHolidayApplication({
        holidayId: holiday.id,
        isCancelled: true,
      }),
    title: `Are you sure you want to delete this holiday application?`,
  });
  const {
    dateFrom,
    dateTo,
    type,
    addInfo,
    isCancelled,
    isAcceptedByAdmin,
    isAcceptedByEmployee,
    isHalfDay,
  } = holiday;

  const holidayView = useMemo(() => {
    const baseView: HolidayRowView = {
      onDeleteHandler: openConfirmationModal,
      onEditHandler: handleEditHoliday,
      onAcceptHandler: undefined,
      style: {},
      icon: CalendarToday,
    };

    if (isCancelled) {
      baseView.onDeleteHandler = undefined;
      baseView.style = adminProfileStyles.cancelledIcon;
      baseView.icon = EventBusy;
    } else if (isAcceptedByAdmin && isAcceptedByEmployee) {
      baseView.style = adminProfileStyles.acceptedIcon;
      baseView.icon = EventAvailable;
    } else if (!isAcceptedByAdmin && isAcceptedByEmployee) {
      baseView.onEditHandler = undefined;
      baseView.onAcceptHandler = () =>
        acceptAdminHolidayApplication({
          holidayId: holiday.id,
        });
    }

    return baseView;
  }, [
    acceptAdminHolidayApplication,
    handleEditHoliday,
    holiday.id,
    isAcceptedByAdmin,
    isAcceptedByEmployee,
    isCancelled,
    openConfirmationModal,
  ]);

  return (
    <Box sx={styles.infoGrid}>
      <IconLabel
        iconStyle={holidayView.style}
        Icon={holidayView.icon}
        label={`${dateFrom} - ${dateTo}`}
      />
      <IconLabel
        Icon={isHalfDay ? HourglassBottom : Label}
        label={`typ: ${HolidaysTypeDict[type as HolidayType]}`}
      />
      <InfoIcon addInfo={addInfo} />
      <ActionButtons
        isStart
        onDeleteHandler={holidayView.onDeleteHandler}
        onEditHandler={holidayView.onEditHandler}
        onAcceptHandler={holidayView.onAcceptHandler}
      />
      <ConfirmationDialog />
    </Box>
  );
};

export default Holiday;
