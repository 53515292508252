import { Clear, Edit, Check } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { ActionButtonsProps } from './ActionButtons.types';
import styles from './ActionButtons.styles';

const ActionButtons: FC<ActionButtonsProps> = ({
  onEditHandler,
  onDeleteHandler,
  onAcceptHandler,
  disabled = false,
  isStart,
}) => (
  <Box sx={[styles.root(isStart)]}>
    {onEditHandler && (
      <IconButton disabled={disabled} onClick={onEditHandler}>
        <Edit />
      </IconButton>
    )}
    {onDeleteHandler && (
      <IconButton disabled={disabled} onClick={onDeleteHandler}>
        <Clear />
      </IconButton>
    )}
    {onAcceptHandler && (
      <IconButton disabled={disabled} onClick={onAcceptHandler}>
        <Check />
      </IconButton>
    )}
  </Box>
);

export default ActionButtons;
