import { memo, useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';

import formStyles from 'common/styles/forms';
import { autoCompleteStyles } from 'components/AutoCompleteInput';
import { ProjectForm } from '../ProjectFormDialog.types';
import { helperTextStyle } from 'components/InputWrapper/InputWrapper.styles';
import { errorMessages, MAX_TECHNOLOGY_LENGTH } from 'common/consts/validation';
import { Option } from 'common/types';
import TechnologyChip from './TechnologyChip';
import { useSkills } from 'hooks/useSkills';
import { mapKeyValueToOption } from 'utils/objects';

const KeyTechnologies = memo(() => {
  const { control } = useFormContext<ProjectForm>();

  const [technology, setTechnology] = useState<Option<string, string> | null>(
    null
  );
  const [addClicked, setAddClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef<HTMLInputElement>();
  const { data, isLoading } = useSkills();

  const addHandler = useCallback(
    (
      onChange: (value: Array<Option<string, string>>) => void,
      value: Array<Option<string, string>>
    ) => {
      inputRef.current?.focus();
      if (!technology || technology.label?.length > MAX_TECHNOLOGY_LENGTH)
        return setAddClicked(true);
      onChange([...value, technology]);
      setAddClicked(false);
      setTechnology(null);
      setInputValue('');
    },
    [technology]
  );

  return (
    <Controller<ProjectForm, 'technologies'>
      control={control}
      name="technologies"
      render={({ field: { value: options, onChange } }) => {
        const isNameTooLong =
          addClicked && (technology?.label.length ?? 0) > MAX_TECHNOLOGY_LENGTH;

        const errorText = isNameTooLong
          ? errorMessages.maxCharacters(MAX_TECHNOLOGY_LENGTH)
          : null;

        const onAddHandler = () =>
          addHandler(
            onChange,
            options.map((option) => option)
          );

        return (
          <Box sx={formStyles.section}>
            <Typography sx={{ mb: 3 }} variant="sub1">
              Key technologies
            </Typography>
            {!!options.length && (
              <Box sx={formStyles.addForm}>
                {options.map(({ value, label }) => (
                  <TechnologyChip
                    key={value}
                    label={label}
                    onClick={() =>
                      onChange(
                        options.filter((option) => option.value !== value)
                      )
                    }
                  />
                ))}
              </Box>
            )}
            <Box sx={formStyles.addInputs}>
              <Autocomplete<Option<string, string>, false, boolean, true>
                clearOnBlur
                onChange={(event, newValue) => {
                  event.preventDefault();
                  if (typeof newValue === 'string') return;

                  setTechnology(newValue);
                }}
                getOptionLabel={(value) =>
                  typeof value === 'string' ? value : value.label
                }
                options={(data ?? [])
                  .filter(
                    ({ id }) => !options.some(({ value }) => `${id}` === value)
                  )
                  .map(mapKeyValueToOption)}
                value={technology}
                inputValue={inputValue}
                disabled={isLoading}
                onInputChange={(_event, value) => setInputValue(value)}
                renderOption={(props, { label, value }) => (
                  <li {...props} key={value}>
                    {label}
                  </li>
                )}
                isOptionEqualToValue={(option, selectedOption) =>
                  option.label === selectedOption.label
                }
                renderInput={(params) => (
                  <TextField
                    error={!!errorText}
                    FormHelperTextProps={{
                      sx: helperTextStyle,
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') onAddHandler();
                    }}
                    helperText={errorText}
                    label="Add technology"
                    inputRef={inputRef}
                    {...params}
                  />
                )}
                ListboxProps={{ sx: autoCompleteStyles.listbox }}
              />
              <Button
                variant="contained"
                disabled={!technology}
                onClick={onAddHandler}
                sx={formStyles.addButton}
              >
                Add
              </Button>
            </Box>
          </Box>
        );
      }}
    />
  );
});
KeyTechnologies.displayName = 'KeyTechnologies';

export default KeyTechnologies;
