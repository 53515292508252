import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';
import { formatToBackendDate, parseNullishBackendDate } from 'utils/date';
import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import {
  DelegationForm,
  DelegationFormDialogProps,
  delegationFormSubmitData,
} from './DelegationFormDialog.types';
import delegationFormValidator from 'validation/delegationFormValidator';
import useMutateUserDelegation from 'hooks/useDelegations/useMutateUserDelegation';

const useDelegationFormDialog = ({
  delegation,
  onClose,
}: DelegationFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === AdminProfileModalType.AddDelegation ||
    currentModal === AdminProfileModalType.EditDelegation;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();

  const methods = useForm<DelegationForm>({
    resolver: zodResolver(delegationFormValidator),
  });

  const { control } = methods;

  const { delegationPatchMutate, delegationPostMutate } =
    useMutateUserDelegation();

  const [dateFromWatcher, dateToWatcher] = useWatch({
    name: ['dateFrom', 'dateTo'],
    control,
  });

  const title = useMemo(
    () =>
      `${
        currentModal === AdminProfileModalType.AddDelegation ? 'Add' : 'Edit'
      } delegation`,
    [currentModal]
  );

  const { dateTo, dateFrom, addInfo } = delegation ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        addInfo: addInfo,
      });
    }
  }, [addInfo, dateFrom, dateTo, isOpen, methods]);

  useEffect(() => {
    if (dateFromWatcher && !dateToWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
    if (!dateFromWatcher && dateToWatcher) {
      methods.setValue('dateFrom', dateToWatcher);
    }
    if (dateToWatcher && dateFromWatcher && dateToWatcher < dateFromWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
  }, [dateFromWatcher, dateToWatcher, methods]);

  const onSubmitHandler = async (data: delegationFormSubmitData) => {
    if (userId) {
      const payload = {
        dateFrom: formatToBackendDate(data.dateFrom),
        dateTo: formatToBackendDate(data.dateTo),
        addInfo: data.addInfo,
        user: userId,
      };
      setIsSubmitting(true);
      try {
        if (delegation) {
          await delegationPatchMutate({
            delegation: { id: delegation.id, ...payload },
          });
        } else {
          await delegationPostMutate({ delegation: payload });
        }
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return {
    dateFromWatcher,
    isOpen,
    methods,
    title,
    onSubmitHandler,
    isLoading: isSubmitting,
  };
};

export default useDelegationFormDialog;
