import { z } from 'zod';

const skillsForm = z.object({
  'Programming Languages': z.array(z.string()),
  'Technical': z.array(z.string()),
  'Management': z.array(z.string()),
  'Soft Skills': z.array(z.string()),
});

export default skillsForm;
