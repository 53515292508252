import { FC } from 'react';
import Dot from 'components/Dot';
import { Box, Typography } from '@mui/material';
import styles from './Legend.styles';
import DisplayWrapper from 'components/DisplayWrapper';
import { LegendProps } from './Legend.types';

export const Legend: FC<LegendProps> = ({ dotsContentScheme }) => {
  return (
    <DisplayWrapper sx={styles.box}>
      {dotsContentScheme.map(({ color, text, borderColor }, index) => (
        <Box key={index} sx={styles.content}>
          <Dot color={color} borderColor={borderColor} />
          <Typography variant="body1">{text}</Typography>
        </Box>
      ))}
    </DisplayWrapper>
  );
};

export default Legend;
