import { useEffect, useMemo, useState } from 'react';
import useDesk from 'hooks/useDesk';
import useDeskFeatures from 'hooks/useDeskFeatures/useDeskFeatures';
import { DeskFeaturesGetResponse, Feature } from 'api/types/deskFeatures';
import { Option } from 'common/types';
import { extractOptionName } from './useChooseDeskFeature.helpers';
import { mapKeyValuesToDeskOptions } from 'pages/DeskReservation/components/Admin/utils/objects';

const useChooseDeskFeature = () => {
  const { features: deskFeatures } = useDeskFeatures();
  const { desk } = useDesk();

  const [features, setFeatures] = useState<Feature[]>([]);
  const featureOptions = useMemo(
    () =>
      mapKeyValuesToDeskOptions(
        deskFeatures?.filter(
          (deskFeature) =>
            !features.some((feature) => feature.name === deskFeature.name)
        ) ?? []
      ),
    [deskFeatures, features]
  );

  useEffect(() => {
    if (desk) {
      setFeatures(desk.features ?? []);
    }
  }, [desk]);

  const handleDeleteFeature = (name: string) =>
    setFeatures(features.filter((feature) => feature.name !== name));

  const onOptionChange = async (value: Option) => {
    //When adding the name of the feature, AutocompleteInput create an option in "Add "option"" pattern
    const optionName = extractOptionName(value.label?.toString());
    const shouldAddFeature =
      !deskFeatures.find(
        ({ name }: DeskFeaturesGetResponse) => name === optionName
      ) && optionName;

    if (shouldAddFeature) {
      const isDuplicateFeature = features.some(
        ({ name }) => name === optionName
      );

      if (isDuplicateFeature) return;

      setFeatures([
        {
          id: undefined,
          name: optionName ?? '',
          isNew: true,
        },
        ...features,
      ]);
    } else {
      const isDuplicateFeature = features.some(
        ({ name }) => name === optionName
      );

      if (isDuplicateFeature) return;

      setFeatures([
        {
          id: Number(value.value),
          name: optionName ?? '',
        },
        ...features,
      ]);
    }
  };

  return {
    features,
    featureOptions,
    handleDeleteFeature,
    onOptionChange,
  };
};

export default useChooseDeskFeature;
