import { Avatar, Box, Typography } from '@mui/material';
import styles from './OfferPrint.styles';
import useOffer from 'hooks/useOffer';
import Logo from 'assets/images/placeholder_team.png';
import { sanitizeAndDisplayHTML } from 'utils/string';

const FrontPage = () => {
  const { data } = useOffer();

  return (
    <>
      <Box sx={styles.frontPage}>
        <Box sx={styles.content}>
          <Avatar src={data?.image ?? Logo} sx={styles.logo} />
          <Typography variant="h1" sx={{ fontSize: 34, lineHeight: '38px' }}>
            Offer for {data?.company}
          </Typography>
          <Box sx={styles.descriptionWrapper}>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={sanitizeAndDisplayHTML(
                data ? data.description : ''
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.pageBreak} />
    </>
  );
};

export default FrontPage;
