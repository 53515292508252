import { createContext, Dispatch, SetStateAction } from 'react';

interface SentKudosContextType {
  isSentKudosView?: boolean;
  setIsSentKudosView: Dispatch<SetStateAction<boolean>>;
}

const SentKudosContext = createContext<SentKudosContextType>({
  setIsSentKudosView: () => void 0,
});

export default SentKudosContext;
