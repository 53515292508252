import { COLORS } from 'src/theme';

const styles = {
  container: {
    display: 'grid',
    width: {
      Desktop: 'auto',
      Tablet: 'auto',
      Mobile: '380px',
    },
  },
  cancelButton: {
    border: `1px solid ${COLORS.borderColor}`,
    width: '10rem',
  },
  saveButton: {
    'width': '10rem',
    'color': COLORS.white,
    'backgroundColor': COLORS.blue[600],
    '&.Mui-disabled': {
      backgroundColor: COLORS.disabledBackground,
      color: COLORS.white,
    },
  },
  buttonContainer: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  inputHolder: {
    width: '17rem',
    maxWidth: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  text: {
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
  topText: {
    marginBottom: '0.5rem',
  },
  headerText: {
    marginBottom: '1rem',
  },
  select: {
    width: '17.5rem',
  },
  featureText: {
    border: `2px solid ${COLORS.blue[500]}`,
    borderRadius: '0.5rem',
    padding: '0.3rem',
    color: COLORS.blue[500],
    marginTop: '0.5rem',
  },
  featuresContainer: {
    display: 'flex',
    marginBottom: '1rem',
    gap: '0.5rem',
  },
};

export default styles;
