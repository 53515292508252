export const API_URL = '/api';
export const XSRF_COOKIE_NAME = 'csrftoken';
export const CSRF_TOKEN_COOKIE_NAME = 'csrftoken';
export const XSRF_HEADER_NAME = 'X-CSRFTOKEN';
export const CONTENT_TYPE = 'application/json';
export const CSRF_HIDDEN_FORM_FIELD_NAME = 'csrfmiddlewaretoken';

export const API_URLS = {
  // skill
  skills: '/skills/',
  skillNames: '/skill-names/',

  // Project
  projectDetails: '/project-details/',
  projects: '/projects/',
  positions: '/positions/',
  projectsDepartments: '/project-roles/',
  departments: '/department-list/',

  // Edit profile eps:
  profileImage: '/profile-image/',
  languages: '/languages/',

  // Education
  schools: '/schools/',
  education: '/education/',
  fieldsOfStudy: '/fields-of-study/',

  // Certificate
  certificates: '/certificates/',
  courses: '/courses/',
  profile: '/profile/',
  offer: '/offer/',

  // Kudos
  kudos: '/kudos/',
  sentKudos: '/kudos/sent-by-me/',
  login: 'login/',

  // Holiday Photos
  holidayPhoto: '/holiday-image/',

  // Desk Reservations
  deskReservations: '/desk-reservations/',
  deskCancelReservations: '/desk-reservations/cancel/',
  desks: '/desks/',
  deskFeatures: '/desk-features/',

  // Room reservation eps:
  roomReservations: '/room-reservations/',

  //Contracts
  contracts: (userId?: number) => `/profile/${userId}/contracts/`,

  //Delegations
  delegations: (userId?: number) => `/profile/${userId}/delegations/`,
};
