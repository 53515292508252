import { memo } from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import styles from './CompanyHeader.styles';
import useOffer from 'hooks/useOffer';
import DisplayWrapper from 'components/DisplayWrapper';
import { sanitizeAndDisplayHTML } from 'utils/string';
import Logo from 'assets/images/placeholder_team.png';

const CompanyHeader = memo(() => {
  const { data } = useOffer();

  return (
    <Box sx={styles.root}>
      <DisplayWrapper>
        <Box sx={styles.companyInfoContainer}>
          <Avatar src={data?.image ?? Logo} sx={styles.image} />
          <Typography variant="h1">Offer for {data?.company}</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={sanitizeAndDisplayHTML(
              data ? data.description : ''
            )}
          />
        </Box>
      </DisplayWrapper>
    </Box>
  );
});

CompanyHeader.displayName = 'CompanyHeader';

export default CompanyHeader;
