import { Box, Button } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import AutoCompleteInput from 'components/AutoCompleteInput';
import useCertificateFormDialog from './useCertificateFormDialog';
import DatePickerInput from 'components/DatePickerInput';
import certificatesFormValidator from 'validation/certificatesFormValidator';
import { mapKeyValueToOption } from 'utils/objects';
import { CertificateFormDialogProps } from './CertificateFormDialog.types';
import CheckboxInput from 'components/CheckboxInput';
import formStyles from 'common/styles/forms';
import TextInput from 'components/TextInput';

const CertificateFormDialog = ({
  certificate,
  onClose,
}: CertificateFormDialogProps) => {
  const {
    issueDateWatcher,
    isOpen,
    expirationDateWatcher,
    methods,
    isPersistentWatcher,
    title,
    isLoading,
    certificates,
    onSubmitHandler,
  } = useCertificateFormDialog({
    certificate,
    onClose,
  });

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="certificate-form" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="certificate-form"
        onSubmit={onSubmitHandler}
        validator={certificatesFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={certificates.map(mapKeyValueToOption)}
              label="Name"
              name="name"
              freeSolo
            />
            <TextInput name="url" label={'Link to certificate'} />
          </Box>
          <Box sx={formStyles.tripleInputs}>
            <DatePickerInput
              disableFuture
              name="issueDate"
              label="Issue date"
              maxDate={expirationDateWatcher ?? undefined}
            />
            <DatePickerInput
              name="expirationDate"
              label="Expiration date"
              disabled={isPersistentWatcher}
              minDate={issueDateWatcher ?? undefined}
            />
            <CheckboxInput
              isSmall
              wrapperSx={formStyles.checkboxInput}
              name="isPersistent"
              label="Doesn't expire"
            />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default CertificateFormDialog;
