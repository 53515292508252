import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { IconLabelProps } from './IconLabel.types';
import styles from './IconLabel.styles';

const IconLabel: FC<IconLabelProps> = ({ Icon, label, iconStyle = {} }) => (
  <Box sx={styles.iconLabel}>
    <Icon sx={[styles.icon, iconStyle]} />
    <Typography variant="body2" sx={{ display: 'inline-block' }}>
      {label}
    </Typography>
  </Box>
);

export default IconLabel;
