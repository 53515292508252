export const LEVEL_LABELS = [
  'Beginner',
  'Basic',
  'Intermediate',
  'Advanced',
  'Fluent',
];

export const ATTITUDE_LABELS = [
  'Stay away',
  'Avoid',
  'Neutral',
  'Like',
  'Love',
];
