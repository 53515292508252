import { Box, Typography } from '@mui/material';
import { CalendarToday, BeachAccess } from '@mui/icons-material';

import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import ActionButtons from '../../ActionButtons';
import IconLabel from '../../../../../components/IconLabel/IconLabel';
import { HolidayPoolProps } from './HolidayPool.types';
import useMutateUserHolidayPool from 'hooks/useHolidayPools/useMutateUserHolidayPool';
import styles from './HolidayPool.styles';

const HolidayPool = ({
  holidayPool,
  handleEditHolidayPool,
}: HolidayPoolProps) => {
  const { holidayPoolDeleteMutate } = useMutateUserHolidayPool();
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      holidayPool.id &&
      holidayPoolDeleteMutate({ holidayPoolId: holidayPool.id }),
    title: `Are you sure you want to delete this contract?`,
  });

  const { year, fullHolidayPool, usedHolidayPool, leftHolidayPool } =
    holidayPool;

  return (
    <Box sx={styles.infoGrid}>
      <IconLabel Icon={CalendarToday} label={year.toString()} />
      <IconLabel
        Icon={BeachAccess}
        label={`pula urlopowa: ${fullHolidayPool.toString()}`}
      />
      <Typography variant="body2">{`wykorzystane: ${usedHolidayPool.toString()}`}</Typography>
      <Typography variant="body2">{`pozostało: ${leftHolidayPool.toString()}`}</Typography>
      <ActionButtons
        isStart
        onDeleteHandler={openConfirmationModal}
        onEditHandler={handleEditHolidayPool}
      />
      <ConfirmationDialog />
    </Box>
  );
};

export default HolidayPool;
