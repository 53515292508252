import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { peopleApiGet } from 'api/axios';
import { AxiosError } from 'axios';
import { GetSocialSecurityBenefitsResponse } from './useSocialSecurityBenefits.types';
import { Nullable } from 'common/types';
import { HttpStatusCode } from 'axios';
import { SocialSecurityBenefitsWithUserModel } from 'api/types/socialSecurityBenefits';

export const fetchSocialSecurityBenefits = async (
  context: QueryFunctionContext<[string, number | null]>
) => {
  try {
    const [, userId] = context.queryKey;
    const response = await peopleApiGet<GetSocialSecurityBenefitsResponse>(
      PEOPLE_API_URLS.socialSecurityBenefits,
      {
        params: {
          user: userId,
        },
      }
    );

    return (response.data.results ||
      []) as SocialSecurityBenefitsWithUserModel[];
  } catch (error) {
    if (error instanceof AxiosError) {
      if (
        [HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(
          error.response?.status || 0
        )
      ) {
        return [] as SocialSecurityBenefitsWithUserModel[];
      }
    }
    console.error(error);
    throw error;
  }
};

export const useSocialSecurityBenefits = (userId: Nullable<number> = null) => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [PEOPLE_API_URLS.socialSecurityBenefits, userId],
    queryFn: fetchSocialSecurityBenefits,
  });

  return {
    data: data ?? [],
    refetch,
    isLoading,
    isFetching,
  };
};
export default useSocialSecurityBenefits;
