import { SkillCategoriesKeys } from 'api/types/skills';
import { Option } from 'common/types';

export const DEFAULT_SKILLS_DICT: Record<SkillCategoriesKeys, Array<string>> = {
  'Programming Languages': [],
  'Technical': [],
  'Management': [],
  'Soft Skills': [],
};

export const DEFAULT_OPTIONS_DICT: Record<
  SkillCategoriesKeys,
  Array<Option<string, string>>
> = {
  'Programming Languages': [],
  'Technical': [],
  'Management': [],
  'Soft Skills': [],
};

export const DEFAULT_ADD_SKILLS_DICT = {
  'new Programming Languages': '',
  'new Technical': '',
  'new Management': '',
  'new Soft Skills': '',
};

export const TMP_PREFIX = 'tmp-';
