import { Box, Button } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import formStyles from 'common/styles/forms';
import SelectInput from 'components/SelectInput';
import TextInput from 'components/TextInput';
import { SocialSecurityBenefitFormProps } from './SocialSecurityBenefitFormDialog.types';
import useSocialSecurityBenefitFormDialog from './useSocialSecurityBenefitFormDialog';
import socialSecurityBenefitFormValidator from 'validation/socialSecurityBenefitFormValidator';
import { useMemo } from 'react';
import { SocialSecurityBenefitsTypeDict } from '../SocialSecurityBenefit.types';
import DatePickerInput from 'components/DatePickerInput';

const SocialSecurityBenefitFormDialog = ({
  socialSecurityBenefit,
  onClose,
}: SocialSecurityBenefitFormProps) => {
  const SOCIAL_SECURITY_BENEFITS_OPTIONS = useMemo(
    () =>
      Object.entries(SocialSecurityBenefitsTypeDict).map(([value, label]) => ({
        value,
        label,
      })),
    []
  );
  const {
    isOpen,
    methods,
    title,
    isLoading,
    onSubmitHandler,
    dateFromWatcher,
  } = useSocialSecurityBenefitFormDialog({
    socialSecurityBenefit,
    onClose,
  });

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            form="social-security-benefits-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="social-security-benefits-form"
        onSubmit={onSubmitHandler}
        validator={socialSecurityBenefitFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <DatePickerInput name="dateFrom" label="Date from" />
            <DatePickerInput
              name="dateTo"
              label="Date to"
              minDate={dateFromWatcher ?? undefined}
            />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <SelectInput
              options={SOCIAL_SECURITY_BENEFITS_OPTIONS}
              label="Type"
              name="type"
            />
          </Box>
          <Box sx={formStyles.doubleInputs}>
            <TextInput name="addInfo" label="Additional information" />
          </Box>
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default SocialSecurityBenefitFormDialog;
