import { FC, Fragment, useContext } from 'react';
import { DrawerProps } from './AppBar.types';
import {
  Box,
  Drawer as MuiDrawer,
  IconButton,
  Typography,
} from '@mui/material';
import useBreakPoints from 'common/hooks/useBreakpoints';
import styles from './AppBar.styles';
import { DRAWER_OPTIONS } from './AppBar.consts';
import { getUserGreeting } from './AppBar.helpers';
import { Close, ExitToApp } from '@mui/icons-material';
import useCustomNavigation from 'src/router/useNavigation';
import DrawerItem from './DrawerItem';
import AuthContext from 'contexts/Auth/AuthContext';

const Drawer: FC<DrawerProps> = ({ isOpen, setIsOpen }) => {
  const { isMobile } = useBreakPoints();
  const { logout, isStaff } = useContext(AuthContext);
  const { navigate, location } = useCustomNavigation();

  return (
    <MuiDrawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchor={isMobile ? 'bottom' : 'right'}
      PaperProps={{ sx: { height: '100%' } }}
    >
      <Box sx={styles.drawer}>
        <IconButton sx={styles.closeButton} onClick={() => setIsOpen(false)}>
          <Close sx={{ color: 'primary.light' }} />
        </IconButton>
        <Typography variant="h1" sx={{ color: 'primary.light' }}>
          {getUserGreeting()}
        </Typography>
        {DRAWER_OPTIONS.map(({ Icon, navigateTo, label, adminOnly }, i) =>
          adminOnly && !isStaff ? (
            <Fragment key={`option${i}`} />
          ) : (
            <DrawerItem
              key={`option${i}`}
              selected={navigateTo === location.pathname}
              Icon={Icon}
              onClick={() => navigate(navigateTo)}
              label={label}
            />
          )
        )}
        <DrawerItem
          key="logout"
          Icon={ExitToApp}
          onClick={logout}
          label="Wyloguj się"
        />
      </Box>
    </MuiDrawer>
  );
};
export default Drawer;
