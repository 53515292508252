import { HolidayType } from 'api/types/holiday';
import {
  HolidayAddModelForm,
  HolidayApplicationFormProps,
  holidayFormSubmitData,
} from './HolidayApplicationForm.types';
import { useEffect, useMemo, useContext } from 'react';
import { useUser } from 'hooks/useUsers/useUser';
import { useForm, useWatch } from 'react-hook-form';
import { useMutateHolidayApplication } from 'hooks/useUserHoliday/useMutateUserHoliday';
import ModalContext, { GlobalModalType } from 'contexts/Modal/ModalContext';
import { parseNullishBackendDate, formatToBackendDate } from 'utils/date';
import { zodResolver } from '@hookform/resolvers/zod';
import holidayFormValidator from 'validation/holidayFormValidator';

export const useHolidayApplicationForm = ({
  dateFrom,
  dateTo,
  userHoliday,
  onClose,
}: HolidayApplicationFormProps) => {
  const user = useUser();
  const {
    postHolidayApplication,
    patchHolidayApplication,
    createAdminHolidayApplication,
  } = useMutateHolidayApplication();
  const { currentModal } = useContext(ModalContext);

  const isOpen = useMemo(
    () =>
      currentModal === GlobalModalType.AddHoliday ||
      currentModal === GlobalModalType.EditHoliday,
    [currentModal]
  );

  const methods = useForm<HolidayAddModelForm>({
    resolver: zodResolver(holidayFormValidator),
    defaultValues: {
      user: user.data?.id,
      isHalfDay: userHoliday?.isHalfDay ?? false,
      type: HolidayType.Leisure,
      dateFrom: dateFrom ?? parseNullishBackendDate(userHoliday?.dateFrom),
      dateTo: dateTo ?? parseNullishBackendDate(userHoliday?.dateTo),
      addInfo: userHoliday?.addInfo ?? '',
    },
  });

  const { setValue, control } = methods;

  useEffect(() => {
    if (isOpen) {
      methods.reset(
        dateTo && dateFrom
          ? {
              dateFrom: dateFrom,
              dateTo: dateTo,
              type: HolidayType.Leisure,
              isHalfDay: false,
            }
          : {
              dateFrom: parseNullishBackendDate(userHoliday?.dateFrom),
              dateTo: parseNullishBackendDate(userHoliday?.dateTo),
              type: userHoliday?.type,
              isHalfDay: userHoliday?.isHalfDay,
              addInfo: userHoliday?.addInfo,
            }
      );
    }
  }, [dateFrom, dateTo, isOpen, methods, userHoliday]);

  const [dateFromWatcher, dateToWatcher, isCheckedWatcher, typeWatcher] =
    useWatch({ name: ['dateFrom', 'dateTo', 'isHalfDay', 'type'], control });

  useEffect(() => {
    const isDateToIncompleteOrInvalid =
      (dateFromWatcher && !dateToWatcher) ||
      (dateToWatcher && dateFromWatcher && dateToWatcher < dateFromWatcher) ||
      (isCheckedWatcher && dateFromWatcher);

    const isDateFromEmpty = !dateFromWatcher && dateToWatcher;

    if (isDateToIncompleteOrInvalid) {
      setValue('dateTo', dateFromWatcher);
    }
    if (isDateFromEmpty) {
      setValue('dateFrom', dateToWatcher);
    }
  }, [dateFromWatcher, dateToWatcher, isCheckedWatcher, setValue]);

  const onSubmitHandler = async (data: holidayFormSubmitData) => {
    if (user.data?.id && data.dateFrom && data.dateTo) {
      try {
        const holidayToApply = {
          ...data,
          dateFrom: formatToBackendDate(data.dateFrom),
          dateTo: formatToBackendDate(data.dateTo),
          addInfo: data.addInfo ?? '',
          user: user.data?.id,
        };
        if (userHoliday) {
          await patchHolidayApplication({
            holiday: { id: userHoliday.id, ...holidayToApply },
          });
        } else if (user.data.isCompanyAdmin) {
          await createAdminHolidayApplication({ holiday: holidayToApply });
        } else {
          await postHolidayApplication({
            holiday: holidayToApply,
          });
        }
        onClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    isOpen,
    onSubmitHandler,
    methods,
    dateFromWatcher,
    dateToWatcher,
    isCheckedWatcher,
    typeWatcher,
  };
};
