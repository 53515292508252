import { ChangeEventHandler, memo, useCallback, useState } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { Add, Close, Edit } from '@mui/icons-material';

import formStyles from 'common/styles/forms';
import Logo from 'assets/images/placeholder_team.png';
import { useFormContext } from 'react-hook-form';
import { ProfileForm } from '../../EditProfileDialog.types';
import { pictureStyles } from './PersonalInformationForm.styles';
import useProfile from 'hooks/useProfile';

const ProfilePictureForm = memo(() => {
  const { setValue } = useFormContext<ProfileForm>();
  const { data } = useProfile();
  const [imageUrl, setImageUrl] = useState<string | null | undefined>(
    data?.image
  );

  const handleImageChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newImage = event.target?.files?.[0];

      if (newImage) {
        setImageUrl(URL.createObjectURL(newImage));
        setValue('image', newImage);
      }
    },
    [setValue]
  );

  const handleImageDeletion = useCallback(() => {
    setValue('image', null);
    setImageUrl(null);
  }, [setValue]);

  const iconProps = {
    color: 'inherit' as const,
    sx: pictureStyles.icon,
  };

  return (
    <Box sx={[formStyles.section, pictureStyles.form]}>
      <Typography sx={{ mb: '0.75rem' }} variant="sub1">
        Profile picture
      </Typography>
      <Box sx={pictureStyles.picture}>
        <Avatar
          src={imageUrl ?? Logo}
          sx={[pictureStyles.avatar, pictureStyles.img]}
        >
          <img src={Logo} alt={`Zdjęcie `} style={pictureStyles.img} />
        </Avatar>
        <Box sx={pictureStyles.buttonContainer}>
          <Button
            component="label"
            startIcon={
              imageUrl ? <Edit {...iconProps} /> : <Add {...iconProps} />
            }
            size="small"
            variant="contained"
            sx={pictureStyles.button}
          >
            {imageUrl ? 'Edit' : 'Add'} profile picture
            <input
              hidden
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={handleImageChange}
            />
          </Button>
          {imageUrl && (
            <Button
              size="small"
              startIcon={<Close {...iconProps} />}
              color="secondary"
              variant="contained"
              sx={pictureStyles.button}
              onClick={handleImageDeletion}
            >
              Delete profile picture
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
});

ProfilePictureForm.displayName = 'ProfilePictureForm';

export default ProfilePictureForm;
