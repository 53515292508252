import { createContext, Dispatch, SetStateAction } from 'react';

interface IProfileContext {
  activeUser?: number;
  setActiveUser: Dispatch<SetStateAction<number | undefined>>;
}

const ProfileContext = createContext<IProfileContext>({
  setActiveUser: () => void 0,
});

export default ProfileContext;
