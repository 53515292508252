import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';

import useProfile from 'hooks/useProfile/useProfile';
import {
  MutateCertificateDeleteProps,
  MutateCertificatePatchProps,
  MutateCertificatePostProps,
} from './useCertificates.types';
import useCertificates from './useCertificates';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const getCertificateUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.certificates}`;

export const useMutateUserCertificates = () => {
  const { userId } = useActiveWorker();

  const { refetchProfile } = useProfile();
  const { refetch } = useCertificates(!!userId);

  const onSuccess = () => Promise.all([refetchProfile(), refetch()]);

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, certificate }: MutateCertificatePatchProps) =>
      apiPatch(`${getCertificateUrl(userId)}${certificate.id}/`, certificate),
    onSuccess: () => {
      enqueueSnackbar('Certificate successfully changed', {
        type: SnackbarType.success,
      });

      return onSuccess();
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ userId, certificateId }: MutateCertificateDeleteProps) =>
      apiDelete(`${getCertificateUrl(userId)}${certificateId}/`),
    onSuccess: () => {
      enqueueSnackbar('Certificate successfully deleted', {
        type: SnackbarType.info,
      });

      return refetchProfile();
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, certificate }: MutateCertificatePostProps) =>
      apiPost(getCertificateUrl(userId), certificate),
    onSuccess: () => {
      enqueueSnackbar('Certificate successfully added', {
        type: SnackbarType.success,
      });

      return onSuccess();
    },
  });

  return {
    patchMutate,
    deleteMutate,
    postMutate,
  };
};

export default useMutateUserCertificates;
