import { isSameDay } from 'date-fns';
import {
  formatDateForReservation,
  formatToBackendDate,
  getBusinessDays,
} from 'utils/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DESK_RESERVATIONS_NUM_DAYS } from 'pages/DeskReservation/DeskReservation.consts';
import {
  ChoosingDatesContent,
  UseChoosingDatesProps,
} from './useChoosingDates.types';

const useChoosingDates = ({
  areDeskReservationsFetched,
  areRoomReservationsFetched,
  desk,
  myReservations,
  reservationsForDesk,
  roomReservations,
}: UseChoosingDatesProps) => {
  const [choosingDatesContent, setChoosingDatesContent] =
    useState<ChoosingDatesContent>({
      options: [],
      deskTitle: '',
    });

  const dates = useMemo(() => getBusinessDays(DESK_RESERVATIONS_NUM_DAYS), []);

  const mapDatesToOption = useCallback(() => {
    return dates.map((date) => {
      const isDeskReserved = reservationsForDesk?.some((reservation) =>
        isSameDay(new Date(reservation.date), date)
      );

      const isMyReservation = Array.isArray(myReservations)
        ? myReservations?.some((reservation) =>
            isSameDay(new Date(reservation.date), date)
          )
        : undefined;

      const isRoomReservation = roomReservations?.some(
        (reservation) =>
          isSameDay(new Date(reservation.date), date) &&
          reservation.room === desk?.room
      );

      return {
        value: formatToBackendDate(date),
        label: formatDateForReservation(date),
        disabled: isDeskReserved || isMyReservation || isRoomReservation,
      };
    });
  }, [
    desk?.room,
    myReservations,
    reservationsForDesk,
    roomReservations,
    dates,
  ]);

  useEffect(() => {
    if (areDeskReservationsFetched && areRoomReservationsFetched) {
      setChoosingDatesContent({
        options: mapDatesToOption(),
        deskTitle: desk?.name,
      });
    }
  }, [
    areDeskReservationsFetched,
    areRoomReservationsFetched,
    mapDatesToOption,
    desk?.name,
  ]);

  return {
    choosingDatesContent,
  };
};

export default useChoosingDates;
