import { Box, Button } from '@mui/material';
import { memo, useContext, useState } from 'react';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import useEditProfileDialog from './useEditProfileDialog';
import editProfileValidator from 'validation/editProfileValidator';
import PersonalInformationForm from './components/PersonalInformationForm/PersonalInformationForm';
import ModalContext from 'contexts/Modal/ModalContext';
import {
  EditProfileDialogProps,
  formSubmitData,
} from './EditProfileDialog.types';
import ExperienceForm from './components/ExperienceForm';
import LanguagesForm from './components/LanguageForm';
import formStyles from 'common/styles/forms';
import useMutateProfile from 'hooks/useProfile/useMutateProfile';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { formatToBackendFormData } from './EditProfileDialog.helpers';
import useProfile from 'hooks/useProfile';

const EditProfileDialog = memo((props: EditProfileDialogProps) => {
  const { closeModal } = useContext(ModalContext);
  const { isOpen, methods } = useEditProfileDialog(props);
  const { patchMutate } = useMutateProfile();
  const { isFetching } = useProfile();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userId } = useActiveWorker();

  const onSubmitHandler = async (data: formSubmitData) => {
    if (userId) {
      setIsSubmitting(true);
      const payload = {
        userId,
        profile: formatToBackendFormData(data),
        image: data.image,
      };

      await patchMutate(payload).finally(() => {
        setIsSubmitting(false);
        closeModal();
      });
    }
  };

  return (
    <AppDialog
      title="Edit your profile"
      actions={
        <>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={isFetching || isSubmitting}
            form="edit-profile-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </>
      }
      open={isOpen}
      onClose={closeModal}
    >
      <FormWrapper
        id="edit-profile-form"
        methods={methods}
        validator={editProfileValidator}
        onSubmit={onSubmitHandler}
      >
        <PersonalInformationForm name={props.worker?.fullName} />
        <Box sx={formStyles.sectionsWrapper}>
          <ExperienceForm />
          <LanguagesForm />
        </Box>
      </FormWrapper>
    </AppDialog>
  );
});

EditProfileDialog.displayName = 'EditProfileDialog';

export default EditProfileDialog;
