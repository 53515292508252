import { z } from 'zod';
import editProfileValidator from 'validation/editProfileValidator';
import { formatToNullableBackendDate } from 'utils/date';

export const formatToBackendFormData = ({
  dateOfBirth,
  inRevolve,
  experienceSince,
  languages,
  ...rest
}: Omit<
  z.infer<typeof editProfileValidator>,
  'language' | 'level' | 'image' | 'calculateFromProjects'
>) => ({
  inRevolve: formatToNullableBackendDate(inRevolve),
  experienceSince: formatToNullableBackendDate(experienceSince),
  dateOfBirth: formatToNullableBackendDate(dateOfBirth),
  languageSet: languages.map(({ id, level }) => ({ code: id, level })),
  ...rest,
});
