import { useCallback, useContext } from 'react';
import useMutateRoomReservation from './useMutateRoomReservations';
import { getFormattedDate } from 'pages/RoomReservation/RoomReservation.helpers';
import useReservation from './useReservation';
import RoomReservationContext, {
  ModalType,
} from 'contexts/RoomReservation/RoomReservationContext';

const useReserveRoom = () => {
  const booker = Number(localStorage.ID);
  const { postMutate } = useMutateRoomReservation();
  const { refetch } = useReservation();
  const { setModal } = useContext(RoomReservationContext);

  const getDateToReserve = useCallback((dateToReserve: Date) => {
    return getFormattedDate(dateToReserve);
  }, []);

  const onReservationRoomSave = async (formatedDate: string) => {
    await postMutate({ date: formatedDate, room: 1, booker }).catch((error) => {
      console.error(error);
    });
    setModal(ModalType.MyReservations);
    await refetch();
  };

  return {
    getDateToReserve,
    onReservationRoomSave,
  };
};

export default useReserveRoom;
