import { CSSProperties, memo, useContext, useMemo } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { differenceInCalendarMonths, format } from 'date-fns';

import styles from './WorkerSummary.styles';
import Logo from 'assets/images/placeholder_team.png';
import { parseBackendDate } from 'utils/date';
import EditProfileDialog from 'pages/Profile/components/WorkerSummary/EditProfileDialog';
import { WorkerSummaryProps } from './WorkerSummary.types';
import {
  getExpString,
  getLanguageString,
  getMonthsOfExp,
} from './WorkerSummary.helpers';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';
import { isEmpty } from 'pages/Profile/Profile.helpers';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';

const WorkerSummary = memo(
  ({ workerDetails, hideEmpty, title, isReadonly }: WorkerSummaryProps) => {
    const { currentModal } = useContext(ModalContext);
    const {
      fullName,
      image,
      inRevolve,
      languageSet,
      projectSet,
      experienceSince,
      description,
    } = workerDetails ?? {};

    const monthsOfExp = useMemo(() => {
      if (experienceSince) {
        const diff = differenceInCalendarMonths(
          new Date(),
          parseBackendDate(experienceSince)
        );

        return diff + (diff < 12 ? 1 : 0);
      }

      return getMonthsOfExp(projectSet ?? []);
    }, [experienceSince, projectSet]);

    const expString = useMemo(() => getExpString(monthsOfExp), [monthsOfExp]);

    const languageString = useMemo(
      () =>
        languageSet?.length
          ? getLanguageString(languageSet)
          : DEFAULT_EMPTY_STRING,
      [languageSet]
    );

    const sinceWhenString = useMemo(
      () =>
        inRevolve
          ? `since ${format(parseBackendDate(inRevolve), 'MMMM yyyy')}`
          : DEFAULT_EMPTY_STRING,
      [inRevolve]
    );

    return (
      <Box sx={styles.root}>
        <Avatar src={image ?? Logo} sx={styles.image}>
          <img
            src={Logo}
            alt={`Zdjęcie ${title ?? fullName ?? ''}`}
            style={styles.image as CSSProperties}
          />
        </Avatar>
        <Box>
          <Typography variant="body1">{fullName ? title : ''}</Typography>
          <Typography variant="extra">{fullName ?? title ?? ''}</Typography>
          <Box sx={styles.userInfo}>
            {!(isEmpty(languageString) && hideEmpty) && (
              <>
                <Typography variant="body2">Language</Typography>
                <Typography variant="body2">{languageString}</Typography>
              </>
            )}
            <Typography variant="body2">Experience</Typography>
            <Typography variant="body2">{expString}</Typography>
            {!(isEmpty(sinceWhenString) && hideEmpty) && (
              <>
                <Typography variant="body2">In Revolve</Typography>
                <Typography variant="body2">{sinceWhenString}</Typography>
              </>
            )}
          </Box>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography>
        </Box>
        {!isReadonly && currentModal === ProfileModalType.EditProfile && (
          <EditProfileDialog worker={workerDetails} />
        )}
      </Box>
    );
  }
);

WorkerSummary.displayName = 'WorkerSummary';

export default WorkerSummary;
