import axios from 'axios';
import {
  API_URL,
  CONTENT_TYPE,
  XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME,
} from './axios.consts';

const instance = axios.create({
  baseURL: API_URL,
  xsrfHeaderName: XSRF_HEADER_NAME,
  xsrfCookieName: XSRF_COOKIE_NAME,
  headers: {
    accept: CONTENT_TYPE,
  },
});

instance.interceptors.request.use(
  (request) => {
    const [token] = document.cookie
      .split(';')
      .filter((line) => line.includes('auth_token'));

    if (token && request.headers) {
      request.headers.authorization = `Token ${token.split('=')[1]}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const {
  get: apiGet,
  post: apiPost,
  patch: apiPatch,
  put: apiPut,
  delete: apiDelete,
} = instance;

export default instance;
