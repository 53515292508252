import { Box, Typography } from '@mui/material';
import { CalendarToday, School } from '@mui/icons-material';

import styles from './Educations.styles';
import ActionButtons from '../ActionButtons';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import { parseAndFormatDate } from 'utils/date';
import { EducationProps } from './Educations.types';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';
import { useMemo } from 'react';
import { isEmpty } from 'pages/Profile/Profile.helpers';
import useMutateUserEducation from 'hooks/useEducation/useMutateUserEducation';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

const Education = (props: EducationProps) => {
  const { education, handleEdit, hideEmpty, isReadonly, isLoading } = props;
  const { school, city, degree, fieldOfStudy, dateFrom, dateTo, department } =
    education;

  const { userId } = useActiveWorker();
  const { deleteMutate } = useMutateUserEducation();

  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      userId && deleteMutate({ userId, educationId: education.id }),
    title: `Are you sure you want to delete school${school.name}?`,
  });

  const dateRange = useMemo(
    () =>
      `${parseAndFormatDate(dateFrom)} - ${
        dateTo ? parseAndFormatDate(dateTo) : 'present'
      }`,
    [dateFrom, dateTo]
  );

  const departmentString = useMemo(() => department || '', [department]);

  const cityString = useMemo(
    () =>
      city &&
      !school.name.toLowerCase().includes(`${city.toLowerCase()}`) &&
      `, ${city}`,
    [city, school.name]
  );

  const degreeString = useMemo(() => {
    if (isEmpty(degree ?? '') && hideEmpty) {
      return '';
    }

    return ` ${degree || DEFAULT_EMPTY_STRING} degree, `;
  }, [degree, hideEmpty]);

  const lastLineString = useMemo(
    () => `${degreeString}${fieldOfStudy?.name}`,
    [degreeString, fieldOfStudy]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {isReadonly ? null : (
        <ActionButtons
          disabled={isLoading}
          isStart
          onDeleteHandler={openConfirmationModal}
          onEditHandler={() => handleEdit(education)}
        />
      )}
      <ConfirmationDialog />
      <Box sx={styles.educationData}>
        <Box sx={styles.iconWrapper}>
          <CalendarToday sx={styles.icon} />
          <Typography variant="body1">{dateRange}</Typography>
        </Box>
        <Box sx={styles.iconWrapper}>
          <School sx={styles.icon} />
          <Box sx={styles.schoolDisplay}>
            <Typography variant="body1">
              {school.name}
              {cityString}
            </Typography>
            {!(departmentString.length === 0) && (
              <Typography variant="body1">{departmentString}</Typography>
            )}
            <Typography variant="body1">{lastLineString}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Education;
