import {
  addDays,
  format,
  Interval,
  isAfter,
  isEqual,
  isWeekend,
  parse,
} from 'date-fns';
import { Nullable } from 'common/types';
import { pl } from 'date-fns/locale';
import { capitalizeString } from 'utils/string';

export const formatToBackendDate = (date: Date) => format(date, 'yyyy-MM-dd');
export const formatToNullableBackendDate = (date: Nullable<Date>) =>
  date ? formatToBackendDate(date) : null;

// null date is always equal present time
export const parseBackendDate = (date: Nullable<string>) =>
  date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date();

export const parseNullishBackendDate = (date: Nullable<string> | undefined) =>
  date ? parse(date, 'yyyy-MM-dd', new Date()) : null;

export const formatDate = (date: Date) => format(date, 'MM.yyyy');
export const formatLongDate = (date: Date) => format(date, 'dd.MM.yyyy');

export const parseAndFormatDate = (date: Nullable<string>) =>
  formatDate(parseBackendDate(date));

export const mergeOverlappingIntervals = (intervals: Interval[]) => {
  if (intervals.length < 2) return intervals;

  intervals.sort((a, b) => +a.start - +b.start);

  const result: Interval[] = [];
  let previous = intervals[0];

  intervals.forEach(({ start, end }, index) => {
    if (index === 0) return; // skip first index
    if (isAfter(previous.end, start) || isEqual(previous.end, start)) {
      previous = {
        start: previous.start,
        end: new Date(Math.max(+previous.end, +end)),
      };
    } else {
      result.push(previous);
      previous = { start, end };
    }
  });

  return [...result, previous];
};

const getPluralText = (text: string, count: number) =>
  `${count} ${text}${count === 1 ? '' : 's'}`;

export const getDurationText = (years: number, months: number) => {
  if (years === 0) return getPluralText('month', months);
  if (months === 0) return getPluralText('year', years);

  return `${getPluralText('year', years)} ${getPluralText('month', months)}`;
};

// Generates an array of string representations of future dates within a specified number of months from today.
export const getFutureDatesInMonthsRange = (months: number): string[] => {
  const startDate = new Date();
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + months,
    1
  );

  const datesInRange: string[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const formattedDate = currentDate.toISOString().split('T')[0];
    datesInRange.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesInRange;
};

export const getDateRangeFromToday = (months: number) => {
  const today = new Date();
  const monthsLater = new Date();
  monthsLater.setMonth(today.getMonth() + months);

  return {
    start: today.toISOString().split('T')[0],
    end: monthsLater.toISOString().split('T')[0],
  };
};

export const getBusinessDays = (numDays: number) => {
  const businessDays = [];
  let currentDate = new Date();

  while (businessDays.length < numDays) {
    if (!isWeekend(currentDate)) businessDays.push(currentDate);

    currentDate = addDays(currentDate, 1);
  }

  return businessDays;
};

export const formatDateForReservation = (date: Date) => {
  return capitalizeString(format(date, 'eeee, d MMMM yyyy', { locale: pl }));
};

export const formatIsoDateForReservation = (date: Date) => {
  return format(date, 'yyyy-MM-dd', { locale: pl });
};
