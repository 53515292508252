import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ChatIcon from 'assets/images/video-chat.svg';
import styles from './NoRoomReservationCard.styles';
import DisplayWrapper from 'components/DisplayWrapper';

export const NoRoomReservationCard: FC = () => (
  <DisplayWrapper>
    <Box sx={styles.content}>
      <img src={ChatIcon} />
      <Typography sx={styles.text} variant="h1">
        {'Wybierz dzień, w którym chcesz zarezerwować pokój cichej pracy'}
      </Typography>
      <Typography sx={styles.text} variant="body1">
        {'Możesz zarezerwować pokój jedynie na trzy miesiące do przodu'}
      </Typography>
    </Box>
  </DisplayWrapper>
);

export default NoRoomReservationCard;
