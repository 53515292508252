import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { memo, useEffect, useMemo } from 'react';

import formStyles from 'common/styles/forms';
import styles from '../../ProjectFormDialog.styles';
import CheckboxInput from 'components/CheckboxInput';
import AutoCompleteInput from 'components/AutoCompleteInput';
import DatePickerInput from 'components/DatePickerInput';
import { ProjectForm } from '../../ProjectFormDialog.types';
import useProjects from 'hooks/useProjects/useProjects';
import usePositions from 'hooks/usePositions';
import { mapKeyValuesAndSort } from './GeneralInformation.helpers';
import SelectInput from 'components/SelectInput';
import { TEAM_SIZE_OPTIONS } from '../../ProjectFormDialog.consts';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

const GeneralInformation = memo(() => {
  const {
    watch,
    setValue,
    formState: { isSubmitted },
    trigger,
  } = useFormContext<ProjectForm>();

  const [projectWatcher, startDateWatcher, endDateWatcher, atPresentWatcher] =
    watch(['name.value', 'dateFrom', 'dateTo', 'atPresent']);

  const { userId } = useActiveWorker();
  const { data } = useProjects(true, userId);
  const { data: positionsData } = usePositions();

  useEffect(() => {
    //  to avoid displaying error when in disabled mode
    if (atPresentWatcher) setValue('dateTo', null, { shouldValidate: true });
  }, [atPresentWatcher, setValue]);

  useEffect(() => {
    if (startDateWatcher && isSubmitted) trigger('dateTo');
  }, [startDateWatcher, isSubmitted, trigger]);

  const newProject = useMemo(
    () => data?.find((project) => `${project.id}` === projectWatcher),
    [data, projectWatcher]
  );

  const { isExternal, teamSize } = newProject ?? {};

  useEffect(() => {
    if (!isExternal && projectWatcher)
      setValue('teamSize', teamSize ? `${teamSize}` : '1');
  }, [isExternal, projectWatcher, setValue, teamSize]);

  const isTeamSizeDisabled = !isExternal && isExternal !== undefined;

  return (
    <Box sx={formStyles.section}>
      <Typography sx={{ mb: 3 }} variant="sub1">
        General information
      </Typography>
      <Box sx={styles.projectDetailsGrid}>
        <AutoCompleteInput
          fullWidth
          clearOnBlur
          selectOnFocus
          handleHomeEndKeys
          freeSolo
          options={mapKeyValuesAndSort(data ?? [])}
          label="Project name"
          name="name"
        />
        <SelectInput
          options={TEAM_SIZE_OPTIONS}
          name="teamSize"
          label="Team size"
          disabled={isTeamSizeDisabled}
        />
        <AutoCompleteInput
          fullWidth
          clearOnBlur
          selectOnFocus
          handleHomeEndKeys
          options={mapKeyValuesAndSort(positionsData ?? [])}
          label="Position"
          name="position"
        />
      </Box>
      <Box sx={styles.dateGrid}>
        <DatePickerInput
          maxDate={endDateWatcher ?? undefined}
          disableFuture
          label="Start date"
          name="dateFrom"
        />
        <DatePickerInput
          minDate={startDateWatcher ?? undefined}
          disabled={atPresentWatcher}
          label="End date"
          name="dateTo"
        />
        <CheckboxInput
          isSmall
          wrapperSx={formStyles.checkboxInput}
          name="atPresent"
          label="At present"
        />
      </Box>
    </Box>
  );
});

GeneralInformation.displayName = 'GeneralInformation';

export default GeneralInformation;
