import { z } from 'zod';
import {
  MIN_HOLIDAY_POOL,
  MIN_YEAR,
  errorMessages,
} from 'common/consts/validation';

const holidayPoolFormValidator = z.object({
  year: z.coerce
    .number({
      invalid_type_error: errorMessages.required,
    })
    .min(MIN_YEAR, { message: errorMessages.minYear(MIN_YEAR) }),
  days: z.coerce
    .number({
      invalid_type_error: errorMessages.required,
    })
    .min(MIN_HOLIDAY_POOL, { message: errorMessages.nonNegativeValue }),
  daysFromPreviousYear: z.coerce
    .number()
    .min(MIN_HOLIDAY_POOL, { message: errorMessages.nonNegativeValue })
    .optional(),
});

export default holidayPoolFormValidator;
