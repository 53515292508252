import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import {
  MutateKudosDeleteProps,
  MutateKudosPatchProps,
  MutateKudosProps,
} from './useKudos.types';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import useKudos from 'hooks/useKudos/useKudos';

export const useMutateKudos = () => {
  const { refetchKudos } = useKudos({ pageNumber: 1, isSentKudosView: true });
  const { mutateAsync: postMutate } = useMutation({
    mutationFn: (props: MutateKudosProps) => apiPost(API_URLS.kudos, props),
    onSuccess: () => {
      enqueueSnackbar('Dobra robota, kudos wysłany!', {
        type: SnackbarType.success,
        title: 'Sukces',
      });
      refetchKudos();
    },
    onError: () => {
      enqueueSnackbar('Błąd podczas wysyłania kudosów', {
        type: SnackbarType.error,
        title: 'Błąd',
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ kudosId }: MutateKudosDeleteProps) =>
      apiDelete(`${API_URLS.kudos}${kudosId}/`),
    onSuccess: () => {
      enqueueSnackbar('Kudos został usunięty pomyślnie!', {
        type: SnackbarType.success,
        title: 'Sukces',
      });
      refetchKudos();
    },
    onError: () => {
      enqueueSnackbar('Nie można usunąc kudosa', {
        type: SnackbarType.error,
        title: 'Błąd',
      });
    },
  });

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ kudosId, kudos }: MutateKudosPatchProps) =>
      apiPatch(`${API_URLS.kudos}${kudosId}/`, kudos),
    onSuccess: () => {
      enqueueSnackbar('Kudos został zmieniony pomyślnie', {
        type: SnackbarType.success,
        title: 'Sukces',
      });
      refetchKudos();
    },
    onError: () => {
      enqueueSnackbar('Nie można edytować kudosa', {
        type: SnackbarType.error,
        title: 'Błąd',
      });
    },
  });

  return {
    postMutate,
    deleteMutate,
    patchMutate,
  };
};

export default useMutateKudos;
