import { useMutation } from '@tanstack/react-query';
import { MutateHolidayPhotoPostProps } from './useHolidayPhoto.types';
import { API_URLS } from 'api/axios/axios.consts';
import { apiPost } from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const useMutateHolidayPhoto = () => {
  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ image }: MutateHolidayPhotoPostProps) =>
      apiPost<AxiosResponse>(
        API_URLS.holidayPhoto,
        { image },
        { headers: { 'content-type': 'multipart/form-data' } }
      ),
    onError: (error: AxiosError) => {
      enqueueSnackbar(error.message, {
        type: SnackbarType.error,
        title: 'Błąd',
      });
    },
  });

  return {
    postMutate,
  };
};

export default useMutateHolidayPhoto;
