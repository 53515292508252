import { Box, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import styles from './RatingsHeaders.styles';
import { RatingsHeadersProps } from './RatingsHeaders.types';

const RatingsHeaders = memo(
  ({ isTwoColumn, isReadonly }: RatingsHeadersProps) => {
    const finalStyle = useMemo(
      () => (isTwoColumn ? {} : styles.hide),
      [isTwoColumn]
    );

    return (
      <>
        <Typography variant={'body2'}>Name</Typography>
        <Typography variant={'body2'}>Level</Typography>
        {!isReadonly && (
          <>
            <Typography variant={'body2'}>Attitude</Typography>
            <Box />
          </>
        )}
        <Typography variant={'body2'} sx={finalStyle}>
          Name
        </Typography>
        <Typography variant={'body2'} sx={finalStyle}>
          Level
        </Typography>
        {!isReadonly && (
          <>
            <Typography variant={'body2'} sx={finalStyle}>
              Attitude
            </Typography>
            <Box sx={finalStyle} />
          </>
        )}
      </>
    );
  }
);

RatingsHeaders.displayName = 'RatingsHeaders';

export default RatingsHeaders;
