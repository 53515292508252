import { COLORS } from 'theme/index';
import { getImageSize } from 'utils/styles';

const styles = {
  usersContainer: {
    marginTop: '2rem',
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  userContainer: (selected: boolean) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    color: COLORS.black,
    borderRadius: '2.5rem',
    padding: '0.5rem 1.5rem 0.5rem 0.5rem',
    background: selected ? COLORS.blue[100] : 'unset',
  }),
  image: {
    ...getImageSize('2rem'),
    borderRadius: '1rem',
  },
};

export default styles;
