import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { HolidayPhotosPageGetResponse } from 'api/types/photo';

const usePageHolidayPhotos = (page?: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`${API_URLS.holidayPhoto}?page=${page}`],
    queryFn: () =>
      apiGet<HolidayPhotosPageGetResponse>(
        `${API_URLS.holidayPhoto}?page=${page}`
      ),
    enabled: !!page,
  });

  return {
    pageHolidayPhotosDataResponse: data?.data,
    isLoadingPageHolidayPhoto: isLoading,
    refetchPageHolidayPhotos: refetch,
  };
};
export default usePageHolidayPhotos;
