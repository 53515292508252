import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';

import useProfile from 'hooks/useProfile/useProfile';
import {
  MutateCourseDeleteProps,
  MutateCoursePatchProps,
  MutateCoursePostProps,
} from './useCourses.types';
import useCourses from './useCourses';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const getCoursesUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.courses}`;

export const useMutateUserCourses = () => {
  const { refetchProfile } = useProfile();
  const { refetch } = useCourses();

  const onSuccess = () => Promise.all([refetchProfile(), refetch()]);

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, course }: MutateCoursePatchProps) =>
      apiPatch(`${getCoursesUrl(userId)}${course.id}/`, course),
    onSuccess: () => {
      enqueueSnackbar('Course successfully changed', {
        type: SnackbarType.success,
      });

      return onSuccess();
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ userId, courseId }: MutateCourseDeleteProps) =>
      apiDelete(`${getCoursesUrl(userId)}${courseId}/`),
    onSuccess: () => {
      enqueueSnackbar('Course successfully deleted', {
        type: SnackbarType.info,
      });

      return refetchProfile();
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, course }: MutateCoursePostProps) =>
      apiPost(getCoursesUrl(userId), course),
    onSuccess: () => {
      enqueueSnackbar('Course successfully added', {
        type: SnackbarType.success,
      });

      return onSuccess();
    },
  });

  return {
    patchMutate,
    deleteMutate,
    postMutate,
  };
};

export default useMutateUserCourses;
