import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';

import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

import useMutateUserHolidayPool from 'hooks/useHolidayPools/useMutateUserHolidayPool';
import {
  HolidayPoolForm,
  UseHolidayPoolFormDialogProps,
  holidayPoolFormSubmitData,
} from './HolidayPoolFormDialog.types';
import holidayPoolFormValidator from 'validation/holidayPoolFormValidator';
import { getYear } from 'date-fns';

const useHolidayPoolFormDialog = ({
  holidayPool,
  onClose,
}: UseHolidayPoolFormDialogProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen =
    currentModal === AdminProfileModalType.AddHolidayPool ||
    currentModal === AdminProfileModalType.EditHolidayPool;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();
  const currentYear = getYear(new Date());

  const methods = useForm<HolidayPoolForm>({
    resolver: zodResolver(holidayPoolFormValidator),
  });

  const { holidayPoolPatchMutate, holidayPoolPostMutate } =
    useMutateUserHolidayPool();

  const title = useMemo(
    () =>
      `${
        currentModal === AdminProfileModalType.AddHolidayPool ? 'Add' : 'Edit'
      } holiday pool`,
    [currentModal]
  );

  const { days, year, daysFromPreviousYear } = holidayPool ?? {};

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        year: year,
        days: days ? parseFloat(days) : undefined,
        daysFromPreviousYear: daysFromPreviousYear
          ? parseFloat(daysFromPreviousYear)
          : undefined,
      });
    }
  }, [currentYear, days, daysFromPreviousYear, isOpen, methods, year]);

  const onSubmitHandler = async (data: holidayPoolFormSubmitData) => {
    if (userId) {
      const payload = {
        year: data.year,
        days: data.days.toString(),
        daysFromPreviousYear: data.daysFromPreviousYear?.toString() ?? '0',
        user: userId,
      };
      setIsSubmitting(true);
      try {
        if (holidayPool) {
          await holidayPoolPatchMutate({
            holidayPool: { id: holidayPool.id, ...payload },
          });
        } else {
          await holidayPoolPostMutate({ holidayPool: payload });
        }
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  return {
    isOpen,
    methods,
    title,
    onSubmitHandler,
    isLoading: isSubmitting,
  };
};

export default useHolidayPoolFormDialog;
