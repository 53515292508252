/* eslint react/prop-types: 0 */
// Generic typing breaks prop-types used for jsx apparently, TODO: Remove prop-types

import { InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import InputWrapper from 'components/InputWrapper';
import { FieldProps, SelectInputProps } from './SelectInput.types';

const SelectInput = <T extends string | Array<string> = string>({
  label,
  options,
  name,
  placeholder,
  required,
  children,
  wrapperSx = {},
  ...props
}: SelectInputProps<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, ref, ...rest },
      }: {
        field: FieldProps<T>;
      }) => (
        <InputWrapper sx={wrapperSx} name={name} fullWidth>
          <InputLabel required={required} id={name}>
            {label}
          </InputLabel>
          <Select<T>
            displayEmpty={!!placeholder}
            input={<OutlinedInput required={required} label={label} />}
            inputRef={ref}
            labelId={name}
            value={value ?? ''}
            {...props}
            {...rest}
          >
            {children
              ? children({ currentValue: value })
              : options.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    <span>{label}</span>
                  </MenuItem>
                ))}
          </Select>
        </InputWrapper>
      )}
    />
  );
};

export default SelectInput;
