import { Box, Typography } from '@mui/material';
import styles from './Projects.styles';
import ActionButtons from '../ActionButtons';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import { ProjectProps } from './Projects.types';
import { DEFAULT_EMPTY_STRING } from 'pages/Profile/Profile.const';
import { isEmpty } from 'pages/Profile/Profile.helpers';
import InfoSection from './InfoSection';
import DescriptionSection from './DescriptionSection';
import { useMemo } from 'react';
import useMutateUserProject from 'hooks/useProjects/useMutateUserProject';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

const Project = (props: ProjectProps) => {
  const { project, isReadonly, hideEmpty, isLoading, editProject } = props;
  const { projectDetails, position } = project;

  const { userId } = useActiveWorker();
  const { deleteMutate } = useMutateUserProject();

  const sectionProps = useMemo(
    () => ({
      project,
      hideEmpty,
    }),
    [hideEmpty, project]
  );

  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      userId && deleteMutate({ userId, projectId: project.id }),
    title: `Are you sure you want to delete project${
      projectDetails?.name ? `: ${projectDetails.name}` : ''
    }?`,
  });

  return (
    <Box sx={styles.projectContainer}>
      <Box sx={styles.headerContainer}>
        {!(isEmpty(position.name || DEFAULT_EMPTY_STRING) && hideEmpty) && (
          <Typography variant="sub1" sx={{ display: 'inline-block' }}>
            {position.name || DEFAULT_EMPTY_STRING}
          </Typography>
        )}
        <ConfirmationDialog />
        {!isReadonly && (
          <ActionButtons
            disabled={isLoading}
            onEditHandler={editProject}
            onDeleteHandler={openConfirmationModal}
          />
        )}
      </Box>
      <InfoSection {...sectionProps} />
      <DescriptionSection {...sectionProps} />
    </Box>
  );
};

export default Project;
