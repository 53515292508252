import { COLORS } from 'theme/index';

const styles = {
  form: { position: 'relative' },
  loader: {
    position: 'absolute',
    background: COLORS.opaqueWhite,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
};

export default styles;
