import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './ReserverRoomModal.styles';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { ReserveRoomModalProps } from './ReserveRoomModal.types';
import { COLORS } from 'theme/Theme.const';
import DisplayWrapper from 'components/DisplayWrapper';
import useReserveRoom from 'hooks/useRoomReservations/useReserveRoom';

const ReserveRoomModal: FC<ReserveRoomModalProps> = ({ dateToReserve }) => {
  const { getDateToReserve, onReservationRoomSave } = useReserveRoom();
  const { day, formattedDayOfWeek, month, monthNumber, year } =
    getDateToReserve(dateToReserve);
  const formattedData = `${year}-${monthNumber}-${day}`;

  return (
    <DisplayWrapper sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant={'h1'}>Zarezerwuj pokój</Typography>
        <Box sx={styles.boxStyle}>
          <MdOutlineCalendarToday color={COLORS.blue[600]} />
          <Typography>
            {formattedDayOfWeek}, {day} {month} {year}
          </Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button
            sx={styles.saveButton}
            onClick={() => onReservationRoomSave(formattedData)}
          >
            Zarezerwuj
          </Button>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default ReserveRoomModal;
