import { EventInput } from '@fullcalendar/core';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import { UseRoomCalendarEventsProps } from './useRoomCalendarEvents.types';
import AuthContext from 'contexts/Auth/AuthContext';
import { getFutureDatesInMonthsRange } from 'utils/date';
import useDesksReservations from 'hooks/useDesksReservations';
import {
  getDeskReservationEvent,
  getRoomReservationBackgroundEvent,
} from 'pages/RoomReservation/components/Calendar/Calendar.helpers';
import useBreakPoints from 'hooks/useBreakpoints';

const useRoomCalendarEvents = ({
  reservedRooms,
}: UseRoomCalendarEventsProps) => {
  const calendarRef = useRef<FullCalendar>(null);
  const { id } = useContext(AuthContext);
  const [events, setEvents] = useState<EventInput>([]);
  const { desksReservations } = useDesksReservations();
  const { isMobile } = useBreakPoints();

  useEffect(() => {
    const datesForEvents = getFutureDatesInMonthsRange(3);
    const eventsToSet = datesForEvents.flatMap((date) => {
      const roomReservation = reservedRooms.find(
        (roomReservation) => roomReservation.date === date
      );
      const deskReservation = desksReservations?.find(
        (deskReservation) => deskReservation.date === date
      );
      const numberOfDesksReservations = desksReservations?.filter(
        (deskReservation) => deskReservation.date === date
      ).length;

      const backgroundEvent = getRoomReservationBackgroundEvent(
        date,
        id,
        roomReservation,
        deskReservation
      );
      const defaultEvent = getDeskReservationEvent(
        date,
        isMobile,
        numberOfDesksReservations
      );

      return [backgroundEvent, defaultEvent ?? {}];
    });
    setEvents(eventsToSet);
  }, [desksReservations, id, reservedRooms, reservedRooms.length, isMobile]);

  const onPrev = (setMonth: Dispatch<SetStateAction<number>>) => {
    const selectedDate = calendarRef.current?.getApi().getDate().getMonth();

    setMonth(selectedDate && selectedDate >= 1 ? selectedDate + 1 - 1 : 12);
    calendarRef.current?.getApi().prev();
  };

  const onNext = (setMonth: Dispatch<SetStateAction<number>>) => {
    const selectedDate = calendarRef.current?.getApi().getDate().getMonth();
    setMonth(selectedDate && selectedDate < 11 ? selectedDate + 2 : 1);
    calendarRef.current?.getApi().next();
  };

  return { events, calendarRef, onPrev, onNext };
};

export default useRoomCalendarEvents;
