import { Link, Typography } from '@mui/material';
import { RouterPaths } from 'src/router/types';
import useCustomNavigation from 'src/router/useNavigation/useNavigation';
import styles from '../Kudos.styles';

const KudosHeader = () => {
  const navigation = useCustomNavigation();

  return (
    <>
      <Typography variant="extra">
        {'Wybierz kartę, którą chcesz wysłać'}
      </Typography>
      <Typography variant="h1">
        <Link
          onClick={() => navigation.navigate(RouterPaths.myKudos)}
          sx={styles.link}
        >
          {'tutaj'.toUpperCase()}
        </Link>
        {' znajdziesz otrzymane kudosy'}
      </Typography>
      <Typography variant="h1">
        <Link
          onClick={() => navigation.navigate(RouterPaths.sentKudos)}
          sx={styles.link}
        >
          {'tutaj'.toUpperCase()}
        </Link>
        {' znajdziesz wysłane kudosy oczekujące na opublikowanie'}
      </Typography>
    </>
  );
};

export default KudosHeader;
