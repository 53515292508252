import { COLORS } from 'theme/Theme.const';

const styles = {
  container: {
    height: 'auto',
    padding: {
      Desktop: '2.5rem',
      Tablet: '2rem',
      Mobile: '2rem',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '2rem',
  },
  text: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  cancelButton: {
    border: `1px solid ${COLORS.lightBlue}`,
  },
  saveButton: {
    backgroundColor: COLORS.blue[600],
    color: COLORS.white,
  },
  mainTextContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
};

export default styles;
