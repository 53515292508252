import { ToastPosition } from 'react-hot-toast';

export enum SnackbarType {
  error = 'error',
  success = 'success',
  info = 'info',
}

export interface SnackbarConfig {
  id: string;
  type: SnackbarType;
  duration: number;
  position: ToastPosition;
  title: string;
}

export interface SnackbarProps {
  message: string;
  config?: Partial<SnackbarConfig>;
}

export interface SnackbarAnimationProps {
  duration?: number;
}
