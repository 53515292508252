import { COLORS } from 'theme/Theme.const';

const infoMessageDeskReservationStyles = {
  boxStyle: {
    height: '8rem',
    width: '100%',
    borderRadius: '0.75rem',
    backgroundColor: COLORS.info,
    border: `1px solid ${COLORS.blue[300]} `,
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  header: {
    fontWeight: '500',
    color: 'inherit',
  },
  subheader: {
    color: 'inherit',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  cancelButton: {
    width: '10rem',
    height: '3rem',
    color: COLORS.white,
    backgroundColor: COLORS.blue[600],
  },
};

export default infoMessageDeskReservationStyles;
