import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import styles from '../ChoosingDates/ChoosingDates.styles';
import editStyles from './EditDesk.styles';
import deskReservationStyles from 'pages/DeskReservation/DeskReservation.styles';
import FormWrapper from 'components/FormWrapper';
import TextInput from 'components/TextInput';
import useDesk from 'hooks/useDesk';
import { Clear } from '@mui/icons-material';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import DisplayWrapper from 'components/DisplayWrapper';
import AutoCompleteInput from 'components/AutoCompleteInput';
import editDeskValidator from 'validation/editDeskValidator';
import { Option } from 'common/types';
import useDeskForm from 'hooks/useDesk/useDeskForm';
import useEditDesk from 'hooks/useDesk/useEditDesk';
import useChooseDeskFeature from 'hooks/useDeskFeatures/useChooseDeskFeature';

const EditDesk: FC = () => {
  const { activeDesk } = useContext(DeskContext);
  const { desk, isLoading } = useDesk();
  const { featureOptions, features, handleDeleteFeature, onOptionChange } =
    useChooseDeskFeature();

  const { userOptions, toggleSwitch, switchState } = useEditDesk();

  const { methods, onSubmitHandler, onCancelHandler } = useDeskForm({
    features,
    switchState,
  });

  useEffect(() => {
    if (desk) {
      const { name, owner } = desk;
      if (name) {
        methods.setValue('name', name);
      }
      if (owner) {
        const selectedOwner = userOptions.find(
          ({ value }) => value === owner.toString()
        );
        methods.setValue('owner', selectedOwner);
      } else {
        methods.resetField('owner');
      }
    }
  }, [desk, methods, userOptions]);

  if (activeDesk === null) {
    return <></>;
  }

  return (
    <DisplayWrapper>
      <Box sx={editStyles.content}>
        <Typography variant="h1" align="center">
          {`Edytuj biurko ${desk?.name}`}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={switchState} onChange={toggleSwitch} />}
            label="HotDesk"
          />
        </FormGroup>
        <FormWrapper
          id="edit-desk-form"
          methods={methods}
          validator={editDeskValidator}
          onSubmit={onSubmitHandler}
          isLoading={isLoading}
        >
          <Box style={editStyles.formContainer}>
            {switchState ? (
              <>
                <TextInput name="name" label="Zmień nazwę biurka" fullWidth />
                <AutoCompleteInput
                  fullWidth
                  clearOnBlur
                  clearOnEscape
                  selectOnFocus
                  onChange={async (_, value) => {
                    await onOptionChange(value as Option);
                  }}
                  handleHomeEndKeys
                  freeSolo
                  isOptionEqualToValue={(option, newValue) => {
                    return option.inputValue === newValue.inputValue;
                  }}
                  options={featureOptions}
                  label="Dodaj cechę biurka"
                  name="features"
                />
                <Box sx={editStyles.chipBox}>
                  {features?.map((data) => (
                    <Chip
                      label={data.name}
                      key={data.name}
                      deleteIcon={<Clear style={editStyles.deleteFeature} />}
                      onDelete={() => handleDeleteFeature(data.name)}
                      style={editStyles.chip}
                    />
                  ))}
                </Box>
              </>
            ) : (
              <AutoCompleteInput
                fullWidth
                clearOnBlur
                disableClearable
                options={userOptions}
                label="Wybierz użytkownika, któremu chcesz przypisać biurko"
                noOptionsText="Brak użytkownika"
                name="owner"
                sx={{ pt: '0.3rem' }}
                isOptionEqualToValue={(option, newValue) => {
                  return option.inputValue === newValue.inputValue;
                }}
              />
            )}
          </Box>
        </FormWrapper>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.cancelButton} onClick={onCancelHandler}>
            Anuluj
          </Button>
          <Button
            sx={deskReservationStyles.saveButton}
            form="edit-desk-form"
            type={'submit'}
          >
            Zapisz
          </Button>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default EditDesk;
