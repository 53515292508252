export enum KudosType {
  thankYou = 1,
  congratulations = 2,
  goodJob = 3,
}

export interface Kudos {
  id: number;
  description: string;
  createDate: string;
  kudosType: number;
  sent: boolean;
  user: number;
}

export interface KudosGetResponse {
  pageCount: number;
  hasNext: boolean;
  nexPageNumber: number;
  result: Array<Kudos>;
}
