import { memo, useContext } from 'react';

import AppDialog from 'components/AppDialog';
import ModalContext, { KudosModalType } from 'contexts/Modal/ModalContext';
import { PreviewKudosModalProps } from './PreviewKudosModal.types';
import KudosCard from 'pages/Kudos/components/KudosCard';
import { Box } from '@mui/material';

const PreviewKudosModal = memo((props: PreviewKudosModalProps) => {
  const { closeModal, currentModal } = useContext(ModalContext);
  const { kudos, setKudos } = props;

  const onCloseHandler = () => {
    setKudos(undefined);
    closeModal();
  };

  return (
    <AppDialog
      title={''}
      actions={[]}
      open={currentModal === KudosModalType.PreviewKudos}
      onClose={onCloseHandler}
    >
      <Box sx={{ padding: '0.5rem 2rem' }}>
        {kudos && (
          <KudosCard
            type={kudos.kudosType}
            value={kudos.description}
            height="15rem"
            disabledInput
          />
        )}
      </Box>
    </AppDialog>
  );
});

PreviewKudosModal.displayName = 'PreviewKudosModal';

export default PreviewKudosModal;
