import { API_URLS } from 'api/axios/axios.consts';
import { apiPost } from 'api/axios';
import { useMutation } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import { useDeskReservation } from 'hooks/useDeskReservation/useDeskReservation';
import { MutateDeskReservationProps } from 'pages/DeskReservation/DeskReservation.types';
import useDesk from 'hooks/useDesk';
import queryClient from 'api/queryClient';
import RoomReservationContext from 'contexts/RoomReservation/RoomReservationContext';
import useReservationsForDesk from 'hooks/useReservationsForDesk';

const useMutateDeskReservation = () => {
  const { refetchReservations } = useDeskReservation();
  const { desk, refetchDesk } = useDesk();
  const { refetchReservationsForDesk } = useReservationsForDesk(desk?.id);
  const { month } = useContext(RoomReservationContext);

  const [isCancelReservationModalOpen, setIsCancelReservationModalOpen] =
    useState(false);

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ dates, desk }: MutateDeskReservationProps) => {
      return apiPost(API_URLS.deskCancelReservations, { dates, desk });
    },
    onSuccess: () => {
      enqueueSnackbar('Desks reservation successfully deleted', {
        type: SnackbarType.success,
      });
      refetchReservationsForDesk();
      refetchReservations();
      refetchDesk();
      queryClient.removeQueries({
        queryKey: [API_URLS.deskReservations, month],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete reservation', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ dates, desk }: MutateDeskReservationProps) => {
      return apiPost(API_URLS.deskReservations, { dates, desk });
    },
    onSuccess: () => {
      enqueueSnackbar('Desks reservation successfully added', {
        type: SnackbarType.success,
      });
      refetchReservationsForDesk();
      refetchReservations();
      refetchDesk();
      queryClient.removeQueries({
        queryKey: [API_URLS.deskReservations, month],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add reservation', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    postMutate,
    isCancelReservationModalOpen,
    setIsCancelReservationModalOpen,
    deleteMutate,
  };
};

export default useMutateDeskReservation;
