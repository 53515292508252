import { createTheme } from '@mui/material/styles';
import { BreakPoints } from './Theme.types';
import { COLORS } from './Theme.const';
import { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    Mobile: true;
    Tablet: true;
    Desktop: true;
  }

  interface TypographyVariants {
    sub1: CSSProperties;
    sub2: CSSProperties;
    extra: CSSProperties;
    caption: CSSProperties;
  }

  interface TypographyVariantsOptions {
    sub1?: CSSProperties;
    sub2?: CSSProperties;
    extra?: CSSProperties;
    caption?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sub1: true;
    sub2: true;
    extra: true;
    caption: true;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
  }
}

// It is used for CardHeader as well
export const typography = {
  h1: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '28px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
  sub1: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
  sub2: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
  extra: {
    fontSize: 36,
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '54px',
    wordBreak: 'break-word',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
  },
} as const;

const { h1, body1, body2, sub1, sub2, extra, caption } = typography;

const themeConfig = {
  palette: {
    primary: {
      main: COLORS.blue[600],
      light: COLORS.white,
    },
    secondary: {
      main: COLORS.gray2,
      light: COLORS.white,
    },
    action: {
      disabledOpacity: 0.6,
      disabledBackground: COLORS.gray[100],
    },
    text: {
      primary: COLORS.black,
      secondary: COLORS.gray2,
    },
  },
  typography: {
    h1,
    body1,
    body2,
    sub1,
    sub2,
    extra,
    caption,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 'auto',
        },
        label: {
          display: 'flex',
          justifyContent: 'center',
          fontSize: 14,
          padding: '0.125rem 0.5rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Poppins", sans-serif',
          textTransform: 'none',
          padding: '0.875rem 1.5rem',
          fontSize: '1rem',
          lineHeight: '1.25rem',
          borderRadius: 6,
        },
        sizeSmall: {
          padding: '0.75rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 0.6,
          },
        },
        colorPrimary: {
          'background': COLORS.blue[600],
          '&:hover': {
            background: COLORS.blue[600],
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: COLORS.blue[500],
        colorSecondary: COLORS.white,
        fontSizeSmall: 15,
        fontSizeMedium: 30,
        fontSizeLarge: 45,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 10,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          'color': COLORS.white,

          '&:hover': {
            color: COLORS.blue[300],
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          border: COLORS.blue[100],
          borderRadius: 20,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          ...h1,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: '100%',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: '100%',
          gap: '1rem',
          justifyContent: 'flex-end',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Poppins", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fieldSet: {
            fontFamily: '"Poppins", sans-serif',
            borderColor: COLORS.blue[200],
            borderRadius: 10,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: COLORS.gray[200],
          },
        },
        root: {
          '& .Mui-disabled svg': {
            color: COLORS.gray[200],
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: '"Poppins", sans-serif',
          borderRadius: '1.25rem',
          border: `1px solid ${COLORS.shadow}`,
          background: COLORS.white,
          boxShadow: '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          ...h1,
          color: COLORS.white,
          fontFamily: 'inherit',
          textAlign: 'center',
        },
        subheader: {
          ...sub2,
          color: COLORS.white,
          fontFamily: 'inherit',
          textAlign: 'center',
        },
        root: {
          backgroundColor: COLORS.blue[500],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          minWidth: '18.75rem',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          // For some reason this still being overwritten without important
          '& .Mui-disabled': {
            color: `${COLORS.gray[200]} !important`,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: COLORS.gray[200],
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          'borderRadius': '0.75rem',
          'justifyContent': 'center',
          'boxShadow': '0px 0.188rem 0.188rem 0px rgba(0, 0, 0, 0.16)',
          '&::before': {
            display: 'none',
          },
        },
        content: {
          gap: '2rem',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '2rem',
          backgroundColor: COLORS.blue[100],
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: '"Poppins", sans-serif',
          textTransform: 'none',
          padding: '0.5rem 0.5rem',
          fontSize: '0.75rem',
          lineHeight: '1rem',
        },
      },
    },
  },
  breakpoints: {
    values: {
      Mobile: BreakPoints.Mobile,
      Tablet: BreakPoints.Tablet,
      Desktop: BreakPoints.Desktop,
    },
  },
} as const;

// This config will ensure that generated print will always be on desktop size
const printConfig = {
  ...themeConfig,
  breakpoints: {
    values: {
      Mobile: -1,
      Tablet: -1,
      Desktop: 0,
    },
  },
} as const;

// Theme for Mui v5, after refactors are done remove old one (Theme.js) and scss
const theme = createTheme(themeConfig);

export const printTheme = createTheme(printConfig);

export default theme;
