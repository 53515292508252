import { SxStylesheet } from 'theme/index';
import { typography } from 'theme/Theme';

const styles: SxStylesheet = {
  root: {
    width: '12rem',
    borderRadius: '1rem',
  },
  header: {
    padding: '0.5rem',
    minHeight: '3.5rem',
  },
  content: {
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    ...typography.sub2,
  },
};

export default styles;
