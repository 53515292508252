import { z } from 'zod';
import {
  errorMessagesPL,
  MAX_KUDOS_MESSAGE_LENGTH,
} from 'common/consts/validation';
import { option } from './types';

const kudosFormValidator = z.object({
  user: z.object(option, {
    required_error: errorMessagesPL.required,
    invalid_type_error: errorMessagesPL.required,
  }),
  message: z
    .string()
    .min(1, errorMessagesPL.required)
    .max(
      MAX_KUDOS_MESSAGE_LENGTH,
      errorMessagesPL.maxCharacters(MAX_KUDOS_MESSAGE_LENGTH)
    ),
});

export default kudosFormValidator;
