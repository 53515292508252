import { RoomReservation } from 'api/types/roomReservation';
import CancelRoomReservationModal from '../CancelRoomReservation/CancelRoomReservationModal';
import NoRoomReservationCard from '../NoRoomReservationCard';
import OtherUserReservedRoom from '../OtherUserReserverdRoom/OtherUserReservedRoom';
import ReserveRoomModal from '../ReserveRoomModal/ReserveRoomModal';
import { FC, useContext, useMemo } from 'react';
import RoomReservationContext, {
  ModalType,
} from 'contexts/RoomReservation/RoomReservationContext';
import ReservedDesksModal from '../ReservedDesksModal/ReservedDesksModal';

const CardContent: FC = () => {
  const {
    roomReservationToCancel,
    setRoomReservationToCancel,
    selectedDate,
    modal,
  } = useContext(RoomReservationContext);

  return useMemo(() => {
    switch (true) {
      case modal === ModalType.CreateReservation:
        return <ReserveRoomModal dateToReserve={selectedDate} />;
      case modal === ModalType.OtherReservation:
        return <OtherUserReservedRoom />;
      case modal === ModalType.CancelReservation:
        return (
          <CancelRoomReservationModal
            roomReservationToCancel={roomReservationToCancel as RoomReservation}
            setRoomReservationToCancel={setRoomReservationToCancel}
          />
        );
      case modal === ModalType.DeskReservations:
        return <ReservedDesksModal />;
      default:
        return <NoRoomReservationCard />;
    }
  }, [
    modal,
    roomReservationToCancel,
    selectedDate,
    setRoomReservationToCancel,
  ]);
};

export default CardContent;
