import './App.scss';

import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import ThemeV5 from './theme';
import Router from './router/Router';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './api/queryClient';
import AuthProvider from 'contexts/Auth/AuthProvider';
import ModalProvider from 'contexts/Modal/ModalProvider';
import Snackbars from 'components/Snackbars';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  });
}

export const App = () => {
  return (
    <ThemeProviderV5 theme={ThemeV5}>
      <Snackbars />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProviderV5>
  );
};

export default App;
