const styles = {
  dialog: {
    padding: {
      Tablet: '3rem',
      Mobile: '1.5rem',
    },
  },
  closeButton: {
    padding: 0,
    height: 'fit-content',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.4rem',
    gap: '1.5rem',
  },
  checkboxArea: {
    display: 'grid',
    gap: '0.5rem 0',
    gridTemplateColumns: {
      Desktop: 'repeat(3, minmax(200px, 300px))',
      Tablet: 'repeat(2, minmax(200px, 300px))',
      Mobile: 'fit-content',
    },
  },
};
export default styles;
