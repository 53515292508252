import { COLORS } from 'theme/index';

const styles = {
  descriptionContainer: {
    display: 'flex',
    gap: `1rem 4rem`,
    flexDirection: {
      Desktop: 'row',
      Tablet: 'row',
      Mobile: 'column',
    },
  },
  descriptionColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  technologiesContainer: {
    display: 'flex',
    gap: '0.975rem 0.5rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  technologyChip: {
    padding: '0.25rem 1rem',
    borderRadius: '1.688rem',
    backgroundColor: COLORS.blue[500],
  },
};

export default styles;
