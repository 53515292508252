import { InputAdornment, TextField } from '@mui/material';
import { FC } from 'react';
import { SearchFieldProps } from './SearchField.types';
import styles from './SearchField.styles';
import { Search } from '@mui/icons-material';

const SearchField: FC<SearchFieldProps> = ({ label, onChange }) => {
  return (
    <TextField
      label={label}
      sx={styles.search}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
    />
  );
};

export default SearchField;
