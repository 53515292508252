import { formatLongDate } from 'utils/date';
import { addDays, getYear } from 'date-fns';

// TODO: Use i18n instead of those objects
export const errorMessages = {
  required: 'This field is required',
  languageAlreadyAdded: 'This language has been already added',
  maxCharacters: (max: number) => `Please enter no more than ${max} characters`,
  minDate: (date: Date) => `Please select date after ${formatLongDate(date)}`,
  maxDate: (date: Date) =>
    `Please select date before ${formatLongDate(addDays(date, 1))}`,
  invalidRange: 'Invalid range',
  existing: 'This value is already added',
  nonNegativeValue: 'Minimum value is 0',
  minYear: (year: number) => `Please provide year greater than ${year}`,
};

export const errorMessagesPL = {
  required: 'To pole jest wymagane',
  maxCharacters: (max: number) => `Wpisz proszę nie więcej niż ${max} znaków`,
};

// maximum values
export const MAX_CERTIFICATE_LENGTH = 100;
export const MAX_CITY_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 3000;
export const MAX_EDUCATION_LENGTH = 100;
export const MAX_NAME_LENGTH = 50;
export const MAX_PROJECT_LENGTH = 100;
export const MAX_SKILL_LENGTH = 50;
export const MAX_TECHNOLOGY_LENGTH = 50;
export const MAX_KUDOS_MESSAGE_LENGTH = 1000;

// minimum values
export const MIN_DATE = new Date('1900-01-01');
export const MIN_HOLIDAY_POOL = 0;
export const MIN_YEAR = getYear(MIN_DATE);
