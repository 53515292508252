import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import useCustomNavigation from 'src/router/useNavigation';
import { useContext } from 'react';
import OfferContext from 'contexts/Offer/OfferContext';
import { Worker } from 'api/types/worker';

export const fetchOfferWorker = (
  context: QueryFunctionContext<[string, string, number | undefined]>
) => {
  const [, offerName = '', activeUser] = context.queryKey;

  return apiGet<Worker>(`${API_URLS.offer}${offerName}/worker/${activeUser}/`);
};

const useOfferWorker = () => {
  const { params } = useCustomNavigation();
  const { offerName } = params as { offerName: string };
  const { activeUser } = useContext(OfferContext);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_URLS.offer, offerName, activeUser],
    queryFn: fetchOfferWorker,
    enabled: activeUser !== undefined,
  });

  return {
    worker: data?.data,
    isLoading,
    refetchWorker: refetch,
  };
};

export default useOfferWorker;
