import { useMutation, useQuery } from '@tanstack/react-query';
import { OfferSettingsI } from 'api/types/offer';
import { apiGet, apiPut } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { Dispatch, SetStateAction, useContext } from 'react';
import useOffer from 'hooks/useOffer';
import useOfferWorker from 'hooks/useOfferWorker';
import OfferContext from 'contexts/Offer/OfferContext';
import useOfferWorkers from './useOfferWorkers';

const useOfferSettings = (
  setCurrentOfferSettings?: Dispatch<
    SetStateAction<OfferSettingsI | undefined>
  >,
  offerUrl?: string
) => {
  const { data, isLoading } = useQuery({
    queryKey: ['settings', API_URLS.offer, offerUrl || ''],
    queryFn: () =>
      apiGet<OfferSettingsI>(`${API_URLS.offer}${offerUrl}/settings/`).then(
        (response) => {
          if (setCurrentOfferSettings) setCurrentOfferSettings(response?.data);

          return response;
        }
      ),
  });

  return {
    data: data?.data,
    isLoading,
  };
};

export const useMutateOfferSettings = () => {
  const { setOfferSettings } = useContext(OfferContext);
  const { refetchOffer, offerName } = useOffer();
  const { refetchWorker } = useOfferWorker();
  const { refetchWorkers } = useOfferWorkers();

  const { mutate } = useMutation({
    mutationFn: (data: OfferSettingsI) =>
      apiPut<OfferSettingsI>(`${API_URLS.offer}${offerName}/settings/`, data),
    onSuccess: async (data) => {
      if (setOfferSettings) {
        setOfferSettings(data.data);
        await Promise.all([refetchOffer(), refetchWorker(), refetchWorkers()]);
      }
    },
  });

  return mutate;
};

export default useOfferSettings;
