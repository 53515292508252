import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { WorkerDetails } from 'api/types/worker';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

export const fetchProfile = (
  context: QueryFunctionContext<[string, number]>
) => {
  const [, userId = 0] = context.queryKey;

  return apiGet<WorkerDetails>(`${API_URLS.profile}${userId}/`);
};

const useProfile = () => {
  const { userId } = useActiveWorker();

  const query = useQuery({
    queryKey: [API_URLS.profile, userId ?? 0],
    queryFn: fetchProfile,
    enabled: userId !== undefined,
  });

  return {
    ...query,
    data: query.data?.data,
    refetchProfile: query.refetch,
  };
};

export default useProfile;
