import { Box, Typography } from '@mui/material';
import { memo, useContext, useState, Fragment } from 'react';

import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import EmptySection from '../../EmptySection';

import { SocialSecurityBenefitsProps } from './SocialSecurityBenefit.types';
import styles from '../AdminProfile.styles';
import SocialSecurityBenefitFormDialog from './SocialSecurityBenefitFormDialog/SocialSecurityBenefitFormDialog';
import SocialSecurityBenefit from './SocialSecurityBenefit';

const SocialSecurityBenefits = memo(
  ({ socialSecurityBenefits }: SocialSecurityBenefitsProps) => {
    const { currentModal, closeModal, openModal } = useContext(ModalContext);

    const [editId, setEditId] = useState<number>();

    const onCloseModal = () => {
      closeModal();
      setEditId(undefined);
    };

    return (
      <Box>
        <Typography component="h1" variant="h1">
          Social security benefits
        </Typography>
        <Box sx={styles.contentWrapper}>
          {socialSecurityBenefits && socialSecurityBenefits?.length > 0 ? (
            socialSecurityBenefits?.map((socialSecurityBenefit) => (
              <Fragment
                key={`${socialSecurityBenefit.id}-socialSecurityBenefit`}
              >
                <SocialSecurityBenefit
                  key={socialSecurityBenefit.id}
                  socialSecurityBenefit={socialSecurityBenefit}
                  handleEditSocialSecurityBenefit={() => {
                    setEditId(socialSecurityBenefit.id);
                    openModal(AdminProfileModalType.EditSocialSecurityBenefit);
                  }}
                />

                {currentModal ===
                  AdminProfileModalType.EditSocialSecurityBenefit &&
                  editId === socialSecurityBenefit.id && (
                    <SocialSecurityBenefitFormDialog
                      socialSecurityBenefit={socialSecurityBenefit}
                      onClose={onCloseModal}
                    />
                  )}
              </Fragment>
            ))
          ) : (
            <EmptySection />
          )}
        </Box>
        {currentModal === AdminProfileModalType.AddSocialSecurityBenefit && (
          <SocialSecurityBenefitFormDialog onClose={onCloseModal} />
        )}
      </Box>
    );
  }
);

SocialSecurityBenefits.displayName = 'SocialSecurityBenefits';

export default SocialSecurityBenefits;
