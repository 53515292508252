import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { UserDesksReservations } from 'api/types/deskReservation';
import { API_URLS } from 'api/axios/axios.consts';
import { useContext } from 'react';
import AuthContext from 'contexts/Auth/AuthContext';

const fetchMyReservations = (userId?: number) =>
  apiGet<Array<UserDesksReservations>>(
    `${API_URLS.deskReservations}?user=${userId}`
  );

export const useDeskReservation = () => {
  const { id } = useContext(AuthContext);

  const {
    data: myReservations,
    refetch: refetchReservations,
    isLoading,
  } = useQuery({
    queryKey: [API_URLS.desks, id],
    queryFn: () => fetchMyReservations(id),
    enabled: !!id,
  });

  return {
    myReservations: myReservations && myReservations?.data,
    refetchReservations,
    isLoading,
  };
};

export default useDeskReservation;
