import useFieldsOfStudy from './useFieldsOfStudy';
import useSchools from './useSchools';

const useEducation = (enabled?: boolean) => {
  const {
    data: fieldsOfStudy,
    isLoading: isLoadingFieldsOfStudy,
    refetch: refetchFieldsOfStudy,
  } = useFieldsOfStudy(enabled);

  const {
    data: schools,
    isLoading: isLoadingSchools,
    refetch: refetchSchools,
  } = useSchools(enabled);

  return {
    data: {
      fieldsOfStudy,
      schools,
    },
    refetch: () => Promise.all([refetchFieldsOfStudy(), refetchSchools()]),
    isLoading: isLoadingFieldsOfStudy || isLoadingSchools,
  };
};

export default useEducation;
