import { FC } from 'react';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import { COLORS } from 'theme/index';
import styles from './AppDialog.styles';
import { AppDialogProps } from './AppDialog.types';

const AppDialog: FC<AppDialogProps> = ({
  title,
  actions,
  children,
  onClose,
  blockBackdropClick,
  PaperProps = {},
  ...props
}) => (
  <Dialog
    PaperProps={{ sx: { width: '64.75rem' }, ...PaperProps }}
    onClose={!blockBackdropClick ? onClose : undefined}
    {...props}
  >
    <DialogTitle sx={styles.dialogTitle(title === '')}>
      {title}
      <IconButton onClick={onClose} sx={styles.closeButton} aria-label="close">
        <Close sx={{ color: COLORS.action }} />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={styles.dialogContent}>{children}</DialogContent>
    <DialogActions sx={styles.dialogActions}>{actions}</DialogActions>
  </Dialog>
);

export default AppDialog;
