import { useQuery } from '@tanstack/react-query';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { peopleApiGet } from 'api/axios';
import { AxiosError } from 'axios';
import { GetUserHolidayPoolResponse } from './useUserHolidayPool.types';
import { HttpStatusCode } from 'axios';

export const fetchUsersHolidayPool = async () => {
  try {
    const response = await peopleApiGet<GetUserHolidayPoolResponse>(
      PEOPLE_API_URLS.holidayPool
    );

    return response.data.results;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (
        [HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(
          error.response?.status || 0
        )
      ) {
        return [];
      }
    }
    console.error(error);
    throw error;
  }
};

export const useUsersHolidayPool = () => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [PEOPLE_API_URLS.holidayPool],
    queryFn: fetchUsersHolidayPool,
  });

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
