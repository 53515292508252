import { FC, PropsWithChildren, useState } from 'react';
import ProjectsDepartmentsContext, {
  RadioValue,
} from './ProjectsDepartmentsContext';
import { Nullable, Option } from 'common/types';

const ProjectsDepartmentsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [radioValue, setRadioValue] = useState<RadioValue>('projects');
  const [optionProject, setOptionProject] =
    useState<Nullable<Option<string, string>>>(null);
  const [optionDepartment, setOptionDepartment] =
    useState<Nullable<Option<string, string>>>(null);

  return (
    <ProjectsDepartmentsContext.Provider
      value={{
        radioValue,
        setRadioValue,
        optionProject,
        setOptionProject,
        optionDepartment,
        setOptionDepartment,
      }}
    >
      {children}
    </ProjectsDepartmentsContext.Provider>
  );
};

export default ProjectsDepartmentsProvider;
