import { COLORS } from 'src/theme';

const styles = {
  text: {
    marginBottom: '2rem',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1800px',
    paddingY: 0,
  },
  saveButton: {
    'color': COLORS.white,
    'backgroundColor': COLORS.blue[600],
    '&.Mui-disabled': {
      backgroundColor: COLORS.disabledBackground,
      color: COLORS.white,
    },
    'width': '90%',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: {
      Desktop: `2fr 1fr`,
      Tablet: `1fr 1fr`,
      Mobile: '1fr',
    },
    gap: '1rem',
    alignItems: 'start',
  },
  map: {
    minHeight: { Desktop: '960px' },
  },
  rightColumn: {
    display: 'grid',
    gap: '0.5rem',
  },
  rightColumnContainer: {
    display: 'grid',
    width: {
      Desktop: 'auto',
      Tablet: 'auto',
      Mobile: '380px',
    },
  },
  image: {
    borderRadius: '1rem',
    maxWidth: '100%',
  },
};

export default styles;
