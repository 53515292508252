import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { MutateProfilePatchProps } from './useProfile.types';
import useProfile from 'hooks/useProfile/useProfile';
import useProfileList from 'hooks/useProfileList/useProfileList';
import AuthContext from 'contexts/Auth/AuthContext';
import { useContext } from 'react';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

const getProfileImageUrl = (userId: number) =>
  `${API_URLS.profile}${userId}${API_URLS.profileImage}`;

export const useMutateProfile = () => {
  const { isStaff } = useContext(AuthContext);
  const { data, refetchProfile } = useProfile();
  const { refetchProfileList } = useProfileList();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ userId, profile, image }: MutateProfilePatchProps) => {
      const localImage = data?.image;

      let imgPromise = Promise.resolve();

      if (image !== undefined) {
        if (localImage && !image) {
          imgPromise = apiDelete(`${getProfileImageUrl(userId)}remove-image/`);
        } else {
          const data = [
            { image },
            { headers: { 'content-type': 'multipart/form-data' } },
          ] as const;

          imgPromise = apiPost(getProfileImageUrl(userId), ...data);
        }
      }

      return Promise.all([
        apiPatch(`${API_URLS.profile}${userId}/`, profile),
        imgPromise,
      ]);
    },
    onSuccess: () => {
      enqueueSnackbar('Profile successfully changed', {
        type: SnackbarType.success,
      });

      return Promise.all([refetchProfile(), isStaff && refetchProfileList()]);
    }, // TODO: Check later if this check is needed
  });

  return {
    patchMutate,
  };
};

export default useMutateProfile;
