const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  projectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginBottom: '2rem',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
};

export default styles;
