import { Box } from '@mui/material';
import { CalendarToday, EventBusy } from '@mui/icons-material';

import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import ActionButtons from 'pages/Profile/components/ActionButtons';
import IconLabel from 'components/IconLabel/IconLabel';
import { Label } from '@mui/icons-material';

import styles from './SocialSecurityBenefit.styles';
import adminProfileStyles from '../AdminProfile.styles';
import {
  SocialSecurityBenefitProps,
  SocialSecurityBenefitsTypeDict,
} from './SocialSecurityBenefit.types';
import useMutateUserSocialSecurityBenefits from 'hooks/useSocialSecurityBenefits/useMutateUserSocialSecurityBenefits';
import { SocialSecurityBenefitType } from 'api/types/socialSecurityBenefits';
import InfoIcon from '../InfoIcon';

const SocialSecurityBenefit = ({
  socialSecurityBenefit,
  handleEditSocialSecurityBenefit,
}: SocialSecurityBenefitProps) => {
  const { socialSecurityBenefitDeleteMutate } =
    useMutateUserSocialSecurityBenefits();
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () =>
      socialSecurityBenefit.id &&
      socialSecurityBenefitDeleteMutate({
        socialSecurityBenefitId: socialSecurityBenefit.id,
        isCancelled: true,
      }),
    title: `Are you sure you want to delete this contract?`,
  });
  const { dateFrom, dateTo, type, addInfo, isCancelled } =
    socialSecurityBenefit;

  return (
    <Box sx={styles.infoGrid}>
      <IconLabel
        iconStyle={isCancelled ? adminProfileStyles.cancelledIcon : {}}
        Icon={isCancelled ? EventBusy : CalendarToday}
        label={`${dateFrom} - ${dateTo}`}
      />
      <IconLabel
        Icon={Label}
        label={`typ: ${SocialSecurityBenefitsTypeDict[type as SocialSecurityBenefitType]}`}
      />
      <InfoIcon addInfo={addInfo} />
      <ActionButtons
        isStart
        onDeleteHandler={!isCancelled ? openConfirmationModal : undefined}
        onEditHandler={handleEditSocialSecurityBenefit}
      />
      <ConfirmationDialog />
    </Box>
  );
};

export default SocialSecurityBenefit;
