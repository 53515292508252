const styles = {
  root: {
    marginX: {
      Desktop: '15rem',
      Tablet: '10rem',
      Mobile: '0',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
};

export default styles;
