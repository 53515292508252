import { Box, Typography, TypographyProps } from '@mui/material';
import { styles } from './UserHolidaysPoolSummary.styles';
import {
  UserHolidaysPoolSummaryProps,
  UserHolidaysPoolSummaryStyle,
} from './UserHolidaysPoolSummary.types';

interface summaryEntryProps extends TypographyProps {
  label: string;
  value: string | number;
}

const SummaryEntry = ({ label, value, ...props }: summaryEntryProps) => {
  return (
    <Box sx={styles.summaryEntry}>
      <Typography variant="body2" {...props}>
        {label}: {value}
      </Typography>
    </Box>
  );
};

export const UserHolidaysPoolSummary = ({
  type = UserHolidaysPoolSummaryStyle.ShortWithName,
  holidaysPool,
}: UserHolidaysPoolSummaryProps) => {
  return (
    <Box sx={styles.root}>
      {type === UserHolidaysPoolSummaryStyle.Short ? (
        <SummaryEntry
          label={`Rok ${holidaysPool.year}`}
          value=""
          fontWeight="bold"
        />
      ) : null}
      {type === UserHolidaysPoolSummaryStyle.ShortWithName ? (
        <SummaryEntry
          label={`${holidaysPool.userData.firstName} ${holidaysPool.userData.firstName}`}
          value=""
        />
      ) : null}
      {type === UserHolidaysPoolSummaryStyle.Full ? (
        <>
          <SummaryEntry
            label={`Rok ${holidaysPool.year}`}
            value=""
            fontWeight="bold"
          />
          <SummaryEntry label="TWOJA PULA URLOPOWA" value={holidaysPool.days} />
          <SummaryEntry
            label={`URLOP Z ${holidaysPool.year - 1}`}
            value={holidaysPool.daysFromPreviousYear}
          />
          <SummaryEntry
            label={`URLOP NA ${holidaysPool.year}`}
            value={holidaysPool.fullHolidayPool}
          />
          <SummaryEntry
            label="URLOP FIRMOWY"
            value={holidaysPool.acceptedCompanyHolidaysDays}
          />
        </>
      ) : null}
      <SummaryEntry label="WYKORZYSTANE" value={holidaysPool.usedHolidayPool} />
      <SummaryEntry
        label="DO WYKORZYSTANIA"
        value={holidaysPool.leftHolidayPool}
      />
    </Box>
  );
};
