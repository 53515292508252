import { useMutation } from '@tanstack/react-query';
import { apiPost } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import useProfile from 'hooks/useProfile/useProfile';
import { MutateSkillNameProps } from './useSkills.types';
import useSkills from './useSkills';

export const useMutateSkills = () => {
  const { refetchProfile } = useProfile();
  const { refetch: refetchSkillNames } = useSkills();

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ userId, skills }: MutateSkillNameProps) =>
      apiPost(
        `${API_URLS.skillNames}${
          userId !== undefined ? `?user=${userId}` : ''
        }`,
        skills
      ),
    onSuccess: (_, { refetch = true, userId }) => {
      if (refetch) {
        refetchSkillNames();
        if (userId !== undefined) refetchProfile();
      }
    },
  });

  return {
    postMutate,
  };
};

export default useMutateSkills;
