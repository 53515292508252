import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import styles from './SkillsDialog.styles';
import useSkillsDialog from './useSkillsDialog';
import FormWrapper from 'components/FormWrapper';
import MultiCheckboxInput from 'components/MultiCheckboxInput';
import { useMemo, useState } from 'react';
import SearchField from 'components/SearchField';
import ButtonRow from './ButtonRow';
import { ProfileModalType } from 'contexts/Modal/ModalContext';

const SkillsDialog = () => {
  const [filterLanguages, setFilterLanguages] = useState('');
  const [filterTechnical, setFilterTechnical] = useState('');
  const [filterManagement, setFilterManagement] = useState('');
  const [filterSoftSkills, setFilterSoftSkills] = useState('');

  const onClose = () => {
    setFilterLanguages('');
    setFilterTechnical('');
    setFilterManagement('');
    setFilterSoftSkills('');
  };

  const {
    type,
    isOpen,
    closeModal,
    methods,
    onSubmitHandler,
    addNewSkill,
    options,
    isLoading,
  } = useSkillsDialog({ onClose });

  const languagesOptions = useMemo(
    () =>
      options['Programming Languages'].filter(({ label }) =>
        label.toLowerCase().includes(filterLanguages.toLowerCase())
      ),
    [filterLanguages, options]
  );

  const technicalOptions = useMemo(
    () =>
      options['Technical'].filter(({ label }) =>
        label.toLowerCase().includes(filterTechnical.toLowerCase())
      ),
    [filterTechnical, options]
  );

  const managementOptions = useMemo(
    () =>
      options['Management'].filter(({ label }) =>
        label.toLowerCase().includes(filterManagement.toLowerCase())
      ),
    [filterManagement, options]
  );

  const softSkillsOptions = useMemo(
    () =>
      options['Soft Skills'].filter(({ label }) =>
        label.toLowerCase().includes(filterSoftSkills.toLowerCase())
      ),
    [options, filterSoftSkills]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{ sx: styles.dialog }}
    >
      <DialogTitle>
        {`Select ${
          type === ProfileModalType.AddSkill
            ? 'skills'
            : 'programming languages'
        }`}
        <IconButton
          onClick={closeModal}
          sx={styles.closeButton}
          aria-label="close"
        >
          <Close color="action" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormWrapper
          methods={methods}
          id="skills-form"
          isLoading={isLoading}
          onSubmit={onSubmitHandler}
          sx={styles.formWrapper}
        >
          {type === ProfileModalType.AddLanguage ? (
            <>
              <SearchField
                label="Search"
                onChange={(e) => setFilterLanguages(e.target.value)}
              />
              <MultiCheckboxInput
                name="Programming Languages"
                sx={styles.checkboxArea}
                options={languagesOptions}
              />
              <ButtonRow
                skillCategory="Programming Languages"
                addNewSkill={() => addNewSkill('Programming Languages')}
              />
            </>
          ) : (
            <>
              <Typography variant="sub1">Technologies</Typography>
              <SearchField
                label="Search"
                onChange={(e) => setFilterTechnical(e.target.value)}
              />
              <MultiCheckboxInput
                name="Technical"
                sx={styles.checkboxArea}
                options={technicalOptions}
              />
              <ButtonRow
                skillCategory="Technical"
                addNewSkill={() => addNewSkill('Technical')}
              />
              <Typography variant="sub1" sx={{ marginTop: 1 }}>
                Management
              </Typography>
              <SearchField
                label="Search"
                onChange={(e) => setFilterManagement(e.target.value)}
              />
              <MultiCheckboxInput
                name="Management"
                sx={styles.checkboxArea}
                options={managementOptions}
              />
              <ButtonRow
                skillCategory="Management"
                addNewSkill={() => addNewSkill('Management')}
              />
              <Typography variant="sub1" sx={{ marginTop: 1 }}>
                Soft skills
              </Typography>
              <SearchField
                label="Search"
                onChange={(e) => setFilterSoftSkills(e.target.value)}
              />
              <MultiCheckboxInput
                name="Soft Skills"
                sx={styles.checkboxArea}
                options={softSkillsOptions}
              />
              <ButtonRow
                skillCategory="Soft Skills"
                addNewSkill={() => addNewSkill('Soft Skills')}
              />
            </>
          )}
        </FormWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          form="skills-form"
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkillsDialog;
