import { z } from 'zod';

import { LanguageLevel } from 'api/types/languages';
import {
  errorMessages,
  MAX_DESCRIPTION_LENGTH,
  MIN_DATE,
} from 'common/consts/validation';
import { option } from './types';

const editProfileValidator = z.intersection(
  z.discriminatedUnion('calculateFromProjects', [
    z.object({
      calculateFromProjects: z.literal(true),
      experienceSince: z.null(),
    }),
    z.object({
      calculateFromProjects: z.literal(false),
      experienceSince: z
        .date({
          invalid_type_error: errorMessages.required,
          required_error: errorMessages.required,
        })
        .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
        .max(new Date(), { message: errorMessages.maxDate(new Date()) }),
    }),
  ]),
  z.object({
    dateOfBirth: z
      .date()
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
      .max(new Date(), { message: errorMessages.maxDate(new Date()) })
      .nullable(),
    description: z
      .string()
      .max(
        MAX_DESCRIPTION_LENGTH,
        errorMessages.maxCharacters(MAX_DESCRIPTION_LENGTH)
      ),
    inRevolve: z
      .date()
      .min(MIN_DATE, { message: errorMessages.minDate(MIN_DATE) })
      .max(new Date(), { message: errorMessages.maxDate(new Date()) })
      .nullable(),
    image: z.instanceof(File).nullish(),
    languages: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        level: z.nativeEnum(LanguageLevel),
      })
    ),
    language: z.optional(z.object(option).nullable()),
    level: z.optional(z.string().nullable()),
  })
);

export default editProfileValidator;
