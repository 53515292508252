import { Nullable } from 'common/types';

export enum SocialSecurityBenefitType {
  L4 = 'l4',
  Care = 'care',
  Maternal = 'maternal',
  Paternal = 'paternal',
  Parental = 'parental',
  ChildCare = 'child_care',
  Rehabilitaion = 'rehabilitation_allowance',
  AdditionalHoliday = 'other',
}

export interface SocialSecurityBenefit {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: string;
  addInfo?: string;
  user: number;
}

export type SocialSecurityBenefits = {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: Nullable<SocialSecurityBenefitType>;
  addInfo: string;
  isCancelled: boolean;
  additionalHolidayId?: number;
  firstName: string;
  lastName: string;
};

export type SocialSecurityBenefitsWithUserModel = {
  id: number;
  firstName: string;
  lastName: string;
  user: number;
  isHalfDay?: boolean;
  isAcceptedByEmployee: boolean;
  isAcceptedByAdmin: boolean;
} & SocialSecurityBenefits;
