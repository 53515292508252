import { EventContentArg } from '@fullcalendar/core';
import { Tooltip, Typography } from '@mui/material';
import { InfoTwoTone } from '@mui/icons-material';
import { COLORS } from 'theme/Theme.const';

const NationalHoliday = (eventInfo: EventContentArg) => {
  return (
    <Tooltip
      title={
        eventInfo.event.extendedProps.additionalInfo || eventInfo.event.title
      }
    >
      <InfoTwoTone htmlColor={COLORS.holiday.nationalIconColor} />
    </Tooltip>
  );
};

const CompanyHoliday = (eventInfo: EventContentArg) => {
  return (
    <Tooltip
      title={
        eventInfo.event.extendedProps.additionalInfo || eventInfo.event.title
      }
    >
      <Typography>{eventInfo.event.title}</Typography>
    </Tooltip>
  );
};

export const CalendarEventRender = (eventInfo: EventContentArg) => {
  if (
    eventInfo.event.classNames.some(
      (className) => className === 'national_holiday'
    )
  ) {
    return NationalHoliday(eventInfo);
  }
  if (
    eventInfo.event.classNames.some(
      (className) => className === 'holiday_comp' || className === 'company'
    )
  ) {
    return CompanyHoliday(eventInfo);
  }

  return (
    <Tooltip
      title={
        eventInfo.event.extendedProps.additionalInfo ||
        eventInfo.event.title ||
        ''
      }
    >
      <Typography>{eventInfo.event.title}</Typography>
    </Tooltip>
  );
};

CalendarEventRender.displayName = 'Calendar Event';
