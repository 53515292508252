import { CSSProperties, memo } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';

import Logo from 'assets/images/placeholder_team.png';

import { WorkerListProps } from './WorkersList.types';
import styles from './WorkersList.styles';
import DisplayWrapper from 'components/DisplayWrapper';

const WorkersList = memo(
  ({ label, workers, selectedWorker, setSelectedWorker }: WorkerListProps) => (
    <DisplayWrapper>
      <Box>
        <Typography variant="h1">{label}</Typography>
        <Box sx={styles.usersContainer}>
          {workers.map(({ id, title, image }) => (
            <Button
              key={`user-${id}`}
              sx={styles.userContainer(id === selectedWorker)}
              variant="outlined"
              onClick={() => setSelectedWorker(id)}
            >
              <Avatar src={image} sx={styles.image}>
                <img
                  src={Logo}
                  alt={`Zdjęcie ${title}`}
                  style={styles.image as CSSProperties}
                />
              </Avatar>
              <Typography variant="sub2">{title}</Typography>
            </Button>
          ))}
        </Box>
      </Box>
    </DisplayWrapper>
  )
);

WorkersList.displayName = 'WorkerList';

export default WorkersList;
