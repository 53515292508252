import { ReactComponent as Human } from 'assets/images/human.svg';
import { useContext, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import useDeskStatus from 'hooks/useDeskStatus';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { formatToBackendDate } from 'utils/date';
import useDesks from 'hooks/useDesks';
import useBreakPoints from 'hooks/useBreakpoints';
import { MapContainerProps } from './types';
import { container, imgStyle } from './MapContainer.styles';

const MapContainer = ({ handleOnPress }: MapContainerProps) => {
  const { allDesks } = useDesks();
  const { getDeskPosition, getIconStyle, getBackgroundImage } = useDeskStatus();
  const { date, setActiveDesk } = useContext(DeskContext);
  const { isMobile } = useBreakPoints();
  const formatedDate = useMemo(() => formatToBackendDate(date as Date), [date]);

  return (
    <Box sx={container}>
      <img style={imgStyle} src={getBackgroundImage(formatedDate)} alt="Map" />
      {allDesks?.map((desk) => (
        <IconButton
          key={desk.id}
          onClick={() => {
            setActiveDesk(desk.id);
            handleOnPress(formatedDate, desk);
          }}
          disabled={isMobile}
          disableRipple
          style={getDeskPosition(desk)}
        >
          <Human style={getIconStyle(formatedDate, desk)} />
        </IconButton>
      ))}
    </Box>
  );
};

export default MapContainer;
