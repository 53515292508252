const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  icon: { height: 15, width: 15 },
  iconWrapper: { display: 'flex', alignItems: 'flex-start', gap: '0.5rem' },
  educationData: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
  },
  schoolDisplay: { display: 'flex', gap: '0.5rem', flexDirection: 'column' },
};

export default styles;
