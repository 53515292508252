import { Box, Typography } from '@mui/material';
import styles from 'pages/DeskReservation/DeskReservation.styles';
import PageContent from 'components/PageContent';
import DisplayWrapper from 'components/DisplayWrapper';
import DateSwitcher from 'pages/DeskReservation/components/DateSwitcher';
import MapContainer from './components/MapContainer';
import { DESK_CONTENT } from 'components/Legend/Legend.consts';
import Legend from 'components/Legend';
import DeskCard from './components/DeskCardContent/DeskCardContent';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { AppBarContent } from 'components/AppBar/AppBar.types';
import workplaceDesk from 'assets/images/workplaceDesk.png';

import { DESK_ADMIN_CONTENT } from 'components/Legend/Legend.consts';
import { FC, useContext, useMemo } from 'react';
import CallToActionComponent from './components/CallToAction/CallToActionComponent';
import useDeskCard from 'hooks/useDeskCard';
import CurrentReservationContent from './components/CurrentReservationsContent/CurrentReservationContent';
import useBreakPoints from 'hooks/useBreakpoints';
import ReserveDesk from './components/ReserveDesk';
import useDeskReservation from 'hooks/useDeskReservation';
import InfoMessageDeskReservation from 'pages/DeskReservation/components/InfoMessageDeskReservation/InfoMessageDeskReservation';
import { formatToBackendDate } from 'utils/date';

const DeskReservation: FC = () => {
  const { editMode } = useContext(DeskContext);
  const { deskCardContent, handleOnPress } = useDeskCard();
  const { isMobile } = useBreakPoints();
  const { date } = useContext(DeskContext);
  const { myReservations } = useDeskReservation();

  const formattedDate = useMemo(() => {
    return formatToBackendDate(date as Date);
  }, [date]);

  const reservationForChosenDate = useMemo(() => {
    return myReservations?.find(
      (myReservation) => myReservation.date === formattedDate
    );
  }, [myReservations, formattedDate]);

  const labelText = editMode
    ? 'Wybierz biurko, które chcesz edytować'
    : 'Wybierz biurko, które chcesz zarezerwować';
  const subText = editMode
    ? ''
    : 'Możesz zarezerwować biurko jedynie na dwa tygodnie do przodu';

  return (
    <PageContent appBarContent={AppBarContent.deskReservation}>
      <Box sx={styles.root}>
        <Typography variant="h1" sx={styles.text}>
          {editMode
            ? 'Zarządzanie Hot Deskami'
            : 'Rezerwacja indywidualnych biurek'}
        </Typography>
        <Box sx={styles.content}>
          <DisplayWrapper>
            {editMode ? null : <DateSwitcher />}
            <Box sx={styles.map}>
              <MapContainer handleOnPress={handleOnPress} />
            </Box>
          </DisplayWrapper>
          <Box sx={styles.rightColumn}>
            {reservationForChosenDate && !editMode && (
              <InfoMessageDeskReservation
                date={date as Date}
                reservationForChosenDate={reservationForChosenDate}
                formattedDate={formattedDate}
              />
            )}
            <CallToActionComponent
              image={workplaceDesk}
              alt="CTA"
              labelText={labelText}
              subText={subText}
            />
            {isMobile ? (
              <ReserveDesk />
            ) : (
              <DeskCard deskCardContent={deskCardContent} />
            )}
            <CurrentReservationContent />
            <Legend
              dotsContentScheme={editMode ? DESK_ADMIN_CONTENT : DESK_CONTENT}
            />
          </Box>
        </Box>
      </Box>
    </PageContent>
  );
};

export default DeskReservation;
