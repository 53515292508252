import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { SkillNamesGetResponse } from 'api/types/skills';

export const fetchSkillNames = () =>
  apiGet<SkillNamesGetResponse>(API_URLS.skillNames);

const useSkills = (enabled?: boolean) => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [API_URLS.skillNames],
    queryFn: fetchSkillNames,
    enabled,
  });

  return {
    data: data?.data,
    refetch,
    isLoading,
    isFetching,
  };
};

export default useSkills;
