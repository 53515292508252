import { Box, Typography } from '@mui/material';
import { memo } from 'react';

import styles from './Legend.styles';

const Attitude = memo(() => (
  <Box sx={styles.section}>
    <Typography variant="sub2">Attitude</Typography>
    <Box sx={styles.content}>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Stay away
        </Typography>
        <Typography variant="body2">Nie chcę z tym pracować</Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Avoid
        </Typography>
        <Typography variant="body2">Wolę tego unikać</Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Neutral
        </Typography>
        <Typography variant="body2">Jest mi obojętne</Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Like
        </Typography>
        <Typography variant="body2">Lubię</Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography variant="body2" sx={styles.label}>
          Love
        </Typography>
        <Typography variant="body2">Bardzo chcę z tym pracować</Typography>
      </Box>
    </Box>
  </Box>
));
Attitude.displayName = 'Attitude';

export default Attitude;
