import { Desk } from 'api/types/deskReservation';
import AuthContext from 'contexts/Auth/AuthContext';
import DeskContext from 'contexts/DeskReservation/DeskContext';

import useDeskReservationsForDay from 'hooks/useDeskReservationsForDay';
import { DeskCardContent } from 'pages/DeskReservation/components/DeskCardContent/DeskCardContent.types';
import { useCallback, useContext, useState } from 'react';

const useDeskCard = () => {
  const { editMode } = useContext(DeskContext);
  const { deskReservations } = useDeskReservationsForDay();
  const [deskCardContent, setDeskCardContent] = useState(
    DeskCardContent.ReserveDesk
  );
  const { id } = useContext(AuthContext);

  const handleOnPress = useCallback(
    (date: string, desk: Desk) => {
      const deskReservation = deskReservations?.find(
        (reservation) =>
          reservation.date === date && reservation.desk === desk.id
      );

      if (editMode) {
        setDeskCardContent(DeskCardContent.EditDesk);
      } else {
        if (deskReservation?.booker !== id) {
          setDeskCardContent(DeskCardContent.OthersDeskReservation);
        }
        if (!desk.isHotdesk) {
          setDeskCardContent(DeskCardContent.NoHotDesk);
        }
        if (
          (!deskReservation && desk.isHotdesk) ||
          deskReservation?.booker === id
        ) {
          setDeskCardContent(DeskCardContent.ReserveDesk);
        }
      }
    },
    [deskReservations, editMode, id]
  );

  return { handleOnPress, deskCardContent };
};
export default useDeskCard;
