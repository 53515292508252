import { COLORS, SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    backgroundColor: COLORS.appBar,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: {
      Tablet: '5rem',
      Mobile: '3.125rem',
    },
    padding: {
      Tablet: '0.9065rem  4rem',
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  drawer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.appBar,
    height: '100%',
    width: {
      Tablet: 350,
      Mobile: '100%',
    },
    gap: '1rem',
    padding: '2.875rem 1.75rem',
  },
  drawerRow: {
    'color': 'primary.light',
    'backgroundColor': COLORS.transparent,

    '&:hover': {
      color: COLORS.blue[300],
    },

    '&.Mui-selected': {
      'backgroundColor': COLORS.transparent,
      'color': COLORS.blue[300],

      '&:hover': {
        backgroundColor: COLORS.transparent,
      },
    },
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'gap': '0.5rem',
    'minHeight': 0,
  },
  drawerIcon: {
    fontSize: 'medium',
    color: 'inherit',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  spacer: { flex: 1, justifySelf: 'stretch', alignSelf: 'stretch' },
  greetingText: {
    wordBreak: 'keep',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'primary.light',
    marginLeft: '1rem',
  },
  printButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    marginRight: {
      Tablet: '0rem',
      Mobile: '2rem',
    },
  },
  deskAdminButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    marginRight: {
      Tablet: '0rem',
      Mobile: '2rem',
    },
  },
  switchStyles: {
    '&.MuiSwitch-root .MuiSwitch-switchBase': {
      color: COLORS.gray[300],
    },
    '& .MuiSwitch-switchBase+.MuiSwitch-track': {
      backgroundColor: COLORS.gray[200],
    },
    '&.MuiSwitch-root .Mui-checked': {
      color: COLORS.green[100],
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: COLORS.green[200],
    },
  },
};

export default styles;
