import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import theme from './Theme';

export type SxStyle = SystemStyleObject<typeof theme>;

export type SxStylesheet = Record<string, SxStyle>;

export type MuiIcon = OverridableComponent<SvgIconTypeMap<object, 'svg'>>;

export enum BreakPoints {
  Mobile = 0,
  Tablet = 768,
  Desktop = 1300,
}
