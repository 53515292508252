import { useEffect } from 'react';
import { AddNewSkillFieldName, SkillForm } from '../SkillsDialog.types';
import { UseFormClearErrors } from 'react-hook-form';
import { MAX_SKILL_LENGTH } from 'common/consts/validation';

const useRemoveButtonRowValidation = (
  valueLength: number | undefined,
  clearErrors: UseFormClearErrors<SkillForm>,
  newSkillName: AddNewSkillFieldName,
  maxLength = MAX_SKILL_LENGTH
) => {
  useEffect(() => {
    if (Number(valueLength) <= maxLength) {
      clearErrors(newSkillName);
    }
  }, [clearErrors, maxLength, newSkillName, valueLength]);
};

export default useRemoveButtonRowValidation;
