import { SxProps } from '@mui/material';
import { SxStylesheet } from 'theme/index';
import { MAX_LEGEND_WIDTH } from './Legend.const';

const flexColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

const styles: SxStylesheet = {
  root: {
    maxWidth: { Mobile: '100%', Desktop: MAX_LEGEND_WIDTH },
    ...flexColumn,
    gap: '1.5rem',
    paddingTop: '0.5rem',
  },
  section: {
    ...flexColumn,
    gap: '1.125rem',
  },
  content: {
    ...flexColumn,
    gap: '0.5rem',
  },
  label: {
    minWidth: '6.25rem',
  },
  row: {
    display: 'flex',
  },
};

export default styles;
