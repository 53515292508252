export enum RouterPaths {
  home = '/',
  forgotPassword = '/forgot',
  holidayImages = '/holiday-images',
  kudos = '/kudos',
  myKudos = '/my_kudos',
  sentKudos = '/sent_kudos',
  login = '/login',
  newOffer = `/new-offer/:offerName`,
  offer = `/offer/:offerName`,
  print = '/print',
  projectsDepartments = '/projects-and-departments',
  resetPassword = '/reset_password/',
  profile = '/profile',
  sendPhoto = '/send-photo',
  documents = '/documents',
  deskReservation = '/desk-reservation',
  notFound = '/404',
  roomReservation = '/room-reservations',
  holidayCalendar = '/holiday-calendar',
}

export enum ExternalPaths {
  cultureBook = 'https://drive.google.com/drive/folders/1dPEDjQ8jl4ypopSpL_s9dSHvwPXn-its',
  qualityPolicy = 'https://drive.google.com/file/d/1t0qDjfY0On5fhQA1FNVZkJLL0jHHRkXn/view', // TODO: Replace with proper link
  companyPage = 'https://revolve.healthcare/',
  securityPolicy = 'https://drive.google.com/drive/folders/12qqgl5klH5D1Lso0nv6qxvUCaM5AAniw',
}
