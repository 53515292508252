import { Box, Typography } from '@mui/material';
import { CalendarToday, VerifiedUser } from '@mui/icons-material';

import styles from './Courses.styles';
import ActionButtons from '../ActionButtons';
import { parseAndFormatDate } from 'utils/date';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';
import { CourseProps } from './Courses.types';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import useMutateUserCourses from 'hooks/useCourses/useMutateUserCourses';

const Course = (props: CourseProps) => {
  const { course, handleEdit, isReadonly, isLoading } = props;
  const { id, name, dateFrom, dateTo } = course;

  const { userId } = useActiveWorker();
  const { deleteMutate } = useMutateUserCourses();

  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: () => userId && deleteMutate({ userId, courseId: id }),
    title: `Are you sure you want to delete course${name ? `: ${name}` : ''}?`,
  });

  return (
    <Box sx={{ display: 'flex' }}>
      {isReadonly ? null : (
        <ActionButtons
          disabled={isLoading}
          isStart
          onDeleteHandler={openConfirmationModal}
          onEditHandler={() => handleEdit(course)}
        />
      )}
      <ConfirmationDialog />
      <Box sx={styles.courseData}>
        <Box sx={styles.iconWrapper}>
          <CalendarToday sx={styles.icon} />
          <Typography variant="body1">
            {parseAndFormatDate(dateFrom)}
            {dateTo && ` - ${parseAndFormatDate(dateTo)}`}
          </Typography>
        </Box>
        <Box sx={styles.iconWrapper}>
          <VerifiedUser sx={styles.icon} />
          <Typography variant="body1">{name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Course;
