import DisplayWrapper from 'components/DisplayWrapper';
import ChoosingDates from '../ChoosingDates';
import useBreakPoints from 'hooks/useBreakpoints';
import ChoosingDesk from '../ChoosingDesk/ChoosingDesk';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { FC, useContext } from 'react';

const ReserveDesk: FC = () => {
  const { isMobile } = useBreakPoints();
  const { activeDesk } = useContext(DeskContext);

  if (!activeDesk && !isMobile) return <></>;

  return (
    <DisplayWrapper>
      {isMobile && <ChoosingDesk />}
      <ChoosingDates />
    </DisplayWrapper>
  );
};

export default ReserveDesk;
