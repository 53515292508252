import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import useCustomNavigation from 'src/router/useNavigation';
import { Worker } from 'api/types/worker';
import useOffer from './useOffer';
import { useMemo } from 'react';

export const fetchOfferWorkers = (
  context: QueryFunctionContext<[string, string, Array<number>]>
) => {
  const [, offerName = '', offerWorkersIds] = context.queryKey;

  return Promise.all(
    offerWorkersIds?.map((id) =>
      apiGet<Worker>(`${API_URLS.offer}${offerName}/worker/${id}/`)
    )
  );
};

const useOfferWorkers = () => {
  const { params } = useCustomNavigation();
  const { offerName } = params as { offerName: string };
  const { data: offerData } = useOffer();
  const offerWorkersIds = useMemo(
    () => offerData?.workers.map((workers) => workers.id) ?? [],
    [offerData?.workers]
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_URLS.offer, offerName, offerWorkersIds],
    queryFn: fetchOfferWorkers,
    enabled: !!offerWorkersIds?.length,
  });

  return {
    workers: data,
    isLoading,
    refetchWorkers: refetch,
  };
};

export default useOfferWorkers;
