import { Chip, IconButton } from '@mui/material';
import formStyles from 'common/styles/forms';
import { Close } from '@mui/icons-material';

const TechnologyChip = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: () => void;
}) => (
  <Chip
    variant="outlined"
    color="primary"
    sx={formStyles.formChip}
    label={
      <>
        {label}
        <IconButton onClick={onClick} sx={formStyles.chipClose.button}>
          <Close sx={formStyles.chipClose.icon} />
        </IconButton>
      </>
    }
  />
);

export default TechnologyChip;
