const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  icon: { height: 15, width: 15 },
  iconWrapper: { display: 'flex', alignItems: 'center', gap: 1 },
  courseData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
  },
};

export default styles;
