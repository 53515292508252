import { useMutation } from '@tanstack/react-query';
import { peopleApiPatch, peopleApiPost } from 'api/axios';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

import useUsersCalendarHolidays from 'hooks/useCalendarHoliday';
import {
  MutateAcceptAdminHolidayApplicationProps,
  MutateDeleteAdminHolidayApplicationProps,
  MutateHolidayApplicationPostProps,
  MutateHolidayApplicationPatchProps,
} from './useUserHoliday.types';
import queryClient from 'api/queryClient';

export const useMutateHolidayApplication = () => {
  const { refetch: refetchUsersHolidays } = useUsersCalendarHolidays({});

  const { mutateAsync: postHolidayApplication } = useMutation({
    mutationFn: ({ holiday }: MutateHolidayApplicationPostProps) =>
      peopleApiPost(PEOPLE_API_URLS.holidaySchedule, {
        ...holiday,
        isAcceptedByEmployee: true,
      }),
    onSuccess: () => {
      enqueueSnackbar('Holiday application was succsessfuly added', {
        type: SnackbarType.success,
      });
      refetchUsersHolidays();
    },
    onError: () => {
      enqueueSnackbar('Cannot add holiday application', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: cancelAdminHolidayApplication } = useMutation({
    mutationFn: ({
      holidayId,
      isCancelled,
    }: MutateDeleteAdminHolidayApplicationProps) =>
      peopleApiPatch(PEOPLE_API_URLS.holidayCancelAdmin(holidayId), {
        isCancelled,
      }),
    onSuccess: () => {
      enqueueSnackbar('Holiday application was succsessfully deleted', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holiday],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete holiday application', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: createAdminHolidayApplication } = useMutation({
    mutationFn: ({ holiday }: MutateHolidayApplicationPostProps) =>
      peopleApiPost(PEOPLE_API_URLS.holidayCreateAdmin, {
        ...holiday,
        isAcceptedByAdmin: true,
      }),
    onSuccess: () => {
      enqueueSnackbar('Holiday application was succsessfully adedd', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holiday],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add holiday application', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: acceptAdminHolidayApplication } = useMutation({
    mutationFn: ({ holidayId }: MutateAcceptAdminHolidayApplicationProps) =>
      peopleApiPatch(`${PEOPLE_API_URLS.holiday}${holidayId}/`, {
        isAcceptedByAdmin: true,
      }),
    onSuccess: () => {
      enqueueSnackbar('Holiday application was succsessfully accepted', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holiday],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot accept holiday application', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: patchHolidayApplication } = useMutation({
    mutationFn: ({ holiday }: MutateHolidayApplicationPatchProps) =>
      peopleApiPatch(`${PEOPLE_API_URLS.holiday}${holiday.id}/`, {
        ...holiday,
      }),
    onSuccess: () => {
      enqueueSnackbar('Holiday was edited successfully', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.holiday],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot edit holiday application', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    postHolidayApplication,
    cancelAdminHolidayApplication,
    createAdminHolidayApplication,
    acceptAdminHolidayApplication,
    patchHolidayApplication,
  };
};
