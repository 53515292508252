import { Box, Button } from '@mui/material';

import AppDialog from 'components/AppDialog';
import FormWrapper from 'components/FormWrapper';
import formStyles from 'common/styles/forms';
import TextInput from 'components/TextInput';
import { DelegationFormDialogProps } from './DelegationFormDialog.types';
import delegationFormValidator from 'validation/delegationFormValidator';
import DatePickerInput from 'components/DatePickerInput';
import useDelegationFormDialog from './useDelegationFormDialog';

const DelegationFormDialog = ({
  delegation,
  onClose,
}: DelegationFormDialogProps) => {
  const {
    isOpen,
    methods,
    title,
    isLoading,
    onSubmitHandler,
    dateFromWatcher,
  } = useDelegationFormDialog({
    delegation,
    onClose,
  });

  return (
    <AppDialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button form="delegation-form" type="submit" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FormWrapper
        isLoading={isLoading}
        methods={methods}
        id="delegation-form"
        onSubmit={onSubmitHandler}
        validator={delegationFormValidator}
      >
        <Box sx={formStyles.secondary}>
          <Box sx={formStyles.doubleInputs}>
            <DatePickerInput name="dateFrom" label="Date from" />
            <DatePickerInput
              name="dateTo"
              label="Date to"
              minDate={dateFromWatcher ?? undefined}
            />
          </Box>
          <TextInput name="addInfo" label="Additional info" />
        </Box>
      </FormWrapper>
    </AppDialog>
  );
};

export default DelegationFormDialog;
