import { memo, useContext, useMemo } from 'react';

import useOffer from 'hooks/useOffer';
import OfferContext from 'contexts/Offer/OfferContext';
import WorkersList from 'pages/Profile/components/WorkersList';

import LoginForm from './components/LoginForm';
import { AppBarContent } from 'components/AppBar/AppBar.types';
import CompanyHeader from './components/CompanyHeader';
import OfferBody from './components/OfferBody';
import useLoginForm from './components/LoginForm/useLoginForm';

import useOfferWorker from 'hooks/useOfferWorker';
import PageContent from 'components/PageContent';
import DisplayWrapper from 'components/DisplayWrapper';

const Offer = memo(() => {
  const { isLogged, methods, loginMutation } = useLoginForm();
  const { mutate, isPending: isAuthLoading } = loginMutation;

  const { data } = useOffer();
  const { worker, isLoading: isOfferWorkerLoading } = useOfferWorker();
  const { activeUser, setActiveUser } = useContext(OfferContext);

  const isLoading = useMemo(() => !data || !worker, [data, worker]);

  if (!isLogged) {
    return (
      <PageContent
        appBarContent={AppBarContent.blank}
        isLoading={isAuthLoading}
      >
        <LoginForm
          methods={methods}
          onSubmitHandler={() => mutate(methods.watch('password'))}
          isLoading={isAuthLoading}
        />
      </PageContent>
    );
  }

  return (
    <PageContent
      appBarContent={AppBarContent.offer}
      header={<CompanyHeader />}
      isLoading={isLoading}
    >
      <WorkersList
        label="Suggested team"
        workers={data?.workers ?? []}
        selectedWorker={activeUser}
        setSelectedWorker={(id) => setActiveUser(id)}
      />
      <DisplayWrapper>
        <OfferBody worker={worker} isLoading={isOfferWorkerLoading} />
      </DisplayWrapper>
    </PageContent>
  );
});

Offer.displayName = 'Offer';

export default Offer;
