import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';

import {
  SocialSecurityBenefitForm,
  SocialSecurityBenefitFormProps,
  socialSecurityBenefitFormSubmitData,
} from './SocialSecurityBenefitFormDialog.types';
import socialSecurityBenefitFormValidator from 'validation/socialSecurityBenefitFormValidator';
import { formatToBackendDate, parseNullishBackendDate } from 'utils/date';
import useMutateUserSocialSecurityBenefits from 'hooks/useSocialSecurityBenefits/useMutateUserSocialSecurityBenefits';

const useSocialSecurityBenefitFormDialog = ({
  socialSecurityBenefit,
  onClose,
}: SocialSecurityBenefitFormProps) => {
  const { currentModal } = useContext(ModalContext);

  const isOpen = useMemo(
    () =>
      currentModal === AdminProfileModalType.AddSocialSecurityBenefit ||
      currentModal === AdminProfileModalType.EditSocialSecurityBenefit,
    [currentModal]
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useActiveWorker();

  const methods = useForm<SocialSecurityBenefitForm>({
    resolver: zodResolver(socialSecurityBenefitFormValidator),
  });

  const { control } = methods;

  const { socialSecurityBenefitPatchMutate, socialSecurityBenefitPostMutate } =
    useMutateUserSocialSecurityBenefits();

  const title = useMemo(
    () =>
      `${
        currentModal === AdminProfileModalType.AddSocialSecurityBenefit
          ? 'Add'
          : 'Edit'
      } social security benefit`,
    [currentModal]
  );

  const { type, addInfo, dateFrom, dateTo } = socialSecurityBenefit ?? {};

  const [dateFromWatcher, dateToWatcher] = useWatch({
    name: ['dateFrom', 'dateTo'],
    control,
  });

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        dateFrom: parseNullishBackendDate(dateFrom),
        dateTo: parseNullishBackendDate(dateTo),
        type: type?.toString(),
        addInfo: addInfo,
      });
    }
  }, [addInfo, dateFrom, dateTo, isOpen, methods, type]);

  useEffect(() => {
    if (dateFromWatcher && !dateToWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
    if (!dateFromWatcher && dateToWatcher) {
      methods.setValue('dateFrom', dateToWatcher);
    }
    if (dateToWatcher && dateFromWatcher && dateToWatcher < dateFromWatcher) {
      methods.setValue('dateTo', dateFromWatcher);
    }
  }, [dateFromWatcher, dateToWatcher, methods]);

  const onSubmitHandler = useCallback(
    async (data: socialSecurityBenefitFormSubmitData) => {
      if (userId) {
        const payload = {
          dateFrom: formatToBackendDate(data.dateFrom),
          dateTo: formatToBackendDate(data.dateTo),
          type: data.type.toString(),
          addInfo: data.addInfo,
          user: userId,
        };
        setIsSubmitting(true);
        try {
          if (socialSecurityBenefit) {
            await socialSecurityBenefitPatchMutate({
              socialSecurityBenefit: {
                id: socialSecurityBenefit.id,
                ...payload,
              },
            });
          } else {
            await socialSecurityBenefitPostMutate({
              socialSecurityBenefit: payload,
            });
          }
          onClose();
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [
      onClose,
      socialSecurityBenefit,
      socialSecurityBenefitPatchMutate,
      socialSecurityBenefitPostMutate,
      userId,
    ]
  );

  return {
    dateFromWatcher,
    isOpen,
    methods,
    title,
    onSubmitHandler,
    isLoading: isSubmitting,
  };
};

export default useSocialSecurityBenefitFormDialog;
