import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { ProjectsDepartmentsDetailsGetResponse } from 'api/types/projectsDepartments';

export const fetchProjectsDepartmentsDetails = (
  context: QueryFunctionContext<[string, number]>
) => {
  const [, id = ''] = context.queryKey;

  return apiGet<ProjectsDepartmentsDetailsGetResponse>(
    `${API_URLS.projectsDepartments}?project=${id}`
  );
};

const useProjectsDepartmentsDetails = (id: number, enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_URLS.projectsDepartments, id],
    queryFn: fetchProjectsDepartmentsDetails,
    enabled,
  });

  return {
    projectsDepartmentsDetails: data?.data,
    isLoadingProjectsDepartmentsDetails: isLoading,
    refetchProjectsDepartmentsDetails: refetch,
  };
};

export default useProjectsDepartmentsDetails;
