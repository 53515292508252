const styles = {
  grid: (isReadonly?: boolean, columns?: number) => ({
    'display': 'grid',
    'rowGap': '1rem',
    'columnGap': isReadonly ? '5rem' : '1rem',
    'gridTemplateColumns': isReadonly
      ? `repeat(${columns}, minmax(min-content, 10rem) 5rem)`
      : `repeat(${columns}, minmax(min-content, 10rem) 5rem 5rem 2rem)`,
    '@media print': {
      gridTemplateColumns: `repeat(2, minmax(min-content, 10rem) 5rem)`,
    },
  }),
};

export default styles;
