import { Box, Typography } from '@mui/material';
import { Fragment, memo, useContext, useMemo, useState } from 'react';

import Certificate from './Certificate';
import CertificateFormDialog from './CertificateFormDialog';

import { CertificatesProps } from './Certificates.types';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';
import EmptySection from '../EmptySection';
import styles from './Certificates.styles';

const Certificates = memo((props: CertificatesProps) => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);
  const { isReadonly, certificates, isOffer, hideEmpty } = props;

  const areCertificatesEmpty = useMemo(
    () => !certificates?.length,
    [certificates?.length]
  );

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  if (hideEmpty && areCertificatesEmpty) return <></>;

  return (
    <Box sx={styles.root}>
      <Typography component="h1" variant="h1">
        Certificates
      </Typography>
      {!areCertificatesEmpty ? (
        certificates?.map((certificate, index) => (
          <Fragment key={`certificate-${index}`}>
            <Certificate
              key={certificate.id}
              certificate={certificate}
              handleEdit={() => {
                setEditId(certificate.id);
                openModal(ProfileModalType.EditCertificate);
              }}
              isReadonly={isReadonly}
              hideEmpty={hideEmpty}
              isOffer={isOffer}
            />
            {!isReadonly &&
              currentModal === ProfileModalType.EditCertificate &&
              editId === certificate.id && (
                <CertificateFormDialog
                  certificate={certificate}
                  onClose={onCloseModal}
                />
              )}
          </Fragment>
        ))
      ) : (
        <EmptySection />
      )}
      {!isReadonly && currentModal === ProfileModalType.AddCertificate && (
        <CertificateFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Certificates.displayName = 'Certificates';

export default Certificates;
