import { Box, CircularProgress, Typography } from '@mui/material';
import ProjectsDepartmentsContext from 'contexts/ProjectsDepartments/ProjectsDepartmentsContext';
import {
  useDepartment,
  useProjectsDepartmentsDetails,
} from 'hooks/useCompanyInfo';
import { FC, useContext, useMemo } from 'react';
import Card from '../Card';
import styles from './Content.styles';
import useProjects from 'hooks/useProjects';
import { Groups } from '@mui/icons-material';

const Content: FC = () => {
  const { radioValue, optionProject, optionDepartment } = useContext(
    ProjectsDepartmentsContext
  );

  const isProject = radioValue === 'projects';

  const { departmentData, isLoadingDepartment } = useDepartment(
    Number(optionDepartment?.value),
    !isProject && !!Number(optionDepartment?.value)
  );
  const { projectsDepartmentsDetails, isLoadingProjectsDepartmentsDetails } =
    useProjectsDepartmentsDetails(
      Number(optionProject?.value),
      isProject && !!Number(optionProject?.value)
    );

  const projects = useProjects().data;
  const projectDetails = projects?.find(
    ({ id }) => id === Number(optionProject?.value)
  );

  const users = useMemo(
    () =>
      (isProject ? projectsDepartmentsDetails : departmentData)?.map(
        ({ user, role }) => ({
          content: user.title,
          title: role?.name,
        })
      ),
    [departmentData, isProject, projectsDepartmentsDetails]
  );

  const leaders = useMemo(
    () => users?.filter(({ title }) => title === 'Project Leader'),
    [users]
  );
  const developers = useMemo(
    () => users?.filter(({ title }) => title === 'Software Developer'),
    [users]
  );
  const restOfTeam = useMemo(
    () =>
      users?.filter(
        ({ title }) =>
          title !== 'Project Leader' && title !== 'Software Developer'
      ),
    [users]
  );

  if (
    (!projectsDepartmentsDetails && isProject) ||
    (!isProject && !departmentData)
  ) {
    return (
      <Box sx={styles.textWrapper}>
        <Groups sx={styles.iconWrapper} />
        <Typography sx={styles.text} variant="sub1">
          Wybierz projekt lub dział żeby zobaczyć szczegóły
        </Typography>
      </Box>
    );
  }

  if (isProject ? isLoadingProjectsDepartmentsDetails : isLoadingDepartment) {
    return (
      <Box sx={styles.loaderWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {isProject ? (
        <>
          <Typography variant="h1">{projectDetails?.name}</Typography>
          {projectDetails && projectDetails.description !== '' && (
            <Typography variant="body1" sx={styles.subHeader}>
              {projectDetails.description}
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography variant="h1">{optionDepartment?.label}</Typography>
        </>
      )}
      <Box sx={styles.cardWrapper}>
        {leaders?.map(({ content, title }, i) => (
          <Card key={`card-leader-${i}`} title={title} content={content} />
        ))}
      </Box>
      <Box sx={styles.cardWrapper}>
        {restOfTeam?.map(({ content, title }, i) => (
          <Card key={`card-team-${i}`} title={title} content={content} />
        ))}
      </Box>
      <Box sx={styles.cardWrapper}>
        {developers?.map(({ content, title }, i) => (
          <Card key={`card-developer${i}`} title={title} content={content} />
        ))}
      </Box>
    </>
  );
};

export default Content;
