import smileEmoji from 'assets/images/smileEmoji.png';
import balloons from 'assets/images/balloons.png';
import thumbsUp from 'assets/images/thumbsUp.png';
import { COLORS } from 'src/theme';
import { KudosType } from 'api/types/kudos';

export const KudosCardProperties = {
  [KudosType.thankYou]: {
    id: KudosType.thankYou,
    icon: smileEmoji,
    header: 'Dziękuję!',
    color: COLORS.red[100],
  },
  [KudosType.congratulations]: {
    id: KudosType.congratulations,
    icon: balloons,
    header: 'Gratulacje!',
    color: COLORS.green[100],
  },
  [KudosType.goodJob]: {
    id: KudosType.goodJob,
    icon: thumbsUp,
    header: 'Dobra robota!',
    color: COLORS.purple[100],
  },
};
