import { COLORS } from 'theme/index';
import { BreakPoints, SxStylesheet } from 'theme/Theme.types';

const styles: (fullWidth: boolean) => SxStylesheet = (fullWidth: boolean) => {
  return {
    baseLine: {
      minHeight: '100%',
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: COLORS.gray[100],
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
      alignContent: 'stretch',

      width: '95%',
      maxWidth: fullWidth ? '100%' : BreakPoints.Desktop,

      paddingY: {
        Desktop: '3rem',
        Tablet: '2rem',
        Mobile: '1rem',
      },
      gap: {
        Desktop: '4rem',
        Tablet: '2rem',
        Mobile: '1rem',
      },
    },
  };
};

export default styles;
