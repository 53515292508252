import { useMutation } from '@tanstack/react-query';
import { peopleApiPatch, peopleApiPost } from 'api/axios';

import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';

import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import queryClient from 'api/queryClient';

import {
  MutateSocialSecurityBenefitsPatchProps,
  MutateSocialSecurityBenefitsDeleteProps,
  MutateSocialSecurityBenefitsPostProps,
} from './useSocialSecurityBenefits.types';

export const useMutateUserSocialSecurityBenefits = () => {
  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({
      socialSecurityBenefit,
    }: MutateSocialSecurityBenefitsPatchProps) =>
      peopleApiPatch(
        `${PEOPLE_API_URLS.socialSecurityBenefits}${socialSecurityBenefit.id}/`,
        socialSecurityBenefit
      ),
    onSuccess: () => {
      enqueueSnackbar('Social security benefit successfully edited', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.socialSecurityBenefits],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete social security benefit', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({
      socialSecurityBenefitId,
      isCancelled,
    }: MutateSocialSecurityBenefitsDeleteProps) =>
      peopleApiPatch(
        `${PEOPLE_API_URLS.socialSecurityBenefits}${socialSecurityBenefitId}/`,
        { isCancelled }
      ),
    onSuccess: () => {
      enqueueSnackbar('Social security benefit successfully deleted', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.socialSecurityBenefits],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete social security benefit', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({
      socialSecurityBenefit,
    }: MutateSocialSecurityBenefitsPostProps) =>
      peopleApiPost(
        PEOPLE_API_URLS.socialSecurityBenefits,
        socialSecurityBenefit
      ),
    onSuccess: () => {
      enqueueSnackbar('Social security benefit successfully added', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [PEOPLE_API_URLS.socialSecurityBenefits],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add new social security benefit', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    socialSecurityBenefitPatchMutate: patchMutate,
    socialSecurityBenefitDeleteMutate: deleteMutate,
    socialSecurityBenefitPostMutate: postMutate,
  };
};

export default useMutateUserSocialSecurityBenefits;
