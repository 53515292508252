import { Box, Typography } from '@mui/material';
import { Fragment, memo, useContext, useMemo, useState } from 'react';

import styles from './Projects.styles';
import Project from './Project';
import { ProjectsProps } from './Projects.types';
import EmptySection from '../EmptySection';
import ProjectFormDialog from './ProjectFormDialog';
import ModalContext, { ProfileModalType } from 'contexts/Modal/ModalContext';

const Projects = memo((props: ProjectsProps) => {
  const { hideEmpty, projects, isReadonly } = props;
  const { currentModal, closeModal, openModal } = useContext(ModalContext);

  const [editId, setEditId] = useState<number>();

  const areProjectsEmpty = useMemo(() => !projects?.length, [projects?.length]);
  const shouldHideComponent = useMemo(
    () => hideEmpty && areProjectsEmpty,
    [areProjectsEmpty, hideEmpty]
  );

  if (shouldHideComponent) return <></>;

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  return (
    <Box sx={styles.root}>
      <Typography variant="h1">Projects</Typography>
      {!areProjectsEmpty ? (
        projects?.map((project, index) => (
          <Fragment key={`project-${index}`}>
            <Project
              project={project}
              editProject={() => {
                setEditId(project.id);
                openModal(ProfileModalType.EditProject);
              }}
              isReadonly={isReadonly}
              {...props}
            />
            {!isReadonly &&
              currentModal === ProfileModalType.EditProject &&
              editId === project.id && (
                <ProjectFormDialog project={project} onClose={onCloseModal} />
              )}
          </Fragment>
        ))
      ) : (
        <EmptySection />
      )}
      {!isReadonly && currentModal === ProfileModalType.AddProject && (
        <ProjectFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Projects.displayName = 'Projects';

export default Projects;
