import { Box, Typography } from '@mui/material';
import { memo, useContext, useState, Fragment } from 'react';

import ModalContext, {
  AdminProfileModalType,
} from 'contexts/Modal/ModalContext';
import EmptySection from '../../EmptySection';
import ContractFormDialog from './ContractFormDialog/ContractFormDialog';
import { ContractsProps } from './Contract.types';
import Contract from './Contract';
import styles from '../AdminProfile.styles';

const Contracts = memo(({ contracts }: ContractsProps) => {
  const { currentModal, closeModal, openModal } = useContext(ModalContext);

  const [editId, setEditId] = useState<number>();

  const onCloseModal = () => {
    closeModal();
    setEditId(undefined);
  };

  return (
    <Box>
      <Typography component="h1" variant="h1">
        Contracts
      </Typography>
      <Box sx={styles.contentWrapper}>
        {contracts && contracts?.length > 0 ? (
          contracts?.map((contract) => (
            <Fragment key={`${contract.id}-contract`}>
              <Contract
                key={contract.id}
                contract={contract}
                handleEditContract={() => {
                  setEditId(contract.id);
                  openModal(AdminProfileModalType.EditContract);
                }}
              />
              {currentModal === AdminProfileModalType.EditContract &&
                editId === contract.id && (
                  <ContractFormDialog
                    contract={contract}
                    onClose={onCloseModal}
                  />
                )}
            </Fragment>
          ))
        ) : (
          <EmptySection />
        )}
      </Box>
      {currentModal === AdminProfileModalType.AddContract && (
        <ContractFormDialog onClose={onCloseModal} />
      )}
    </Box>
  );
});

Contracts.displayName = 'Contracts';

export default Contracts;
