import { Chip, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import formStyles from 'common/styles/forms';
import { FC } from 'react';
import { LanguageChipProps } from './LanguageForm.types';

const LanguageChip: FC<LanguageChipProps> = ({ label, onClick }) => (
  <Chip
    variant="outlined"
    color="primary"
    sx={formStyles.formChip}
    label={
      <>
        {label}
        <IconButton onClick={onClick} sx={formStyles.chipClose.button}>
          <Close sx={formStyles.chipClose.icon} />
        </IconButton>
      </>
    }
  />
);

export default LanguageChip;
