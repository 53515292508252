import { QueryCache, QueryClient } from '@tanstack/react-query';
import { STALE_TIME_MS } from './client.consts';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'components/Snackbars';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: STALE_TIME_MS,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      refetchOnReconnect: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      let errorMessage = (error as AxiosError).message;

      if (query.meta?.errorMessage) {
        errorMessage = query.meta.errorMessage as string;
      }

      enqueueSnackbar(`Something went wrong: ${errorMessage}`),
        { type: 'error' };
    },
  }),
});

export default queryClient;
