import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { SchoolsGetResponse } from 'api/types/schools';

export const fetchSchools = () => apiGet<SchoolsGetResponse>(API_URLS.schools);

const useSchools = (enabled?: boolean) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [API_URLS.schools],
    queryFn: fetchSchools,
    enabled,
  });

  return {
    data: data?.data ?? [],
    refetch,
    isLoading,
  };
};

export default useSchools;
