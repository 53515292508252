import { Box, Button, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import plLocale from '@fullcalendar/core/locales/pl';
import interactionPlugin from '@fullcalendar/interaction';
import { memo, useContext } from 'react';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent';
import styles from './HolidayCalendar.styles';
import './HolidayCalendar.scss';

import { useHolidayCalendar } from './useHolidayCalendar';
import { CalendarEventRender } from './components/CalendarEventRender';
import { LEGEND_CONTENT } from './HolidayCalendar.helpers';
import Legend from 'components/Legend';
import { SelectUser } from './components/SelectUser';
import { HolidayPoolSummary } from './components/HolidayPoolSummary';
import { HolidayApplicationForm } from './components/HolidayApplicationForm';
import ModalContext, { GlobalModalType } from 'contexts/Modal/ModalContext';

const HolidayCalendar = memo(() => {
  const { currentModal, openModal, closeModal } = useContext(ModalContext);

  const {
    calendarEveentClick,
    selectDateRange,
    currentEvents,
    setHoveredEventId,
    ConfirmationDialog,
    selectedDateRange,
    selectUser,
    setSelectUser,
    setSelectedDateRange,
    expandCalendarRows,
    setExpandCalendarRows,
    isLoading,
  } = useHolidayCalendar();

  return (
    <PageContent isLoading={isLoading} fullWidth={true}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          {isLoading ? null : (
            <Box sx={styles.leftPanel}>
              <Typography variant="h1">
                Plan Urlopów {selectUser?.firstName} {selectUser?.lastName}
              </Typography>
              <SelectUser setSelectUser={setSelectUser} />
              <HolidayPoolSummary selectUser={selectUser} />
              <Typography variant="h1">Legenda</Typography>
              <Legend dotsContentScheme={LEGEND_CONTENT} />
            </Box>
          )}
          <Box sx={styles.mainPanel}>
            <FullCalendar
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              locales={[plLocale]}
              locale="pl"
              selectable
              selectMirror
              headerToolbar={{
                right: '',
                center: 'title',
                left: 'prev,today,next',
              }}
              hiddenDays={[0, 6]}
              select={selectDateRange}
              contentHeight={expandCalendarRows ? 'auto' : undefined}
              moreLinkClick={() => {
                setExpandCalendarRows(true);

                return 'timeGridDay';
              }}
              eventClick={calendarEveentClick}
              dayMaxEvents={!expandCalendarRows}
              eventMouseEnter={(mouseEnterInfo) =>
                setHoveredEventId(mouseEnterInfo.event.id)
              }
              eventMouseLeave={() => setHoveredEventId(undefined)}
              moreLinkText=""
              firstDay={1}
              events={currentEvents}
              eventContent={CalendarEventRender}
              aspectRatio={1.2}
            />
            <Button
              variant="contained"
              onClick={() => openModal(GlobalModalType.AddHoliday)}
              sx={styles.button}
            >
              Złóż Wniosek
            </Button>
          </Box>
        </Box>
      </DisplayWrapper>
      <ConfirmationDialog />
      {currentModal === GlobalModalType.AddHoliday && (
        <HolidayApplicationForm
          onClose={() => {
            closeModal();
            setSelectedDateRange({});
          }}
          dateFrom={selectedDateRange.dateFrom}
          dateTo={selectedDateRange.dateTo}
        />
      )}
    </PageContent>
  );
});

HolidayCalendar.displayName = 'Holiday Calendar';

export default HolidayCalendar;
