import { FC } from 'react';
import { Box } from '@mui/material';
import { DotProps } from './Dot.types';
import styles from './Dot.styles';

export const Dot: FC<DotProps> = ({ color, borderColor }) => {
  return (
    <Box
      bgcolor={color}
      sx={[
        styles.dotStyle,
        { border: borderColor != null ? `3px solid ${borderColor}` : 'none' },
      ]}
    ></Box>
  );
};

export default Dot;
