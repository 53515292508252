import { Box, Modal, Pagination, Typography } from '@mui/material';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent';
import { ChangeEvent, CSSProperties, memo, useEffect, useState } from 'react';
import styles from './HolidayPhotos.styles';
import { BsCheckLg } from 'react-icons/bs';
import { HolidayPhotosPageGetResponse } from 'api/types/photo';
import useHolidayPhoto from 'hooks/useHolidayPhoto/useHolidayPhoto';
import usePageHolidayPhotos from 'hooks/useHolidayPhoto/usePageHolidayPhotos';
import useBreakPoints from 'hooks/useBreakpoints';

const HolidayPhotos = memo(() => {
  const [page, setPage] = useState(1);
  const [photos, setPhotos] = useState<
    HolidayPhotosPageGetResponse | undefined
  >();
  const [id, setId] = useState<number>();
  const { isMobile } = useBreakPoints();

  const handlePageClick = (_: ChangeEvent<unknown>, value: number) =>
    setPage(value);
  const { holidayPhotoDataResponse } = useHolidayPhoto(id);
  const { pageHolidayPhotosDataResponse, isLoadingPageHolidayPhoto } =
    usePageHolidayPhotos(page);

  useEffect(() => {
    setPhotos(pageHolidayPhotosDataResponse);
  }, [setPhotos, pageHolidayPhotosDataResponse]);

  return (
    <PageContent isLoading={isLoadingPageHolidayPhoto}>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="h1">Galeria zdjęć</Typography>
          <Box sx={styles.photosContainer}>
            {photos?.page
              ? photos.page.map((photo) => (
                  <Box key={photo.id} sx={styles.imageContainer}>
                    {photo.isPublished ? (
                      <Box sx={[styles.svgWrapper, styles.checkIcon]}>
                        <BsCheckLg />
                      </Box>
                    ) : (
                      <></>
                    )}
                    <img
                      key={photo.id}
                      src={photo.imageUrl}
                      alt={photo.id.toString()}
                      style={styles.image as CSSProperties}
                      onClick={() => setId(photo.id)}
                    />
                  </Box>
                ))
              : []}
            <Modal
              sx={styles.modalImageContainer}
              open={!!id}
              onClose={() => setId(undefined)}
            >
              <img
                style={
                  isMobile ? (styles.singleImageMobile as CSSProperties) : {}
                }
                src={`data:image/png;base64,${holidayPhotoDataResponse}`}
              />
            </Modal>
          </Box>
          <Pagination
            sx={styles.pagination}
            onChange={handlePageClick}
            count={photos?.pageCount}
            color={'primary'}
          />
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

HolidayPhotos.displayName = 'HolidayPhotos';

export default HolidayPhotos;
