import { COLORS } from 'src/theme';
const styles = {
  container: {
    width: {
      Desktop: 'auto',
      Tablet: 'auto',
      Mobile: '380px',
    },
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  cancelButton: {
    border: `1px solid ${COLORS.borderColor}`,
    width: '175px',
  },
  dateContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  headerText: {
    width: '80%',
  },
  reservationTextContainer: {
    width: '80%',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
  },
  bottomText: {
    marginBottom: '1rem',
  },
  featureText: {
    border: `2px solid ${COLORS.blue[500]}`,
    borderRadius: '0.5rem',
    padding: '0.3rem',
    color: COLORS.blue[500],
  },
  featuresContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  textContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    marginRight: 'auto',
  },
};
export default styles;
