const styles = {
  dialogTitle: (iconOnly?: boolean) => ({
    padding: { Mobile: '1.5rem 1.5rem 2rem', Desktop: '3rem 3rem' },
    justifyContent: iconOnly ? 'flex-end' : undefined,
  }),
  dialogContent: {
    paddingX: { Mobile: '1.5rem', Desktop: '3rem' },
    paddingY: 0,
  },
  dialogActions: {
    padding: { Mobile: '2rem 1.5rem 1.5rem', Desktop: '3rem 3rem' },
  },
  closeButton: { padding: 0, height: 'fit-content' },
};

export default styles;
