import { KeyValueDesk } from 'pages/DeskReservation/components/Admin/EditDesk.types';

export const mapKeyValueToDeskOption = ({ id, name }: KeyValueDesk) => ({
  value: `${id}`,
  label: name,
});

export const mapKeyValuesToDeskOptions = (data: KeyValueDesk[]) =>
  data.map(({ name, id }) => ({
    label: name,
    value: id || null,
  }));
