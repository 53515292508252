import useDepartments from './useDepartments';
import useProjectsDepartments from './useProjectsDepartments';

const useCompanyInfo = () => {
  const {
    data: projectsDepartmentsData,
    isFetching: areProjectsDepartmentsLoading,
  } = useProjectsDepartments();
  const { data: departmentsData, isFetching: areDepartmentsLoading } =
    useDepartments();

  return {
    projectsDepartmentsData,
    departmentsData,
    isLoading: areProjectsDepartmentsLoading || areDepartmentsLoading,
  };
};

export default useCompanyInfo;
