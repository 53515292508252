import AuthContext from 'contexts/Auth/AuthContext';
import ProfileContext from 'contexts/Profile/ProfileContext';
import { useContext, useMemo } from 'react';

const useActiveWorker = () => {
  const { id } = useContext(AuthContext);
  const { activeUser } = useContext(ProfileContext);

  const userId = useMemo(() => activeUser ?? id ?? undefined, [activeUser, id]);

  return {
    userId,
  };
};

export default useActiveWorker;
