import { z } from 'zod';
import { errorMessages, MIN_DATE } from 'common/consts/validation';
import { isBefore, isEqual } from 'date-fns';
import { HolidayType } from 'api/types/holiday';

const CompassionateSubtype = z.enum(['Ślub', 'Narodziny dziecka', 'Pogrzeb']);

const holidayFormValidator = z
  .object({
    dateFrom: z
      .date()
      .min(new Date(MIN_DATE), { message: errorMessages.minDate(MIN_DATE) }),
    dateTo: z
      .date()
      .min(new Date(MIN_DATE), { message: errorMessages.minDate(MIN_DATE) }),
    type: z.nativeEnum(HolidayType),
    addInfo: z.string().optional(),
    compassionateSubType: CompassionateSubtype.optional(),
    isHalfDay: z.boolean().default(false),
  })
  .refine(
    ({ isHalfDay, dateFrom, dateTo }) =>
      isHalfDay ? isEqual(dateFrom, dateTo) : true,
    {
      message: 'For half-day holidays, dateTo must be the same as dateFrom',
      path: ['dateTo'],
    }
  )
  .refine(
    ({ type, compassionateSubType }) =>
      type === HolidayType.Compassionate
        ? compassionateSubType !== undefined
        : true,
    {
      message: 'Subtype is required for compassionate type',
      path: ['compassionateSubType'],
    }
  )
  .refine(({ dateFrom, dateTo }) => !isBefore(dateTo, dateFrom), {
    message: errorMessages.invalidRange,
    path: ['dateTo'],
  });

export default holidayFormValidator;
