import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  displayWrapper: {
    marginX: {
      Tablet: '8rem',
      Mobile: '0',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '1rem',
  },
  img: {
    width: '12rem',
    height: '12rem',
  },
};

export default styles;
