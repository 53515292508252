import {
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  StrikethroughS,
} from '@mui/icons-material';
import { Box, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import useMenuBar from './useMenuBar';
import styles from './MenuBar.styles';
import { FC } from 'react';
import { MenuBarProps } from './MenuBar.types';

const MenuBar: FC<MenuBarProps> = ({ isError }) => {
  const {
    toggleBold,
    isBold,
    toggleItalic,
    isItalic,
    toggleUnderline,
    isUnderline,
    toggleStrike,
    isStrike,
    toggleBulletList,
    isBulletList,
    toggleOrderedList,
    isOrderedList,
    clearFormatting,
  } = useMenuBar();

  return (
    <Box sx={styles.root(isError)}>
      <ToggleButtonGroup sx={styles.buttonGroup} size="small">
        <ToggleButton value={'bold'} onClick={toggleBold} selected={isBold}>
          <FormatBold />
        </ToggleButton>
        <ToggleButton
          value={'italic'}
          onClick={toggleItalic}
          selected={isItalic}
        >
          <FormatItalic />
        </ToggleButton>
        <ToggleButton
          value={'underline'}
          onClick={toggleUnderline}
          selected={isUnderline}
        >
          <FormatUnderlined />
        </ToggleButton>
        <ToggleButton
          value={'strikethrough'}
          onClick={toggleStrike}
          selected={isStrike}
        >
          <StrikethroughS />
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={styles.divider} />
      <ToggleButtonGroup sx={styles.buttonGroup} size="small">
        <ToggleButton value={'clearFormat'} onClick={clearFormatting}>
          <FormatClear />
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={styles.divider} />
      <ToggleButtonGroup sx={styles.buttonGroup} size="small">
        <ToggleButton
          value={'bulletList'}
          onClick={toggleBulletList}
          selected={isBulletList}
        >
          <FormatListBulleted />
        </ToggleButton>
        <ToggleButton
          value={'orderedList'}
          onClick={toggleOrderedList}
          selected={isOrderedList}
        >
          <FormatListNumbered />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default MenuBar;
