import { useContext, useMemo } from 'react';
import useProfileList from 'hooks/useProfileList';
import useReservation from './useReservation';
import RoomReservationContext from 'contexts/RoomReservation/RoomReservationContext';
import {
  formatDateForReservation,
  formatIsoDateForReservation,
} from 'utils/date';

const useOtherUserRoomReservation = () => {
  const { reservedRooms } = useReservation();
  const { selectedDate } = useContext(RoomReservationContext);

  const { data: workersData } = useProfileList();

  const formattedDate = useMemo(
    () => formatDateForReservation(selectedDate),
    [selectedDate]
  );
  const dateToCompare = useMemo(
    () => formatIsoDateForReservation(selectedDate),
    [selectedDate]
  );

  const roomReservationsBookerIds = useMemo(
    () =>
      reservedRooms
        .filter((reservation) => reservation.date === dateToCompare)
        .map((reservation) => reservation.booker),
    [dateToCompare, reservedRooms]
  );

  const bookerData = workersData.find(
    (data) => data.id === roomReservationsBookerIds[0]
  );

  return {
    bookerData,
    formattedDate,
  };
};

export default useOtherUserRoomReservation;
