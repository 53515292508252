import { UserData } from 'api/types/holidayPool';
import { ShortHolidayPoolSummary } from './ShortHolidayPoolSummary';
import { DetailedHolidayPoolSummary } from './DetailedHolidayPoolSummary';
import { FC } from 'react';
import {
  UserHolidayPoolSummary,
  useUsersHolidayPool,
} from 'hooks/useUserHolidayPool';
import { Nullable } from 'common/types';

interface Props {
  selectUser: Nullable<UserData>;
}

export const HolidayPoolSummary: FC<Props> = ({ selectUser }) => {
  const usersHolidayPool = useUsersHolidayPool();
  const curentYear = new Date().getFullYear();

  const thisYearAllUsersHolidayPools =
    usersHolidayPool.data
      ?.filter((pool) => pool.year === curentYear)
      .sort((p1, p2) =>
        p2.userData.firstName.localeCompare(p1.userData.firstName)
      ) || Array<UserHolidayPoolSummary>();

  const selectedUsersHolidayPools =
    usersHolidayPool.data
      ?.filter((pool) => pool.user === selectUser?.id)
      .sort((p1, p2) => p2.year - p1.year) || Array<UserHolidayPoolSummary>();

  if (selectUser === null) {
    return (
      <ShortHolidayPoolSummary holidayPools={thisYearAllUsersHolidayPools} />
    );
  } else if (selectedUsersHolidayPools.length > 0) {
    return (
      <DetailedHolidayPoolSummary holidayPools={selectedUsersHolidayPools} />
    );
  } else {
    return null;
  }
};
