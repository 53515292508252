import { KeyValueBase } from 'api/types/common';

export const mapKeyValueToOption = ({ id, name }: KeyValueBase) => ({
  value: `${id}`,
  label: name,
});

export const mapKeyValuesToOptions = (data: KeyValueBase[]) =>
  data.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
