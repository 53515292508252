import { MAX_LEGEND_WIDTH } from './components/Legend/Legend.const';

const styles = {
  skillContainer: {
    display: 'flex',
    flexDirection: {
      Desktop: 'row',
      Tablet: 'column-reverse',
      Mobile: 'column-reverse',
    },
    columnGap: '6.25rem',
    rowGap: '2rem',
  },
  skillColumn: (showLegend?: boolean) => ({
    flex: 1,
    maxWidth: {
      Desktop: showLegend ? MAX_LEGEND_WIDTH : '100%',
      Tablet: showLegend ? MAX_LEGEND_WIDTH : '100%',
      Mobile: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }),
};

export default styles;
