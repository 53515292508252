import { Autocomplete, TextField } from '@mui/material';
import { Controller, get, useFormContext } from 'react-hook-form';

import InputWrapper from '../InputWrapper';
import { Option } from 'common/types';
import { AutoCompleteInputProps } from './AutoCompleteInput.types';
import {
  getFilterOptions,
  getOptionLabel,
  transformEventValue,
} from 'components/AutoCompleteInput/AutoCompleteInput.helpers';
import styles from 'components/AutoCompleteInput/AutoCompleteInput.styles';
import { NEW_VALUE_PREFIX } from './AutoCompleteInput.const';

const AutoCompleteInput = ({
  name,
  label,
  fullWidth = false,
  handleChange,
  wrapperSx = {},
  freeSolo,
  ...rest
}: AutoCompleteInputProps) => {
  const { control } = useFormContext();

  return (
    <InputWrapper sx={wrapperSx} fullWidth={fullWidth} name={name}>
      <Controller
        control={control}
        name={name}
        render={({
          formState: { errors },
          field: { ref, onChange, value, ...fieldProps },
        }) => (
          <Autocomplete<
            Option<number | null | string, string> & { inputValue?: string },
            false,
            boolean,
            boolean
          >
            onChange={(event, newValue) => {
              event.preventDefault();
              const value = transformEventValue(newValue);

              handleChange?.(value);
              onChange(value);
            }}
            isOptionEqualToValue={(option, selectedOption) => {
              // Just to mute material warning for default empty value
              if (`${selectedOption.value}`.includes(NEW_VALUE_PREFIX))
                return true;

              return option.label === selectedOption.label;
            }}
            getOptionLabel={getOptionLabel}
            filterOptions={freeSolo ? getFilterOptions : undefined}
            value={value ?? null}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                error={!!get(errors, name) || !!get(errors, `${name}.label`)}
                inputRef={ref}
                label={label}
                {...params}
              />
            )}
            ListboxProps={{ sx: styles.listbox }}
            {...fieldProps}
            {...rest}
          />
        )}
      />
    </InputWrapper>
  );
};

export default AutoCompleteInput;
