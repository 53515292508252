import { memo, useContext, useMemo } from 'react';

import AppDialog from 'components/AppDialog';
import { SendKudosModalProps } from './SendKudosModal.types';
import KudosCard from 'pages/Kudos/components/KudosCard';
import { Button } from '@mui/material';
import FormWrapper from 'components/FormWrapper';
import InputWrapper from 'components/InputWrapper';
import useSendKudos from './useSendKudos';
import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';
import { mapProfileListToOption } from './SendKudosModal.helpers';
import useProfileList from 'hooks/useProfileList/useProfileList';
import kudosFormValidator from 'validation/kudosFormValidator';
import AuthContext from 'contexts/Auth/AuthContext';

const SendKudosModal = memo((props: SendKudosModalProps) => {
  const { id: userId } = useContext(AuthContext);
  const { kudosType } = props;

  const {
    methods,
    messageController,
    isOpen,
    onCloseHandler,
    onSubmitHandler,
    isSending,
  } = useSendKudos(props);

  const { data } = useProfileList(true);

  const selectOptions = useMemo(
    () =>
      data?.filter(({ id }) => id !== userId).map(mapProfileListToOption) ?? [],
    [data, userId]
  );

  return (
    <AppDialog
      title={'Wyślij kudosa'}
      actions={
        <>
          <Button variant="outlined" onClick={onCloseHandler}>
            Anuluj
          </Button>
          <Button
            disabled={isSending}
            form="kudos-form"
            type="submit"
            variant="contained"
          >
            Wyślij
          </Button>
        </>
      }
      open={isOpen}
      onClose={onCloseHandler}
      blockBackdropClick={true}
    >
      <FormWrapper
        id="kudos-form"
        methods={methods}
        validator={kudosFormValidator}
        onSubmit={onSubmitHandler}
      >
        <AutoCompleteInput
          fullWidth
          clearOnBlur
          options={selectOptions}
          label="Wybierz komu chcesz wysłać kudosa"
          noOptionsText="Brak użytkownika"
          name="user"
          sx={{ pt: '0.3rem' }}
        />
        <InputWrapper sx={{ gap: '0.5rem' }} name="message" fullWidth>
          {kudosType && (
            <KudosCard
              type={kudosType}
              height="14rem"
              controller={messageController}
            />
          )}
        </InputWrapper>
      </FormWrapper>
    </AppDialog>
  );
});

SendKudosModal.displayName = 'SendKudosModal';

export default SendKudosModal;
