const styles = {
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1rem',
  },
  text: {
    textAlign: 'center',
  },
};

export default styles;
