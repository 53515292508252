import { useQuery } from '@tanstack/react-query';
import { PEOPLE_API_URLS } from 'api/axios/axiosPeople.consts';
import { peopleApiGet } from 'api/axios';
import { AxiosError } from 'axios';
import { UserGetResponse } from './useUser.types';
import { HttpStatusCode } from 'axios';

export const fetchUser = async () => {
  try {
    const response = await peopleApiGet<UserGetResponse>(PEOPLE_API_URLS.user);

    return response.data.results;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (
        [HttpStatusCode.NotFound, HttpStatusCode.Forbidden].includes(
          error.response?.status || 0
        )
      ) {
        return null;
      }
    }
    console.error(error);
    throw error;
  }
};

export const useUser = () => {
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [PEOPLE_API_URLS.user],
    queryFn: fetchUser,
  });

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
