import { FC, PropsWithChildren } from 'react';
import { FieldError, get, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';

import { InputWrapperProps } from './types';
import { helperTextStyle } from 'components/InputWrapper/InputWrapper.styles';

const InputWrapper: FC<PropsWithChildren<InputWrapperProps>> = ({
  children,
  name,
  sx,
  fullWidth = false,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  //not memoized, to avoid race condition preventing message access
  const errorText = (get(errors, name) as FieldError)?.message;

  return (
    <FormControl sx={sx} error={!!get(errors, name)} fullWidth={fullWidth}>
      {children}
      <FormHelperText sx={helperTextStyle}>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default InputWrapper;
