import { memo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import NotFound from 'pages/NotFound';
import Offer from 'pages/Offer';
import Profile from 'pages/Profile';
import Kudos from 'pages/Kudos';
import MyKudos from 'pages/MyKudos';
import { RouterPaths } from './types';
import ProfileProvider from 'contexts/Profile/ProfileProvider';
import OfferProvider from 'contexts/Offer/OfferProvider';
import Photos from 'pages/Photos';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Documents from 'pages/Documents';
import ProjectsDepartmentsProvider from 'contexts/ProjectsDepartments/ProjectsDepartmentsProvider';
import { HolidayPhotos } from 'pages/HolidayPhotos';
import RoomReservation from 'pages/RoomReservation';
import RoomReservationProvider from 'contexts/RoomReservation/RoomReservationProvider';
import DeskReservation from 'pages/DeskReservation';
import DeskProvider from 'contexts/DeskReservation/DeskProvider';
import ProjectsDepartments from 'pages/ProjectsDepartments';
import HolidayCalendar from 'pages/HolidayCalendar/HolidayCalendar';
import { SentKudosProvider } from 'contexts/SentKudos/SentKudosProvider';

const Router = memo(() => (
  <BrowserRouter>
    <Routes>
      <Route
        path={RouterPaths.home}
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.profile}
        element={
          <PrivateRoute>
            <ProfileProvider>
              <Profile />
            </ProfileProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.kudos}
        element={
          <PrivateRoute>
            <SentKudosProvider>
              <Kudos />
            </SentKudosProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.myKudos}
        element={
          <PrivateRoute>
            <SentKudosProvider>
              <MyKudos />
            </SentKudosProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.sentKudos}
        element={
          <PrivateRoute>
            <SentKudosProvider>
              <MyKudos />
            </SentKudosProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.projectsDepartments}
        element={
          <PrivateRoute>
            <ProjectsDepartmentsProvider>
              <ProjectsDepartments />
            </ProjectsDepartmentsProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.sendPhoto}
        element={
          <PrivateRoute>
            <Photos />
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.holidayImages}
        element={
          <PrivateRoute>
            <HolidayPhotos />
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.roomReservation}
        element={
          <PrivateRoute>
            <RoomReservationProvider>
              <RoomReservation />
            </RoomReservationProvider>
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.documents}
        element={
          <PrivateRoute>
            <Documents />
          </PrivateRoute>
        }
      />
      <Route
        path={RouterPaths.deskReservation}
        element={
          <PrivateRoute>
            <RoomReservationProvider>
              <DeskProvider>
                <DeskReservation />
              </DeskProvider>
            </RoomReservationProvider>
          </PrivateRoute>
        }
      />
      <Route path={RouterPaths.login} element={<Login />} />
      <Route
        path={RouterPaths.offer}
        element={
          <OfferProvider>
            <Offer />
          </OfferProvider>
        }
      />
      <Route path={RouterPaths.notFound} element={<NotFound />} />
      <Route
        path={RouterPaths.notFound}
        element={<Navigate to={RouterPaths.notFound} />}
      />
      <Route
        path={RouterPaths.holidayCalendar}
        element={
          <PrivateRoute>
            <HolidayCalendar />
          </PrivateRoute>
        }
      />
    </Routes>
  </BrowserRouter>
));

Router.displayName = 'Router';

export default Router;
