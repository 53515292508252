import { COLORS } from 'theme/Theme.const';

const styles = {
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: {
      Desktop: `1.5fr 1.5fr 2fr 0.5fr`,
      Tablet: `1.5fr 1fr 1.5fr 0.5fr`,
      Mobile: '1fr',
    },
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '1rem',
    rowGap: '1rem',
    padding: '1.2rem',
    borderBottom: `1px solid ${COLORS.gray[200]}`,
  },
  actionButton: {
    borderRadius: 1.5,
    color: 'primary.light',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterButtons: {
    gap: '1rem',
    display: 'flex',
    marginRight: '1rem',
  },
};
export default styles;
