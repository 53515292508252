import { COLORS } from 'src/theme';

const styles = {
  container: {
    width: {
      Tablet: 'auto',
      Mobile: '380px',
    },
  },
  headerText: {
    width: '80%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  reservationTextContainer: {
    'borderBottom': `1px solid ${COLORS.gray[200]}`,
    '&:hover': {
      bgcolor: `${COLORS.gray[50]}`,
      cursor: 'pointer',
    },
    'padding': '1rem',
  },
  reservationContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  bottomText: {
    marginLeft: '2.5rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
};

export default styles;
