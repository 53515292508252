import { FC } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { RatingRowProps } from './RatingRow.types';
import Rating from 'components/Rating';
import { ATTITUDE_LABELS, LEVEL_LABELS } from './RatingRow.consts';
import styles from './RatingRow.styles';
import useConfirmationModal from 'hooks/useConfirmationModal/useConfirmationModal';

const RatingRow: FC<RatingRowProps> = ({
  label,
  level,
  setLevel,
  attitude,
  setAttitude,
  onDeleteHandler,
  disabled = false,
}) => {
  const { ConfirmationDialog, openConfirmationModal } = useConfirmationModal({
    acceptHandler: onDeleteHandler ?? (() => void 0),
    title: `Are you sure you want to delete skill: ${label}?`,
  });

  return (
    <>
      <ConfirmationDialog />
      <Typography variant="body1">{label}</Typography>
      <Rating
        disabled={disabled}
        rating={level}
        setRating={setLevel ?? (() => void 0)}
        hoverLabels={LEVEL_LABELS}
        sx={styles.disabled}
      />
      {attitude && (
        <Rating
          disabled={disabled}
          rating={attitude}
          setRating={setAttitude ?? (() => void 0)}
          hoverLabels={ATTITUDE_LABELS}
          sx={styles.disabled}
        />
      )}
      {onDeleteHandler && (
        <IconButton
          disabled={disabled}
          sx={styles.icon}
          onClick={openConfirmationModal}
        >
          <Close fontSize="inherit" sx={styles.icon} />
        </IconButton>
      )}
    </>
  );
};

export default RatingRow;
