import { useEffect, useMemo, useState } from 'react';
import useDesk from 'hooks/useDesk';
import useProfileList from 'hooks/useProfileList';
import { mapProfileListToOption } from 'pages/Kudos/components/SendKudosModal/SendKudosModal.helpers';
import useDesks from 'hooks/useDesks';

const useEditDesk = () => {
  const { desk } = useDesk();
  const { data } = useProfileList(true);
  const [switchState, setSwitchState] = useState(desk?.isHotdesk || false);
  const { allDesks } = useDesks();
  const ownersIds = useMemo(
    () => allDesks?.filter((desk) => desk.owner).map((desk) => desk.owner),
    [allDesks]
  );

  const userOptions = useMemo(() => {
    const filteredUsers = data?.filter(({ id }) =>
      ownersIds?.every((ownerId) => id !== ownerId)
    );
    const deskOwner = data?.find(({ id }) => id === desk?.owner);

    return deskOwner
      ? [...filteredUsers, deskOwner].map(mapProfileListToOption)
      : filteredUsers.map(mapProfileListToOption);
  }, [data, desk?.owner, ownersIds]);

  useEffect(() => {
    if (desk) {
      setSwitchState(desk.isHotdesk);
    }
  }, [desk]);

  const toggleSwitch = () => {
    setSwitchState(!switchState);
  };

  return {
    switchState,
    toggleSwitch,
    userOptions,
  };
};

export default useEditDesk;
