import { useMutation } from '@tanstack/react-query';
import { apiDelete, apiPatch, apiPost } from 'api/axios';

import { enqueueSnackbar, SnackbarType } from 'components/Snackbars';
import {
  MutateUserContractDeleteProps,
  MutateUserContractPatchProps,
  MutateUserContractPostProps,
} from './useContracts.types';
import queryClient from 'api/queryClient';
import useActiveWorker from 'pages/Profile/hooks/useActiveWorker';
import { API_URLS } from 'api/axios/axios.consts';

export const useMutateUserContract = () => {
  const { userId } = useActiveWorker();

  const { mutateAsync: patchMutate } = useMutation({
    mutationFn: ({ contract }: MutateUserContractPatchProps) =>
      apiPatch(`${API_URLS.contracts(userId)}${contract.id}/`, contract),
    onSuccess: () => {
      enqueueSnackbar('Contract successfully changed', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.contracts(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot edit contract', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: ({ contractId }: MutateUserContractDeleteProps) =>
      apiDelete(`${API_URLS.contracts(userId)}${contractId}/`),
    onSuccess: () => {
      enqueueSnackbar('Contract successfully deleted', {
        type: SnackbarType.info,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.contracts(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot delete contract', {
        type: SnackbarType.error,
      });
    },
  });

  const { mutateAsync: postMutate } = useMutation({
    mutationFn: ({ contract }: MutateUserContractPostProps) =>
      apiPost(API_URLS.contracts(userId), contract),
    onSuccess: () => {
      enqueueSnackbar('Contract successfully added', {
        type: SnackbarType.success,
      });
      queryClient.invalidateQueries({
        queryKey: [API_URLS.contracts(userId)],
      });
    },
    onError: () => {
      enqueueSnackbar('Cannot add new contract', {
        type: SnackbarType.error,
      });
    },
  });

  return {
    contractPatchMutate: patchMutate,
    contractDeleteMutate: deleteMutate,
    contractPostMutate: postMutate,
  };
};

export default useMutateUserContract;
