import { Typography } from '@mui/material';
import { memo } from 'react';
import { DEFAULT_EMPTY_STRING } from '../Profile.const';

const EmptySection = memo(() => (
  <Typography variant="body1">{DEFAULT_EMPTY_STRING}</Typography>
));

EmptySection.displayName = 'EmptySection';

export default EmptySection;
