import { memo, useCallback, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';

import formStyles from 'common/styles/forms';
import { capitalizeString } from 'utils/string';
import { FormLanguage, ProfileForm } from '../../EditProfileDialog.types';
import { LANGUAGE_LEVELS, LANGUAGE_OPTIONS } from './LanguageForm.consts';
import LanguageChip from './LanguageChip';
import AutoCompleteInput from 'components/AutoCompleteInput';
import SelectInput from 'components/SelectInput';

const LanguagesForm = memo(() => {
  const methods = useFormContext<ProfileForm>();

  const { control } = methods;

  const ref = useRef<HTMLInputElement>();

  const languages = methods.watch('languages');
  const level = methods.watch('level');
  const language = methods.watch('language');

  const addHandler = useCallback(
    (onChange: (value: FormLanguage[]) => void, value: FormLanguage[]) => {
      if (!level?.length || !language) return;
      ref.current?.focus();

      const { label, value: langValue } = language;

      onChange([
        ...value,
        {
          id: `${langValue}`,
          level,
          name: capitalizeString(label),
        },
      ]);

      methods.setValue('language', null);
      methods.setValue('level', null);
    },
    [language, level, methods]
  );

  const languageOptions = useMemo(
    () =>
      LANGUAGE_OPTIONS.filter(
        ({ value }) => !languages.some(({ id }) => value === id)
      ),
    [languages]
  );

  return (
    <Controller<ProfileForm, 'languages'>
      control={control}
      name="languages"
      render={({ field: { value, onChange } }) => (
        <Box sx={formStyles.section}>
          <Typography sx={{ mb: 2.5 }} variant="sub1">
            Languages
          </Typography>
          <Box sx={formStyles.addForm}>
            {value.map(({ name, level }, i) => (
              <LanguageChip
                key={`languageChip-${i}`}
                label={`${name} ${level}`}
                onClick={() => {
                  onChange(
                    value.filter((currentValue) => currentValue.name !== name)
                  );
                }}
              />
            ))}
          </Box>
          <Box sx={formStyles.addInputs}>
            <AutoCompleteInput
              fullWidth
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={languageOptions}
              label="Language"
              name="language"
            />
            <SelectInput options={LANGUAGE_LEVELS} name="level" label="Level" />
            <Button
              variant="contained"
              disabled={!level?.length || !language}
              onClick={() => addHandler(onChange, value)}
              sx={formStyles.addButton}
            >
              Add
            </Button>
          </Box>
        </Box>
      )}
    />
  );
});

LanguagesForm.displayName = 'LanguagesForm';

export default LanguagesForm;
