import { useContext } from 'react';

import useProfile from 'hooks/useProfile/useProfile';
import useProfileList from 'hooks/useProfileList/useProfileList';
import ProfileContext from 'contexts/Profile/ProfileContext';

import WorkersList from '../WorkersList/WorkersList';

import DisplayWrapper from 'components/DisplayWrapper';
import WorkerSummary from '../WorkerSummary';
import { ProfileModalType } from 'contexts/Modal/ModalContext';
import Accordion from 'components/Accordion';
import UserExperienceInformation from '../UserExperienceInformation';
import CompanyAdminVisibleDetails from '../CompanyAdminVisibleDetails';

const AdminProfile = () => {
  const { activeUser, setActiveUser } = useContext(ProfileContext);
  const { data: workerData } = useProfile();
  const { data: workersData } = useProfileList();

  return (
    <>
      <WorkersList
        label="Select a profile to edit:"
        workers={workersData}
        selectedWorker={activeUser}
        setSelectedWorker={(id) => setActiveUser(id)}
      />
      <DisplayWrapper modalToOpen={ProfileModalType.EditProfile}>
        <WorkerSummary workerDetails={workerData} />
      </DisplayWrapper>
      <Accordion
        summary="User experience information"
        details={<UserExperienceInformation />}
      />
      {process.env.NODE_ENV !== 'production' && (
        <Accordion
          summary="User contract and holiday information"
          details={<CompanyAdminVisibleDetails />}
        />
      )}
    </>
  );
};

export default AdminProfile;
