import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

const listOptions = {
  HTMLAttributes: {
    style: 'padding-left: 1.5rem',
  },
};

const extensions = [
  StarterKit.configure({
    italic: {
      HTMLAttributes: {
        // Without that it's just setting it as <em>
        style: 'font-style: italic',
      },
    },
    bulletList: listOptions,
    orderedList: listOptions,
  }),
  Underline,
];

export default extensions;
