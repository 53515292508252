import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { ProjectsDepartmentsDetailsGetResponse } from 'api/types/projectsDepartments';

export const fetchDepartment = (
  context: QueryFunctionContext<[string, number]>
) => {
  const [, id = ''] = context.queryKey;

  return apiGet<ProjectsDepartmentsDetailsGetResponse>(
    `${API_URLS.projectsDepartments}?department=${id}`
  );
};

const useDepartment = (id: number, enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_URLS.projectsDepartments, id],
    queryFn: fetchDepartment,
    enabled,
  });

  return {
    departmentData: data?.data,
    isLoadingDepartment: isLoading,
    refetchDepartment: refetch,
  };
};

export default useDepartment;
