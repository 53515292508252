import { SxStylesheet } from 'theme/index';

const styles: SxStylesheet = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: {
      Tablet: '1fr 1fr',
      Mobile: '1fr',
    },
    gap: '1.5rem',
    paddingX: {
      Desktop: '6rem',
      Mobile: '1rem',
    },
  },
  button: {
    height: '5rem',
    fontSize: 20,
  },
};

export default styles;
