import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { MdOutlineCalendarToday, MdPerson } from 'react-icons/md';
import styles from './OtherUserReservedRoom.styles';
import DisplayWrapper from 'components/DisplayWrapper';
import { COLORS } from 'theme/Theme.const';
import useOtherUserRoomReservation from 'hooks/useRoomReservations/useOtherUserRoomReservation';

const OtherUserReservedRoom: FC = () => {
  const { bookerData, formattedDate } = useOtherUserRoomReservation();

  return (
    <DisplayWrapper>
      <Box sx={styles.box}>
        <Typography variant={'h1'}>Zarezerwowany pokój</Typography>
        <Box sx={styles.innerBox}>
          <MdPerson color={COLORS.blue[600]} size={22} />
          <Typography>{bookerData?.title}</Typography>
        </Box>
        <Box sx={styles.innerBox}>
          <MdOutlineCalendarToday color={COLORS.blue[600]} size={20} />
          <Typography>{formattedDate}</Typography>
        </Box>
      </Box>
    </DisplayWrapper>
  );
};

export default OtherUserReservedRoom;
