import { useQuery } from '@tanstack/react-query';
import { API_URLS } from 'api/axios/axios.consts';
import { apiGet } from 'api/axios';
import { DeskDetails } from 'api/types/deskReservation';
import { useContext } from 'react';
import DeskContext from 'contexts/DeskReservation/DeskContext';
import { Nullable } from 'common/types';

const fetchDesk = (activeDesk: Nullable<number>) => {
  return apiGet<DeskDetails>(`${API_URLS.desks}${activeDesk}/`);
};

const useDesk = () => {
  const { activeDesk } = useContext(DeskContext);

  const {
    data: deskData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [API_URLS.desks, `activeDesk=${activeDesk}`],
    queryFn: () => fetchDesk(activeDesk),
    enabled: !!activeDesk,
  });

  return {
    isLoading,
    desk: deskData?.data,
    refetchDesk: refetch,
  };
};

export default useDesk;
