import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';

export const capitalizeDayName = (dayOfWeek: string) =>
  dayOfWeek ? dayOfWeek.charAt(0).toUpperCase() + dayOfWeek?.slice(1) : '';

export const getFormattedDate = (date: Date) => {
  const day = date.getDate();
  const month = format(date, 'LLLL', { locale: pl });
  const year = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  const dayOfWeek = format(date, 'EEEE', { locale: pl });
  const formattedDayOfWeek = capitalizeDayName(dayOfWeek);
  const formattedDay = format(date, 'dd');
  const formattedMonth = format(date, 'MM');

  return {
    day,
    month,
    year,
    formattedDayOfWeek,
    monthNumber,
    formattedDay,
    formattedMonth,
  };
};

export const formatDate = (myReservationDate: string) => {
  const date = parseISO(myReservationDate);
  const day = date.getDate();
  const month = format(date, 'LLLL', { locale: pl });
  const year = date.getFullYear();
  const dayOfWeek = format(date, 'EEEE', { locale: pl });
  const formattedDayOfWeek = capitalizeDayName(dayOfWeek);

  return `${formattedDayOfWeek}, ${day} ${month} ${year}`;
};
